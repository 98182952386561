
/** ------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export class AddAlternativeInput {
    component_id: number;
    user_id: number;
}

export class RemoveAlternativeInput {
    component_id?: number;
    user_id?: number;
    rowNum?: number;
}

export class UpdateComponentSubInput {
    component_id?: number;
    user_id?: number;
    rowNum?: number;
    part_number?: string;
    subIndex?: number;
    quantity?: number;
}

export class AddComponentSizeInput {
    size_desc_imp?: string;
    size_desc_m3?: string;
}

export class AddCustomItemInput {
    partnumber_us?: string;
    style?: string;
    size_code?: string;
    gasket_type?: string;
    style_desc?: string;
    component_desc?: string;
    price_usd?: number;
    price_eur?: number;
    price_gbp?: number;
    price_jpy?: number;
    active?: number;
    weight?: number;
    group_id?: number;
    labor_mcaa?: number;
}

export class DeleteComponentImageInput {
    component_id: number;
    image_id: number;
}

export class UpdateComponentSizeInput {
    size_desc_imp?: string;
    size_desc_m3?: string;
    size_code?: string;
}

export class UpdateComponentInput {
    component_id?: number;
    gasket_type?: string;
    style_desc?: string;
    component_desc?: string;
    component_desc_m3?: string;
    size_code?: string;
    weight?: number;
    style?: string;
    group_id?: number;
    labor_mcaa?: number;
    price_usd?: number;
    price_eur?: number;
    price_gbp?: number;
    price_jpy?: number;
    active?: number;
}

export class CopyPartNumberLinkInput {
    component_id?: number;
    rev_size?: string;
    rev_desc?: string;
    profile_id?: number;
    user_id?: number;
}

export class DeletePartNumberLinkInput {
    link_id?: number;
}

export class AddPartNumberProfileInput {
    profile: string;
    profile_desc?: string;
    user_id?: number;
}

export class UpdatePartNumberProfileInput {
    profile_id?: number;
    profile: string;
    profile_desc?: string;
}

export class CopyProjectPartNumberLinkInput {
    component_id?: number;
    rev_size?: string;
    rev_desc?: string;
    project_profile_id?: number;
    user_id?: number;
}

export class DeleteProjectPartNumberLinkInput {
    link_id?: number;
}

export class AddProjectProfileInput {
    project_profile: string;
    project_profile_desc?: string;
    user_id?: number;
    proj_id: number;
}

export class UpdateProjectProfileInput {
    proj_id: number;
    project_profile_id: number;
    project_profile: string;
    project_profile_desc?: string;
}

export class UpdateSalesmanMarkupsInput {
    salesman_markup_id?: number;
    salesmanID: string;
    offeringid: number;
    dist_markup: number;
    cust_markup: number;
}

export class UpdateSalesmanMultipliersInput {
    salesmanID?: string;
    groupMultipliers?: SalesmanMultiplierInput[];
}

export class SalesmanMultiplierInput {
    group_id?: number;
    value?: number;
    salesman_multiplier_id?: number;
}

export class UpdateSalesmanInput {
    salesregion_id?: string;
    metroID?: string;
    groupID?: number;
    divisionID?: string;
    reports_to?: string;
    email?: string;
    salesmanID?: string;
    inactive?: number;
}

export class ApproveChangeOrderInput {
    proj_id: number;
    projid: number;
    coordinator_id: number;
    areaid: number;
    change_order_id: number;
    quoteid: number;
    user_id: number;
    supervisor_id: number;
    change_order_name: string;
    change_order_description: string;
    change_order_drawing_num?: string;
    preChangeOrderBom: ChangeOrderBom[];
    postChangeOrderBom: ChangeOrderBom[];
    bimHoursData: BimHourDataInput[];
    changeOrderNotes?: ChangeOrderNoteInput[];
    changeOrderSuggestedValues?: ChangeOrderSuggestedValueInput[];
    finances: FinancesInput[];
    financesHours: FinancesHoursInput[];
    isInternal?: boolean;
    withSuggestedValues?: boolean;
    isAdmin?: boolean;
}

export class DeclineChangeOrderInput {
    proj_id: number;
    change_order_id: number;
    coordinator_id: number;
    areaid: number;
    user_id: number;
    supervisor_id: number;
}

export class SubmitChangeOrderRevisionInput {
    proj_id: number;
    projid: number;
    user_id: number;
    supervisor_id: number;
    change_order_id: number;
    quoteid: number;
    areaid: number;
    preChangeOrderBom: ChangeOrderBom[];
    postChangeOrderBom: ChangeOrderBom[];
    bimHoursData: BimHourDataInput[];
    changeOrderNotes?: ChangeOrderNoteInput[];
    finances: FinancesInput[];
    financesHours: FinancesHoursInput[];
    changeOrderSuggestedValues?: ChangeOrderSuggestedValueInput[];
}

export class SubmitChangeOrderInput {
    proj_id: number;
    projid: number;
    user_id: number;
    supervisor_id: number;
    change_order_name: string;
    change_order_description: string;
    change_order_drawing_num?: string;
    preChangeOrderBom: ChangeOrderBom[];
    postChangeOrderBom: ChangeOrderBom[];
    bimHoursData: BimHourDataInput[];
    changeOrderNotes?: ChangeOrderNoteInput[];
    finances: FinancesInput[];
    financesHours: FinancesHoursInput[];
    changeOrderSuggestedValues?: ChangeOrderSuggestedValueInput[];
}

export class FinancesInput {
    offeringid: number;
    count?: number;
    value: number;
    dist_markup: number;
    cust_markup: number;
}

export class FinancesHoursInput {
    finance_hours_type_id: number;
    value: number;
}

export class ChangeOrderSuggestedValueInput {
    offeringid: number;
    value: number;
}

export class ChangeOrderNoteInput {
    note_id?: number;
    change_order_id?: number;
    finance_revision_id?: number;
    user_id?: number;
    note?: string;
}

export class UpdateChangeOrderBasicInfoInput {
    user_id: number;
    proj_id: number;
    change_order_id: number;
    change_order_name: string;
    change_order_description: string;
    change_order_drawing_num?: string;
}

export class ChangeOrderPreviewInput {
    proj_id: number;
    isFabJob: boolean;
    region_id: number;
    preChangeOrderBom: ChangeOrderBom[];
    postChangeOrderBom: ChangeOrderBom[];
    bimHoursData: BimHourDataInput[];
    quoteid?: number;
    isAdmin?: boolean;
    isRevision?: boolean;
}

export class ChangeOrderBom {
    revision_id: number;
    revision_name?: string;
    system?: string;
    system_id: number;
}

export class BimHourDataInput {
    bim_hour_type_id: number;
    value: number;
}

export class BimAndCoObjectInput {
    Id?: string;
    UpdatedAt?: string;
    CreatedAt?: string;
    Name?: string;
    Description?: string;
    DefaultPhoto?: string;
    Catalogs?: BimAndCoCatalogInput[];
    Photos?: BimAndCoPhotosInput[];
}

export class BimObjectInput {
    Id?: string;
    Name?: string;
    Photo?: string;
    Catalog?: BimAndCoCatalogInput;
}

export class BimAndCoCatalogInput {
    Id?: string;
    Name?: string;
}

export class UpdateObjectCatalogsInput {
    Objects?: UpdateObjectCatalogObjectInput[];
}

export class UpdateObjectCatalogObjectInput {
    ObjectId?: string;
    OldCatalogIds?: string[];
    NewCatalogIds?: string[];
}

export class AddBimAndCoObjectPhotosInput {
    objectIds?: string[];
    photoUrls?: string[];
    fileNames?: string[];
}

export class BimAndCoPhotosInput {
    Id?: string;
    FileName?: string;
    IsDefault?: string;
    Path?: string;
}

export class DeleteBimAndCoObjectPhotosInput {
    objectIds?: string[];
    photoIds?: string[];
}

export class SetDefaultBimAndCoPhotoInput {
    objectId?: string[];
    imageId?: string[];
}

export class ModelProperties {
    ModelId?: string;
    ModelLevelOfDetail?: string;
    ModelTypeFormat?: string;
    ModelSoftwareType?: string;
}

export class UpdateModelPropertiesInput {
    ObjectId?: string;
    Models?: ModelProperties[];
}

export class UpdateObjectPropertiesInput {
    ObjectIds?: string[];
    File?: string;
    FileName?: string;
    DistributionCountries?: string;
    FileType?: string;
}

export class RemoveSpotConnectionsInput {
    ObjectName?: string;
    Permalink?: string;
}

export class SpotRecordInput {
    SpotDescription?: string;
    Permalink?: string;
    Style?: string;
}

export class UpdateObjectModelPropertiesInput {
    ObjectId?: string;
    SoftwareName?: string;
    ModelId?: string;
    ModelName?: string;
    SoftwareId?: string;
    ModelLevelOfDetail?: string;
    ModelTypeFormat?: string;
}

export class UpdateObjectSpotConnectionInput {
    ObjectName?: string;
    ObjectBimAndCoId?: string;
    SpotRecords?: SpotRecordInput[];
}

export class UpdateSpotDataInput {
    ObjectName?: string;
    ObjectPermalink?: string;
    FilesToUpdate?: UpdateSpotFileInput[];
}

export class UpdateSpotFileInput {
    SoftwareName?: string;
    FileDirectory?: string;
    Timestamp?: string;
}

export class UpdateObjectSubmittalsInput {
    ObjectId?: string;
    Permalinks?: string[];
}

export class UpdateSpotSoftwareInput {
    BimObjectId?: string;
    SoftwareRecords?: UpdateSpotSoftwareRecordInput[];
}

export class UpdateSpotSoftwareRecordInput {
    FilePath?: string;
    PrimeSoftwareId?: string;
    SpotTimestamp?: string;
}

export class UpdateObjectNameInput {
    Id?: string;
    NewName?: string;
}

export class CreateNewProjectAreaInput {
    projid: number;
    areaName: string;
}

export class CreateNewMasterQuoteFieldInput {
    field: string;
    man_hours?: number;
    totalled?: number;
    section_id?: number;
    worksheet_id?: number;
}

export class CreateNewPhaseQuoteInput {
    phase_id: number;
    quote_name: string;
    worksheet_id?: number;
    drawing_numbers?: string;
    drawing_revision?: string;
    limits?: string;
    rate?: number;
    rate_calc?: number;
}

export class DeleteQuoteInput {
    phase_id: number;
    quote_id: number;
}

export class InsertQuoteScheduleRecordInput {
    service_type?: string;
    mbe_status?: string;
    mbe_planned_completion_date?: string;
    quote_status?: string;
    requested_date_sales?: string;
    mbe_requested_completion_date?: string;
    requested_sales_date_changed?: string;
    kkto_status?: string;
    mbe_resource_start_date?: string;
    updated_by?: string;
    koil_kit_resource?: string;
    vdc_resource?: string;
    quote_resource?: string;
}

export class UpdateActiveQuoteFieldInput {
    quote_id: number;
    quote_field_id: number;
    active: number;
}

export class UpdateEstimatingHoursInput {
    hfm_id: number;
    hours: number;
    area_id: number;
}

export class UpdateBaseQuoteFieldsInput {
    quote_field_id: number;
    field: string;
    man_hours: number;
    section_id: number;
    totalled: number;
    worksheet_id: number;
}

export class UpdateFieldManualInput {
    quote_field_id: number;
    manual: number;
}

export class UpdateAreaPhaseInput {
    area_id?: number;
    phase_id?: number;
    market?: string;
    name?: string;
    is_active?: number;
}

export class UpdateProjectHoursFieldsInput {
    fill_all: boolean;
    delete: boolean;
    delete_all?: boolean;
    projid: number;
    hfm_id?: number;
}

export class UpdateProjectOfferingsInput {
    delete: boolean;
    projid: number;
    offeringid: number;
    proj_offering_id?: number;
}

export class UpdateQuoteFieldDataInput {
    quote_id: number;
    phase_id: number;
    quote_field_id?: number;
    quote_field_master_id?: number;
    active?: number;
    manual?: number;
    units?: number;
    man_hours?: number;
    calc_value?: number;
    calc_cost?: number;
}

export class UpdateQuoteWorksheetIdInput {
    worksheet_id: number;
    quote_id: number;
}

export class UpdateRiserOffsetInput {
    quote_id: number;
    phase_id: number;
    riser_offset: number;
}

export class UpdateUnitsManuallyInput {
    quote_field_id: number;
    units: number;
    calc_value: number;
    calc_cost: number;
}

export class QuoteFieldRecordInput {
    quote_field_id?: number;
    quote_field_master_id?: number;
    quote_id?: number;
    units?: number;
    section_id?: number;
    field_name?: string;
    man_hours?: number;
    active?: number;
    manual?: number;
    calc_cost?: number;
    calc_value?: number;
}

export class AutoPopulateAreaQuotesInput {
    proj_id: number;
    isRetrofit: boolean;
}

export class CreateNewAreaPhaseInput {
    area_id: number;
    name: string;
    market: string;
}

export class ResetQuoteEquipmentInput {
    proj_id: number;
    quote_id: number;
}

export class UpdateEstimatingAreaInput {
    areaid?: number;
    area?: string;
    changeorder?: number;
    estimate?: number;
    marked_for_delete?: boolean;
    schedule_impact?: number;
    status?: string;
    status_id?: number;
    updated_po?: number;
}

export class UpdateQuoteBaseInfoInput {
    quote_id: number;
    phase_id: number;
    quote_name?: string;
    drawing_numbers?: string;
    drawing_revision?: string;
    limits?: string;
    rate?: number;
    rate_calc?: number;
}

export class AddAdditionalFinancesOfferingInput {
    proj_id: number;
    user_id?: number;
    quote_id: number;
    finance_revision_id: number;
    offeringid: number;
}

export class AddFinancesRevisionInput {
    proj_id: number;
    user_id: number;
    quote_id: number;
    finance_revision_name: string;
}

export class DeleteFinancesRevisionInput {
    finance_revision_id: number;
    quote_id: number;
    proj_id: number;
    user_id: number;
    deleteBomRevision: boolean;
}

export class EditFinancesRevisionInput {
    proj_id: number;
    user_id: number;
    quote_id: number;
    finance_revision_name: string;
    finance_revision_id: number;
}

export class FetchLatestDataInput {
    proj_id: number;
    currency?: string;
    user_id?: number;
    quote_id?: number;
    finance_revision_id?: number;
    isFabJob?: boolean;
    market_id?: number;
    system_id?: number[];
    revision_id?: number[];
}

export class MarkFinancesRevisionSoldInput {
    user_id: number;
    quote_id: number;
    finance_revision_id: number;
    proj_id: number;
}

export class RemoveAdditionalFinancesOfferingInput {
    projid: number;
    proj_id: number;
    offeringid: number;
    finance_data_id: number;
    user_id: number;
    quote_id: number;
    finance_revision_id: number;
    isTransBreakdown?: boolean;
}

export class RestoreFinancesRevisionInput {
    proj_id: number;
    user_id: number;
    quote_id: number;
    finance_revision_id: number;
}

export class RevisionMultipleQuotesInput {
    proj_id: number;
    user_id: number;
    quote_ids: number[];
    finance_revision_name: string;
}

export class SaveFinancesInputParams {
    finance_revision_id?: number;
    proj_id?: number;
    quote_id?: number;
    offeringid?: number;
    count?: number;
    buffer?: number;
    value?: number;
    dist_markup?: number;
    cust_markup?: number;
}

export class SetFinancesCompleteInput {
    proj_id: number;
    user_id: number;
}

export class UpdateFinancesHoursInput {
    finance_hours_id: number;
    value: number;
    proj_id?: number;
    user_id?: number;
    quote_id?: number;
    finance_revision_id?: number;
}

export class UpdateFinancesItemInput {
    finance_data_id: number;
    property: string;
    value: number;
    proj_id: number;
    user_id?: number;
    quote_id: number;
    finance_revision_id?: number;
    offeringid?: number;
    isMatBreakdown?: boolean;
    isTransBreakdown?: boolean;
}

export class GetFinancesInput {
    proj_id: number;
    user_id?: number;
    quote_id: number;
    finance_revision_id: number;
    offeringid?: number[];
}

export class AddAppliedProjectNoteTypeInput {
    note_id: number;
    note_type_id: number;
}

export class AddCustomerContactInput {
    proj_id: number;
    cell_phone?: string;
    company_name?: string;
    contact_type: string;
    email?: string;
    name?: string;
    office_phone?: string;
    title?: string;
    address?: string;
}

export class AddNewProjectInput {
    project_name: string;
    proj_type_id: number;
    proj_type: string;
    additionalProjectType: number;
    status: string;
    offeringid?: number[];
    requested_date_sales: string;
    customer_id?: number;
    region_id: number;
    region: string;
    currency?: string;
    user_id: number;
    bid_date?: string;
    post_bid: number;
    probabilityOfSale?: string;
    autoID?: number;
    salesregion_id?: string;
    metroID?: string;
    group_id?: number;
    divisionID?: string;
    project_long: number;
    project_lat: number;
    project_address?: string;
    city?: string;
    state?: string;
    country?: string;
    project_location?: string;
    warehouse_one?: string;
    warehouse_two?: string;
    warehouse_three?: string;
    market_id: number;
    market: string;
    distributor?: string;
    price_list_id?: number;
    nda_required: number;
    legislativeActs?: string[];
}

export class AddProjectNoteInput {
    projid: number;
    note: string;
    user_id: number;
    note_type_id: number[];
}

export class UpdateProjectTeamMemberInput {
    user_id: number;
    proj_id: number;
}

export class DeleteAppliedProjectNoteTypeInput {
    note_type_applied_id: number;
    note_id: number;
}

export class DeleteCustomerContactInput {
    _id: string;
    proj_id: number;
}

export class DeleteProjectNoteInput {
    note_id: number;
    projid: number;
}

export class SetProjectInactiveInput {
    proj_id: number;
}

export class UpdateCustomerContactInput {
    proj_id: number;
    _id?: string;
    cell_phone?: string;
    company_name?: string;
    contact_type?: string;
    email?: string;
    name?: string;
    office_phone?: string;
    title?: string;
    address?: string;
}

export class UpdateProjectInfoViewInput {
    biddate?: string;
    current_user_id?: number;
    customer_id?: number;
    current_project_type?: number;
    cpsservice?: string;
    currency?: string;
    datesubmitted?: string;
    distributor?: string;
    distributor_id?: string;
    employeeid?: number;
    dom_man?: string;
    dom_note?: string;
    engineerid?: number;
    market_id?: number;
    order_number?: string;
    postbid?: number;
    probofsale?: string;
    proj_id: number;
    project_name?: string;
    proj_num?: string;
    project_type_id?: number;
    proj_type?: number;
    price_list_id?: number;
    po_date?: string;
    poaa?: string;
    poaa_date?: string;
    salesuser_id?: number;
    union_notunion?: string;
    nda_required?: number;
    user_id?: number;
    worksheet_date?: boolean;
}

export class UpdateProjectLocationInput {
    proj_id: number;
    address?: string;
    city?: string;
    country?: string;
    region_id?: number;
    regioncode?: string;
    state?: string;
    longitude: number;
    latitude: number;
}

export class UpdateProjectNoteInput {
    projid: number;
    note: string;
    user_id: number;
    note_id: number;
}

export class UpdateProjectStatusDateInput {
    status_id: number;
    status_date: string;
    proj_id: number;
}

export class ProjectStatusUpdateInput {
    proj_id?: number;
    projid?: number;
    status?: string;
    reason_id?: string;
    status_date?: string;
    current_project_type?: string;
}

export class UpdateProjectTypeInput {
    proj_id: number;
    project_type_id: number;
    user_id?: number;
    current_user_id?: number;
}

export class ProjectSearchInput {
    coordinator?: number;
    customer?: number;
    project_status?: string;
    project_type?: number;
    project_name_or_number?: string;
    region?: number;
    salesman?: number;
    team_leader?: number;
    market?: number;
    additional_project_type?: number;
    service?: string;
}

export class UpdateProjectBimBaseInput {
    proj_id: number;
    drawing_percent_complete?: number;
    customer_care_id?: number;
    ph_status_id?: number;
    purchase_order_date?: string;
    contractor_turnover_date?: string;
    closeout_meeting_date?: string;
}

export class AddNewHeaderInput {
    system_id: number;
    proj_id: number;
    user_id: number;
    region_id: number;
    header_id?: number;
    price_list_id: number;
    bag_tag?: string;
    system_abbr?: string;
    mark?: string;
    assembly_name?: string;
    area?: string;
    zone?: string;
    sequence?: string;
    pipe_size: string;
    outlet_size?: string;
    outlet_size2?: string;
    outlet_size3?: string;
    outlet_size4?: string;
    outlet_size5?: string;
    outlet_size6?: string;
    pipe_length_ft: number;
    pipe_length_in: number;
    outlet_count?: number;
    tap_count?: number;
    three_quarter_tap?: number;
    one_tap?: number;
    one_quarter_tap?: number;
    one_half_tap?: number;
    two_tap?: number;
    header_cost?: number;
    header_cost_ep?: number;
    header_coating_id?: number;
    header_lining_id?: number;
    header_material_id?: number;
}

export class AddNewProjectPricingInput {
    proj_id: number;
    partnumber?: string;
    price?: number;
    size?: string;
    description?: string;
    multiplier?: number;
    user_id: number;
}

export class ApplyProfileInput {
    system_id: number;
    profile_id: number;
    revision_id?: number;
    user_id: number;
    flip_id: number;
    proj_id: number;
}

export class ApplyProjectProfileInput {
    system_id: number;
    project_profile_id: number;
    revision_id?: number;
    user_id: number;
    flip_id: number;
    proj_id: number;
}

export class CreateProjectAncillariesInput {
    proj_id: number;
    user_id: number;
    systems?: AncillariesSystem[];
}

export class AncillariesSystem {
    system_id: number;
    system?: string;
    selected?: boolean;
    revisions?: AncillariesRevision[];
}

export class AncillariesRevision {
    revision_id: number;
    revision_name?: string;
    selected?: boolean;
    system_id: number;
    actual_sold?: boolean;
}

export class DeleteHeadersInput {
    proj_id: number;
    region_id: number;
    user_id: number;
    headers_id: number[];
}

export class DeleteMaterialInput {
    proj_id: number;
    system_id: number;
    revision_id: number;
    flip_id: number;
    price_list_id: number;
    region_id: number;
    bill_id: number[];
    user_id: number;
}

export class FlipComponentInput {
    substitutes?: ComponentSubstitutes[];
    input?: FlipSystemInput;
    component_id?: number;
    rowNum?: number;
    bill_id?: number;
}

export class ComponentSubstitutes {
    PARTCODE?: number;
    ALTERNATE?: number;
    VICSUB1?: number;
    VICSUB1QTY?: number;
    VICSUB2?: number;
    VICSUB2QTY?: number;
    VICSUB3?: number;
    VICSUB3QTY?: number;
    VICSUB4?: number;
    VICSUB4QTY?: number;
    VICSUB5?: number;
    VICSUB5QTY?: number;
    VICSUB6?: number;
    VICSUB6QTY?: number;
    VICSUB7?: number;
    VICSUB7QTY?: number;
    VICSUB8?: number;
    VICSUB8QTY?: number;
    VICSUB9?: number;
    VICSUB9QTY?: number;
    VICSUB10?: number;
    VICSUB10QTY?: number;
    VICSUB11?: number;
    VICSUB11QTY?: number;
    rowNum?: number;
    REGION_ID?: number;
}

export class FlipSystemInput {
    system_id: number;
    revision_id: number;
    proj_id: number;
    user_id: number;
    region_id: number;
    position?: number;
    price_list_id?: number;
    alwaysPrimary?: boolean;
}

export class GroupSystemsInput {
    checkedSystems: SystemInput[];
    system_id?: number;
    system?: string;
    proj_id: number;
    user_id: number;
    profile_id: number;
}

export class SystemInput {
    system_id: number;
    revision_id: number;
}

export class MapSystemsToQuotesInput {
    system_id: number;
    quoteid?: number;
    proj_id?: number;
}

export class MoveHeadersInput {
    system_id: number;
    proj_id: number;
    headers_id: number[];
    user_id: number;
    region_id: number;
}

export class MutateRevisionInput {
    revision_name?: string;
    revision_id?: number[];
    system_id: number[];
    user_id?: number;
    forissue?: number;
    latest_sold?: boolean;
    actual_sold?: boolean;
    proj_id: number;
}

export class MutateSystemInput {
    system?: string;
    system_id?: number[];
    system_desc?: string;
    quoteid?: number;
    gasket_id?: number;
    dwg?: string;
    area?: string;
    proj_id?: number;
    profile_id?: number;
    user_id?: number;
}

export class RemovePartNumberInput {
    proj_id: number;
    system_id: number;
    revision_id: number;
    price_list_id: number;
    region_id: number;
    flip_id: number;
    bill_id: number[];
    profile_id: number;
}

export class SetGroupMultipliersInput {
    proj_id: number;
    user_id: number;
    group_multipliers?: ComponentGroupInput[];
}

export class ComponentGroupInput {
    group_id?: number;
    value?: number;
    updated?: boolean;
}

export class SplitSystemsInput {
    system_id: number;
    proj_id: number;
    user_id: number;
    splitOptions: string[];
}

export class UpdateMaterialInput {
    proj_id: number;
    system_id: number;
    revision_id: number;
    price_list_id: number;
    region_id: number;
    flip_id: number;
    user_id?: number;
    bill_id?: number;
    component_id?: number;
    property_name?: string;
    property_value?: string;
}

export class UpdateProjectPricingInput {
    project_pricing_id: number;
    price?: number;
    multiplier?: number;
    partnumber?: string;
    size?: string;
    description?: string;
    user_id: number;
    proj_id: number;
}

export class UsePartNumberInput {
    proj_id: number;
    system_id: number;
    revision_id?: number;
    component_id: number;
    bill_id: number;
    group_id?: number;
    partnumber?: string;
    user_id: number;
    rev_desc?: string;
    rev_size?: string;
    profile_id?: number;
    project_profile_id?: number;
}

export class GetAlternativeBomInput {
    proj_id: number;
    system_id: number;
    revision_id: number;
    price_list_id: number;
    region_id: number;
}

export class GetAssemblyEquipmentInput {
    proj_id: number;
    assembly_name: string;
}

export class GetHeaderPriceInput {
    price_list_id: number;
    pipe_size: string;
    outlet_size?: string;
    outlet_size2?: string;
    outlet_size3?: string;
    outlet_size4?: string;
    outlet_size5?: string;
    outlet_size6?: string;
    pipe_length_ft: number;
    pipe_length_in: number;
    outlet_count?: number;
    tap_count?: number;
    region_id: number;
    header_coating_id?: number;
    header_material_id?: number;
    header_lining_id?: number;
}

export class GetPartNumbersOptionsInput {
    partnumber?: string;
    style?: string;
    rev_desc?: string;
    rev_size?: string;
    region_id?: number;
}

export class GetPredictedPartNumbersInput {
    size?: string;
    description?: string;
    user_id: number;
    region_id: number;
    system_id: number;
}

export class GetSystemRevisionMaterialListInput {
    proj_id: number;
    system_id: number;
    flip_id: number;
    revision_id: number;
    price_list_id: number;
    region_id: number;
    fromExport?: boolean;
}

export class AddNewCustomerInput {
    name: string;
    country?: string;
    city?: string;
    state?: string;
    user_id?: number;
    type_id?: number;
    market_id?: number;
}

export class UpdateQuoteDocumentInput {
    proj_id?: number;
    revision?: number;
    drawingInfo?: string;
    specInfo?: string;
    areaLimits?: AreaInput[];
    notes?: QuoteNoteInput[];
    user_id?: number;
    inclusions?: InclusionInput[];
    areaLimitsString?: string;
    pipingSystemsString?: string;
}

export class QuoteNoteInput {
    master_class?: string;
    sub_class?: string;
    value?: string;
}

export class AreaInput {
    areaid?: number;
    value?: string;
    selected?: number;
    equipments?: EquipmentInput[];
    dwg?: string;
    equipments_modified?: boolean;
}

export class InclusionInput {
    inclusion_id?: number;
    value?: number;
    default_value?: number;
}

export class EquipmentInput {
    mark?: string;
    type?: string;
    equipment_id?: number;
}

export class GetProjectSchedulesInput {
    project_name_or_number?: string;
    coordinator_ids?: number[];
    region_id?: number;
    vdc_project_type_id?: number;
    start_date?: string;
    end_date?: string;
    offset?: number;
}

export class AddNewSequenceInput {
    proj_id: number;
    template_id?: number;
    name?: string;
    order_number?: number;
    sequence_color?: string;
    sequence_notes?: string;
    fab_shop_id?: number;
    fab_contact_id?: number;
    description?: string;
}

export class AddSequenceDateInput {
    sequence_id: number;
    date_type_id: number;
    proj_id: number;
}

export class AddTemplateDatesInput {
    template_id: number;
    date_type_ids: number[];
}

export class AssignTruckInput {
    proj_id: number;
    sequence_id: number;
    logistic_ids: number[];
}

export class CopySequenceInput {
    sequence_id: number;
    proj_id: number;
    fab_shop_id?: number;
    fab_contact_id?: number;
    template_id?: number;
    description?: string;
}

export class CreateSequenceTemplateInput {
    name: string;
    proj_type_id?: number;
}

export class DeleteSequenceDateInput {
    sequence_date_id: number;
    proj_id: number;
}

export class DeleteSequenceInput {
    sequence_id: number;
    proj_id: number;
    proj_num: string;
}

export class DeleteTemplateDateInput {
    template_id: number;
    date_type_id: number;
}

export class SubmitFabricationRequestInput {
    sequence_id: number;
    proj_id: number;
    user_id: number;
    proj_num: string;
}

export class UnassignTruckInput {
    logistics_id: number;
    proj_id: number;
}

export class UpdateSequenceBaseInput {
    description?: string;
    is_complete?: boolean;
    sequence_id: number;
    name: string;
    sequence_color?: string;
    sequence_notes?: string;
    template_id?: number;
    proj_id: number;
    header_status?: string;
    iso_drop_status?: string;
    broker_id?: number;
    delivery_address?: string;
    order_number?: number;
    fab_shop_id?: number;
    fab_contact_id?: number;
    non_vic_fab_contact?: string;
    non_vic_fab_shop?: string;
    coating_type_id?: number;
    fab_comments?: string;
}

export class UpdateSequenceDateInput {
    sequence_date_id: number;
    date_value?: string;
    date_note?: string;
    proj_id: number;
    is_confirmed?: boolean;
}

export class UpdateSequenceLogisticsInput {
    logistics_id: number;
    truck_number: number;
    shipping_date: string;
    log_received_date?: string;
    proj_id: number;
    has_shipped?: boolean;
}

export class UpdateSequencesOrderInput {
    sequence_id: number;
    order_number: number;
}

export class UpdateTemplateBaseInput {
    name: string;
    template_id: number;
    active?: number;
}

export class AddVtfrTrainingContactInput {
    training_id?: number;
    contact_name?: string;
    contact_email?: string;
    contact_company?: string;
}

export class AddVtfrTrainingLogInput {
    user_id?: number;
    type_id?: number;
    duration_num?: number;
    duration_type?: string;
    customer_id?: number;
    salesman_id?: number;
    sales_division_id?: string;
    sales_region_id?: string;
    sales_metro_id?: string;
    sales_group_id?: number;
    bill_amt?: number;
    expenses?: number;
    currency?: string;
    notes?: string;
    t_market_id?: number[];
    c_type_id?: number;
    pres_date?: string;
    sold?: number;
    quote?: number;
    contacts?: AddVtfrTrainingContactInput[];
}

export class UpdateVtfrTrainingContactInput {
    contact_id?: number;
    training_id?: number;
    contact_name?: string;
    contact_email?: string;
    contact_company?: string;
}

export class UpdateVtfrTrainingLogInput {
    training_id: number;
    user_id?: number;
    type_id?: number;
    duration_num?: number;
    duration_type?: string;
    customer_id?: number;
    salesman_id?: number;
    sales_division_id?: string;
    sales_region_id?: string;
    sales_metro_id?: string;
    sales_group_id?: number;
    bill_amt?: number;
    expenses?: number;
    currency?: string;
    notes?: string;
    t_market_id?: number[];
    c_type_id?: number;
    pres_date?: string;
    sold?: number;
    quote?: number;
    contacts?: UpdateVtfrTrainingContactInput[];
}

export class UpdateRecentUserProjectsInput {
    user_id: number;
    proj_id: number;
}

export class GetAllUsersInput {
    only_active_users?: boolean;
}

export interface IProjectSchedulingCalendarEvent {
    title?: string;
    start?: string;
    color?: ProjectSchedulingCalendarEventColor;
}

export interface IProjectSchedulingGanttTask {
    id?: number;
    text?: string;
    duration?: number;
    start_date?: string;
    project_name?: string;
    color?: string;
    parent?: number;
    open?: boolean;
    not_entered?: string;
    coordinator?: User;
}

export interface IProjectSchedulingType {
    gantt_tasks: IProjectSchedulingGanttTask[];
    calendar_events: IProjectSchedulingCalendarEvent[];
}

export class AdditionalProjectType {
    project_type_id?: number;
    project_type?: string;
    active?: number;
}

export class AreaBIM {
    area_id?: number;
    abom_date?: string;
    area_complete?: boolean;
    area_status?: string;
    cc_delivery_date?: string;
    customer_fabshop_address?: string;
    ep_delivery_date?: string;
    expected_drawing_completion_date?: string;
    long_lead_items?: boolean;
    header_onsite_date?: string;
    header_status?: string;
    iso_status?: string;
    job_site_address?: string;
    material_to_customer_shop_date?: string;
    model_approval_date?: string;
    proj_id?: number;
    projid?: number;
    revizto_created?: boolean;
    quoted_drawing_hours?: number;
    quoted_drawing_revenue?: number;
}

export class AreaFabrication {
    id?: string;
    area_fab_id?: number;
    current_fab_cost?: number;
    current_fab_hours?: number;
    current_truck_count?: number;
    cut_count?: number;
    date_added?: string;
    fabshop_id?: number;
    fab_shop_start_date?: string;
    logistics?: TruckRecord[];
    onehub_date?: string;
    onsite_date?: string;
    pipe_to_shop_date?: string;
    prv_count?: number;
    quoted_fab_cost?: number;
    quoted_fab_hours?: number;
    quoted_truck_count?: number;
    couplings?: CouplingRecord[];
    coupling_total_hours?: number;
    coupling_count?: number;
    mech_hours?: number;
    mech_count?: number;
    fabrication_allowance?: number;
    fab_hours?: number;
    fab_total_plus10?: number;
    fab_total_no_overhead?: number;
    total_fab_new_estimating?: number;
    fab_labor_rate?: number;
    region_id?: number;
    pipecubicFootagetMultiplier?: number;
    shopMultiplier?: number;
    truckCubicFootage?: number;
    lessThan12Diam?: number;
    twelveTo16Diam?: number;
    eighteenPlusDiam?: number;
    lessThan12Shop?: number;
    twelveTo16Shop?: number;
    eighteenPlusShop?: number;
    lessThan12Hours?: number;
    twelveTo16Hours?: number;
    eighteenPlusHours?: number;
    pipe?: PipeRecord[];
    groove_count?: number;
    pipeCubicFeetNoMultiplier?: number;
    pipeCubicPlus25?: number;
    truck_count?: number;
    pallet_cost?: number;
    pallet_count?: number;
    pump_drop_count?: number;
    ahu_count?: number;
    header_count?: number;
}

export class CouplingRecord {
    size?: string;
    qty?: number;
    diameterInches?: number;
}

export class PipeRecord {
    linear_feet?: number;
    size?: string;
    cubic_feet?: number;
}

export class ProjectAreaPhase {
    area_id?: number;
    is_active?: number;
    market?: string;
    name?: string;
    phase_id?: number;
    quotes?: PhaseQuote[];
}

export class ProjectArea {
    active?: number;
    areaid?: number;
    areaBIM?: AreaBIM;
    area?: string;
    changeorder?: number;
    created_at?: string;
    cost?: number;
    estimate?: number;
    estimatingHours?: EstimatingHoursField[];
    fabcost?: number;
    areaFabrication?: AreaFabrication;
    fabsketch_hours?: number;
    iso_hours?: number;
    layout_hours?: number;
    marked_for_delete?: boolean;
    phases?: ProjectAreaPhase[];
    schedule_impact?: number;
    status?: string;
    status_id?: number;
    status_date?: string;
    updated_po?: number;
    quotedate?: string;
    quoteid?: number;
    quotename?: string;
}

export class AuthenticationKey {
    auth_key_id?: number;
    key_for?: string;
    key?: string;
}

export class BimAndCoCatalog {
    Id?: string;
    Name?: string;
}

export class BimAndCoModel {
    Id?: string;
    Name?: string;
    FileName?: string;
    Extension?: string;
    LevelOfDetail?: string;
}

export class BimAndCoObject {
    Id?: string;
    UpdatedAt?: string;
    CreatedAt?: string;
    Name?: string;
    Description?: string;
    DefaultPhoto?: string;
    Photos?: BimAndCoPhoto[];
    Catalogs?: BimAndCoCatalog[];
    ThreeDModels?: BimAndCoModel[];
    TwoDModels?: BimAndCoModel[];
}

export class BimAndCoPhoto {
    Id?: string;
    FileName?: string;
    IsDefault?: string;
    Path?: string;
}

export class BimObject {
    Id?: string;
    Name?: string;
    Photo?: string;
    Catalog?: BimAndCoCatalog;
}

export class GetBimObjectResponse {
    BimObjects?: BimObject[];
    Catalogs?: BimAndCoCatalog[];
}

export class SpotContentObjectBimTimestamps {
    SoftwareType?: string;
    LastUpdated?: string;
}

export class SpotContentObjectData {
    Software?: string;
    FilePath?: string;
    FileName?: string;
    TimeStamp?: string;
}

export class SpotContentObject {
    ObjectName?: string;
    PermaLink?: string;
    PrimeTimestamps?: SpotContentObjectBimTimestamps[];
    SpotData?: SpotContentObjectData[];
}

export class SpotStylesObject {
    Style?: string;
    Description?: string;
    Permalink?: string;
    Submittal?: string;
}

export class BimCoordinationCalendarEvent implements IProjectSchedulingCalendarEvent {
    title?: string;
    start?: string;
    color?: ProjectSchedulingCalendarEventColor;
}

export class BimCoordinationGanttTask implements IProjectSchedulingGanttTask {
    id?: number;
    text?: string;
    duration?: number;
    start_date?: string;
    project_name?: string;
    color?: string;
    parent?: number;
    open?: boolean;
    not_entered?: string;
    coordinator?: User;
}

export class BimCoordinationSchedule implements IProjectSchedulingType {
    gantt_tasks: BimCoordinationGanttTask[];
    calendar_events: BimCoordinationCalendarEvent[];
}

export class BimDashboardData {
    efficiency?: number;
    materialDifference?: number;
    projectHoursSold?: number;
    projectMaterialSold?: number;
    bimTablesData?: BimDashboardTable[];
    upcomingDatesProjects?: BimDashboardTable[];
    coordinatorsInfoList?: BimDashboardTable[];
}

export class BimDashboardTable {
    project_number?: string;
    project_name?: string;
    coordinator?: string;
    quoted_drawing_hours?: number;
    bim_hours_used?: number;
    percent_complete?: string;
    efficiency?: number;
    total_sold_material?: string;
    current_material?: string;
    material_difference_dollars?: string;
    material_difference?: string;
    material_difference_number?: number;
    missing_pn?: number;
    sequence_count?: number;
    sequence_errors?: number;
    sequence_name?: string;
    sequence_missing_dates?: number;
    sequence_date_value?: string;
    sequence_date_type?: string;
    numberOfProjects?: number;
    amount?: string;
    amount_number?: number;
    quote_name?: string;
    status_name?: string;
    percent_date?: string;
    status?: string;
}

export class BimHourType {
    bim_hour_type_id?: number;
    bim_hour_type?: string;
    positive?: number;
}

export class BimDocsAdminParams {
    company_id?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    role?: string;
    service_type?: string;
}

export class BIM360ProjectCreationParams {
    name: string;
    start_date: string;
    end_date: string;
    proj_id?: number;
    value?: number;
    currency?: string;
    template_project_Id?: string;
    include_locations?: boolean;
    include_companies?: boolean;
}

export class Bim360ProjectFolder {
    folder_ref_id?: number;
    proj_id: number;
    name?: string;
    bim360_project_id?: string;
    bim360_folder_id?: string;
    bim360_parent_id?: string;
    vdc_folder_id: number;
    vdc_parent_id?: number;
    has_template_file?: boolean;
    sub_folders?: Bim360ProjectFolder[];
}

export class Bim360Project {
    bim360_proj_id?: string;
    docs_home_page_url?: string;
    vdc_docs_proj_id?: number;
}

export class BomColumn {
    column_id: number;
    column: string;
    label?: string;
    is_split: number;
    selected_by_default: number;
    editable: number;
    type?: string;
    width?: string;
}

export class ChangeOrderStatus {
    status_id?: number;
    status_name?: string;
}

export class ChangeOrderBimHourData {
    co_bim_hour_id?: number;
    bim_hour_type_id?: number;
    hours?: number;
    finance_revision_id?: number;
    change_order_id?: number;
}

export class ChangeOrderComparedBom {
    co_compared_bom_id?: number;
    change_order_id?: number;
    finance_revision_id?: number;
    system_id?: number;
    pre_revision_id?: number;
    post_revision_id?: number;
}

export class ChangeOrderNote {
    change_order_id?: number;
    note_id?: number;
    note?: string;
    name?: string;
    user_id?: number;
    date_added?: string;
}

export class ChangeOrderSuggestedValue {
    co_suggested_value_id?: number;
    offeringid?: number;
    value?: string;
    change_order_id?: number;
    finance_revision_id?: number;
}

export class ChangeOrder {
    areaid?: number;
    created_at?: string;
    cost?: number;
    fabcost?: number;
    fabsketch_hours?: number;
    schedule_impact?: boolean;
    iso_hours?: number;
    layout_hours?: number;
    name?: string;
    quotedate?: string;
    quoteid?: number;
    quotename?: string;
    status?: string;
    status_date?: string;
    updated_po?: boolean;
    drawing_hours?: number;
    fab_hours?: number;
    header_count?: number;
    iso_drop_count?: number;
    ahu_drop_count?: number;
    material_dist?: number;
    cps_dist?: number;
    fabrication_dist?: number;
    headers_dist?: number;
    iso_drop_dist?: number;
    ahu_drop_dist?: number;
    finance_date?: string;
    pf_id?: number;
    koil_kit_count?: number;
    kk_dist?: number;
    status_id?: number;
    transportation_dist?: number;
    truck_count?: number;
}

export class ProjectChangeOrder {
    change_order_id?: number;
    proj_id?: number;
    projid?: number;
    quoteid?: number;
    areaid?: number;
    proj_num?: string;
    project_name?: string;
    currency?: string;
    status_id?: number;
    change_order_name?: string;
    change_order_description?: string;
    change_order_status?: string;
    change_order_status_id?: number;
    open_duration?: string;
    finance_revision_name?: string;
    finance_revision_id?: number;
    change_order_number?: string;
    change_order_price?: string;
    bimHoursData?: ChangeOrderBimHourData[];
    comparedBoms?: ChangeOrderComparedBom[];
    changeOrderNotes?: ProjectNote[];
    changeOrderSuggestedValues?: ChangeOrderSuggestedValue[];
    finances?: Finances[];
    financesHours?: FinancesHours[];
    vdcHours?: number;
    fabHours?: number;
    materialCost?: string;
    fabricationCost?: string;
    vdcCost?: string;
    transportationCost?: string;
    additionalCost?: string;
    changeOrderNotesString?: string;
    change_order_approved_date?: string;
    change_order_drawing_num?: string;
    updated_po?: boolean;
}

export class ComponentAlternative {
    title?: string;
    rowNum?: number;
    subParts?: ComponentSubstitute[];
}

export class ComponentCategory {
    group_id?: number;
    group_desc?: string;
    group_char?: string;
}

export class ComponentGroup {
    group_id?: number;
    group_char?: string;
    group_desc?: string;
    sort_id?: number;
    value?: number;
    updated?: boolean;
}

export class ComponentSizeCodes {
    size_code?: string;
    size_desc_imp?: string;
    size_desc_m3?: string;
    row_num?: number;
}

export class ComponentSizes {
    size_id?: number;
    size_imp?: string;
    size_met?: number;
}

export class ComponentSubstitute {
    part_number?: string;
    description?: string;
    quantity?: number;
}

export class Component {
    component_id?: number;
    partnumber_us?: string;
    partnumber_global?: string;
    partnumber_m3?: string;
    size?: string;
    size_desc_m3?: string;
    size_desc_imp?: string;
    concat_component_desc?: string;
    nominal_imp?: string;
    component_desc_m3?: string;
    component_desc?: string;
    style_desc?: string;
    custom_item?: number;
    image_path?: string;
    image_id?: number;
    style?: string;
    labor_mcaa?: number;
    weight?: number;
    size_code?: string;
    gasket_type?: string;
    group_id?: number;
    active?: number;
    price_usd?: number;
    price_eur?: number;
    price_gbp?: number;
    price_jpy?: number;
    part_size?: string;
    component_cost?: number;
    description?: string;
    bill_id?: number;
}

export class ComponentsSelectOptions {
    partSizes?: PartSize[];
    gasketTypes?: GasketType[];
    componentCategories?: ComponentCategory[];
    currencies?: Currency[];
    regions?: Region[];
}

export class ContentManagementResponse {
    responseStatus?: string;
    responseMessage?: string;
}

export class Country {
    country_id?: number;
    country?: string;
}

export class CurrencyConversionRate {
    rate_id?: number;
    date?: string;
    currency?: string;
    rate?: number;
}

export class Currency {
    currency?: string;
    symbol?: string;
}

export class CustomerCareRep {
    rep_id?: number;
    name?: string;
    phone?: string;
    email?: string;
}

export class CustomerContact {
    _id?: string;
    cell_phone?: string;
    company_name?: string;
    contact_type?: string;
    email?: string;
    name?: string;
    office_phone?: string;
    title?: string;
    address?: string;
}

export class CustomerType {
    c_type_id?: number;
    c_type?: string;
}

export class Customer {
    customer_id?: number;
    customer?: string;
    country?: string;
    city?: string;
    state?: string;
    c_type_id?: number;
    type?: string;
    market_id?: number;
    active?: number;
    user_id?: number;
    company_id?: number;
}

export class DamImage {
    smallImageUrl?: string;
    largeImageUrl?: string;
    fileName?: string;
}

export class DomesticRequirement {
    requirement?: string;
    requirement_note?: string;
}

export class Engineer {
    engineer_id?: number;
    name?: string;
    location?: string;
    active?: number;
}

export class EquipmentItem {
    equipment_id?: number;
    proj_id?: number;
    manufacturer?: string;
    model?: string;
    type?: string;
    size?: string;
    vic_area_pt?: string;
    vic_system_pt?: string;
    vic_sequence?: string;
    vic_zone?: string;
    mark?: string;
    pairs?: number;
    count?: number;
}

export class EstimatingHoursField {
    est_id?: number;
    hfm_id?: number;
    hours?: number;
    area_id?: number;
}

export class EstimatingScheduleFields {
    proj_estimating_id?: number;
    koil_kit_resource?: string;
    kkto_status?: string;
    last_updated?: string;
    mbe_status?: string;
    mbe_resource_start_date?: string;
    mbe_planned_completion_date?: string;
    quote_status?: string;
    quote_resource?: string;
    requested_date_sales?: string;
    service_type?: string;
    vdc_resource?: string;
    mbe_requested_completion_date?: string;
    requested_sales_date_changed?: string;
    updated_by?: string;
}

export class FabricationContact {
    fab_contact_id?: number;
    name?: string;
    email?: string;
    phone?: string;
}

export class FabricationShop {
    fab_shop_id?: number;
    fab_shop_name?: string;
    location?: string;
    region_id?: number;
    hours_per_day?: number;
    lattitude?: number;
    longitude?: number;
    active?: number;
}

export class FinanceHoursType {
    finance_hours_type_id?: number;
    finance_hours_type?: string;
    property?: string;
    order?: number;
}

export class FinancesData {
    financesHours?: FinancesHours[];
    finances?: Finances[];
    materialBreakdown?: Finances[];
    transportationBreakdown?: Finances[];
    additionalOfferings?: ProjectOffering[];
    missingPartNumbers?: number;
}

export class Finances {
    finance_data_id?: number;
    finance_revision_id?: number;
    proj_id?: number;
    quoteid?: number;
    offeringid?: number;
    offering?: string;
    count?: number;
    buffer?: number;
    value?: string;
    value_number?: number;
    value_string?: string;
    value_with_buffer?: number;
    value_with_buffer_string?: string;
    dist_markup?: string;
    dist_cost?: number;
    dist_cost_string?: string;
    cust_markup?: string;
    cust_cost?: number;
    cust_cost_string?: string;
    suggestedDistValue?: string;
    suggestedCustValue?: string;
    group_as_material?: number;
    group_as_transportation?: number;
    for_change_orders?: number;
    for_additional_change_orders?: number;
}

export class LatestData {
    financesData?: FinancesData;
    errors?: string[];
}

export class ProjectFinancesData {
    quotes?: PhaseQuote[];
    quoteRevisions?: QuoteRevision[];
    changeOrderStatusTypes?: ChangeOrderStatus[];
}

export class FinancesHours {
    finance_hours_id?: number;
    finance_hours_type_id?: number;
    finance_hours_type?: string;
    value?: number;
    finance_revision_id?: number;
    proj_id?: number;
    quoteid?: number;
}

export class Finish {
    finish_id?: number;
    system_id?: number;
    finish?: string;
}

export class GasketType {
    gasket_id?: number;
    gasket_type?: string;
    gasket_value?: string;
}

export class HeaderCoating {
    header_coating_id?: number;
    header_coating?: string;
}

export class HeaderLining {
    header_lining_id?: number;
    header_lining?: string;
}

export class HeaderMaterial {
    header_material_id?: number;
    header_material?: string;
}

export class HeaderPriceList {
    option_id?: number;
    price_list_name?: string;
}

export class HeaderSizes {
    nominal_size?: string;
    headersize_id?: number;
    outlet_size?: string;
}

export class Header {
    header_id: number;
    proj_id: number;
    system_id: number;
    quantity?: number;
    price_list_id?: number;
    price_list_name?: string;
    header_name?: string;
    assembly_name?: string;
    bag_tag?: string;
    system_abbr?: string;
    mark?: string;
    area?: string;
    sequence?: string;
    zone?: string;
    pipe_size?: string;
    outlet_size?: string;
    outlet_size2?: string;
    outlet_size3?: string;
    outlet_size4?: string;
    outlet_size5?: string;
    outlet_size6?: string;
    three_quarter_tap?: number;
    one_tap?: number;
    one_quarter_tap?: number;
    one_half_tap?: number;
    two_tap?: number;
    pipe_length?: string;
    pipe_length_ft?: number;
    pipe_length_in?: number;
    outlet_count?: number;
    tap_count?: number;
    price?: string;
    header_cost?: number;
    header_cost_ep?: number;
    current_location?: string;
    header_coating_id?: number;
    header_coating?: string;
    header_lining_id?: number;
    header_lining?: string;
    header_material_id?: number;
    header_material?: string;
}

export class HeadersSelectOptions {
    price_list?: HeaderPriceList[];
    areas?: ProjectBillItem[];
    zones?: ProjectBillItem[];
    sequences?: ProjectBillItem[];
    mainPipeSizes?: HeaderSizes[];
    headerCoatings?: HeaderCoating[];
    headerLinings?: HeaderLining[];
    headerMaterials?: HeaderMaterial[];
}

export class HoursFieldRecord {
    pf_id?: number;
    hfm_id?: number;
    projid?: number;
}

export class KickoffChecklist {
    bim_execution_plan: BimExecutionPlan;
    contract_drawings: ContractDrawingChecklist;
    control_valves: ControlValvesPackage;
    customer_fabrication_preferences: CustomerFabricationPreferences;
    drawing_requirements: DrawingRequirements;
    equipment_submittals: EquipmentSubmittalInfo;
    general_info: ProjectKickoffGeneralInfo;
    hanger_support_specification: HangerSupportSpecification;
    header_elevation_info: PipeHeaderElevationInfo;
    housekeeping_pad_info: HousekeepingPadInfo;
    insulation_specification: InsulationSpecification;
    noted_issues: string;
    material_delivery_requirements: MaterialDeliveryRequirements;
    mechanical_specification: MechanicalSpecification;
    modeling_guidelines: ModelingGuidelines;
    pipe_elevation_info: PipeElevationInfo;
    pipe_length_info: PipeLengthInfo;
    pipe_material_specification: PipeMaterialSpecification;
    proj_id: number;
    projid: number;
    reducers_info: ReducersInfo;
    schedule_areas: ScheduleAreaInfo;
    tap_requirements: TapRequirementsInfo;
    vibration_isolation_info: VibrationIsolationInfo;
    vic_material_guidelines: VicMaterialGuidelines;
}

export class BimExecutionPlan {
    _id?: string;
    has_bim_execution_plan?: boolean;
    bim_execution_plan_received_date?: string;
    notes?: string;
}

export class ContractDrawingRecord {
    _id?: string;
    contract_drawing_type?: ContractDrawingType;
    drawing_received?: boolean;
    drawing_received_date?: string;
    revision?: string;
    notes?: string;
}

export class ContractDrawingType {
    _id?: string;
    drawing_type?: string;
}

export class ContractDrawingChecklist {
    _id?: string;
    contract_drawings?: ContractDrawingRecord[];
}

export class ControlValvesPackage {
    _id?: string;
    control_package_approved?: boolean;
    control_package_received?: boolean;
    control_valve_submittal_type?: string;
    control_valve_submittal_approval_received?: string;
    flow_diameters_upstream?: string;
    flow_diameters_downstream?: string;
    flow_pressure_rating?: string;
    vic_control_valves_used?: boolean;
    valves_provided_by_vic?: boolean;
    temperature_requirements?: string;
    notes?: string;
}

export class CustomerFabricationPreferences {
    _id?: string;
    fabrication_software_used?: string;
    flanged_gasket_thickness?: string;
    field_groove_tool_model_type?: string;
    groove_tool_available_in_field?: boolean;
    extra_length_pipe_permitted?: boolean;
    notes?: string;
    preferred_cut_length?: string;
}

export class DrawingRequirements {
    _id?: string;
    bim360_glue_version?: string;
    customer_title_block_name_and_conventions_received_date?: string;
    ftp_coordination_site?: string;
    imperial_drawing_size_required_for_layout?: string;
    metric_drawing_size_required_for_layout?: string;
    navisworks_version?: string;
    other_coordination_software?: string;
    other_metric_drawing_size_required_for_layout?: string;
    revit_version?: string;
    notes?: string;
    navisworks_used?: boolean;
    revitzo_used?: boolean;
    bim360_glue_used?: boolean;
    ftp_site_used?: boolean;
    drawing_scale_requirements?: string;
}

export class EquipmentSubmittalRecord {
    _id?: string;
    boiler_requirements?: string;
    connection_type?: string;
    date_received?: string;
    dunnage_support_steel_status?: string;
    equipment_tag?: string;
    has_boiler_requirement?: boolean;
    notes?: string;
    pad_height?: string;
    status?: string;
    submittal_type?: EquipmentSubmittalType;
    submittal_received?: boolean;
    vibration_isolator_status?: string;
}

export class EquipmentSubmittalType {
    _id?: string;
    name?: string;
    pad_label?: string;
}

export class EquipmentSubmittalInfo {
    submittals: EquipmentSubmittalRecord[];
}

export class HangerSupportSpecification {
    _id?: string;
    current_hanger_support_specification?: string;
    hanger_point_layout_requirement?: boolean;
    guides_and_anchors_required?: boolean;
    seismic_support_requirements?: boolean;
    has_spacing_requirements?: boolean;
    spacing_requirements?: string;
    structural_racking_support_requirements?: boolean;
    clevis_support_system?: boolean;
    trapeze_support_system?: boolean;
    roller_support_system?: boolean;
    supported_from_above?: boolean;
    supported_from_below?: boolean;
    supported_from_other?: boolean;
    supported_from_other_type?: string;
    notes?: string;
}

export class HousekeepingPadInfo {
    _id?: string;
    ahu_pads_set_by?: string;
    air_handling_units?: string;
    air_handling_units_not_apply?: boolean;
    customer_pouring_pads?: boolean;
    pad_extension_beyond_base?: string;
    pads_being_located_by?: string;
    single_large_pad_permitted?: boolean;
    true_or_nominal?: string;
    notes?: string;
}

export class InsulationSpecification {
    _id?: string;
    insulation_types?: InsulationTypeRecord[];
    notes?: string;
}

export class InsulationType {
    _id?: string;
    name?: string;
}

export class InsulationTypeRecord {
    _id?: string;
    base_insulation_type?: InsulationType;
    insulation_type?: string;
    insulation_material?: string;
    max_size?: string;
    min_size?: string;
    pipe_type?: string;
    thickness?: string;
}

export class KickoffIssueResolution {
    notes?: string;
}

export class MaterialDeliveryRequirements {
    _id?: string;
    area_to_receive_and_stage_pipe?: boolean;
    has_delivery_requirements?: boolean;
    delivery_requirements?: string;
    flatbed_trucked_permitted?: boolean;
    required_truck_type?: string;
    lift_available?: boolean;
    has_packing_requirements?: boolean;
    packaging_requirements?: string;
    notes?: string;
    not_emeai_job?: boolean;
    emeai_requires_delivery_by_appointment?: boolean;
    emeai_truck_type?: string;
    emeai_truck_conditions?: string;
}

export class MechanicalSpecification {
    _id?: string;
    section_id?: string;
    current_mechanical_spec_date?: string;
    current_mechanical_spec_revision?: string;
    notes?: string;
}

export class ConnectionType {
    _id?: string;
    has_connection_type?: string;
    limitations?: string;
    type?: string;
    welded_connection_type?: string;
    branch_length_determined_by?: string;
    branch_length_notes?: string;
    fab_shop_weld_gap_size?: string;
    fishmouth_connection_used?: boolean;
    stabin_connection_used?: boolean;
    weldolet_connection_used?: boolean;
    threadolet_connection_used?: boolean;
}

export class ModelingGuidelines {
    _id?: string;
    non_vic_flanges_used?: string;
    slip_on_flange_reveal_depth?: string;
    reducing_branch_connections?: ConnectionType[];
    notes?: string;
}

export class PipeElevationInfo {
    _id?: string;
    cooling_tower_elevations_reference?: string;
    cooling_tower_elevations_reference_other?: string;
    equipment_set_from?: string;
    feet_and_inches_ok?: boolean;
    feet_and_inches_alternative?: string;
    header_elevations_spools_held?: string;
    header_elevations_spools_held_other?: string;
    preferred_equipment_reference_point?: string;
    notes?: string;
}

export class PipeHeaderElevationInfo {
    _id?: string;
    preferred_elevation_for_pipe_runs?: boolean;
    preferred_elevation_info?: string;
    notes?: string;
}

export class PipeLengthInfo {
    _id?: string;
    pipe_length?: string;
    pipe_length_specification?: string;
    notes?: string;
}

export class PipeMaterialSpecification {
    _id?: string;
    carbon_steel_to_be_astm?: string;
    has_chain_wheel_requirement?: boolean;
    chain_wheel_required_size_from?: string;
    chain_wheel_required_size_to?: string;
    chain_wheel_above_requirement?: string;
    chain_wheel_bottom_valve_or_cl?: string;
    notes?: string;
    pipe_grade?: string;
    pipe_schedule?: string;
    pipe_spec_type?: string;
    pipe_spec_other_type?: string;
    standard_weight_12in_larger?: boolean;
    standard_weight_12in_larger_not_apply?: boolean;
    valve_gear_handle_from?: string;
    valve_gear_handle_to?: string;
    valve_level_handle_from?: string;
    valve_level_handle_to?: string;
    pipe_types?: PipeType[];
}

export class ProjectKickoffGeneralInfo {
    _id?: string;
    additional_addresses?: AdditionalAddress[];
    coordinator_office_phone?: string;
    coordinator_title?: string;
    customer_care_email?: string;
    customer_care_phone?: string;
    customer_contacts?: CustomerContact[];
    delivery_address?: string;
    headers_delivered_to?: string;
    jobsite_address?: string;
    kickoff_meeting_date?: string;
    line_sales_phone?: string;
    line_sales_email?: string;
    mailing_address?: string;
    material_delivered_to_jobsite?: boolean;
    regional_sales_phone?: string;
    regional_sales_email?: string;
    notes?: string;
}

export class AdditionalAddress {
    address?: string;
    address_type?: string;
}

export class ReducersInfo {
    _id?: string;
    type_for_horizontal_pipe?: string;
    eccentric_horizontal_position?: string;
    notes?: string;
}

export class ScheduleArea {
    _id?: string;
    area_name?: string;
    confirmed_coordination_date?: string;
    confirmed_drawings_date?: string;
    confirmed_onsite_date?: string;
    notes?: string;
    quote_id?: number;
    requested_coordination_date?: string;
    requested_drawings_date?: string;
    requested_onsite_date?: string;
}

export class ScheduleAreaInfo {
    _id?: string;
    areas?: ScheduleArea[];
}

export class TapRequirement {
    _id?: string;
    name?: string;
    size?: string;
    submittal_received?: string;
    type?: string;
}

export class TapRequirementsInfo {
    _id?: string;
    tap_requirements?: TapRequirement[];
    notes?: string;
}

export class VicMaterialGuidelines {
    _id?: string;
    signed_approval_for_full_vic_usage?: boolean;
    notes?: string;
}

export class VibrationIsolationInfo {
    _id?: string;
    flex_coupling_not_acceptable_location?: boolean;
    not_acceptable_explanation?: string;
    notes?: string;
}

export class LegislativeAct {
    act_id?: number;
    act_name?: string;
}

export class Market {
    market_id?: number;
    market?: string;
    active?: number;
}

export class MaterialListData {
    boms?: System[];
    partNumberProfiles?: PartNumberProfiles[];
}

export class MetropolitanArea {
    metro_id?: string;
    metro?: string;
}

export class MtoRevision {
    quoteRevisions?: QuoteRevision[];
    bomRevisions?: Revision[];
}

export class MuleSoftCouplingInfo {
    shop?: MuleSoftProductInfoType[];
    field?: MuleSoftProductInfoType[];
}

export class MuleSoftPipeCutInfo {
    count?: number;
}

export class MuleSoftGrooveInfo {
    count?: number;
    endPreparation?: string;
}

export class MendixSpoolStatus {
    spool_status_id?: number;
    spool_status?: string;
    spool_status_name?: string;
    spool_status_color?: string;
}

export class MendixProject {
    uniqueJobId: number;
    jobId: string;
    jobName: string;
    market: string;
    status: string;
    vdcCoordinator: string;
    vdcCoordinatorEmail: string;
    salesperson: string;
    salespersonEmail: string;
    soldJobHours: number
}

export class MuleSoftPipeInfo {
    description?: string;
    size?: number;
    grooves?: MuleSoftGrooveInfo[];
    cuts?: MuleSoftPipeCutInfo;
}

export class MuleSoftProductInfoType {
    productId?: string;
    productDescription?: string;
    size?: number;
    count?: number;
}

export class MuleSoftProductInfo {
    coupling?: MuleSoftCouplingInfo[];
    holeCut?: MuleSoftProductInfoType[];
}

export class PartNumberProfiles {
    profile_id?: number;
    profile?: string;
    profile_desc?: string;
    user?: string;
    user_id?: number;
    region_id?: number;
}

export class PartSize {
    size_code?: string;
    part_size?: string;
}

export class PartNumberLink {
    link_id?: number;
    component_id?: number;
    rev_size?: string;
    rev_desc?: string;
    profile_id?: number;
    project_profile_id?: number;
    user_id?: number;
    date?: string;
}

export class PhaseQuote {
    drawing_numbers?: string;
    drawing_revision?: string;
    limits?: string;
    phase_id?: number;
    quote_id?: number;
    areaid?: number;
    quote_fields?: QuoteFieldRecord[];
    quote_name?: string;
    rate?: number;
    rate_calc?: number;
    riser_offset?: number;
    total_layout?: number;
    worksheet_id?: number;
    last_updated?: string;
    updated_by?: number;
    change_order?: number;
    change_order_id?: number;
    schedule_impact?: number;
    updated_po?: number;
    status_id?: number;
}

export class PipeType {
    pipe_type_id?: number;
    description?: string;
    region?: string;
    market?: string;
    material?: string;
    specification?: string;
    grade?: string;
    schedule?: string;
    coating?: string;
    construction_method?: string;
}

export class PreBidDashboardData {
    activeSuccessRate?: number;
    modelCompletionSuccessRate?: number;
    mtoModelCompletionSuccessRate?: number;
    daysStatusIsEP?: number;
    averageOfQuotes?: number;
    legislativeDaysToQuote?: number;
    nonLegislativeDaysToQuote?: number;
    allProjectsDaysToQuote?: number;
    legislativeHoursToQuote?: number;
    nonLegislativeHoursToQuote?: number;
    allProjectsHoursToQuote?: number;
    departmentTimeToQuote?: number[];
    collectiveHoursToQuote?: number[];
    quotesByServiceType?: number[];
}

export class PreBidModelResourceCalendarEvent implements IProjectSchedulingCalendarEvent {
    title?: string;
    start?: string;
    color?: ProjectSchedulingCalendarEventColor;
}

export class PrebidModelResourceTask implements IProjectSchedulingGanttTask {
    id?: number;
    text?: string;
    duration?: number;
    start_date?: string;
    project_name?: string;
    color?: string;
    parent?: number;
    open?: boolean;
    not_entered?: string;
    coordinator?: User;
    mbe_planned_completion_date?: string;
    model_submission_date?: string;
    quality_control_date?: string;
    requested_date_sales?: string;
    proj_id?: number;
    proj_base_name?: string;
    date_key?: string;
    model_status?: string;
    project_status?: string;
    model_resource?: string;
    status_color?: string;
    project_status_color?: string;
}

export class PreBidModelResourceSchedule implements IProjectSchedulingType {
    gantt_tasks: PrebidModelResourceTask[];
    calendar_events: PreBidModelResourceCalendarEvent[];
}

export class PreBidQualityControlCalendarEvent implements IProjectSchedulingCalendarEvent {
    title?: string;
    start?: string;
    color?: ProjectSchedulingCalendarEventColor;
}

export class PrebidQualityControlResourceTask implements IProjectSchedulingGanttTask {
    id?: number;
    text?: string;
    duration?: number;
    start_date?: string;
    project_name?: string;
    color?: string;
    parent?: number;
    open?: boolean;
    not_entered?: string;
    coordinator?: User;
}

export class PreBidQualityControlResourceSchedule implements IProjectSchedulingType {
    gantt_tasks: PrebidQualityControlResourceTask[];
    calendar_events: PreBidQualityControlCalendarEvent[];
}

export class PreBidResourcesSchedule {
    id: string;
    model_resources_schedule: PreBidModelResourceSchedule;
    quality_control_resources_schedule: PreBidQualityControlResourceSchedule;
}

export class PredictedPartNumbers {
    components?: Component[];
    user_type: number;
    pn_profile: number;
}

export class PriceList {
    price_list_id?: number;
    price_list_name?: string;
    region_id?: number;
    currency?: string;
}

export class ProfilePartNumbers {
    component_id?: number;
    link_id?: number;
    rev_size?: string;
    rev_desc?: string;
    partnumber_us?: string;
    partnumber_global?: string;
    linked_size?: string;
    linked_desc?: string;
    name?: string;
    linked_date?: string;
    profile_id?: number;
    project_profile_id?: number;
}

export class ProjectAncillaries {
    newSystems?: System[];
    newRevisions?: Revision[];
}

export class ProjectBillItem {
    component_id?: number;
    equipment_id?: number;
    cat_id?: number;
    compsort?: number;
    typical?: number;
    bill_id?: number;
    proj_id?: number;
    system_id?: number;
    flip_id?: number;
    revision_id?: number;
    vh_id?: number;
    seq_id?: number;
    tap_count?: number;
    quantity?: number;
    multiplier?: number;
    rev_desc?: string;
    rev_size?: string;
    pipe_length?: string;
    system_abbr?: string;
    assembly_name?: string;
    mark?: string;
    end_prep?: string;
    vic_system_pt?: string;
    vic_area_pt?: string;
    vic_zone?: string;
    vic_field_material?: number;
    vic_field_cut?: number;
    vic_note?: string;
    vic_bag_tag?: string;
    mark_up?: number;
    vic_seq?: string;
    part_number?: string;
    partnumber_us?: string;
    partnumber_global?: string;
    partnumber_m3?: string;
    weight?: number;
    labor_mcaa?: number;
    style_desc?: string;
    component_desc?: string;
    component_desc_us?: string;
    component_desc_m3?: string;
    component_desc_global?: string;
    size_desc_us?: string;
    size_desc_imp?: string;
    size_desc_m3?: string;
    size_desc?: string;
    size?: string;
    item_price?: number;
    list_price_part_us?: number;
    list_price_part_m3?: number;
    component_cost?: number;
    net_price?: number;
    extended_net_price?: number;
    image_path?: string;
    group_id?: number;
}

export class ProjectBimSchedule {
    buyer_acceptance_agreement_date?: ProjectScheduleDateType;
    kickoff_meeting_date?: ProjectScheduleDateType;
    purchase_order_date?: ProjectScheduleDateType;
    expected_drawing_completion_date?: ProjectScheduleDateType;
    closeout_meeting_date?: ProjectScheduleDateType;
    contractor_turnover_date?: ProjectScheduleDateType;
}

export class ProjectBIM {
    closeout_meeting_date?: string;
    status_color?: string;
    contractor_turnover_date?: string;
    customer_care_contact?: CustomerCareRep;
    customer_care_id?: number;
    drawing_percent_complete?: number;
    expected_drawing_completion_date?: string;
    purchase_order_date?: string;
    project_health_status?: ProjectHealthStatus;
    drawing_hours_info?: ProjectDrawingHoursInfo;
}

export class ProjectDrawingHoursInfo {
    current_drawing_hours?: number;
    efficiency_rating?: number;
}

export class ProjectEstimatingSchedule {
    vdc_received_date?: ProjectScheduleDateType;
    bid_date?: ProjectScheduleDateType;
    model_resource_start_date?: ProjectScheduleDateType;
    model_requested_completion_date?: ProjectScheduleDateType;
    model_resource_planned_completion_date?: ProjectScheduleDateType;
    quality_control_date?: ProjectScheduleDateType;
    estimated_completion_date?: ProjectScheduleDateType;
    requested_sales_date?: ProjectScheduleDateType;
}

export class ProjectHealthStatus {
    ph_status_id?: number;
    ph_status?: string;
}

export abstract class IQuery {
    abstract project_health_projects(): ProjectHealthPayload | Promise<ProjectHealthPayload>;

    abstract getAdminComponents(): Component[] | Promise<Component[]>;

    abstract getComponentsSelectOptions(): ComponentsSelectOptions | Promise<ComponentsSelectOptions>;

    abstract getComponentSubstitutes(user_id: number, component_id: number): ComponentAlternative[] | Promise<ComponentAlternative[]>;

    abstract getFilteredComponents(keyword: string): Component[] | Promise<Component[]>;

    abstract getPartNumberProfiles(): PartNumberProfiles[] | Promise<PartNumberProfiles[]>;

    abstract getProfilePartNumbers(profile_id: number): ProfilePartNumbers[] | Promise<ProfilePartNumbers[]>;

    abstract getCurrencyConversionRates(year?: number): CurrencyConversionRate[] | Promise<CurrencyConversionRate[]>;

    abstract getProjectProfilePartNumbers(project_profile_id: number): ProfilePartNumbers[] | Promise<ProfilePartNumbers[]>;

    abstract getProjectProfiles(): ProjectProfiles[] | Promise<ProjectProfiles[]>;

    abstract getAdminProjects(startDate: string, endDate: string): SoldAdminProject[] | Promise<SoldAdminProject[]>;

    abstract getQuoteDocumentInclusions(language?: string): Inclusion[] | Promise<Inclusion[]>;

    abstract getAdminSalesman(): Salesman[] | Promise<Salesman[]>;

    abstract getSalesmanMarkups(salesmanID: string): SalesmanMarkup[] | Promise<SalesmanMarkup[]>;

    abstract getSalesmanMultipliers(salesmanID: string): SalesmanMultiplier[] | Promise<SalesmanMultiplier[]>;

    abstract getAllChangeOrders(): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract getChangeOrderNotes(change_order_id: number, finance_revision_id: number): ChangeOrderNote[] | Promise<ChangeOrderNote[]>;

    abstract getChangeOrderPreview(input: ChangeOrderPreviewInput): FinancesData | Promise<FinancesData>;

    abstract getProjectChangeOrders(proj_id: number): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract getBimAndCoObjectModels(objectIdList?: string[]): GetBimAndCoObjectModelsResponse[] | Promise<GetBimAndCoObjectModelsResponse[]>;

    abstract getBimAndCoObjects(objectIdList?: string[]): BimAndCoObject[] | Promise<BimAndCoObject[]>;

    abstract getBimObjects(): GetBimObjectResponse | Promise<GetBimObjectResponse>;

    abstract getDamImages(search_val: string): DamImage[] | Promise<DamImage[]>;

    abstract getSpotData(object_names: string[]): SpotContentObject[] | Promise<SpotContentObject[]>;

    abstract getSpotSoftwareRecords(): SpotSoftwareRecord[] | Promise<SpotSoftwareRecord[]>;

    abstract getSpotStatuses(): SpotStatus[] | Promise<SpotStatus[]>;

    abstract getSpotStyles(): SpotStylesObject[] | Promise<SpotStylesObject[]>;

    abstract getPreBidModelResourceSchedule(): PreBidModelResourceSchedule | Promise<PreBidModelResourceSchedule>;

    abstract getPreBidQualityControlResourceSchedule(): PreBidQualityControlResourceSchedule | Promise<PreBidQualityControlResourceSchedule>;

    abstract estimatingSchedule(): Project[] | Promise<Project[]>;

    abstract getProjectFabCalcDataV2(proj_id: number): Project | Promise<Project>;

    abstract getFinances(input: GetFinancesInput): FinancesData | Promise<FinancesData>;

    abstract getProjectTotalFinances(proj_id: number): TotalProjectFinancesData | Promise<TotalProjectFinancesData>;

    abstract project(proj_id: number): Project | Promise<Project>;

    abstract currencyByRegion(region_id: number): Currency[] | Promise<Currency[]>;

    abstract filteredPriceList(region_id: number, currency: string): PriceList[] | Promise<PriceList[]>;

    abstract getProjectArea(area_id: number): ProjectArea[] | Promise<ProjectArea[]>;

    abstract projectNotes(projid: number): ProjectNote[] | Promise<ProjectNote[]>;

    abstract projectSearch(searchFields?: ProjectSearchInput): ProjectSearchResult[] | Promise<ProjectSearchResult[]>;

    abstract getAlternativeBom(input: GetAlternativeBomInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract getAssemblyEquipment(input: GetAssemblyEquipmentInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract getGroupMultipliers(proj_id: number): ComponentGroup[] | Promise<ComponentGroup[]>;

    abstract getHeaderPrice(input: GetHeaderPriceInput): number | Promise<number>;

    abstract getHeadersSelectOptions(proj_id: number): HeadersSelectOptions | Promise<HeadersSelectOptions>;

    abstract getMaterialData(proj_id?: number): MaterialListData | Promise<MaterialListData>;

    abstract getOutletSizes(region_id: number, nominal_size: string): HeaderSizes[] | Promise<HeaderSizes[]>;

    abstract getPartNumbersOptions(input: GetPartNumbersOptionsInput): Component[] | Promise<Component[]>;

    abstract getPredictedPartNumbers(input: GetPredictedPartNumbersInput): PredictedPartNumbers | Promise<PredictedPartNumbers>;

    abstract getProjectHeaders(proj_id: number): Header[] | Promise<Header[]>;

    abstract getProjectPricing(proj_id: number): ProjectPricing[] | Promise<ProjectPricing[]>;

    abstract getSystemRevisionMaterialList(input: GetSystemRevisionMaterialListInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract projectSelectOptions(): ProjectSelectOptions | Promise<ProjectSelectOptions>;

    abstract getProjectStats(proj_id: number): ProjectStats | Promise<ProjectStats>;

    abstract getQuoteDocumentData(proj_id: number): QuoteDocument | Promise<QuoteDocument>;

    abstract getBimCoordinationSchedule(): BimCoordinationSchedule | Promise<BimCoordinationSchedule>;

    abstract getProjectSchedules(input: GetProjectSchedulesInput): ProjectSchedule[] | Promise<ProjectSchedule[]>;

    abstract getFabRequestInfo(sequence_id: number): SequenceFabRequestInfo | Promise<SequenceFabRequestInfo>;

    abstract getSequenceTemplates(): SequenceTemplate[] | Promise<SequenceTemplate[]>;

    abstract getSequence(sequence_id: number): Sequence | Promise<Sequence>;

    abstract bimErrorRate(startDate: string, endDate: string): number | Promise<number>;

    abstract bimMetricsData(startDate: string, endDate: string, coordinators?: number[], markets?: number[]): BimDashboardData | Promise<BimDashboardData>;

    abstract preBidCharts(startDate: string, endDate: string): PreBidDashboardData | Promise<PreBidDashboardData>;

    abstract preBidKPIs(startDate: string, endDate: string): PreBidDashboardData | Promise<PreBidDashboardData>;

    abstract getPtoCalendar(date: string): PtoCalendarData[] | Promise<PtoCalendarData[]>;

    abstract vtfrTrainingsCalendar(): VtfrTrainingsData[] | Promise<VtfrTrainingsData[]>;

    abstract vtfrTrainingsMetrics(startDate: string, endDate: string): VtfrTrainingsByTrainerMetrics | Promise<VtfrTrainingsByTrainerMetrics>;

    abstract vtfrTrainingsTable(startDate: string, endDate: string): VtfrTrainingsData[] | Promise<VtfrTrainingsData[]>;

    abstract getVdcHighlightsData(year: number): VdcHighlightsData[] | Promise<VdcHighlightsData[]>;

    abstract vicToolSalesByQuarter(toolName: string): number[] | Promise<number[]>;

    abstract vicToolSalesByYear(toolName: string): number[] | Promise<number[]>;

    abstract vicToolSalesTable(toolName: string): VicToolsDashboardData[] | Promise<VicToolsDashboardData[]>;

    abstract vicToolTotalSales(startDate: string, endDate: string, toolName: string): VicToolsDashboardData | Promise<VicToolsDashboardData>;

    abstract vicToolsVersions(toolName: string): VicToolVersion[] | Promise<VicToolVersion[]>;

    abstract vtfrDownloadsByQuarter(): number[] | Promise<number[]>;

    abstract vtfrRevenueByYear(toolName: string): number[] | Promise<number[]>;

    abstract vtfrTotalDownloads(startDate: string, endDate: string): VicToolsDashboardData | Promise<VicToolsDashboardData>;

    abstract vtfrTotalRevenue(startDate: string, endDate: string): VicToolsDashboardData | Promise<VicToolsDashboardData>;

    abstract vtfrWebPresentations(): VicToolsDashboardData | Promise<VicToolsDashboardData>;

    abstract getVdcTvData(): VdcTvData | Promise<VdcTvData>;

    abstract getAllUsers(input?: GetAllUsersInput): User[] | Promise<User[]>;

    abstract recentUserProjects(user_id: number): Project[] | Promise<Project[]>;

    abstract getAllUsersGroupedByUserGroup(input?: GetAllUsersInput): UsersGroupedByUserGroup[] | Promise<UsersGroupedByUserGroup[]>;
}

export class ProjectHealthPayload {
    coordinators?: User[];
    projects?: Project[];
    project_statuses?: ProjectHealthStatuses;
    unassigned?: Project[];
}

export class User {
    project_count?: number;
    project_counts?: CoordCountTotals;
    access_token?: string;
    refresh_token?: string;
    cps_group?: string;
    currency?: string;
    current_project_totals?: UserProjectTotals;
    date_added?: string;
    email_address?: string;
    employeeid?: number;
    first_name?: string;
    full_name?: string;
    group?: string;
    country?: string;
    job_title?: string;
    last_name?: string;
    microsoft_id?: string;
    microsoft_graph_token?: string;
    microsoft_tenant_id?: string;
    picture?: string;
    presenter?: boolean;
    region_id?: number;
    resource_color?: string;
    status?: number;
    supervisor_id?: number;
    user_id?: number;
    user_type?: number;
    user_type_name?: string;
    profile_id?: number;
    user_access?: UsersAccess;
    projects?: Project[];
    user_login?: string;
    user_password?: string;
    company_id?: number;
    lang_code?: string;
    market_id?: number;
    user_resources?: UserResources;
    user_sso?: UserSSO;
}

export class CoordCountTotals {
    hundred_percent?: number;
    not_complete?: number;
}

export class ProjectHealthStatuses {
    green_count?: number;
    yellow_count?: number;
    red_count?: number;
    project_count?: number;
    completed_count?: number;
    not_completed_count?: number;
}

export class Project {
    project_health_status?: ProjectHealthStatus;
    coordinator_name?: string;
    coordinator_user_id?: number;
    coordinator_employee_id?: number;
    salesman_email?: string;
    regional_salesman?: string;
    regional_salesman_email?: string;
    divisional_salesman?: string;
    divisional_salesman_email?: string;
    region_name?: string;
    market_name?: string;
    customer_name?: string;
    expected_drawing_completion_date?: string;
    percent_complete?: number;
    rvtVersion?: string;
    active_status?: boolean;
    activedate?: string;
    activity_history?: TrackingActivity[];
    areas?: ProjectArea[];
    bid_date?: string;
    bim360_project?: Bim360Project;
    bill_of_material?: ProjectMaterialData;
    change_orders?: ChangeOrder[];
    changeOrders?: ProjectChangeOrder[];
    cpsservice?: string;
    current_project_type?: string;
    current_project_type_id?: number;
    currency?: string;
    currency_symbol?: string;
    customer_id?: number;
    coordinator?: User;
    customer?: Customer;
    customer_contacts?: CustomerContact[];
    datesubmitted?: string;
    distributor?: string;
    distributor_id?: string;
    division_id?: number;
    divisional_sales?: string;
    divisional_sales_email?: string;
    domestic_requirements?: DomesticRequirement;
    engineerid?: number;
    estimator?: User;
    estimating?: EstimatingScheduleFields;
    group_id?: number;
    hours_fields?: HoursFieldRecord[];
    is_kkto_project?: boolean;
    kkto_status?: string;
    location?: string;
    lostdate?: string;
    lostreason?: string;
    line_sales?: string;
    line_sales_email?: string;
    market?: Market;
    market_id?: number;
    metro_id?: number;
    multiplier_date?: string;
    nda_required?: number;
    notifications?: ProjectNotification[];
    notificationItems?: ProjectNotificationItem[];
    notes?: ProjectNote[];
    offerings?: ProjectOffering[];
    order_number?: string;
    po_date?: string;
    po_amount?: number;
    postbid?: number;
    poaa?: string;
    poaa_date?: string;
    price_list_id?: number;
    probofsale?: string;
    proj_id?: number;
    projid?: number;
    project_distributor_value?: number;
    project_finances?: ProjectFinancesData;
    project_location?: ProjectLocation;
    project_name?: string;
    proj_num?: string;
    price_list?: PriceList;
    projectBIM?: ProjectBIM;
    projectcountry?: string;
    projectstate?: string;
    projectcity?: string;
    project_long?: number;
    project_lat?: number;
    proj_status?: string;
    proj_type?: AdditionalProjectType;
    quote_hours_info?: ProjectQuoteHoursInfo;
    region?: Region;
    regioncode?: string;
    requires_manual_fab_quote?: boolean;
    region_id?: number;
    region_pn?: string;
    regional_sales?: string;
    regional_sales_email?: string;
    salesman?: Salesman;
    salesuser_id?: number;
    schedule?: ProjectSchedule;
    schedule_link?: string;
    sequences?: Sequence[];
    sequenceTrucksAvailable?: SequenceLogistics[];
    software_tracking?: SoftwareTracking;
    solddate?: string;
    sold_financials_updated?: number;
    soldreason?: string;
    standard?: string;
    statusHistory?: ProjectStatusRecord[];
    team_members?: User[];
    turnover_date?: string;
    union_notunion?: string;
    user_id?: number;
    worksheet_date?: string;
    weight_unit?: string;
    // mendix_job_id?: number;
}

export class ProjectLocation {
    address?: string;
    city?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
    region?: string;
    regioncode?: string;
    region_id?: number;
    state?: string;
}

export class ProjectMaterialData {
    systems: System[];
    revisions: Revision[];
    partNumberProfiles: PartNumberProfiles[];
    projectProfiles?: ProjectProfiles[];
    quotes?: SystemQuote[];
}

export class ProjectNoteHistory {
    note_history_id?: number;
    note_id?: number;
    note?: string;
    date_added?: string;
    added_by?: string;
}

export class ProjectNoteType {
    note_type_id?: number;
    note_type?: string;
    note_id?: number;
    note_type_applied_id?: number;
}

export class ProjectNote {
    projid?: number;
    note_id?: number;
    note?: string;
    date_added?: string;
    user_id?: number;
    added_by?: string;
    availableNoteTypes?: ProjectNoteType[];
    appliedNoteTypes?: ProjectNoteType[];
    history?: ProjectNoteHistory[];
}

export class MultipleProjectNotificationsResult {
    proj_id?: number;
    notifications?: ProjectNotification[];
}

export class ProjectNotificationItem {
    proj_notif_id: number;
    proj_id: number;
    notif_type_id: number;
    confirmed: boolean;
    confirmed_date: string;
}

export class ProjectNotification {
    has_notification?: boolean;
    message?: string;
}

export class ProjectOffering {
    unique_id?: number;
    proj_offering_id?: number;
    offeringid?: number;
    offering?: string;
    active?: number;
    for_finances?: number;
    for_change_orders?: number;
    for_additional_change_orders?: number;
    for_salesman_markups?: number;
    billing_category?: string;
    group_as_material?: number;
    group_as_transportation?: number;
    order?: number;
    property?: string;
}

export class ProjectPricing {
    price?: number;
    size?: string;
    multiplier?: number;
    component_id?: number;
    project_pricing_id?: number;
    description?: string;
    partnumber_us?: string;
    partnumber_global?: string;
    partnumber?: string;
}

export class ProjectProfiles {
    project_profile_id?: number;
    project_profile?: string;
    project_profile_desc?: string;
    user?: string;
    user_id?: number;
    proj_id?: number;
    project_desc?: string;
}

export class ProjectQuoteHoursInfo {
    quoted_drawing_hours?: number;
    quoted_drawing_revenue?: number;
}

export class ProjectScheduleDateType {
    date_value?: string;
    date_type?: string;
    date_group?: string;
}

export class ProjectSchedule {
    proj_id: number;
    project_name: string;
    proj_num: string;
    coordinator?: User;
    bim_schedule?: ProjectBimSchedule;
    estimating_schedule?: ProjectEstimatingSchedule;
    sequences: Sequence[];
}

export class ProjectSchedulingCalendarEventColor {
    primary?: string;
    secondary?: string;
}

export class ProjectSearchResult {
    proj_id?: number;
    proj_num?: string;
    project_name?: string;
    region?: string;
    proj_status?: string;
}

export class ProjectSelectOptions {
    additionalProjectTypes?: AdditionalProjectType[];
    availableTemplateProjTypes?: ProjectType[];
    changeOrderStatusTypes?: ChangeOrderStatus[];
    countries?: Country[];
    currencies?: Currency[];
    customers?: Customer[];
    customerCareReps?: CustomerCareRep[];
    customerTypes?: CustomerType[];
    engineers?: Engineer[];
    fabContacts?: FabricationContact[];
    fabShops?: FabricationShop[];
    groups?: VdcGroup[];
    salesRegions?: SalesRegion[];
    hoursFields?: HoursField[];
    hoursFieldsCategories?: HoursFieldCategory[];
    markets?: Market[];
    metros?: MetropolitanArea[];
    offerings?: ProjectOffering[];
    priceLists?: PriceList[];
    projectHealthStatuses?: ProjectHealthStatus[];
    projectStatuses?: ProjectStatus[];
    projectTypes?: ProjectType[];
    quoteFields?: QuoteField[];
    quoteSections?: QuoteSection[];
    quoteWorksheets?: QuoteWorksheet[];
    regions?: Region[];
    salesmen?: Salesman[];
    services?: VdcServiceType[];
    sequencePipeCoatings?: SequencePipeCoating[];
    sequenceDates?: SequenceDateType[];
    sequenceTemplates?: SequenceTemplate[];
    states?: string[];
    provinces?: Province[];
    team_leaders?: User[];
    trackingActivityGroups?: TrackingActivityGroup[];
    transportationBrokers?: TransportationBroker[];
    users?: User[];
    usersAccess?: UsersAccess[];
    projects?: Project[];
    legislativeActs?: LegislativeAct[];
    salesmanTable?: Salesman[];
    gasket_types?: GasketType[];
    bomColumns?: BomColumn[];
    presenters?: User[];
    trainingsTypes?: TrainingType[];
    trainingMarkets?: TrainingMarket[];
    cpsGroups?: string[];
    userGroups?: string[];
    bimHours?: BimHourType[];
    changeOrderAdditionalOfferings?: ProjectOffering[];
    projectNoteTypes?: ProjectNoteType[];
}

export class KeyPerformanceEquipMetric {
    equipment?: string;
    sold?: number;
    final?: number;
}

export class KeyPerformanceProjMetric {
    metric?: string;
    sold?: string;
    current?: string;
    status?: string;
    difference?: string;
}

export class PipingSystemMetric {
    pipingSystem?: string;
}

export class ProjectStats {
    keyPerformanceProjMetircs?: KeyPerformanceProjMetric[];
    keyPerformanceEquipMetrics?: KeyPerformanceEquipMetric[];
    quickStatsMetrics?: QuickStatsMetric[];
    pipingSystems?: PipingSystemMetric[];
}

export class QuickStatsMetric {
    metric?: string;
    current?: number;
    isPercentage?: boolean;
}

export class ProjectStatusRecord {
    status_id?: number;
    projid?: number;
    status?: string;
    reason?: string;
    status_date?: string;
}

export class ProjectStatusUpdateResult {
    projectStatusRecords?: ProjectStatusRecord[];
    missingFields?: string[];
}

export class ProjectStatus {
    project_status_id?: number;
    status_name?: string;
    active?: number;
}

export class ProjectType {
    projtypeid?: number;
    projtype?: string;
    projtypesuffix?: string;
    current_project_type?: string;
    typedate?: string;
    current_project_type_id?: number;
}

export class Province {
    province?: string;
    province_name?: string;
}

export class PtoCalendarData {
    tsid?: number;
    user_id?: number;
    full_name?: string;
    group?: string;
    cps_group?: string;
    date?: string;
    end_date?: string;
    notes?: string;
    full_day?: boolean;
}

export class QuoteDocument {
    latestRevUsed?: number;
    quoteRevisions?: Revision[];
    drawingInfo?: string;
    specInfo?: string;
    pipingSystems?: string[];
    areaLimits?: AreaLimit[];
    notes?: QuoteNote[];
    inclusionsDictionary?: MasterClass;
    inclusionsList?: Inclusion[];
    numberOfSubclasses?: number;
    areaLimitsString?: string;
    pipingSystemsString?: string;
}

export class AreaLimit {
    area?: string;
    areaid?: number;
    value?: string;
    selected?: number;
    equipments_modified?: boolean;
    dwg?: string;
    equipments?: EquipmentItem[];
}

export class QuoteNote {
    master_class?: string;
    sub_class?: string;
    value?: string;
}

export class MasterClass {
    Drawing?: SubClass[];
    General?: SubClass[];
    Material?: SubClass[];
}

export class SubClass {
    index?: number;
    sub_class?: string;
    inclusions?: Inclusion[];
}

export class Inclusion {
    inclusion_id?: number;
    row_id?: number;
    master_class?: string;
    sub_class?: string;
    description?: string;
    default_value?: number;
    order?: number;
    active?: number;
    pha_fab?: number;
    pha_craft?: number;
    drawing?: number;
    oil_gas_fab?: number;
    oil_gas_craft?: number;
    municipal_fab?: number;
    municipal_craft?: number;
    other_visible_when_selected?: number;
    oil_gas_visible_when_selected?: number;
    value?: number;
}

export class QuoteFieldRecord {
    quote_field_id?: number;
    quote_id?: number;
    quote_field_master_id?: number;
    section_id?: number;
    units?: number;
    field_name?: string;
    man_hours?: number;
    calc_value?: number;
    calc_cost?: number;
    active?: number;
    manual?: number;
}

export class QuoteField {
    active?: number;
    quote_field_id?: number;
    worksheet_id?: number;
    section_id?: number;
    field?: string;
    drop_count?: number;
    man_hours?: number;
    totalled?: number;
    order_number?: number;
    pairs?: number;
}

export class HoursFieldCategory {
    category_id?: number;
    category?: string;
}

export class HoursField {
    hfm_id?: number;
    field?: string;
    category?: string;
    hour_category?: number;
    percent?: number;
    section_id?: number;
    estimated?: number;
    active?: number;
}

export class QuoteRevision {
    finance_revision_id?: number;
    finance_revision_name?: string;
    finance_revision_date?: string;
    quoteid?: number;
    proj_id?: number;
    sold?: number;
}

export class QuoteSection {
    section_id?: number;
    section?: string;
}

export class QuoteWorksheet {
    worksheet_id?: number;
    worksheet?: string;
}

export class Region {
    region_id?: number;
    region?: string;
    region_part_number_type?: string;
    unit_of_measure?: string;
    region_code?: string;
    active?: number;
}

export class Revision {
    revision_id: number;
    system_id: number;
    revision_date: string;
    revision_name: string;
    revision?: string;
    user_id?: number;
    forissue?: number;
    latest_sold?: boolean;
    actual_sold?: boolean;
}

export class SalesRegion {
    salesregion_id?: string;
    salesregion_name?: string;
}

export class SalesmanMarkup {
    salesman_markup_id?: number;
    salesmanID?: string;
    offeringid?: number;
    offering?: string;
    dist_markup?: number;
    cust_markup?: number;
}

export class SalesmanMultiplier {
    salesman_multiplier_id?: number;
    salesmanID?: string;
    group_id?: number;
    group_desc?: string;
    value?: number;
}

export class Salesman {
    autoID?: number;
    country?: string;
    email?: string;
    salesmanID?: string;
    metroID?: string;
    metro_id?: string;
    metro?: string;
    name?: string;
    phone?: string;
    salesregion_name?: string;
    salesregion_id?: string;
    group_name?: string;
    groupID?: number;
    countryID?: number;
    group_id?: number;
    divisionID?: string;
    division?: string;
    reports_to?: string;
    reports_to_name?: string;
    inactive?: number;
    longitude?: number;
    latitude?: number;
    market?: string;
}

export class SequenceDateRecord {
    action?: string;
    id?: string;
    sequence_date_id?: number;
    sequence_id: number;
    date_type_id?: number;
    date_type?: SequenceDateType;
    date_value?: string;
    date_note?: string;
    proj_id: number;
    is_confirmed?: boolean;
}

export class SequenceDateType {
    sequence_date_type_id: number;
    date_type: string;
    mendix_managed?: boolean;
}

export class SequenceFabRequestInfo {
    onSiteLocation?: string;
    onSiteDate?: string;
    calculatedHours?: number;
    mendixCalculatedHours?: number;
    truckCount?: number;
    comments?: string;
    subcontractor?: boolean;
    pipeInformation?: MuleSoftPipeInfo[];
    productInformation?: MuleSoftProductInfo[];
}

export class SequenceLogistics {
    logistics_id?: number;
    proj_id?: number;
    sequence_id?: number;
    shipping_date?: string;
    truck_number?: number;
    log_received_date?: string;
    has_shipped?: boolean;
}

export class SequenceMendixAssociation {
    sequence_id: number;
    mendix_id: number;
}

export class SequencePipeCoating {
    coating_type_id: number;
    coating_type: string;
}

export class SequenceTemplate {
    id?: string;
    active?: number;
    template_id: number;
    name: string;
    proj_type_id?: number;
    project_type?: ProjectType;
    sequence_dates: SequenceDateType[];
}

export class Sequence {
    action?: string;
    broker_id?: number;
    broker_name?: string;
    coating_type_id?: number;
    coating_type?: string;
    delivery_address?: string;
    description?: string;
    equipment?: ProjectBillItem[];
    fab_contact_id?: number;
    fab_comments?: string;
    fab_shop_id?: number;
    fab_contact?: FabricationContact;
    fab_shop?: FabricationShop;
    full_name?: string;
    fab_request_info?: SequenceFabRequestInfo;
    header_status?: string;
    id?: string;
    is_complete?: boolean;
    iso_drop_status?: string;
    logistics: SequenceLogistics[];
    mendix_id?: number;
    name: string;
    non_vic_fab_contact?: string;
    non_vic_fab_shop?: string;
    notifications: ProjectNotification[];
    order_number?: number;
    pipe_data?: ProjectBillItem[];
    proj_id: number;
    sequence_color?: string;
    sequence_dates: SequenceDateRecord[];
    sequence_id: number;
    sequence_notes?: string;
    template_id?: number;
    template_name?: string;
    template?: SequenceTemplate;
    transportation_broker?: TransportationBroker;
}

export class SoftwareTracking {
    user_login?: string;
    rvtVersion?: string;
    vtfrVersion?: string;
    fileName?: string;
    proj_id?: number;
    applicationBuild?: string;
    savedPath?: string;
    workShared?: boolean;
    isCentralFile?: boolean;
    timestamp?: string;
}

export class SoldAdminProject {
    index?: number;
    proj_id?: number;
    projid?: number;
    total_project_dist?: number;
    currency?: string;
    region_pn?: string;
    region_id?: number;
    price_list_id?: number;
    project_number?: string;
    project_name?: string;
    price_list_name?: string;
    coordinator?: string;
    sequence_count?: number;
    sequence_errors?: number;
    sequence_missing_dates?: number;
    line_sales?: string;
    regional_sales?: string;
    divisional_sales?: string;
    contractor_name?: string;
    market?: string;
    distributor_po?: string;
    buyer_acceptance_date?: string;
    multiplier_added?: string;
    finance_complete?: string;
    sold_date?: string;
    expected_completion_date?: string;
    onsite_date?: string;
    close_meeting_date?: string;
    ph_status?: string;
    total_sold_material?: string;
    total_sold_material_number?: number;
    total_sold_material_with_buffer?: string;
    current_material?: string;
    current_material_number?: number;
    material_difference?: string;
    percentage_material_difference?: string;
    percentage_material_difference_number?: number;
    quoted_drawing_hours?: number;
    bim_hours_used?: number;
    bim_hours_percentage?: string;
    bim_hours_percentage_number?: number;
    drawing_percentage_complete?: string;
    drawingPercentCompleteNumber?: number;
    estimated_bim_hours?: number;
    efficiency?: number;
    truck_count?: number;
    fab_truck_count?: number;
    transportation?: string;
    fab_hours_quoted?: number;
    fab_hours_actual?: number;
    fab_hours_actual_string?: string;
    difference_fab_hours?: string;
    difference_fab_hours_numbers?: number;
    koil_kit_count?: number;
    koil_kit_value?: string;
    buffer_dollars?: string;
    material_buffer?: string;
    percentage_buffer_used?: string;
    total_project_quoted?: string;
    total_sold_project?: string;
    total_project_actual?: string;
    total_project_actual_number?: number;
    percentage_total_project_difference?: string;
    percentage_total_project_difference_number?: number;
    total_open_change_orders?: string;
    total_internal_change_orders?: string;
    finance_notes?: string;
    signed_cos?: number;
    received_revised_pos?: number;
    percent_billed?: string;
    number_of_billings?: number;
    open?: string;
    open_number?: number;
    amount_billed?: number;
    number_of_areas?: number;
}

export class SplitSystem {
    systems?: System[];
    revisions?: Revision[];
}

export class Spool {
    row_num?: number;
    file_name?: string;
    system_id?: number;
    revision_id?: number;
    name?: string;
}

export class SystemFlips {
    flip_sys_id?: number;
    system_id?: number;
    flip_id?: number;
    labor_rate?: number;
    prod_factor?: number;
}

export class SystemQuote {
    quoteid?: number;
    cost?: number;
    hours?: number;
    quotedate?: string;
    quotename?: string;
    projname?: string;
    projno?: string;
}

export class System {
    system_id?: number;
    system?: string;
    system_desc?: string;
    market_id?: number;
    proj_id?: number;
    revision_id?: number;
    dwg?: string;
    area?: string;
    profile_id?: number;
    project_profile_id?: number;
    quoteid?: number;
    quotename?: string;
    gasket_id?: number;
    gasket_type?: string;
    gasket_value?: string;
}

export class TimesheetHours {
    tsid?: number;
    projid?: number;
    salesno?: number;
    user_id?: number;
    projdate?: string;
    comments?: string;
    notes?: string;
    areaid?: number;
    subareaid?: number;
    approval?: number;
    approvedby?: number;
    datecreated?: string;
}

export class ProjTotalsColumnsLabels {
    label?: string;
    property?: string;
    billing_category?: string;
}

export class TotalFinances {
    type?: string;
    drawing_hours?: string;
    fabrication_hours?: string;
    fab?: string;
    risers?: string;
    kkto_vdc?: string;
    kkto_sales?: string;
    vis?: string;
    skids?: string;
    material?: string;
    transportation?: string;
    headers?: string;
    vipds?: string;
    ahus?: string;
    vdc?: string;
    pipe?: string;
    pipe_support?: string;
    third_party_fab?: string;
    third_party_welding?: string;
    standard_prv?: string;
    vic_tool?: string;
    engineering_services?: string;
    total?: string;
    total_float?: number;
}

export class TotalProjectFinancesData {
    totalFinances?: TotalFinances[];
    projTotalsColumnsLabels?: ProjTotalsColumnsLabels[];
}

export class TrackingActivityGroup {
    activity_group_id?: number;
    group_type?: string;
    active?: number;
}

export class TrackingActivityType {
    activity_type_id: number;
    description: string;
    operation_name: string;
    activity_group_id: number;
}

export class TrackingActivity {
    activity_id?: number;
    activity_type_id: number;
    activity_type?: TrackingActivityType;
    additional_info?: string;
    description?: string;
    proj_id: number;
    timestamp?: string;
    user_id: number;
    user_name?: string;
}

export class TrainingContact {
    contact_id?: number;
    training_id?: number;
    contact_name?: string;
    contact_email?: string;
    contact_company?: string;
}

export class TrainingMarket {
    training_id?: number;
    t_market_id?: number;
    t_market?: string;
    checked?: boolean;
}

export class TrainingType {
    type_id?: number;
    type_var?: string;
    type?: string;
}

export class TransportationBroker {
    broker_id?: number;
    broker_name?: string;
    broker_phone?: string;
}

export class TruckRecord {
    logistics_id?: number;
    quote_id?: number;
    proj_id?: number;
    truck_number?: number;
    ship_date?: string;
    log_received_date?: string;
    shipping_confirmed?: number;
    log_receive_confirmed?: number;
    added_by?: number;
    added_at?: string;
}

export class UserProjectTotals {
    hundred_percent?: number;
    not_complete?: number;
}

export class UserResources {
    user_resource_id?: number;
    user_id?: number;
    hours_per_day?: number;
}

export class UserSSO {
    vdc_user_id?: number;
    microsoft_id?: string;
    bim360_id?: string;
    workday_id?: string;
}

export class UserType {
    user_type_id?: number;
    user_type_name?: string;
}

export class UsersAccess {
    user_id?: number;
    bimcreator?: number;
    d_vtfr?: number;
    d_quotes?: number;
    d_training?: number;
    d_resources?: number;
    d_projects?: number;
    d_highlights?: number;
    p_resources?: number;
    p_finances?: number;
    d_finances?: number;
    cost_compare?: number;
    aic_compare?: number;
    d_emp_hours?: number;
    pn_profile?: number;
    projcreator?: number;
    billviewer?: number;
    billmanager?: number;
    prv?: number;
}

export class UsersGroupedByUserGroup {
    group: string;
    users: User[];
}

export class VdcGroup {
    group_id?: number;
    group_name?: string;
}

export class VdcHighlightsData {
    service?: string;
    location?: string;
    previous_count?: number;
    previous_hours?: number;
    previous_ytd_count?: number;
    previous_ytd_hours?: number;
    ytd_count?: number;
    ytd_hours?: number;
    count_percentage_change?: number;
    hours_percentage_change?: number;
    count_forecast?: number;
    hours_forecast?: number;
}

export class VdcServiceType {
    service_type_id?: number;
    service_name?: string;
    active?: number;
}

export class VdcTvData {
    upcomingDatesProjects?: BimDashboardTable[];
    materialDifference?: BimDashboardTable[];
}

export class VicToolVersion {
    title?: string;
}

export class VicToolsDashboardData {
    title?: string;
    order_id?: number;
    order_date?: string;
    customer?: string;
    city?: string;
    state?: string;
    country?: string;
    customer_contact?: string;
    sales_rep?: string;
    vicToolVersion2030?: number;
    vicToolVersion2029?: number;
    vicToolVersion2028?: number;
    vicToolVersion2027?: number;
    vicToolVersion2026?: number;
    vicToolVersion2025?: number;
    vicToolVersion2024?: number;
    vicToolVersion2023?: number;
    vicToolVersion2022?: number;
    vicToolVersion2021?: number;
    vicToolVersion2020?: number;
    vicToolVersion2019?: number;
    vicToolVersion2018?: number;
    vicToolVersion2017?: number;
    vicToolVersion2016?: number;
    vicToolVersion2015?: number;
    vicToolVersion2014?: number;
    vicToolVersionv14?: number;
    vicToolVersionv15?: number;
    vicToolVersionv16?: number;
    vicToolVersionv17?: number;
    vicToolVersionv18?: number;
    vicToolVersionv19?: number;
    vicToolVersionv20?: number;
    vicToolVersionv21?: number;
    vicToolVersionv22?: number;
    vicToolVersionv23?: number;
    vicToolVersionv24?: number;
    vicToolVersionv25?: number;
    vicToolVersionv26?: number;
    vicToolVersionv27?: number;
    vicToolVersionv28?: number;
    vicToolVersionv29?: number;
    vicToolVersionv30?: number;
    totalExpectedRevenue?: string;
    discountAverage?: string;
    totalRevenue?: string;
    totalSales?: number;
    totalCustomers?: number;
    averageLicenses?: number;
    totalVTFRDownloads?: number;
    sessions?: number[];
    hours?: number[];
}

export class VtfrTrainingsData {
    training_id?: number;
    trainer?: string;
    type?: string;
    duration_num?: number;
    duration_type?: string;
    duration?: string;
    company?: string;
    salesman_name?: string;
    salesman_id?: number;
    pres_date?: string;
    notes?: string;
    sold?: number;
    quote?: number;
    status?: string;
    currency?: string;
    bill_amt?: number;
    bill_amount?: string;
    market?: string;
    markets?: TrainingMarket[];
    contact?: string;
    contacts?: TrainingContact[];
    user_id?: number;
    type_id?: number;
    customer_id?: number;
    expenses?: number;
}

export class VtfrTrainingsByTrainerMetrics {
    metrics?: VtfrTrainingsMetrics[];
    trainingsTypes?: TrainingType[];
}

export class VtfrTrainingsMetrics {
    rem_pres?: number;
    on_pres?: number;
    corp_pres?: number;
    event_pres?: number;
    rem_supp?: number;
    fab_cell?: number;
    pd_trn?: number;
    sa_trn?: number;
    trainer?: string;
    total?: number;
}

export abstract class IMutation {
    abstract addComponentAlternative(input: AddAlternativeInput): ComponentAlternative[] | Promise<ComponentAlternative[]>;

    abstract removeComponentAlternative(input: RemoveAlternativeInput): ComponentAlternative[] | Promise<ComponentAlternative[]>;

    abstract updateComponentSub(input: UpdateComponentSubInput): ComponentAlternative[] | Promise<ComponentAlternative[]>;

    abstract addComponentSize(input: AddComponentSizeInput): Component | Promise<Component>;

    abstract addCustomItem(input: AddCustomItemInput): Component | Promise<Component>;

    abstract deleteComponentImage(input: DeleteComponentImageInput): Component | Promise<Component>;

    abstract updateComponentSize(input: UpdateComponentSizeInput): Component | Promise<Component>;

    abstract updateComponent(input: UpdateComponentInput): Component | Promise<Component>;

    abstract copyPartNumberLink(input: CopyPartNumberLinkInput[]): number | Promise<number>;

    abstract deletePartNumberLink(input: DeletePartNumberLinkInput[]): number[] | Promise<number[]>;

    abstract addPartNumberProfile(input: AddPartNumberProfileInput): PartNumberProfiles | Promise<PartNumberProfiles>;

    abstract updatePartNumberProfile(input: UpdatePartNumberProfileInput): PartNumberProfiles | Promise<PartNumberProfiles>;

    abstract copyProjectPartNumberLink(input: CopyProjectPartNumberLinkInput[]): number | Promise<number>;

    abstract deleteProjectPartNumberLink(input: DeleteProjectPartNumberLinkInput[]): number[] | Promise<number[]>;

    abstract addProjectProfile(input: AddProjectProfileInput): ProjectProfiles[] | Promise<ProjectProfiles[]>;

    abstract updateProjectProfile(input: UpdateProjectProfileInput): ProjectProfiles[] | Promise<ProjectProfiles[]>;

    abstract updateSalesmanMarkups(input: UpdateSalesmanMarkupsInput[]): SalesmanMarkup[] | Promise<SalesmanMarkup[]>;

    abstract updateSalesmanMultipliers(input: UpdateSalesmanMultipliersInput): boolean | Promise<boolean>;

    abstract updateSalesman(input: UpdateSalesmanInput): Salesman | Promise<Salesman>;

    abstract approveChangeOrder(input: ApproveChangeOrderInput): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract declineChangeOrder(input: DeclineChangeOrderInput): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract submitChangeOrderRevision(input: SubmitChangeOrderRevisionInput): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract submitChangeOrder(input: SubmitChangeOrderInput): ProjectChangeOrder[] | Promise<ProjectChangeOrder[]>;

    abstract updateChangeOrderBasicInfo(input: UpdateChangeOrderBasicInfoInput): boolean | Promise<boolean>;

    abstract updateObjectCatalogs(input: UpdateObjectCatalogsInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract addBimAndCoObjectPhotos(input: AddBimAndCoObjectPhotosInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract deleteBimAndCoObjectPhotos(input: DeleteBimAndCoObjectPhotosInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract setDefaultBimAndCoPhoto(input: SetDefaultBimAndCoPhotoInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateModelProperties(input: UpdateModelPropertiesInput[]): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectProperties(input: UpdateObjectPropertiesInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract removeSpotConnections(input: RemoveSpotConnectionsInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectModelProperties(input: UpdateObjectModelPropertiesInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectSpotConnection(input: UpdateObjectSpotConnectionInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectSpotData(input: UpdateSpotDataInput[]): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectSubmittals(input: UpdateObjectSubmittalsInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateSpotSoftware(input: UpdateSpotSoftwareInput[]): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract updateObjectName(input: UpdateObjectNameInput): ContentManagementResponse | Promise<ContentManagementResponse>;

    abstract addQuoteFields(input?: QuoteFieldRecordInput[]): QuoteFieldRecord[] | Promise<QuoteFieldRecord[]>;

    abstract autoPopulateAreaQuotes(input: AutoPopulateAreaQuotesInput): AutoPopulateAreaQuotesResult | Promise<AutoPopulateAreaQuotesResult>;

    abstract createNewAreaPhase(input: CreateNewAreaPhaseInput): ProjectAreaPhase[] | Promise<ProjectAreaPhase[]>;

    abstract createNewPhaseQuote(input: CreateNewPhaseQuoteInput): PhaseQuote | Promise<PhaseQuote>;

    abstract addActiveQuoteField(input: UpdateActiveQuoteFieldInput): QuoteFieldRecord[] | Promise<QuoteFieldRecord[]>;

    abstract clearQuoteFieldsFromSection(input?: QuoteFieldRecordInput[]): QuoteFieldRecord[] | Promise<QuoteFieldRecord[]>;

    abstract createNewProjectArea(input: CreateNewProjectAreaInput): ProjectArea[] | Promise<ProjectArea[]>;

    abstract createNewMasterQuoteField(input: CreateNewMasterQuoteFieldInput): QuoteField[] | Promise<QuoteField[]>;

    abstract deleteQuote(input?: DeleteQuoteInput): PhaseQuote[] | Promise<PhaseQuote[]>;

    abstract insertQuoteScheduleRecord(input: InsertQuoteScheduleRecordInput): Project | Promise<Project>;

    abstract updateAreaPhase(input: UpdateAreaPhaseInput): ProjectAreaPhase[] | Promise<ProjectAreaPhase[]>;

    abstract updateBaseQuoteFields(input?: UpdateBaseQuoteFieldsInput[]): QuoteField[] | Promise<QuoteField[]>;

    abstract updateEstimatingAreaHours(input?: UpdateEstimatingHoursInput): EstimatingHoursField | Promise<EstimatingHoursField>;

    abstract updateFieldManual(input: UpdateFieldManualInput): boolean | Promise<boolean>;

    abstract updateProjectHoursFields(input?: UpdateProjectHoursFieldsInput): HoursFieldRecord[] | Promise<HoursFieldRecord[]>;

    abstract updateProjectOffering(input?: UpdateProjectOfferingsInput): ProjectOffering[] | Promise<ProjectOffering[]>;

    abstract updateQuoteFieldData(input: UpdateQuoteFieldDataInput[]): QuoteFieldRecord[] | Promise<QuoteFieldRecord[]>;

    abstract updateQuoteWorksheetID(input: UpdateQuoteWorksheetIdInput): number | Promise<number>;

    abstract updateQuoteFieldUnits(input?: QuoteFieldRecordInput): QuoteFieldRecord[] | Promise<QuoteFieldRecord[]>;

    abstract updateRiserOffset(input: UpdateRiserOffsetInput): boolean | Promise<boolean>;

    abstract updateUnitsManually(input: UpdateUnitsManuallyInput): boolean | Promise<boolean>;

    abstract resetQuoteEquipment(input: ResetQuoteEquipmentInput): AutoPopulateAreaQuotesResult | Promise<AutoPopulateAreaQuotesResult>;

    abstract updateEstimatingArea(input?: UpdateEstimatingAreaInput): ProjectArea | Promise<ProjectArea>;

    abstract updateQuoteBaseInfo(input: UpdateQuoteBaseInfoInput): PhaseQuote[] | Promise<PhaseQuote[]>;

    abstract addAdditionalFinancesOffering(input: AddAdditionalFinancesOfferingInput): FinancesData | Promise<FinancesData>;

    abstract addFinancesRevision(input: AddFinancesRevisionInput): MtoRevision | Promise<MtoRevision>;

    abstract deleteFinancesRevision(input: DeleteFinancesRevisionInput): MtoRevision | Promise<MtoRevision>;

    abstract editFinancesRevision(input: EditFinancesRevisionInput): MtoRevision | Promise<MtoRevision>;

    abstract fetchLatestData(input: FetchLatestDataInput): LatestData | Promise<LatestData>;

    abstract markFinancesRevisionSold(input: MarkFinancesRevisionSoldInput): QuoteRevision[] | Promise<QuoteRevision[]>;

    abstract removeAdditionalFinancesOffering(input: RemoveAdditionalFinancesOfferingInput): FinancesData | Promise<FinancesData>;

    abstract restoreFinancesRevision(input: RestoreFinancesRevisionInput): QuoteRevision[] | Promise<QuoteRevision[]>;

    abstract revisionMultipleQuotes(input: RevisionMultipleQuotesInput): MtoRevision | Promise<MtoRevision>;

    abstract setFinancesComplete(input: SetFinancesCompleteInput): boolean | Promise<boolean>;

    abstract toggleScheduleImpact(input: UpdateEstimatingAreaInput): boolean | Promise<boolean>;

    abstract toggleUpdatedPo(input: UpdateEstimatingAreaInput): boolean | Promise<boolean>;

    abstract updateChangeOrderStatus(input: UpdateEstimatingAreaInput): boolean | Promise<boolean>;

    abstract updateFinancesHours(input: UpdateFinancesHoursInput): FinancesHours[] | Promise<FinancesHours[]>;

    abstract updateFinancesItem(input: UpdateFinancesItemInput): FinancesData | Promise<FinancesData>;

    abstract updateProjectHolder(input?: number): Project | Promise<Project>;

    abstract addAppliedProjectNoteType(input: AddAppliedProjectNoteTypeInput): ProjectNoteType[] | Promise<ProjectNoteType[]>;

    abstract addCustomerContact(input: AddCustomerContactInput): Project | Promise<Project>;

    abstract addNewProject(input: AddNewProjectInput): Project | Promise<Project>;

    abstract addProjectNote(input: AddProjectNoteInput): ProjectNote | Promise<ProjectNote>;

    abstract addProjectTeamMember(input: UpdateProjectTeamMemberInput): User[] | Promise<User[]>;

    abstract deleteAppliedProjectNoteType(input: DeleteAppliedProjectNoteTypeInput): ProjectNoteType[] | Promise<ProjectNoteType[]>;

    abstract deleteCustomerContact(input: DeleteCustomerContactInput): Project | Promise<Project>;

    abstract deleteProjectNote(input: DeleteProjectNoteInput): ProjectNote[] | Promise<ProjectNote[]>;

    abstract removeProjectTeamMember(input: UpdateProjectTeamMemberInput): User[] | Promise<User[]>;

    abstract setProjectInactive(input: SetProjectInactiveInput): boolean | Promise<boolean>;

    abstract updateCustomerContact(input: UpdateCustomerContactInput): Project | Promise<Project>;

    abstract updateProjectInfoView(input: UpdateProjectInfoViewInput): Project | Promise<Project>;

    abstract updateProjectLocation(input: UpdateProjectLocationInput): ProjectLocation | Promise<ProjectLocation>;

    abstract updateProjectNote(input: UpdateProjectNoteInput): ProjectNote | Promise<ProjectNote>;

    abstract updateProjectStatusDate(input: UpdateProjectStatusDateInput): Project | Promise<Project>;

    abstract updateProjectStatus(input: ProjectStatusUpdateInput): ProjectStatusUpdateResult | Promise<ProjectStatusUpdateResult>;

    abstract updateProjectType(input?: UpdateProjectTypeInput): string | Promise<string>;

    abstract updateProjectBimBase(input: UpdateProjectBimBaseInput): Project | Promise<Project>;

    abstract addNewHeader(input: AddNewHeaderInput): Header[] | Promise<Header[]>;

    abstract addNewMaterialItem(input: UpdateMaterialInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract addNewProjectPricing(input: AddNewProjectPricingInput): ProjectPricing[] | Promise<ProjectPricing[]>;

    abstract applyProfile(input: ApplyProfileInput): boolean | Promise<boolean>;

    abstract applyProjectPricing(proj_id: number): boolean | Promise<boolean>;

    abstract applyProjectProfile(input: ApplyProjectProfileInput): boolean | Promise<boolean>;

    abstract createProjectAncillaries(input: CreateProjectAncillariesInput): ProjectAncillaries | Promise<ProjectAncillaries>;

    abstract deleteHeaders(input: DeleteHeadersInput): Header[] | Promise<Header[]>;

    abstract deleteMaterialItem(input: DeleteMaterialInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract flipComponent(input: FlipComponentInput): boolean | Promise<boolean>;

    abstract flipSystem(input: FlipSystemInput): FlipSystemResult | Promise<FlipSystemResult>;

    abstract groupSystems(input: GroupSystemsInput): GroupedSystems | Promise<GroupedSystems>;

    abstract mapSystemsToQuotes(input: MapSystemsToQuotesInput[]): System[] | Promise<System[]>;

    abstract moveHeaders(input: MoveHeadersInput): Header[] | Promise<Header[]>;

    abstract addNewRevision(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract deleteRevision(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract markRevisionSold(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract restoreRevision(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract revisionMultipleSystems(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract updateRevision(input: MutateRevisionInput): Revision[] | Promise<Revision[]>;

    abstract addNewSystem(input: MutateSystemInput): System[] | Promise<System[]>;

    abstract copySystem(input: MutateSystemInput): System[] | Promise<System[]>;

    abstract deleteSystem(input: MutateSystemInput): System[] | Promise<System[]>;

    abstract updateSystem(input: MutateSystemInput): System[] | Promise<System[]>;

    abstract removePartNumber(input: RemovePartNumberInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract removeProjectPricing(project_pricing_id: number): boolean | Promise<boolean>;

    abstract setGroupMultipliers(input: SetGroupMultipliersInput): boolean | Promise<boolean>;

    abstract splitSystems(input: SplitSystemsInput): SplitSystem | Promise<SplitSystem>;

    abstract updateHeader(input: AddNewHeaderInput): Header[] | Promise<Header[]>;

    abstract updateMaterial(input: UpdateMaterialInput): ProjectBillItem[] | Promise<ProjectBillItem[]>;

    abstract updateProjectPricing(input: UpdateProjectPricingInput): ProjectPricing[] | Promise<ProjectPricing[]>;

    abstract addComponentToProfile(input: UsePartNumberInput): boolean | Promise<boolean>;

    abstract addComponentToProjectProfile(input: UsePartNumberInput): boolean | Promise<boolean>;

    abstract usePartNumberAlways(input: UsePartNumberInput): boolean | Promise<boolean>;

    abstract usePartNumberOnce(input: UsePartNumberInput): boolean | Promise<boolean>;

    abstract addNewCustomer(input: AddNewCustomerInput): ProjectSelectOptions | Promise<ProjectSelectOptions>;

    abstract updateQuoteDocument(input: UpdateQuoteDocumentInput): Project | Promise<Project>;

    abstract addNewSequence(input?: AddNewSequenceInput): Project | Promise<Project>;

    abstract addSequenceDates(input: AddSequenceDateInput[]): Project | Promise<Project>;

    abstract addTemplateDates(input: AddTemplateDatesInput): SequenceTemplate[] | Promise<SequenceTemplate[]>;

    abstract assignTruck(input: AssignTruckInput): Project | Promise<Project>;

    abstract copySequence(input: CopySequenceInput): Project | Promise<Project>;

    abstract createSequenceTemplate(input: CreateSequenceTemplateInput): ProjectSelectOptions | Promise<ProjectSelectOptions>;

    abstract deleteSequenceDate(input: DeleteSequenceDateInput): Project | Promise<Project>;

    abstract deleteSequence(input: DeleteSequenceInput): Project | Promise<Project>;

    abstract deleteTemplateDate(input: DeleteTemplateDateInput): SequenceTemplate[] | Promise<SequenceTemplate[]>;

    abstract submitFabricationRequest(input: SubmitFabricationRequestInput): SubmitFabricationRequestResponse | Promise<SubmitFabricationRequestResponse>;

    abstract unassignTruck(input: UnassignTruckInput): Project | Promise<Project>;

    abstract updateSequenceBase(input: UpdateSequenceBaseInput): Project | Promise<Project>;

    abstract updateSequenceDate(input: UpdateSequenceDateInput): Project | Promise<Project>;

    abstract updateSequenceLogistics(input: UpdateSequenceLogisticsInput): Project | Promise<Project>;

    abstract updateSequencesOrder(input: UpdateSequencesOrderInput[]): boolean | Promise<boolean>;

    abstract updateTemplateBase(input: UpdateTemplateBaseInput): SequenceTemplate[] | Promise<SequenceTemplate[]>;

    abstract addVtfrTrainingLog(input: AddVtfrTrainingLogInput): VtfrTrainingsData[] | Promise<VtfrTrainingsData[]>;

    abstract deleteVtfrTrainingLog(training_id: number): boolean | Promise<boolean>;

    abstract updateVtfrTrainingLog(input: UpdateVtfrTrainingLogInput): VtfrTrainingsData[] | Promise<VtfrTrainingsData[]>;

    abstract updateRecentUserProjects(input: UpdateRecentUserProjectsInput): Project[] | Promise<Project[]>;
}

export class BimAndCoObjectModel {
    Id?: string;
    FileName?: string;
    ViewableId?: string;
    LevelOfDetail?: string;
    Software?: string;
}

export class GetBimAndCoObjectModelsResponse {
    ObjectId?: string;
    Models3D?: BimAndCoObjectModel[];
    Models2D?: BimAndCoObjectModel[];
}

export class SpotSoftwareRecord {
    FilePath?: string;
    FileName?: string;
    Software?: string;
    LastUpdated?: string;
}

export class SpotStatus {
    content_object_name?: string;
    description?: string;
    permalink?: string;
    style?: string;
    last_updated?: string;
    file_path?: string;
    file_name?: string;
    content_software_status_id?: string;
    content_object_id?: string;
    uploaded_bim_and_co?: string;
}

export class AutoPopulateAreaQuotesResult {
    missingEquipment: EquipmentItem[];
    quotes: PhaseQuote[];
    quoteFailed: boolean;
}

export class FlipSystemResult {
    alternatives?: ComponentAlternative[];
    componentData?: Component;
    position?: number;
    totalItems?: number;
    flippedProjectBillItems?: ProjectBillItem[];
}

export class GroupedSystems {
    systems?: System[];
    revisions?: Revision[];
}

export class SubmitFabricationRequestResponse {
    success: boolean;
    message: string;
}

export class VtfrTrainingLogSelectOptions {
    presenters?: User[];
    trainingsTypes?: TrainingType[];
    companies?: Customer[];
    salesman?: Salesman[];
    currencies?: Currency[];
    trainingMarkets?: TrainingMarket[];
    customerTypes?: CustomerType[];
    markets?: Market[];
}
