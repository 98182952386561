import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type AdditionalProjectType = {
  __typename?: 'AdditionalProjectType';
  project_type_id?: Maybe<Scalars['Int']>;
  project_type?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type AreaBim = {
  __typename?: 'AreaBIM';
  area_id?: Maybe<Scalars['Int']>;
  abom_date?: Maybe<Scalars['String']>;
  area_complete?: Maybe<Scalars['Boolean']>;
  area_status?: Maybe<Scalars['String']>;
  cc_delivery_date?: Maybe<Scalars['String']>;
  customer_fabshop_address?: Maybe<Scalars['String']>;
  ep_delivery_date?: Maybe<Scalars['String']>;
  expected_drawing_completion_date?: Maybe<Scalars['String']>;
  long_lead_items?: Maybe<Scalars['Boolean']>;
  header_onsite_date?: Maybe<Scalars['String']>;
  header_status?: Maybe<Scalars['String']>;
  iso_status?: Maybe<Scalars['String']>;
  job_site_address?: Maybe<Scalars['String']>;
  material_to_customer_shop_date?: Maybe<Scalars['String']>;
  model_approval_date?: Maybe<Scalars['String']>;
  proj_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  revizto_created?: Maybe<Scalars['Boolean']>;
  quoted_drawing_hours?: Maybe<Scalars['Int']>;
  quoted_drawing_revenue?: Maybe<Scalars['Float']>;
};

export type AreaFabrication = {
  __typename?: 'AreaFabrication';
  id?: Maybe<Scalars['ID']>;
  area_fab_id?: Maybe<Scalars['Int']>;
  current_fab_cost?: Maybe<Scalars['Float']>;
  current_fab_hours?: Maybe<Scalars['Int']>;
  current_truck_count?: Maybe<Scalars['Int']>;
  cut_count?: Maybe<Scalars['Int']>;
  date_added?: Maybe<Scalars['String']>;
  fabshop_id?: Maybe<Scalars['Int']>;
  fab_shop_start_date?: Maybe<Scalars['String']>;
  logistics?: Maybe<Array<Maybe<TruckRecord>>>;
  onehub_date?: Maybe<Scalars['String']>;
  onsite_date?: Maybe<Scalars['String']>;
  pipe_to_shop_date?: Maybe<Scalars['String']>;
  prv_count?: Maybe<Scalars['Int']>;
  quoted_fab_cost?: Maybe<Scalars['Float']>;
  quoted_fab_hours?: Maybe<Scalars['Int']>;
  quoted_truck_count?: Maybe<Scalars['Int']>;
  couplings?: Maybe<Array<Maybe<CouplingRecord>>>;
  coupling_total_hours?: Maybe<Scalars['Int']>;
  coupling_count?: Maybe<Scalars['Int']>;
  mech_hours?: Maybe<Scalars['Float']>;
  mech_count?: Maybe<Scalars['Int']>;
  fabrication_allowance?: Maybe<Scalars['Float']>;
  fab_hours?: Maybe<Scalars['Int']>;
  fab_total_plus10?: Maybe<Scalars['Float']>;
  fab_total_no_overhead?: Maybe<Scalars['Float']>;
  total_fab_new_estimating?: Maybe<Scalars['Float']>;
  fab_labor_rate?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
  pipecubicFootagetMultiplier?: Maybe<Scalars['Float']>;
  shopMultiplier?: Maybe<Scalars['Float']>;
  truckCubicFootage?: Maybe<Scalars['Int']>;
  lessThan12Diam?: Maybe<Scalars['Float']>;
  twelveTo16Diam?: Maybe<Scalars['Float']>;
  eighteenPlusDiam?: Maybe<Scalars['Float']>;
  lessThan12Shop?: Maybe<Scalars['Float']>;
  twelveTo16Shop?: Maybe<Scalars['Float']>;
  eighteenPlusShop?: Maybe<Scalars['Float']>;
  lessThan12Hours?: Maybe<Scalars['Float']>;
  twelveTo16Hours?: Maybe<Scalars['Float']>;
  eighteenPlusHours?: Maybe<Scalars['Float']>;
  pipe?: Maybe<Array<Maybe<PipeRecord>>>;
  groove_count?: Maybe<Scalars['Int']>;
  pipeCubicFeetNoMultiplier?: Maybe<Scalars['Float']>;
  pipeCubicPlus25?: Maybe<Scalars['Float']>;
  truck_count?: Maybe<Scalars['Int']>;
  pallet_cost?: Maybe<Scalars['Float']>;
  pallet_count?: Maybe<Scalars['Int']>;
  pump_drop_count?: Maybe<Scalars['Int']>;
  ahu_count?: Maybe<Scalars['Int']>;
  header_count?: Maybe<Scalars['Int']>;
};

export type CouplingRecord = {
  __typename?: 'CouplingRecord';
  size?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Int']>;
  diameterInches?: Maybe<Scalars['Float']>;
};

export type PipeRecord = {
  __typename?: 'PipeRecord';
  linear_feet?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  cubic_feet?: Maybe<Scalars['Float']>;
};

export type ProjectAreaPhase = {
  __typename?: 'ProjectAreaPhase';
  area_id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Int']>;
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phase_id?: Maybe<Scalars['Int']>;
  quotes?: Maybe<Array<Maybe<PhaseQuote>>>;
};

export type ProjectArea = {
  __typename?: 'ProjectArea';
  active?: Maybe<Scalars['Int']>;
  areaid?: Maybe<Scalars['Int']>;
  areaBIM?: Maybe<AreaBim>;
  area?: Maybe<Scalars['String']>;
  changeorder?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  estimate?: Maybe<Scalars['Int']>;
  estimatingHours?: Maybe<Array<Maybe<EstimatingHoursField>>>;
  fabcost?: Maybe<Scalars['Int']>;
  areaFabrication?: Maybe<AreaFabrication>;
  fabsketch_hours?: Maybe<Scalars['Float']>;
  iso_hours?: Maybe<Scalars['Float']>;
  layout_hours?: Maybe<Scalars['Float']>;
  marked_for_delete?: Maybe<Scalars['Boolean']>;
  phases?: Maybe<Array<Maybe<ProjectAreaPhase>>>;
  schedule_impact?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  status_date?: Maybe<Scalars['String']>;
  updated_po?: Maybe<Scalars['Int']>;
  quotedate?: Maybe<Scalars['String']>;
  quoteid?: Maybe<Scalars['Int']>;
  quotename?: Maybe<Scalars['String']>;
};

export type AuthenticationKey = {
  __typename?: 'AuthenticationKey';
  auth_key_id?: Maybe<Scalars['Int']>;
  key_for?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type BimAndCoCatalog = {
  __typename?: 'BimAndCoCatalog';
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type BimAndCoModel = {
  __typename?: 'BimAndCoModel';
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  Extension?: Maybe<Scalars['String']>;
  LevelOfDetail?: Maybe<Scalars['String']>;
};

export type BimAndCoObject = {
  __typename?: 'BimAndCoObject';
  Id?: Maybe<Scalars['String']>;
  UpdatedAt?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefaultPhoto?: Maybe<Scalars['String']>;
  Photos?: Maybe<Array<Maybe<BimAndCoPhoto>>>;
  Catalogs?: Maybe<Array<Maybe<BimAndCoCatalog>>>;
  ThreeDModels?: Maybe<Array<Maybe<BimAndCoModel>>>;
  TwoDModels?: Maybe<Array<Maybe<BimAndCoModel>>>;
};

export type BimAndCoPhoto = {
  __typename?: 'BimAndCoPhoto';
  Id?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['String']>;
  Path?: Maybe<Scalars['String']>;
};

export type BimObject = {
  __typename?: 'BimObject';
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Photo?: Maybe<Scalars['String']>;
  Catalog?: Maybe<BimAndCoCatalog>;
};

export type GetBimObjectResponse = {
  __typename?: 'GetBimObjectResponse';
  BimObjects?: Maybe<Array<Maybe<BimObject>>>;
  Catalogs?: Maybe<Array<Maybe<BimAndCoCatalog>>>;
};

export type SpotContentObjectBimTimestamps = {
  __typename?: 'SpotContentObjectBimTimestamps';
  SoftwareType?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
};

export type SpotContentObjectData = {
  __typename?: 'SpotContentObjectData';
  Software?: Maybe<Scalars['String']>;
  FilePath?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  TimeStamp?: Maybe<Scalars['String']>;
};

export type SpotContentObject = {
  __typename?: 'SpotContentObject';
  ObjectName?: Maybe<Scalars['String']>;
  PermaLink?: Maybe<Scalars['String']>;
  PrimeTimestamps?: Maybe<Array<Maybe<SpotContentObjectBimTimestamps>>>;
  SpotData?: Maybe<Array<Maybe<SpotContentObjectData>>>;
};

export type SpotStylesObject = {
  __typename?: 'SpotStylesObject';
  Style?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Permalink?: Maybe<Scalars['String']>;
  Submittal?: Maybe<Scalars['String']>;
};

export type BimCoordinationCalendarEvent = IProjectSchedulingCalendarEvent & {
  __typename?: 'BimCoordinationCalendarEvent';
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  color?: Maybe<ProjectSchedulingCalendarEventColor>;
};

export type BimCoordinationGanttTask = IProjectSchedulingGanttTask & {
  __typename?: 'BimCoordinationGanttTask';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  not_entered?: Maybe<Scalars['String']>;
  coordinator?: Maybe<User>;
};

export type BimCoordinationSchedule = IProjectSchedulingType & {
  __typename?: 'BimCoordinationSchedule';
  gantt_tasks: Array<Maybe<BimCoordinationGanttTask>>;
  calendar_events: Array<Maybe<BimCoordinationCalendarEvent>>;
};

export type BimDashboardData = {
  __typename?: 'BimDashboardData';
  efficiency?: Maybe<Scalars['Float']>;
  materialDifference?: Maybe<Scalars['Float']>;
  projectHoursSold?: Maybe<Scalars['Float']>;
  projectMaterialSold?: Maybe<Scalars['Float']>;
  bimTablesData?: Maybe<Array<Maybe<BimDashboardTable>>>;
  upcomingDatesProjects?: Maybe<Array<Maybe<BimDashboardTable>>>;
  coordinatorsInfoList?: Maybe<Array<Maybe<BimDashboardTable>>>;
};

export type BimDashboardTable = {
  __typename?: 'BimDashboardTable';
  project_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  coordinator?: Maybe<Scalars['String']>;
  quoted_drawing_hours?: Maybe<Scalars['Float']>;
  bim_hours_used?: Maybe<Scalars['Float']>;
  percent_complete?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['Float']>;
  total_sold_material?: Maybe<Scalars['String']>;
  current_material?: Maybe<Scalars['String']>;
  material_difference_dollars?: Maybe<Scalars['String']>;
  material_difference?: Maybe<Scalars['String']>;
  material_difference_number?: Maybe<Scalars['Float']>;
  missing_pn?: Maybe<Scalars['Int']>;
  sequence_count?: Maybe<Scalars['Int']>;
  sequence_errors?: Maybe<Scalars['Int']>;
  sequence_name?: Maybe<Scalars['String']>;
  sequence_missing_dates?: Maybe<Scalars['Int']>;
  sequence_date_value?: Maybe<Scalars['String']>;
  sequence_date_type?: Maybe<Scalars['String']>;
  numberOfProjects?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['String']>;
  amount_number?: Maybe<Scalars['Float']>;
  quote_name?: Maybe<Scalars['String']>;
  status_name?: Maybe<Scalars['String']>;
  percent_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type BimHourType = {
  __typename?: 'BimHourType';
  bim_hour_type_id?: Maybe<Scalars['Int']>;
  bim_hour_type?: Maybe<Scalars['String']>;
  positive?: Maybe<Scalars['Int']>;
};

export type BimDocsAdminParams = {
  __typename?: 'BimDocsAdminParams';
  company_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
};

export type Bim360ProjectCreationParams = {
  __typename?: 'BIM360ProjectCreationParams';
  name: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  proj_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  template_project_Id?: Maybe<Scalars['String']>;
  include_locations?: Maybe<Scalars['Boolean']>;
  include_companies?: Maybe<Scalars['Boolean']>;
};

export type Bim360ProjectFolder = {
  __typename?: 'Bim360ProjectFolder';
  folder_ref_id?: Maybe<Scalars['Int']>;
  proj_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  bim360_project_id?: Maybe<Scalars['String']>;
  bim360_folder_id?: Maybe<Scalars['String']>;
  bim360_parent_id?: Maybe<Scalars['String']>;
  vdc_folder_id: Scalars['Int'];
  vdc_parent_id?: Maybe<Scalars['Int']>;
  has_template_file?: Maybe<Scalars['Boolean']>;
  sub_folders?: Maybe<Array<Maybe<Bim360ProjectFolder>>>;
};

export type Bim360Project = {
  __typename?: 'Bim360Project';
  bim360_proj_id?: Maybe<Scalars['String']>;
  docs_home_page_url?: Maybe<Scalars['String']>;
  vdc_docs_proj_id?: Maybe<Scalars['Int']>;
};

export type BomColumn = {
  __typename?: 'BomColumn';
  column_id: Scalars['Int'];
  column: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  is_split: Scalars['Int'];
  selected_by_default: Scalars['Int'];
  editable: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type ChangeOrderStatus = {
  __typename?: 'ChangeOrderStatus';
  status_id?: Maybe<Scalars['Int']>;
  status_name?: Maybe<Scalars['String']>;
};

export type ChangeOrderBimHourData = {
  __typename?: 'ChangeOrderBimHourData';
  co_bim_hour_id?: Maybe<Scalars['Int']>;
  bim_hour_type_id?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  change_order_id?: Maybe<Scalars['Int']>;
};

export type ChangeOrderComparedBom = {
  __typename?: 'ChangeOrderComparedBom';
  co_compared_bom_id?: Maybe<Scalars['Int']>;
  change_order_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['Int']>;
  pre_revision_id?: Maybe<Scalars['Int']>;
  post_revision_id?: Maybe<Scalars['Int']>;
};

export type ChangeOrderNote = {
  __typename?: 'ChangeOrderNote';
  change_order_id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  date_added?: Maybe<Scalars['String']>;
};

export type ChangeOrderSuggestedValue = {
  __typename?: 'ChangeOrderSuggestedValue';
  co_suggested_value_id?: Maybe<Scalars['Int']>;
  offeringid?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  change_order_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
};

export type ChangeOrder = {
  __typename?: 'ChangeOrder';
  areaid?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  fabcost?: Maybe<Scalars['Int']>;
  fabsketch_hours?: Maybe<Scalars['Float']>;
  schedule_impact?: Maybe<Scalars['Boolean']>;
  iso_hours?: Maybe<Scalars['Float']>;
  layout_hours?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  quotedate?: Maybe<Scalars['String']>;
  quoteid?: Maybe<Scalars['Int']>;
  quotename?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  updated_po?: Maybe<Scalars['Boolean']>;
  drawing_hours?: Maybe<Scalars['Int']>;
  fab_hours?: Maybe<Scalars['Int']>;
  header_count?: Maybe<Scalars['Int']>;
  iso_drop_count?: Maybe<Scalars['Int']>;
  ahu_drop_count?: Maybe<Scalars['Int']>;
  material_dist?: Maybe<Scalars['Float']>;
  cps_dist?: Maybe<Scalars['Float']>;
  fabrication_dist?: Maybe<Scalars['Float']>;
  headers_dist?: Maybe<Scalars['Float']>;
  iso_drop_dist?: Maybe<Scalars['Float']>;
  ahu_drop_dist?: Maybe<Scalars['Float']>;
  finance_date?: Maybe<Scalars['String']>;
  pf_id?: Maybe<Scalars['Int']>;
  koil_kit_count?: Maybe<Scalars['Int']>;
  kk_dist?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Int']>;
  transportation_dist?: Maybe<Scalars['Float']>;
  truck_count?: Maybe<Scalars['Int']>;
};

export type ProjectChangeOrder = {
  __typename?: 'ProjectChangeOrder';
  change_order_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  quoteid?: Maybe<Scalars['Int']>;
  areaid?: Maybe<Scalars['Int']>;
  proj_num?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  change_order_name?: Maybe<Scalars['String']>;
  change_order_description?: Maybe<Scalars['String']>;
  change_order_status?: Maybe<Scalars['String']>;
  change_order_status_id?: Maybe<Scalars['Int']>;
  open_duration?: Maybe<Scalars['String']>;
  finance_revision_name?: Maybe<Scalars['String']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  change_order_number?: Maybe<Scalars['String']>;
  change_order_price?: Maybe<Scalars['String']>;
  bimHoursData?: Maybe<Array<Maybe<ChangeOrderBimHourData>>>;
  comparedBoms?: Maybe<Array<Maybe<ChangeOrderComparedBom>>>;
  changeOrderNotes?: Maybe<Array<Maybe<ProjectNote>>>;
  changeOrderSuggestedValues?: Maybe<Array<Maybe<ChangeOrderSuggestedValue>>>;
  finances?: Maybe<Array<Maybe<Finances>>>;
  financesHours?: Maybe<Array<Maybe<FinancesHours>>>;
  vdcHours?: Maybe<Scalars['Int']>;
  fabHours?: Maybe<Scalars['Int']>;
  materialCost?: Maybe<Scalars['String']>;
  fabricationCost?: Maybe<Scalars['String']>;
  vdcCost?: Maybe<Scalars['String']>;
  transportationCost?: Maybe<Scalars['String']>;
  additionalCost?: Maybe<Scalars['String']>;
  changeOrderNotesString?: Maybe<Scalars['String']>;
  change_order_approved_date?: Maybe<Scalars['String']>;
  change_order_drawing_num?: Maybe<Scalars['String']>;
  updated_po?: Maybe<Scalars['Boolean']>;
};

export type ComponentAlternative = {
  __typename?: 'ComponentAlternative';
  title?: Maybe<Scalars['String']>;
  rowNum?: Maybe<Scalars['Int']>;
  subParts?: Maybe<Array<Maybe<ComponentSubstitute>>>;
};

export type ComponentCategory = {
  __typename?: 'ComponentCategory';
  group_id?: Maybe<Scalars['Int']>;
  group_desc?: Maybe<Scalars['String']>;
  group_char?: Maybe<Scalars['String']>;
};

export type ComponentGroup = {
  __typename?: 'ComponentGroup';
  group_id?: Maybe<Scalars['Int']>;
  group_char?: Maybe<Scalars['String']>;
  group_desc?: Maybe<Scalars['String']>;
  sort_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type ComponentSizeCodes = {
  __typename?: 'ComponentSizeCodes';
  size_code?: Maybe<Scalars['String']>;
  size_desc_imp?: Maybe<Scalars['String']>;
  size_desc_m3?: Maybe<Scalars['String']>;
  row_num?: Maybe<Scalars['Int']>;
};

export type ComponentSizes = {
  __typename?: 'ComponentSizes';
  size_id?: Maybe<Scalars['Int']>;
  size_imp?: Maybe<Scalars['String']>;
  size_met?: Maybe<Scalars['Float']>;
};

export type ComponentSubstitute = {
  __typename?: 'ComponentSubstitute';
  part_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type Component = {
  __typename?: 'Component';
  component_id?: Maybe<Scalars['Int']>;
  partnumber_us?: Maybe<Scalars['String']>;
  partnumber_global?: Maybe<Scalars['String']>;
  partnumber_m3?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  size_desc_m3?: Maybe<Scalars['String']>;
  size_desc_imp?: Maybe<Scalars['String']>;
  concat_component_desc?: Maybe<Scalars['String']>;
  nominal_imp?: Maybe<Scalars['String']>;
  component_desc_m3?: Maybe<Scalars['String']>;
  component_desc?: Maybe<Scalars['String']>;
  style_desc?: Maybe<Scalars['String']>;
  custom_item?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['Int']>;
  style?: Maybe<Scalars['String']>;
  labor_mcaa?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  size_code?: Maybe<Scalars['String']>;
  gasket_type?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  price_usd?: Maybe<Scalars['Float']>;
  price_eur?: Maybe<Scalars['Float']>;
  price_gbp?: Maybe<Scalars['Float']>;
  price_jpy?: Maybe<Scalars['Float']>;
  part_size?: Maybe<Scalars['String']>;
  component_cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  bill_id?: Maybe<Scalars['Int']>;
};

export type ComponentsSelectOptions = {
  __typename?: 'ComponentsSelectOptions';
  partSizes?: Maybe<Array<Maybe<PartSize>>>;
  gasketTypes?: Maybe<Array<Maybe<GasketType>>>;
  componentCategories?: Maybe<Array<Maybe<ComponentCategory>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  regions?: Maybe<Array<Maybe<Region>>>;
};

export type ContentManagementResponse = {
  __typename?: 'ContentManagementResponse';
  responseStatus?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  country_id?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
};

export type CurrencyConversionRate = {
  __typename?: 'CurrencyConversionRate';
  rate_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type Currency = {
  __typename?: 'Currency';
  currency?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerCareRep = {
  __typename?: 'CustomerCareRep';
  rep_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CustomerContact = {
  __typename?: 'CustomerContact';
  _id?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contact_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  office_phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  c_type_id?: Maybe<Scalars['Int']>;
  c_type?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  customer_id?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  c_type_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  market_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['Int']>;
};

export type DamImage = {
  __typename?: 'DamImage';
  smallImageUrl?: Maybe<Scalars['String']>;
  largeImageUrl?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type DomesticRequirement = {
  __typename?: 'DomesticRequirement';
  requirement?: Maybe<Scalars['String']>;
  requirement_note?: Maybe<Scalars['String']>;
};

export type Engineer = {
  __typename?: 'Engineer';
  engineer_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  equipment_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  vic_area_pt?: Maybe<Scalars['String']>;
  vic_system_pt?: Maybe<Scalars['String']>;
  vic_sequence?: Maybe<Scalars['String']>;
  vic_zone?: Maybe<Scalars['String']>;
  mark?: Maybe<Scalars['String']>;
  pairs?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type EstimatingHoursField = {
  __typename?: 'EstimatingHoursField';
  est_id?: Maybe<Scalars['Int']>;
  hfm_id?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Float']>;
  area_id?: Maybe<Scalars['Int']>;
};

export type EstimatingScheduleFields = {
  __typename?: 'EstimatingScheduleFields';
  proj_estimating_id?: Maybe<Scalars['Int']>;
  koil_kit_resource?: Maybe<Scalars['String']>;
  kkto_status?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  mbe_status?: Maybe<Scalars['String']>;
  mbe_resource_start_date?: Maybe<Scalars['String']>;
  mbe_planned_completion_date?: Maybe<Scalars['String']>;
  quote_status?: Maybe<Scalars['String']>;
  quote_resource?: Maybe<Scalars['String']>;
  requested_date_sales?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  vdc_resource?: Maybe<Scalars['String']>;
  mbe_requested_completion_date?: Maybe<Scalars['String']>;
  requested_sales_date_changed?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type FabricationContact = {
  __typename?: 'FabricationContact';
  fab_contact_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type FabricationShop = {
  __typename?: 'FabricationShop';
  fab_shop_id?: Maybe<Scalars['Int']>;
  fab_shop_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  hours_per_day?: Maybe<Scalars['Int']>;
  lattitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Int']>;
};

export type FinanceHoursType = {
  __typename?: 'FinanceHoursType';
  finance_hours_type_id?: Maybe<Scalars['Int']>;
  finance_hours_type?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type FinancesData = {
  __typename?: 'FinancesData';
  financesHours?: Maybe<Array<Maybe<FinancesHours>>>;
  finances?: Maybe<Array<Maybe<Finances>>>;
  materialBreakdown?: Maybe<Array<Maybe<Finances>>>;
  transportationBreakdown?: Maybe<Array<Maybe<Finances>>>;
  additionalOfferings?: Maybe<Array<Maybe<ProjectOffering>>>;
  missingPartNumbers?: Maybe<Scalars['Int']>;
};

export type Finances = {
  __typename?: 'Finances';
  finance_data_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  quoteid?: Maybe<Scalars['Int']>;
  offeringid?: Maybe<Scalars['Int']>;
  offering?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  buffer?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  value_number?: Maybe<Scalars['Float']>;
  value_string?: Maybe<Scalars['String']>;
  value_with_buffer?: Maybe<Scalars['Float']>;
  value_with_buffer_string?: Maybe<Scalars['String']>;
  dist_markup?: Maybe<Scalars['String']>;
  dist_cost?: Maybe<Scalars['Float']>;
  dist_cost_string?: Maybe<Scalars['String']>;
  cust_markup?: Maybe<Scalars['String']>;
  cust_cost?: Maybe<Scalars['Float']>;
  cust_cost_string?: Maybe<Scalars['String']>;
  suggestedDistValue?: Maybe<Scalars['String']>;
  suggestedCustValue?: Maybe<Scalars['String']>;
  group_as_material?: Maybe<Scalars['Int']>;
  group_as_transportation?: Maybe<Scalars['Int']>;
  for_change_orders?: Maybe<Scalars['Int']>;
  for_additional_change_orders?: Maybe<Scalars['Int']>;
};

export type LatestData = {
  __typename?: 'LatestData';
  financesData?: Maybe<FinancesData>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectFinancesData = {
  __typename?: 'ProjectFinancesData';
  quotes?: Maybe<Array<Maybe<PhaseQuote>>>;
  quoteRevisions?: Maybe<Array<Maybe<QuoteRevision>>>;
  changeOrderStatusTypes?: Maybe<Array<Maybe<ChangeOrderStatus>>>;
};

export type FinancesHours = {
  __typename?: 'FinancesHours';
  finance_hours_id?: Maybe<Scalars['Int']>;
  finance_hours_type_id?: Maybe<Scalars['Int']>;
  finance_hours_type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  quoteid?: Maybe<Scalars['Int']>;
};

export type Finish = {
  __typename?: 'Finish';
  finish_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['Int']>;
  finish?: Maybe<Scalars['String']>;
};

export type GasketType = {
  __typename?: 'GasketType';
  gasket_id?: Maybe<Scalars['Int']>;
  gasket_type?: Maybe<Scalars['String']>;
  gasket_value?: Maybe<Scalars['String']>;
};

export type HeaderCoating = {
  __typename?: 'HeaderCoating';
  header_coating_id?: Maybe<Scalars['Int']>;
  header_coating?: Maybe<Scalars['String']>;
};

export type HeaderLining = {
  __typename?: 'HeaderLining';
  header_lining_id?: Maybe<Scalars['Int']>;
  header_lining?: Maybe<Scalars['String']>;
};

export type HeaderMaterial = {
  __typename?: 'HeaderMaterial';
  header_material_id?: Maybe<Scalars['Int']>;
  header_material?: Maybe<Scalars['String']>;
};

export type HeaderPriceList = {
  __typename?: 'HeaderPriceList';
  option_id?: Maybe<Scalars['Int']>;
  price_list_name?: Maybe<Scalars['String']>;
};

export type HeaderSizes = {
  __typename?: 'HeaderSizes';
  nominal_size?: Maybe<Scalars['String']>;
  headersize_id?: Maybe<Scalars['Int']>;
  outlet_size?: Maybe<Scalars['String']>;
};

export type Header = {
  __typename?: 'Header';
  header_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  price_list_id?: Maybe<Scalars['Int']>;
  price_list_name?: Maybe<Scalars['String']>;
  header_name?: Maybe<Scalars['String']>;
  assembly_name?: Maybe<Scalars['String']>;
  bag_tag?: Maybe<Scalars['String']>;
  system_abbr?: Maybe<Scalars['String']>;
  mark?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  pipe_size?: Maybe<Scalars['String']>;
  outlet_size?: Maybe<Scalars['String']>;
  outlet_size2?: Maybe<Scalars['String']>;
  outlet_size3?: Maybe<Scalars['String']>;
  outlet_size4?: Maybe<Scalars['String']>;
  outlet_size5?: Maybe<Scalars['String']>;
  outlet_size6?: Maybe<Scalars['String']>;
  three_quarter_tap?: Maybe<Scalars['Int']>;
  one_tap?: Maybe<Scalars['Int']>;
  one_quarter_tap?: Maybe<Scalars['Int']>;
  one_half_tap?: Maybe<Scalars['Int']>;
  two_tap?: Maybe<Scalars['Int']>;
  pipe_length?: Maybe<Scalars['String']>;
  pipe_length_ft?: Maybe<Scalars['Int']>;
  pipe_length_in?: Maybe<Scalars['Float']>;
  outlet_count?: Maybe<Scalars['Int']>;
  tap_count?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  header_cost?: Maybe<Scalars['Float']>;
  header_cost_ep?: Maybe<Scalars['Float']>;
  current_location?: Maybe<Scalars['String']>;
  header_coating_id?: Maybe<Scalars['Int']>;
  header_coating?: Maybe<Scalars['String']>;
  header_lining_id?: Maybe<Scalars['Int']>;
  header_lining?: Maybe<Scalars['String']>;
  header_material_id?: Maybe<Scalars['Int']>;
  header_material?: Maybe<Scalars['String']>;
};

export type HeadersSelectOptions = {
  __typename?: 'HeadersSelectOptions';
  price_list?: Maybe<Array<Maybe<HeaderPriceList>>>;
  areas?: Maybe<Array<Maybe<ProjectBillItem>>>;
  zones?: Maybe<Array<Maybe<ProjectBillItem>>>;
  sequences?: Maybe<Array<Maybe<ProjectBillItem>>>;
  mainPipeSizes?: Maybe<Array<Maybe<HeaderSizes>>>;
  headerCoatings?: Maybe<Array<Maybe<HeaderCoating>>>;
  headerLinings?: Maybe<Array<Maybe<HeaderLining>>>;
  headerMaterials?: Maybe<Array<Maybe<HeaderMaterial>>>;
};

export type HoursFieldRecord = {
  __typename?: 'HoursFieldRecord';
  pf_id?: Maybe<Scalars['Int']>;
  hfm_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
};

export type KickoffChecklist = {
  __typename?: 'KickoffChecklist';
  bim_execution_plan: BimExecutionPlan;
  contract_drawings: ContractDrawingChecklist;
  control_valves: ControlValvesPackage;
  customer_fabrication_preferences: CustomerFabricationPreferences;
  drawing_requirements: DrawingRequirements;
  equipment_submittals: EquipmentSubmittalInfo;
  general_info: ProjectKickoffGeneralInfo;
  hanger_support_specification: HangerSupportSpecification;
  header_elevation_info: PipeHeaderElevationInfo;
  housekeeping_pad_info: HousekeepingPadInfo;
  insulation_specification: InsulationSpecification;
  noted_issues: Scalars['String'];
  material_delivery_requirements: MaterialDeliveryRequirements;
  mechanical_specification: MechanicalSpecification;
  modeling_guidelines: ModelingGuidelines;
  pipe_elevation_info: PipeElevationInfo;
  pipe_length_info: PipeLengthInfo;
  pipe_material_specification: PipeMaterialSpecification;
  proj_id: Scalars['Int'];
  projid: Scalars['Int'];
  reducers_info: ReducersInfo;
  schedule_areas: ScheduleAreaInfo;
  tap_requirements: TapRequirementsInfo;
  vibration_isolation_info: VibrationIsolationInfo;
  vic_material_guidelines: VicMaterialGuidelines;
};

export type BimExecutionPlan = {
  __typename?: 'BimExecutionPlan';
  _id?: Maybe<Scalars['String']>;
  has_bim_execution_plan?: Maybe<Scalars['Boolean']>;
  bim_execution_plan_received_date?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type ContractDrawingRecord = {
  __typename?: 'ContractDrawingRecord';
  _id?: Maybe<Scalars['String']>;
  contract_drawing_type?: Maybe<ContractDrawingType>;
  drawing_received?: Maybe<Scalars['Boolean']>;
  drawing_received_date?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type ContractDrawingType = {
  __typename?: 'ContractDrawingType';
  _id?: Maybe<Scalars['String']>;
  drawing_type?: Maybe<Scalars['String']>;
};

export type ContractDrawingChecklist = {
  __typename?: 'ContractDrawingChecklist';
  _id?: Maybe<Scalars['String']>;
  contract_drawings?: Maybe<Array<Maybe<ContractDrawingRecord>>>;
};

export type ControlValvesPackage = {
  __typename?: 'ControlValvesPackage';
  _id?: Maybe<Scalars['String']>;
  control_package_approved?: Maybe<Scalars['Boolean']>;
  control_package_received?: Maybe<Scalars['Boolean']>;
  control_valve_submittal_type?: Maybe<Scalars['String']>;
  control_valve_submittal_approval_received?: Maybe<Scalars['String']>;
  flow_diameters_upstream?: Maybe<Scalars['String']>;
  flow_diameters_downstream?: Maybe<Scalars['String']>;
  flow_pressure_rating?: Maybe<Scalars['String']>;
  vic_control_valves_used?: Maybe<Scalars['Boolean']>;
  valves_provided_by_vic?: Maybe<Scalars['Boolean']>;
  temperature_requirements?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type CustomerFabricationPreferences = {
  __typename?: 'CustomerFabricationPreferences';
  _id?: Maybe<Scalars['String']>;
  fabrication_software_used?: Maybe<Scalars['String']>;
  flanged_gasket_thickness?: Maybe<Scalars['String']>;
  field_groove_tool_model_type?: Maybe<Scalars['String']>;
  groove_tool_available_in_field?: Maybe<Scalars['Boolean']>;
  extra_length_pipe_permitted?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  preferred_cut_length?: Maybe<Scalars['String']>;
};

export type DrawingRequirements = {
  __typename?: 'DrawingRequirements';
  _id?: Maybe<Scalars['String']>;
  bim360_glue_version?: Maybe<Scalars['String']>;
  customer_title_block_name_and_conventions_received_date?: Maybe<Scalars['String']>;
  ftp_coordination_site?: Maybe<Scalars['String']>;
  imperial_drawing_size_required_for_layout?: Maybe<Scalars['String']>;
  metric_drawing_size_required_for_layout?: Maybe<Scalars['String']>;
  navisworks_version?: Maybe<Scalars['String']>;
  other_coordination_software?: Maybe<Scalars['String']>;
  other_metric_drawing_size_required_for_layout?: Maybe<Scalars['String']>;
  revit_version?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  navisworks_used?: Maybe<Scalars['Boolean']>;
  revitzo_used?: Maybe<Scalars['Boolean']>;
  bim360_glue_used?: Maybe<Scalars['Boolean']>;
  ftp_site_used?: Maybe<Scalars['Boolean']>;
  drawing_scale_requirements?: Maybe<Scalars['String']>;
};

export type EquipmentSubmittalRecord = {
  __typename?: 'EquipmentSubmittalRecord';
  _id?: Maybe<Scalars['String']>;
  boiler_requirements?: Maybe<Scalars['String']>;
  connection_type?: Maybe<Scalars['String']>;
  date_received?: Maybe<Scalars['String']>;
  dunnage_support_steel_status?: Maybe<Scalars['String']>;
  equipment_tag?: Maybe<Scalars['String']>;
  has_boiler_requirement?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  pad_height?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittal_type?: Maybe<EquipmentSubmittalType>;
  submittal_received?: Maybe<Scalars['Boolean']>;
  vibration_isolator_status?: Maybe<Scalars['String']>;
};

export type EquipmentSubmittalType = {
  __typename?: 'EquipmentSubmittalType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pad_label?: Maybe<Scalars['String']>;
};

export type EquipmentSubmittalInfo = {
  __typename?: 'EquipmentSubmittalInfo';
  submittals: Array<Maybe<EquipmentSubmittalRecord>>;
};

export type HangerSupportSpecification = {
  __typename?: 'HangerSupportSpecification';
  _id?: Maybe<Scalars['String']>;
  current_hanger_support_specification?: Maybe<Scalars['String']>;
  hanger_point_layout_requirement?: Maybe<Scalars['Boolean']>;
  guides_and_anchors_required?: Maybe<Scalars['Boolean']>;
  seismic_support_requirements?: Maybe<Scalars['Boolean']>;
  has_spacing_requirements?: Maybe<Scalars['Boolean']>;
  spacing_requirements?: Maybe<Scalars['String']>;
  structural_racking_support_requirements?: Maybe<Scalars['Boolean']>;
  clevis_support_system?: Maybe<Scalars['Boolean']>;
  trapeze_support_system?: Maybe<Scalars['Boolean']>;
  roller_support_system?: Maybe<Scalars['Boolean']>;
  supported_from_above?: Maybe<Scalars['Boolean']>;
  supported_from_below?: Maybe<Scalars['Boolean']>;
  supported_from_other?: Maybe<Scalars['Boolean']>;
  supported_from_other_type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type HousekeepingPadInfo = {
  __typename?: 'HousekeepingPadInfo';
  _id?: Maybe<Scalars['String']>;
  ahu_pads_set_by?: Maybe<Scalars['String']>;
  air_handling_units?: Maybe<Scalars['String']>;
  air_handling_units_not_apply?: Maybe<Scalars['Boolean']>;
  customer_pouring_pads?: Maybe<Scalars['Boolean']>;
  pad_extension_beyond_base?: Maybe<Scalars['String']>;
  pads_being_located_by?: Maybe<Scalars['String']>;
  single_large_pad_permitted?: Maybe<Scalars['Boolean']>;
  true_or_nominal?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type InsulationSpecification = {
  __typename?: 'InsulationSpecification';
  _id?: Maybe<Scalars['String']>;
  insulation_types?: Maybe<Array<Maybe<InsulationTypeRecord>>>;
  notes?: Maybe<Scalars['String']>;
};

export type InsulationType = {
  __typename?: 'InsulationType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InsulationTypeRecord = {
  __typename?: 'InsulationTypeRecord';
  _id?: Maybe<Scalars['String']>;
  base_insulation_type?: Maybe<InsulationType>;
  insulation_type?: Maybe<Scalars['String']>;
  insulation_material?: Maybe<Scalars['String']>;
  max_size?: Maybe<Scalars['String']>;
  min_size?: Maybe<Scalars['String']>;
  pipe_type?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
};

export type KickoffIssueResolution = {
  __typename?: 'KickoffIssueResolution';
  notes?: Maybe<Scalars['String']>;
};

export type MaterialDeliveryRequirements = {
  __typename?: 'MaterialDeliveryRequirements';
  _id?: Maybe<Scalars['String']>;
  area_to_receive_and_stage_pipe?: Maybe<Scalars['Boolean']>;
  has_delivery_requirements?: Maybe<Scalars['Boolean']>;
  delivery_requirements?: Maybe<Scalars['String']>;
  flatbed_trucked_permitted?: Maybe<Scalars['Boolean']>;
  required_truck_type?: Maybe<Scalars['String']>;
  lift_available?: Maybe<Scalars['Boolean']>;
  has_packing_requirements?: Maybe<Scalars['Boolean']>;
  packaging_requirements?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  not_emeai_job?: Maybe<Scalars['Boolean']>;
  emeai_requires_delivery_by_appointment?: Maybe<Scalars['Boolean']>;
  emeai_truck_type?: Maybe<Scalars['String']>;
  emeai_truck_conditions?: Maybe<Scalars['String']>;
};

export type MechanicalSpecification = {
  __typename?: 'MechanicalSpecification';
  _id?: Maybe<Scalars['String']>;
  section_id?: Maybe<Scalars['String']>;
  current_mechanical_spec_date?: Maybe<Scalars['String']>;
  current_mechanical_spec_revision?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type ConnectionType = {
  __typename?: 'ConnectionType';
  _id?: Maybe<Scalars['String']>;
  has_connection_type?: Maybe<Scalars['String']>;
  limitations?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  welded_connection_type?: Maybe<Scalars['String']>;
  branch_length_determined_by?: Maybe<Scalars['String']>;
  branch_length_notes?: Maybe<Scalars['String']>;
  fab_shop_weld_gap_size?: Maybe<Scalars['String']>;
  fishmouth_connection_used?: Maybe<Scalars['Boolean']>;
  stabin_connection_used?: Maybe<Scalars['Boolean']>;
  weldolet_connection_used?: Maybe<Scalars['Boolean']>;
  threadolet_connection_used?: Maybe<Scalars['Boolean']>;
};

export type ModelingGuidelines = {
  __typename?: 'ModelingGuidelines';
  _id?: Maybe<Scalars['String']>;
  non_vic_flanges_used?: Maybe<Scalars['String']>;
  slip_on_flange_reveal_depth?: Maybe<Scalars['String']>;
  reducing_branch_connections?: Maybe<Array<Maybe<ConnectionType>>>;
  notes?: Maybe<Scalars['String']>;
};

export type PipeElevationInfo = {
  __typename?: 'PipeElevationInfo';
  _id?: Maybe<Scalars['String']>;
  cooling_tower_elevations_reference?: Maybe<Scalars['String']>;
  cooling_tower_elevations_reference_other?: Maybe<Scalars['String']>;
  equipment_set_from?: Maybe<Scalars['String']>;
  feet_and_inches_ok?: Maybe<Scalars['Boolean']>;
  feet_and_inches_alternative?: Maybe<Scalars['String']>;
  header_elevations_spools_held?: Maybe<Scalars['String']>;
  header_elevations_spools_held_other?: Maybe<Scalars['String']>;
  preferred_equipment_reference_point?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type PipeHeaderElevationInfo = {
  __typename?: 'PipeHeaderElevationInfo';
  _id?: Maybe<Scalars['String']>;
  preferred_elevation_for_pipe_runs?: Maybe<Scalars['Boolean']>;
  preferred_elevation_info?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type PipeLengthInfo = {
  __typename?: 'PipeLengthInfo';
  _id?: Maybe<Scalars['String']>;
  pipe_length?: Maybe<Scalars['String']>;
  pipe_length_specification?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type PipeMaterialSpecification = {
  __typename?: 'PipeMaterialSpecification';
  _id?: Maybe<Scalars['String']>;
  carbon_steel_to_be_astm?: Maybe<Scalars['String']>;
  has_chain_wheel_requirement?: Maybe<Scalars['Boolean']>;
  chain_wheel_required_size_from?: Maybe<Scalars['String']>;
  chain_wheel_required_size_to?: Maybe<Scalars['String']>;
  chain_wheel_above_requirement?: Maybe<Scalars['String']>;
  chain_wheel_bottom_valve_or_cl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  pipe_grade?: Maybe<Scalars['String']>;
  pipe_schedule?: Maybe<Scalars['String']>;
  pipe_spec_type?: Maybe<Scalars['String']>;
  pipe_spec_other_type?: Maybe<Scalars['String']>;
  standard_weight_12in_larger?: Maybe<Scalars['Boolean']>;
  standard_weight_12in_larger_not_apply?: Maybe<Scalars['Boolean']>;
  valve_gear_handle_from?: Maybe<Scalars['String']>;
  valve_gear_handle_to?: Maybe<Scalars['String']>;
  valve_level_handle_from?: Maybe<Scalars['String']>;
  valve_level_handle_to?: Maybe<Scalars['String']>;
  pipe_types?: Maybe<Array<Maybe<PipeType>>>;
};

export type ProjectKickoffGeneralInfo = {
  __typename?: 'ProjectKickoffGeneralInfo';
  _id?: Maybe<Scalars['String']>;
  additional_addresses?: Maybe<Array<Maybe<AdditionalAddress>>>;
  coordinator_office_phone?: Maybe<Scalars['String']>;
  coordinator_title?: Maybe<Scalars['String']>;
  customer_care_email?: Maybe<Scalars['String']>;
  customer_care_phone?: Maybe<Scalars['String']>;
  customer_contacts?: Maybe<Array<Maybe<CustomerContact>>>;
  delivery_address?: Maybe<Scalars['String']>;
  headers_delivered_to?: Maybe<Scalars['String']>;
  jobsite_address?: Maybe<Scalars['String']>;
  kickoff_meeting_date?: Maybe<Scalars['String']>;
  line_sales_phone?: Maybe<Scalars['String']>;
  line_sales_email?: Maybe<Scalars['String']>;
  mailing_address?: Maybe<Scalars['String']>;
  material_delivered_to_jobsite?: Maybe<Scalars['Boolean']>;
  regional_sales_phone?: Maybe<Scalars['String']>;
  regional_sales_email?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AdditionalAddress = {
  __typename?: 'AdditionalAddress';
  address?: Maybe<Scalars['String']>;
  address_type?: Maybe<Scalars['String']>;
};

export type ReducersInfo = {
  __typename?: 'ReducersInfo';
  _id?: Maybe<Scalars['String']>;
  type_for_horizontal_pipe?: Maybe<Scalars['String']>;
  eccentric_horizontal_position?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type ScheduleArea = {
  __typename?: 'ScheduleArea';
  _id?: Maybe<Scalars['String']>;
  area_name?: Maybe<Scalars['String']>;
  confirmed_coordination_date?: Maybe<Scalars['String']>;
  confirmed_drawings_date?: Maybe<Scalars['String']>;
  confirmed_onsite_date?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  quote_id?: Maybe<Scalars['Int']>;
  requested_coordination_date?: Maybe<Scalars['String']>;
  requested_drawings_date?: Maybe<Scalars['String']>;
  requested_onsite_date?: Maybe<Scalars['String']>;
};

export type ScheduleAreaInfo = {
  __typename?: 'ScheduleAreaInfo';
  _id?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<ScheduleArea>>>;
};

export type TapRequirement = {
  __typename?: 'TapRequirement';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  submittal_received?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TapRequirementsInfo = {
  __typename?: 'TapRequirementsInfo';
  _id?: Maybe<Scalars['String']>;
  tap_requirements?: Maybe<Array<Maybe<TapRequirement>>>;
  notes?: Maybe<Scalars['String']>;
};

export type VicMaterialGuidelines = {
  __typename?: 'VicMaterialGuidelines';
  _id?: Maybe<Scalars['String']>;
  signed_approval_for_full_vic_usage?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type VibrationIsolationInfo = {
  __typename?: 'VibrationIsolationInfo';
  _id?: Maybe<Scalars['String']>;
  flex_coupling_not_acceptable_location?: Maybe<Scalars['Boolean']>;
  not_acceptable_explanation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type LegislativeAct = {
  __typename?: 'LegislativeAct';
  act_id?: Maybe<Scalars['Int']>;
  act_name?: Maybe<Scalars['String']>;
};

export type Market = {
  __typename?: 'Market';
  market_id?: Maybe<Scalars['Int']>;
  market?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type MaterialListData = {
  __typename?: 'MaterialListData';
  boms?: Maybe<Array<Maybe<System>>>;
  partNumberProfiles?: Maybe<Array<Maybe<PartNumberProfiles>>>;
};

export type MetropolitanArea = {
  __typename?: 'MetropolitanArea';
  metro_id?: Maybe<Scalars['String']>;
  metro?: Maybe<Scalars['String']>;
};

export type MtoRevision = {
  __typename?: 'MtoRevision';
  quoteRevisions?: Maybe<Array<Maybe<QuoteRevision>>>;
  bomRevisions?: Maybe<Array<Maybe<Revision>>>;
};

export type MuleSoftCouplingInfo = {
  __typename?: 'MuleSoftCouplingInfo';
  shop?: Maybe<Array<Maybe<MuleSoftProductInfoType>>>;
  field?: Maybe<Array<Maybe<MuleSoftProductInfoType>>>;
};

export type MuleSoftPipeCutInfo = {
  __typename?: 'MuleSoftPipeCutInfo';
  count?: Maybe<Scalars['Int']>;
};

export type MuleSoftGrooveInfo = {
  __typename?: 'MuleSoftGrooveInfo';
  count?: Maybe<Scalars['Int']>;
  endPreparation?: Maybe<Scalars['String']>;
};

export type MendixSpoolStatus = {
  __typename?: 'MendixSpoolStatus';
  spool_status_id?: Maybe<Scalars['Int']>;
  spool_status?: Maybe<Scalars['String']>;
  spool_status_name?: Maybe<Scalars['String']>;
  spool_status_color?: Maybe<Scalars['String']>;
};

export type MuleSoftPipeInfo = {
  __typename?: 'MuleSoftPipeInfo';
  description?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  grooves?: Maybe<Array<Maybe<MuleSoftGrooveInfo>>>;
  cuts?: Maybe<MuleSoftPipeCutInfo>;
};

export type MuleSoftProductInfoType = {
  __typename?: 'MuleSoftProductInfoType';
  productId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
};

export type MuleSoftProductInfo = {
  __typename?: 'MuleSoftProductInfo';
  coupling?: Maybe<Array<Maybe<MuleSoftCouplingInfo>>>;
  holeCut?: Maybe<Array<Maybe<MuleSoftProductInfoType>>>;
};

export type PartNumberProfiles = {
  __typename?: 'PartNumberProfiles';
  profile_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Scalars['String']>;
  profile_desc?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
};

export type PartSize = {
  __typename?: 'PartSize';
  size_code?: Maybe<Scalars['String']>;
  part_size?: Maybe<Scalars['String']>;
};

export type PartNumberLink = {
  __typename?: 'PartNumberLink';
  link_id?: Maybe<Scalars['Int']>;
  component_id?: Maybe<Scalars['Int']>;
  rev_size?: Maybe<Scalars['String']>;
  rev_desc?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  project_profile_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
};

export type PhaseQuote = {
  __typename?: 'PhaseQuote';
  drawing_numbers?: Maybe<Scalars['String']>;
  drawing_revision?: Maybe<Scalars['String']>;
  limits?: Maybe<Scalars['String']>;
  phase_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  areaid?: Maybe<Scalars['Int']>;
  quote_fields?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  quote_name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rate_calc?: Maybe<Scalars['Float']>;
  riser_offset?: Maybe<Scalars['Float']>;
  total_layout?: Maybe<Scalars['Int']>;
  worksheet_id?: Maybe<Scalars['Int']>;
  last_updated?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['Int']>;
  change_order?: Maybe<Scalars['Int']>;
  change_order_id?: Maybe<Scalars['Int']>;
  schedule_impact?: Maybe<Scalars['Int']>;
  updated_po?: Maybe<Scalars['Int']>;
  status_id?: Maybe<Scalars['Int']>;
};

export type PipeType = {
  __typename?: 'PipeType';
  pipe_type_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  specification?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  coating?: Maybe<Scalars['String']>;
  construction_method?: Maybe<Scalars['String']>;
};

export type PreBidDashboardData = {
  __typename?: 'PreBidDashboardData';
  activeSuccessRate?: Maybe<Scalars['Float']>;
  modelCompletionSuccessRate?: Maybe<Scalars['Int']>;
  mtoModelCompletionSuccessRate?: Maybe<Scalars['Int']>;
  daysStatusIsEP?: Maybe<Scalars['Int']>;
  averageOfQuotes?: Maybe<Scalars['Float']>;
  legislativeDaysToQuote?: Maybe<Scalars['Float']>;
  nonLegislativeDaysToQuote?: Maybe<Scalars['Float']>;
  allProjectsDaysToQuote?: Maybe<Scalars['Float']>;
  legislativeHoursToQuote?: Maybe<Scalars['Float']>;
  nonLegislativeHoursToQuote?: Maybe<Scalars['Float']>;
  allProjectsHoursToQuote?: Maybe<Scalars['Float']>;
  departmentTimeToQuote?: Maybe<Array<Maybe<Scalars['Float']>>>;
  collectiveHoursToQuote?: Maybe<Array<Maybe<Scalars['Float']>>>;
  quotesByServiceType?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PreBidModelResourceCalendarEvent = IProjectSchedulingCalendarEvent & {
  __typename?: 'PreBidModelResourceCalendarEvent';
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  color?: Maybe<ProjectSchedulingCalendarEventColor>;
};

export type PrebidModelResourceTask = IProjectSchedulingGanttTask & {
  __typename?: 'PrebidModelResourceTask';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  not_entered?: Maybe<Scalars['String']>;
  coordinator?: Maybe<User>;
  mbe_planned_completion_date?: Maybe<Scalars['String']>;
  model_submission_date?: Maybe<Scalars['String']>;
  quality_control_date?: Maybe<Scalars['String']>;
  requested_date_sales?: Maybe<Scalars['String']>;
  proj_id?: Maybe<Scalars['Int']>;
  proj_base_name?: Maybe<Scalars['String']>;
  date_key?: Maybe<Scalars['String']>;
  model_status?: Maybe<Scalars['String']>;
  project_status?: Maybe<Scalars['String']>;
  model_resource?: Maybe<Scalars['String']>;
  status_color?: Maybe<Scalars['String']>;
  project_status_color?: Maybe<Scalars['String']>;
};

export type PreBidModelResourceSchedule = IProjectSchedulingType & {
  __typename?: 'PreBidModelResourceSchedule';
  gantt_tasks: Array<Maybe<PrebidModelResourceTask>>;
  calendar_events: Array<Maybe<PreBidModelResourceCalendarEvent>>;
};

export type PreBidQualityControlCalendarEvent = IProjectSchedulingCalendarEvent & {
  __typename?: 'PreBidQualityControlCalendarEvent';
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  color?: Maybe<ProjectSchedulingCalendarEventColor>;
};

export type PrebidQualityControlResourceTask = IProjectSchedulingGanttTask & {
  __typename?: 'PrebidQualityControlResourceTask';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  not_entered?: Maybe<Scalars['String']>;
  coordinator?: Maybe<User>;
};

export type PreBidQualityControlResourceSchedule = IProjectSchedulingType & {
  __typename?: 'PreBidQualityControlResourceSchedule';
  gantt_tasks: Array<Maybe<PrebidQualityControlResourceTask>>;
  calendar_events: Array<Maybe<PreBidQualityControlCalendarEvent>>;
};

export type PreBidResourcesSchedule = {
  __typename?: 'PreBidResourcesSchedule';
  id: Scalars['ID'];
  model_resources_schedule: PreBidModelResourceSchedule;
  quality_control_resources_schedule: PreBidQualityControlResourceSchedule;
};

export type PredictedPartNumbers = {
  __typename?: 'PredictedPartNumbers';
  components?: Maybe<Array<Maybe<Component>>>;
  user_type: Scalars['Int'];
  pn_profile: Scalars['Int'];
};

export type PriceList = {
  __typename?: 'PriceList';
  price_list_id?: Maybe<Scalars['Int']>;
  price_list_name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
};

export type ProfilePartNumbers = {
  __typename?: 'ProfilePartNumbers';
  component_id?: Maybe<Scalars['Int']>;
  link_id?: Maybe<Scalars['Int']>;
  linked_date?: Maybe<Scalars['String']>;
  rev_size?: Maybe<Scalars['String']>;
  rev_desc?: Maybe<Scalars['String']>;
  partnumber_us?: Maybe<Scalars['String']>;
  partnumber_global?: Maybe<Scalars['String']>;
  linked_size?: Maybe<Scalars['String']>;
  linked_desc?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  project_profile_id?: Maybe<Scalars['Int']>;
};

export type ProjectAncillaries = {
  __typename?: 'ProjectAncillaries';
  newSystems?: Maybe<Array<Maybe<System>>>;
  newRevisions?: Maybe<Array<Maybe<Revision>>>;
};

export type ProjectBillItem = {
  __typename?: 'ProjectBillItem';
  component_id?: Maybe<Scalars['Int']>;
  equipment_id?: Maybe<Scalars['Int']>;
  cat_id?: Maybe<Scalars['Int']>;
  compsort?: Maybe<Scalars['Int']>;
  typical?: Maybe<Scalars['Int']>;
  bill_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['Int']>;
  flip_id?: Maybe<Scalars['Int']>;
  revision_id?: Maybe<Scalars['Int']>;
  vh_id?: Maybe<Scalars['Int']>;
  seq_id?: Maybe<Scalars['Int']>;
  tap_count?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  multiplier?: Maybe<Scalars['Float']>;
  rev_desc?: Maybe<Scalars['String']>;
  rev_size?: Maybe<Scalars['String']>;
  pipe_length?: Maybe<Scalars['String']>;
  system_abbr?: Maybe<Scalars['String']>;
  assembly_name?: Maybe<Scalars['String']>;
  mark?: Maybe<Scalars['String']>;
  end_prep?: Maybe<Scalars['String']>;
  vic_system_pt?: Maybe<Scalars['String']>;
  vic_area_pt?: Maybe<Scalars['String']>;
  vic_zone?: Maybe<Scalars['String']>;
  vic_field_material?: Maybe<Scalars['Int']>;
  vic_field_cut?: Maybe<Scalars['Int']>;
  vic_note?: Maybe<Scalars['String']>;
  vic_bag_tag?: Maybe<Scalars['String']>;
  mark_up?: Maybe<Scalars['Float']>;
  vic_seq?: Maybe<Scalars['String']>;
  part_number?: Maybe<Scalars['String']>;
  partnumber_us?: Maybe<Scalars['String']>;
  partnumber_global?: Maybe<Scalars['String']>;
  partnumber_m3?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  labor_mcaa?: Maybe<Scalars['Float']>;
  style_desc?: Maybe<Scalars['String']>;
  component_desc?: Maybe<Scalars['String']>;
  component_desc_us?: Maybe<Scalars['String']>;
  component_desc_m3?: Maybe<Scalars['String']>;
  component_desc_global?: Maybe<Scalars['String']>;
  size_desc_us?: Maybe<Scalars['String']>;
  size_desc_imp?: Maybe<Scalars['String']>;
  size_desc_m3?: Maybe<Scalars['String']>;
  size_desc?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  list_price_part_us?: Maybe<Scalars['Float']>;
  list_price_part_m3?: Maybe<Scalars['Float']>;
  component_cost?: Maybe<Scalars['Float']>;
  net_price?: Maybe<Scalars['Float']>;
  extended_net_price?: Maybe<Scalars['Float']>;
  image_path?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
};

export type ProjectBimSchedule = {
  __typename?: 'ProjectBimSchedule';
  buyer_acceptance_agreement_date?: Maybe<ProjectScheduleDateType>;
  kickoff_meeting_date?: Maybe<ProjectScheduleDateType>;
  purchase_order_date?: Maybe<ProjectScheduleDateType>;
  expected_drawing_completion_date?: Maybe<ProjectScheduleDateType>;
  closeout_meeting_date?: Maybe<ProjectScheduleDateType>;
  contractor_turnover_date?: Maybe<ProjectScheduleDateType>;
};

export type ProjectBim = {
  __typename?: 'ProjectBIM';
  closeout_meeting_date?: Maybe<Scalars['String']>;
  status_color?: Maybe<Scalars['String']>;
  contractor_turnover_date?: Maybe<Scalars['String']>;
  customer_care_contact?: Maybe<CustomerCareRep>;
  customer_care_id?: Maybe<Scalars['Int']>;
  drawing_percent_complete?: Maybe<Scalars['Float']>;
  expected_drawing_completion_date?: Maybe<Scalars['String']>;
  purchase_order_date?: Maybe<Scalars['String']>;
  project_health_status?: Maybe<ProjectHealthStatus>;
  drawing_hours_info?: Maybe<ProjectDrawingHoursInfo>;
};

export type ProjectDrawingHoursInfo = {
  __typename?: 'ProjectDrawingHoursInfo';
  current_drawing_hours?: Maybe<Scalars['Int']>;
  efficiency_rating?: Maybe<Scalars['Float']>;
};

export type ProjectEstimatingSchedule = {
  __typename?: 'ProjectEstimatingSchedule';
  vdc_received_date?: Maybe<ProjectScheduleDateType>;
  bid_date?: Maybe<ProjectScheduleDateType>;
  model_resource_start_date?: Maybe<ProjectScheduleDateType>;
  model_requested_completion_date?: Maybe<ProjectScheduleDateType>;
  model_resource_planned_completion_date?: Maybe<ProjectScheduleDateType>;
  quality_control_date?: Maybe<ProjectScheduleDateType>;
  estimated_completion_date?: Maybe<ProjectScheduleDateType>;
  requested_sales_date?: Maybe<ProjectScheduleDateType>;
};

export type ProjectHealthStatus = {
  __typename?: 'ProjectHealthStatus';
  ph_status_id?: Maybe<Scalars['Int']>;
  ph_status?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  project_health_projects?: Maybe<ProjectHealthPayload>;
  getAdminComponents?: Maybe<Array<Maybe<Component>>>;
  getComponentsSelectOptions?: Maybe<ComponentsSelectOptions>;
  getComponentSubstitutes?: Maybe<Array<Maybe<ComponentAlternative>>>;
  getFilteredComponents?: Maybe<Array<Maybe<Component>>>;
  getPartNumberProfiles?: Maybe<Array<Maybe<PartNumberProfiles>>>;
  getProfilePartNumbers?: Maybe<Array<Maybe<ProfilePartNumbers>>>;
  getCurrencyConversionRates?: Maybe<Array<Maybe<CurrencyConversionRate>>>;
  getProjectProfilePartNumbers?: Maybe<Array<Maybe<ProfilePartNumbers>>>;
  getProjectProfiles?: Maybe<Array<Maybe<ProjectProfiles>>>;
  getAdminProjects?: Maybe<Array<Maybe<SoldAdminProject>>>;
  getQuoteDocumentInclusions?: Maybe<Array<Maybe<Inclusion>>>;
  getAdminSalesman?: Maybe<Array<Maybe<Salesman>>>;
  getSalesmanMarkups?: Maybe<Array<Maybe<SalesmanMarkup>>>;
  getSalesmanMultipliers?: Maybe<Array<Maybe<SalesmanMultiplier>>>;
  getAllChangeOrders?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  getChangeOrderNotes?: Maybe<Array<Maybe<ChangeOrderNote>>>;
  getChangeOrderPreview?: Maybe<FinancesData>;
  getProjectChangeOrders?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  getBimAndCoObjectModels?: Maybe<Array<Maybe<GetBimAndCoObjectModelsResponse>>>;
  getBimAndCoObjects?: Maybe<Array<Maybe<BimAndCoObject>>>;
  getBimObjects?: Maybe<GetBimObjectResponse>;
  getDamImages?: Maybe<Array<Maybe<DamImage>>>;
  getSpotData?: Maybe<Array<Maybe<SpotContentObject>>>;
  getSpotSoftwareRecords?: Maybe<Array<Maybe<SpotSoftwareRecord>>>;
  getSpotStatuses?: Maybe<Array<Maybe<SpotStatus>>>;
  getSpotStyles?: Maybe<Array<Maybe<SpotStylesObject>>>;
  getPreBidModelResourceSchedule: PreBidModelResourceSchedule;
  getPreBidQualityControlResourceSchedule: PreBidQualityControlResourceSchedule;
  estimatingSchedule?: Maybe<Array<Maybe<Project>>>;
  getProjectFabCalcDataV2?: Maybe<Project>;
  getFinances?: Maybe<FinancesData>;
  getProjectTotalFinances?: Maybe<TotalProjectFinancesData>;
  project?: Maybe<Project>;
  currencyByRegion?: Maybe<Array<Maybe<Currency>>>;
  filteredPriceList?: Maybe<Array<Maybe<PriceList>>>;
  getProjectArea?: Maybe<Array<Maybe<ProjectArea>>>;
  projectNotes?: Maybe<Array<Maybe<ProjectNote>>>;
  projectSearch: Array<Maybe<ProjectSearchResult>>;
  getAlternativeBom?: Maybe<Array<Maybe<ProjectBillItem>>>;
  getAssemblyEquipment?: Maybe<Array<Maybe<ProjectBillItem>>>;
  getGroupMultipliers?: Maybe<Array<Maybe<ComponentGroup>>>;
  getHeaderPrice?: Maybe<Scalars['Float']>;
  getHeadersSelectOptions?: Maybe<HeadersSelectOptions>;
  getMaterialData?: Maybe<MaterialListData>;
  getOutletSizes?: Maybe<Array<Maybe<HeaderSizes>>>;
  getPartNumbersOptions?: Maybe<Array<Maybe<Component>>>;
  getPredictedPartNumbers?: Maybe<PredictedPartNumbers>;
  getProjectHeaders: Array<Maybe<Header>>;
  getProjectPricing: Array<Maybe<ProjectPricing>>;
  getSystemRevisionMaterialList: Array<Maybe<ProjectBillItem>>;
  projectSelectOptions?: Maybe<ProjectSelectOptions>;
  getProjectStats?: Maybe<ProjectStats>;
  getQuoteDocumentData?: Maybe<QuoteDocument>;
  getBimCoordinationSchedule: BimCoordinationSchedule;
  getProjectSchedules: Array<Maybe<ProjectSchedule>>;
  getFabRequestInfo?: Maybe<SequenceFabRequestInfo>;
  getSequenceTemplates?: Maybe<Array<Maybe<SequenceTemplate>>>;
  getSequence?: Maybe<Sequence>;
  bimErrorRate?: Maybe<Scalars['Float']>;
  bimMetricsData?: Maybe<BimDashboardData>;
  preBidCharts?: Maybe<PreBidDashboardData>;
  preBidKPIs?: Maybe<PreBidDashboardData>;
  getPtoCalendar?: Maybe<Array<Maybe<PtoCalendarData>>>;
  vtfrTrainingsCalendar?: Maybe<Array<Maybe<VtfrTrainingsData>>>;
  vtfrTrainingsMetrics?: Maybe<VtfrTrainingsByTrainerMetrics>;
  vtfrTrainingsTable?: Maybe<Array<Maybe<VtfrTrainingsData>>>;
  getVdcHighlightsData?: Maybe<Array<Maybe<VdcHighlightsData>>>;
  vicToolSalesByQuarter?: Maybe<Array<Maybe<Scalars['Int']>>>;
  vicToolSalesByYear?: Maybe<Array<Maybe<Scalars['Int']>>>;
  vicToolSalesTable?: Maybe<Array<Maybe<VicToolsDashboardData>>>;
  vicToolTotalSales?: Maybe<VicToolsDashboardData>;
  vicToolsVersions?: Maybe<Array<Maybe<VicToolVersion>>>;
  vtfrDownloadsByQuarter?: Maybe<Array<Maybe<Scalars['Int']>>>;
  vtfrRevenueByYear?: Maybe<Array<Maybe<Scalars['Float']>>>;
  vtfrTotalDownloads?: Maybe<VicToolsDashboardData>;
  vtfrTotalRevenue?: Maybe<VicToolsDashboardData>;
  vtfrWebPresentations?: Maybe<VicToolsDashboardData>;
  getVdcTvData: VdcTvData;
  getAllUsers: Array<Maybe<User>>;
  recentUserProjects?: Maybe<Array<Maybe<Project>>>;
  getAllUsersGroupedByUserGroup: Array<Maybe<UsersGroupedByUserGroup>>;
};

export type QueryGetComponentArgs = {
  user_id: Scalars['Int'];
  component_id: Scalars['Int'];
};

export type QueryGetComponentSubstitutesArgs = {
  user_id: Scalars['Int'];
  component_id: Scalars['Int'];
};


export type QueryGetFilteredComponentsArgs = {
  keyword: Scalars['String'];
};


export type QueryGetProfilePartNumbersArgs = {
  profile_id: Scalars['Int'];
};


export type QueryGetCurrencyConversionRatesArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetProjectProfilePartNumbersArgs = {
  project_profile_id: Scalars['Int'];
};


export type QueryGetAdminProjectsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetQuoteDocumentInclusionsArgs = {
  language?: Maybe<Scalars['String']>;
};


export type QueryGetSalesmanMarkupsArgs = {
  salesmanID: Scalars['String'];
};


export type QueryGetSalesmanMultipliersArgs = {
  salesmanID: Scalars['String'];
};


export type QueryGetChangeOrderNotesArgs = {
  change_order_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
};


export type QueryGetChangeOrderPreviewArgs = {
  input: ChangeOrderPreviewInput;
};


export type QueryGetProjectChangeOrdersArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetBimAndCoObjectModelsArgs = {
  objectIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetBimAndCoObjectsArgs = {
  objectIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetDamImagesArgs = {
  search_val: Scalars['String'];
};


export type QueryGetSpotDataArgs = {
  object_names: Array<Maybe<Scalars['String']>>;
};


export type QueryGetProjectFabCalcDataV2Args = {
  proj_id: Scalars['Int'];
};


export type QueryGetFinancesArgs = {
  input: GetFinancesInput;
};


export type QueryGetProjectTotalFinancesArgs = {
  proj_id: Scalars['Int'];
};


export type QueryProjectArgs = {
  proj_id: Scalars['Int'];
};


export type QueryCurrencyByRegionArgs = {
  region_id: Scalars['Int'];
};


export type QueryFilteredPriceListArgs = {
  region_id: Scalars['Int'];
  currency: Scalars['String'];
};


export type QueryGetProjectAreaArgs = {
  area_id: Scalars['Int'];
};


export type QueryProjectNotesArgs = {
  projid: Scalars['Int'];
};


export type QueryProjectSearchArgs = {
  searchFields?: Maybe<ProjectSearchInput>;
};


export type QueryGetAlternativeBomArgs = {
  input: GetAlternativeBomInput;
};


export type QueryGetAssemblyEquipmentArgs = {
  input: GetAssemblyEquipmentInput;
};


export type QueryGetGroupMultipliersArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetHeaderPriceArgs = {
  input: GetHeaderPriceInput;
};


export type QueryGetHeadersSelectOptionsArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetMaterialDataArgs = {
  proj_id?: Maybe<Scalars['Int']>;
};


export type QueryGetOutletSizesArgs = {
  region_id: Scalars['Int'];
  nominal_size: Scalars['String'];
};


export type QueryGetPartNumbersOptionsArgs = {
  input: GetPartNumbersOptionsInput;
};


export type QueryGetPredictedPartNumbersArgs = {
  input: GetPredictedPartNumbersInput;
};


export type QueryGetProjectHeadersArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetProjectPricingArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetSystemRevisionMaterialListArgs = {
  input: GetSystemRevisionMaterialListInput;
};


export type QueryGetProjectStatsArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetQuoteDocumentDataArgs = {
  proj_id: Scalars['Int'];
};


export type QueryGetProjectSchedulesArgs = {
  input: GetProjectSchedulesInput;
};


export type QueryGetFabRequestInfoArgs = {
  sequence_id: Scalars['Int'];
};


export type QueryGetSequenceArgs = {
  sequence_id: Scalars['Int'];
};


export type QueryBimErrorRateArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryBimMetricsDataArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  coordinators?: Maybe<Array<Maybe<Scalars['Int']>>>;
  markets?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryPreBidChartsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryPreBidKpIsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetPtoCalendarArgs = {
  date: Scalars['String'];
};


export type QueryVtfrTrainingsMetricsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryVtfrTrainingsTableArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetVdcHighlightsDataArgs = {
  year: Scalars['Int'];
};


export type QueryVicToolSalesByQuarterArgs = {
  toolName: Scalars['String'];
};


export type QueryVicToolSalesByYearArgs = {
  toolName: Scalars['String'];
};


export type QueryVicToolSalesTableArgs = {
  toolName: Scalars['String'];
};


export type QueryVicToolTotalSalesArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  toolName: Scalars['String'];
};


export type QueryVicToolsVersionsArgs = {
  toolName: Scalars['String'];
};


export type QueryVtfrRevenueByYearArgs = {
  toolName: Scalars['String'];
};


export type QueryVtfrTotalDownloadsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryVtfrTotalRevenueArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetAllUsersArgs = {
  input?: Maybe<GetAllUsersInput>;
};


export type QueryRecentUserProjectsArgs = {
  user_id: Scalars['Int'];
};


export type QueryGetAllUsersGroupedByUserGroupArgs = {
  input?: Maybe<GetAllUsersInput>;
};

export type ProjectHealthPayload = {
  __typename?: 'ProjectHealthPayload';
  coordinators?: Maybe<Array<Maybe<User>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  project_statuses?: Maybe<ProjectHealthStatuses>;
  unassigned?: Maybe<Array<Maybe<Project>>>;
};

export type User = {
  __typename?: 'User';
  project_count?: Maybe<Scalars['Int']>;
  project_counts?: Maybe<CoordCountTotals>;
  access_token?: Maybe<Scalars['String']>;
  cps_group?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  current_project_totals?: Maybe<UserProjectTotals>;
  date_added?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  employeeid?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  microsoft_id?: Maybe<Scalars['String']>;
  microsoft_graph_token?: Maybe<Scalars['String']>;
  microsoft_tenant_id?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  presenter?: Maybe<Scalars['Boolean']>;
  region_id?: Maybe<Scalars['Int']>;
  resource_color?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  supervisor_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  user_type?: Maybe<Scalars['Int']>;
  user_type_name?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  user_access?: Maybe<UsersAccess>;
  projects?: Maybe<Array<Maybe<Project>>>;
  user_login?: Maybe<Scalars['String']>;
  user_password?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  lang_code?: Maybe<Scalars['String']>;
  market_id?: Maybe<Scalars['Int']>;
  user_resources?: Maybe<UserResources>;
};

export type CoordCountTotals = {
  __typename?: 'CoordCountTotals';
  hundred_percent?: Maybe<Scalars['Int']>;
  not_complete?: Maybe<Scalars['Int']>;
};

export type ProjectHealthStatuses = {
  __typename?: 'ProjectHealthStatuses';
  green_count?: Maybe<Scalars['Int']>;
  yellow_count?: Maybe<Scalars['Int']>;
  red_count?: Maybe<Scalars['Int']>;
  project_count?: Maybe<Scalars['Int']>;
  completed_count?: Maybe<Scalars['Int']>;
  not_completed_count?: Maybe<Scalars['Int']>;
};

export type Project = {
  __typename?: 'Project';
  project_health_status?: Maybe<ProjectHealthStatus>;
  coordinator_name?: Maybe<Scalars['String']>;
  coordinator_user_id?: Maybe<Scalars['Int']>;
  coordinator_employee_id?: Maybe<Scalars['Int']>;
  salesman_email?: Maybe<Scalars['String']>;
  regional_salesman?: Maybe<Scalars['String']>;
  regional_salesman_email?: Maybe<Scalars['String']>;
  divisional_salesman?: Maybe<Scalars['String']>;
  divisional_salesman_email?: Maybe<Scalars['String']>;
  region_name?: Maybe<Scalars['String']>;
  market_name?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  expected_drawing_completion_date?: Maybe<Scalars['String']>;
  percent_complete?: Maybe<Scalars['Float']>;
  rvtVersion?: Maybe<Scalars['String']>;
  active_status?: Maybe<Scalars['Boolean']>;
  activedate?: Maybe<Scalars['String']>;
  activity_history?: Maybe<Array<Maybe<TrackingActivity>>>;
  areas?: Maybe<Array<Maybe<ProjectArea>>>;
  bid_date?: Maybe<Scalars['String']>;
  bim360_project?: Maybe<Bim360Project>;
  bill_of_material?: Maybe<ProjectMaterialData>;
  change_orders?: Maybe<Array<Maybe<ChangeOrder>>>;
  changeOrders?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  cpsservice?: Maybe<Scalars['String']>;
  current_project_type?: Maybe<Scalars['String']>;
  current_project_type_id?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  currency_symbol?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  coordinator?: Maybe<User>;
  customer?: Maybe<Customer>;
  customer_contacts?: Maybe<Array<Maybe<CustomerContact>>>;
  datesubmitted?: Maybe<Scalars['String']>;
  distributor?: Maybe<Scalars['String']>;
  distributor_id?: Maybe<Scalars['String']>;
  division_id?: Maybe<Scalars['Int']>;
  divisional_sales?: Maybe<Scalars['String']>;
  divisional_sales_email?: Maybe<Scalars['String']>;
  domestic_requirements?: Maybe<DomesticRequirement>;
  engineerid?: Maybe<Scalars['Int']>;
  estimator?: Maybe<User>;
  is_favorite?: Maybe<Scalars['Boolean']>;
  estimating?: Maybe<EstimatingScheduleFields>;
  group_id?: Maybe<Scalars['Int']>;
  hours_fields?: Maybe<Array<Maybe<HoursFieldRecord>>>;
  is_kkto_project?: Maybe<Scalars['Boolean']>;
  kkto_status?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  lostdate?: Maybe<Scalars['String']>;
  lostreason?: Maybe<Scalars['String']>;
  line_sales?: Maybe<Scalars['String']>;
  line_sales_email?: Maybe<Scalars['String']>;
  market?: Maybe<Market>;
  market_id?: Maybe<Scalars['Int']>;
  metro_id?: Maybe<Scalars['Int']>;
  multiplier_date?: Maybe<Scalars['String']>;
  nda_required?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<ProjectNotification>>>;
  notificationItems?: Maybe<Array<Maybe<ProjectNotificationItem>>>;
  notes?: Maybe<Array<Maybe<ProjectNote>>>;
  offerings?: Maybe<Array<Maybe<ProjectOffering>>>;
  order_number?: Maybe<Scalars['String']>;
  po_date?: Maybe<Scalars['String']>;
  po_amount?: Maybe<Scalars['Float']>;
  postbid?: Maybe<Scalars['Int']>;
  poaa?: Maybe<Scalars['String']>;
  poaa_date?: Maybe<Scalars['String']>;
  price_list_id?: Maybe<Scalars['Int']>;
  probofsale?: Maybe<Scalars['String']>;
  proj_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  project_distributor_value?: Maybe<Scalars['Float']>;
  project_finances?: Maybe<ProjectFinancesData>;
  project_location?: Maybe<ProjectLocation>;
  project_name?: Maybe<Scalars['String']>;
  proj_num?: Maybe<Scalars['String']>;
  price_list?: Maybe<PriceList>;
  projectBIM?: Maybe<ProjectBim>;
  projectcountry?: Maybe<Scalars['String']>;
  projectstate?: Maybe<Scalars['String']>;
  projectcity?: Maybe<Scalars['String']>;
  project_long?: Maybe<Scalars['Float']>;
  project_lat?: Maybe<Scalars['Float']>;
  proj_status?: Maybe<Scalars['String']>;
  proj_type?: Maybe<AdditionalProjectType>;
  quote_hours_info?: Maybe<ProjectQuoteHoursInfo>;
  region?: Maybe<Region>;
  regioncode?: Maybe<Scalars['String']>;
  requires_manual_fab_quote?: Maybe<Scalars['Boolean']>;
  region_id?: Maybe<Scalars['Int']>;
  region_pn?: Maybe<Scalars['String']>;
  regional_sales?: Maybe<Scalars['String']>;
  regional_sales_email?: Maybe<Scalars['String']>;
  salesman?: Maybe<Salesman>;
  salesuser_id?: Maybe<Scalars['Int']>;
  schedule?: Maybe<ProjectSchedule>;
  schedule_link?: Maybe<Scalars['String']>;
  sequences?: Maybe<Array<Maybe<Sequence>>>;
  sequenceTrucksAvailable?: Maybe<Array<Maybe<SequenceLogistics>>>;
  software_tracking?: Maybe<SoftwareTracking>;
  solddate?: Maybe<Scalars['String']>;
  sold_financials_updated?: Maybe<Scalars['Int']>;
  soldreason?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  statusHistory?: Maybe<Array<Maybe<ProjectStatusRecord>>>;
  team_members?: Maybe<Array<Maybe<User>>>;
  turnover_date?: Maybe<Scalars['String']>;
  union_notunion?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  worksheet_date?: Maybe<Scalars['String']>;
  weight_unit?: Maybe<Scalars['String']>;
};

export type ProjectLocation = {
  __typename?: 'ProjectLocation';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  regioncode?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type ProjectMaterialData = {
  __typename?: 'ProjectMaterialData';
  systems: Array<Maybe<System>>;
  revisions: Array<Maybe<Revision>>;
  partNumberProfiles: Array<Maybe<PartNumberProfiles>>;
  projectProfiles?: Maybe<Array<Maybe<ProjectProfiles>>>;
  quotes?: Maybe<Array<Maybe<SystemQuote>>>;
};

export type ProjectNoteHistory = {
  __typename?: 'ProjectNoteHistory';
  note_history_id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  date_added?: Maybe<Scalars['String']>;
  added_by?: Maybe<Scalars['String']>;
};

export type ProjectNoteType = {
  __typename?: 'ProjectNoteType';
  note_type_id?: Maybe<Scalars['Int']>;
  note_type?: Maybe<Scalars['String']>;
  note_id?: Maybe<Scalars['Int']>;
  note_type_applied_id?: Maybe<Scalars['Int']>;
};

export type ProjectNote = {
  __typename?: 'ProjectNote';
  projid?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  date_added?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  added_by?: Maybe<Scalars['String']>;
  availableNoteTypes?: Maybe<Array<Maybe<ProjectNoteType>>>;
  appliedNoteTypes?: Maybe<Array<Maybe<ProjectNoteType>>>;
  history?: Maybe<Array<Maybe<ProjectNoteHistory>>>;
};

export type MultipleProjectNotificationsResult = {
  __typename?: 'MultipleProjectNotificationsResult';
  proj_id?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<ProjectNotification>>>;
};

export type ProjectNotificationItem = {
  __typename?: 'ProjectNotificationItem';
  proj_notif_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  notif_type_id: Scalars['Int'];
  confirmed: Scalars['Boolean'];
  confirmed_date: Scalars['String'];
};

export type ProjectNotification = {
  __typename?: 'ProjectNotification';
  has_notification?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ProjectOffering = {
  __typename?: 'ProjectOffering';
  unique_id?: Maybe<Scalars['Int']>;
  proj_offering_id?: Maybe<Scalars['Int']>;
  offeringid?: Maybe<Scalars['Int']>;
  offering?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
  for_finances?: Maybe<Scalars['Int']>;
  for_change_orders?: Maybe<Scalars['Int']>;
  for_additional_change_orders?: Maybe<Scalars['Int']>;
  for_salesman_markups?: Maybe<Scalars['Int']>;
  billing_category?: Maybe<Scalars['String']>;
  group_as_material?: Maybe<Scalars['Int']>;
  group_as_transportation?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  property?: Maybe<Scalars['String']>;
};

export type ProjectPricing = {
  __typename?: 'ProjectPricing';
  price?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  component_id?: Maybe<Scalars['Int']>;
  project_pricing_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  partnumber_us?: Maybe<Scalars['String']>;
  partnumber_global?: Maybe<Scalars['String']>;
  partnumber?: Maybe<Scalars['String']>;
};

export type ProjectProfiles = {
  __typename?: 'ProjectProfiles';
  project_profile_id?: Maybe<Scalars['Int']>;
  project_profile?: Maybe<Scalars['String']>;
  project_profile_desc?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  project_desc?: Maybe<Scalars['String']>;
};

export type ProjectQuoteHoursInfo = {
  __typename?: 'ProjectQuoteHoursInfo';
  quoted_drawing_hours?: Maybe<Scalars['Int']>;
  quoted_drawing_revenue?: Maybe<Scalars['Float']>;
};

export type ProjectScheduleDateType = {
  __typename?: 'ProjectScheduleDateType';
  date_value?: Maybe<Scalars['String']>;
  date_type?: Maybe<Scalars['String']>;
  date_group?: Maybe<Scalars['String']>;
};

export type ProjectSchedule = {
  __typename?: 'ProjectSchedule';
  proj_id: Scalars['Int'];
  project_name: Scalars['String'];
  proj_num: Scalars['String'];
  coordinator?: Maybe<User>;
  bim_schedule?: Maybe<ProjectBimSchedule>;
  estimating_schedule?: Maybe<ProjectEstimatingSchedule>;
  sequences: Array<Maybe<Sequence>>;
};

export type ProjectSchedulingCalendarEventColor = {
  __typename?: 'ProjectSchedulingCalendarEventColor';
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
};

export type IProjectSchedulingCalendarEvent = {
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  color?: Maybe<ProjectSchedulingCalendarEventColor>;
};

export type IProjectSchedulingGanttTask = {
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  not_entered?: Maybe<Scalars['String']>;
  coordinator?: Maybe<User>;
};

export type IProjectSchedulingType = {
  gantt_tasks: Array<Maybe<IProjectSchedulingGanttTask>>;
  calendar_events: Array<Maybe<IProjectSchedulingCalendarEvent>>;
};

export type ProjectSearchResult = {
  __typename?: 'ProjectSearchResult';
  proj_id?: Maybe<Scalars['Int']>;
  proj_num?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  proj_status?: Maybe<Scalars['String']>;
};

export type ProjectSelectOptions = {
  __typename?: 'ProjectSelectOptions';
  additionalProjectTypes?: Maybe<Array<Maybe<AdditionalProjectType>>>;
  availableTemplateProjTypes?: Maybe<Array<Maybe<ProjectType>>>;
  changeOrderStatusTypes?: Maybe<Array<Maybe<ChangeOrderStatus>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  customerCareReps?: Maybe<Array<Maybe<CustomerCareRep>>>;
  customerTypes?: Maybe<Array<Maybe<CustomerType>>>;
  engineers?: Maybe<Array<Maybe<Engineer>>>;
  fabContacts?: Maybe<Array<Maybe<FabricationContact>>>;
  fabShops?: Maybe<Array<Maybe<FabricationShop>>>;
  groups?: Maybe<Array<Maybe<VdcGroup>>>;
  salesRegions?: Maybe<Array<Maybe<SalesRegion>>>;
  hoursFields?: Maybe<Array<Maybe<HoursField>>>;
  hoursFieldsCategories?: Maybe<Array<Maybe<HoursFieldCategory>>>;
  markets?: Maybe<Array<Maybe<Market>>>;
  metros?: Maybe<Array<Maybe<MetropolitanArea>>>;
  offerings?: Maybe<Array<Maybe<ProjectOffering>>>;
  priceLists?: Maybe<Array<Maybe<PriceList>>>;
  projectHealthStatuses?: Maybe<Array<Maybe<ProjectHealthStatus>>>;
  projectStatuses?: Maybe<Array<Maybe<ProjectStatus>>>;
  projectTypes?: Maybe<Array<Maybe<ProjectType>>>;
  quoteFields?: Maybe<Array<Maybe<QuoteField>>>;
  quoteSections?: Maybe<Array<Maybe<QuoteSection>>>;
  quoteWorksheets?: Maybe<Array<Maybe<QuoteWorksheet>>>;
  regions?: Maybe<Array<Maybe<Region>>>;
  salesmen?: Maybe<Array<Maybe<Salesman>>>;
  services?: Maybe<Array<Maybe<VdcServiceType>>>;
  sequencePipeCoatings?: Maybe<Array<Maybe<SequencePipeCoating>>>;
  sequenceDates?: Maybe<Array<Maybe<SequenceDateType>>>;
  sequenceTemplates?: Maybe<Array<Maybe<SequenceTemplate>>>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  provinces?: Maybe<Array<Maybe<Province>>>;
  team_leaders?: Maybe<Array<Maybe<User>>>;
  trackingActivityGroups?: Maybe<Array<Maybe<TrackingActivityGroup>>>;
  transportationBrokers?: Maybe<Array<Maybe<TransportationBroker>>>;
  users?: Maybe<Array<Maybe<User>>>;
  usersAccess?: Maybe<Array<Maybe<UsersAccess>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  legislativeActs?: Maybe<Array<Maybe<LegislativeAct>>>;
  salesmanTable?: Maybe<Array<Maybe<Salesman>>>;
  gasket_types?: Maybe<Array<Maybe<GasketType>>>;
  bomColumns?: Maybe<Array<Maybe<BomColumn>>>;
  presenters?: Maybe<Array<Maybe<User>>>;
  trainingsTypes?: Maybe<Array<Maybe<TrainingType>>>;
  trainingMarkets?: Maybe<Array<Maybe<TrainingMarket>>>;
  cpsGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  userGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  bimHours?: Maybe<Array<Maybe<BimHourType>>>;
  changeOrderAdditionalOfferings?: Maybe<Array<Maybe<ProjectOffering>>>;
  projectNoteTypes?: Maybe<Array<Maybe<ProjectNoteType>>>;
};

export type KeyPerformanceEquipMetric = {
  __typename?: 'KeyPerformanceEquipMetric';
  equipment?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['Float']>;
  final?: Maybe<Scalars['Float']>;
};

export type KeyPerformanceProjMetric = {
  __typename?: 'KeyPerformanceProjMetric';
  metric?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['String']>;
};

export type PipingSystemMetric = {
  __typename?: 'PipingSystemMetric';
  pipingSystem?: Maybe<Scalars['String']>;
};

export type ProjectStats = {
  __typename?: 'ProjectStats';
  keyPerformanceProjMetircs?: Maybe<Array<Maybe<KeyPerformanceProjMetric>>>;
  keyPerformanceEquipMetrics?: Maybe<Array<Maybe<KeyPerformanceEquipMetric>>>;
  quickStatsMetrics?: Maybe<Array<Maybe<QuickStatsMetric>>>;
  pipingSystems?: Maybe<Array<Maybe<PipingSystemMetric>>>;
};

export type QuickStatsMetric = {
  __typename?: 'QuickStatsMetric';
  metric?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Int']>;
  isPercentage?: Maybe<Scalars['Boolean']>;
};

export type ProjectStatusRecord = {
  __typename?: 'ProjectStatusRecord';
  status_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
};

export type ProjectStatusUpdateResult = {
  __typename?: 'ProjectStatusUpdateResult';
  projectStatusRecords?: Maybe<Array<Maybe<ProjectStatusRecord>>>;
  missingFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectStatus = {
  __typename?: 'ProjectStatus';
  project_status_id?: Maybe<Scalars['Int']>;
  status_name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  projtypeid?: Maybe<Scalars['Int']>;
  projtype?: Maybe<Scalars['String']>;
  projtypesuffix?: Maybe<Scalars['String']>;
  current_project_type?: Maybe<Scalars['String']>;
  typedate?: Maybe<Scalars['String']>;
  current_project_type_id?: Maybe<Scalars['Int']>;
};

export type Province = {
  __typename?: 'Province';
  province?: Maybe<Scalars['String']>;
  province_name?: Maybe<Scalars['String']>;
};

export type PtoCalendarData = {
  __typename?: 'PtoCalendarData';
  tsid?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  cps_group?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  full_day?: Maybe<Scalars['Boolean']>;
};

export type QuoteDocument = {
  __typename?: 'QuoteDocument';
  latestRevUsed?: Maybe<Scalars['Int']>;
  quoteRevisions?: Maybe<Array<Maybe<Revision>>>;
  drawingInfo?: Maybe<Scalars['String']>;
  specInfo?: Maybe<Scalars['String']>;
  pipingSystems?: Maybe<Array<Maybe<Scalars['String']>>>;
  areaLimits?: Maybe<Array<Maybe<AreaLimit>>>;
  notes?: Maybe<Array<Maybe<QuoteNote>>>;
  inclusionsDictionary?: Maybe<MasterClass>;
  inclusionsList?: Maybe<Array<Maybe<Inclusion>>>;
  numberOfSubclasses?: Maybe<Scalars['Int']>;
  areaLimitsString?: Maybe<Scalars['String']>;
  pipingSystemsString?: Maybe<Scalars['String']>;
};

export type AreaLimit = {
  __typename?: 'AreaLimit';
  area?: Maybe<Scalars['String']>;
  areaid?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Int']>;
  equipments_modified?: Maybe<Scalars['Boolean']>;
  dwg?: Maybe<Scalars['String']>;
  equipments?: Maybe<Array<Maybe<EquipmentItem>>>;
};

export type QuoteNote = {
  __typename?: 'QuoteNote';
  master_class?: Maybe<Scalars['String']>;
  sub_class?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MasterClass = {
  __typename?: 'MasterClass';
  Drawing?: Maybe<Array<Maybe<SubClass>>>;
  General?: Maybe<Array<Maybe<SubClass>>>;
  Material?: Maybe<Array<Maybe<SubClass>>>;
};

export type SubClass = {
  __typename?: 'SubClass';
  index?: Maybe<Scalars['Int']>;
  sub_class?: Maybe<Scalars['String']>;
  inclusions?: Maybe<Array<Maybe<Inclusion>>>;
};

export type Inclusion = {
  __typename?: 'Inclusion';
  inclusion_id?: Maybe<Scalars['Int']>;
  row_id?: Maybe<Scalars['Int']>;
  master_class?: Maybe<Scalars['String']>;
  sub_class?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  pha_fab?: Maybe<Scalars['Int']>;
  pha_craft?: Maybe<Scalars['Int']>;
  drawing?: Maybe<Scalars['Int']>;
  oil_gas_fab?: Maybe<Scalars['Int']>;
  oil_gas_craft?: Maybe<Scalars['Int']>;
  municipal_fab?: Maybe<Scalars['Int']>;
  municipal_craft?: Maybe<Scalars['Int']>;
  other_visible_when_selected?: Maybe<Scalars['Int']>;
  oil_gas_visible_when_selected?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type QuoteFieldRecord = {
  __typename?: 'QuoteFieldRecord';
  quote_field_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  quote_field_master_id?: Maybe<Scalars['Int']>;
  section_id?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  field_name?: Maybe<Scalars['String']>;
  man_hours?: Maybe<Scalars['Float']>;
  calc_value?: Maybe<Scalars['Float']>;
  calc_cost?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Int']>;
  manual?: Maybe<Scalars['Int']>;
};

export type QuoteField = {
  __typename?: 'QuoteField';
  active?: Maybe<Scalars['Int']>;
  quote_field_id?: Maybe<Scalars['Int']>;
  worksheet_id?: Maybe<Scalars['Int']>;
  section_id?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  drop_count?: Maybe<Scalars['Int']>;
  man_hours?: Maybe<Scalars['Float']>;
  totalled?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['Int']>;
  pairs?: Maybe<Scalars['Int']>;
};

export type HoursFieldCategory = {
  __typename?: 'HoursFieldCategory';
  category_id?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
};

export type HoursField = {
  __typename?: 'HoursField';
  hfm_id?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  hour_category?: Maybe<Scalars['Int']>;
  percent?: Maybe<Scalars['Float']>;
  section_id?: Maybe<Scalars['Int']>;
  estimated?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
};

export type QuoteRevision = {
  __typename?: 'QuoteRevision';
  finance_revision_id?: Maybe<Scalars['Int']>;
  finance_revision_name?: Maybe<Scalars['String']>;
  finance_revision_date?: Maybe<Scalars['String']>;
  quoteid?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  sold?: Maybe<Scalars['Int']>;
};

export type QuoteSection = {
  __typename?: 'QuoteSection';
  section_id?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
};

export type QuoteWorksheet = {
  __typename?: 'QuoteWorksheet';
  worksheet_id?: Maybe<Scalars['Int']>;
  worksheet?: Maybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  region_id?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  region_part_number_type?: Maybe<Scalars['String']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type Revision = {
  __typename?: 'Revision';
  revision_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_date: Scalars['String'];
  revision_name: Scalars['String'];
  revision?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  forissue?: Maybe<Scalars['Int']>;
  latest_sold?: Maybe<Scalars['Boolean']>;
  actual_sold?: Maybe<Scalars['Boolean']>;
};

export type SalesRegion = {
  __typename?: 'SalesRegion';
  salesregion_id?: Maybe<Scalars['String']>;
  salesregion_name?: Maybe<Scalars['String']>;
};

export type SalesmanMarkup = {
  __typename?: 'SalesmanMarkup';
  salesman_markup_id?: Maybe<Scalars['Int']>;
  salesmanID?: Maybe<Scalars['String']>;
  offeringid?: Maybe<Scalars['Int']>;
  offering?: Maybe<Scalars['String']>;
  dist_markup?: Maybe<Scalars['Float']>;
  cust_markup?: Maybe<Scalars['Float']>;
};

export type SalesmanMultiplier = {
  __typename?: 'SalesmanMultiplier';
  salesman_multiplier_id?: Maybe<Scalars['Int']>;
  salesmanID?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  group_desc?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Salesman = {
  __typename?: 'Salesman';
  autoID?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  salesmanID?: Maybe<Scalars['String']>;
  metroID?: Maybe<Scalars['String']>;
  metro_id?: Maybe<Scalars['String']>;
  metro?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  salesregion_name?: Maybe<Scalars['String']>;
  salesregion_id?: Maybe<Scalars['String']>;
  group_name?: Maybe<Scalars['String']>;
  groupID?: Maybe<Scalars['Int']>;
  countryID?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  divisionID?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  reports_to?: Maybe<Scalars['String']>;
  reports_to_name?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  market?: Maybe<Scalars['String']>;
};

export type SequenceDateRecord = {
  __typename?: 'SequenceDateRecord';
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sequence_date_id?: Maybe<Scalars['Int']>;
  sequence_id: Scalars['Int'];
  date_type_id?: Maybe<Scalars['Int']>;
  date_type?: Maybe<SequenceDateType>;
  date_value?: Maybe<Scalars['String']>;
  date_note?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  is_confirmed?: Maybe<Scalars['Boolean']>;
};

export type SequenceDateType = {
  __typename?: 'SequenceDateType';
  sequence_date_type_id: Scalars['Int'];
  date_type: Scalars['String'];
  mendix_managed?: Maybe<Scalars['Boolean']>;
};

export type SequenceFabRequestInfo = {
  __typename?: 'SequenceFabRequestInfo';
  onSiteLocation?: Maybe<Scalars['String']>;
  onSiteDate?: Maybe<Scalars['String']>;
  calculatedHours?: Maybe<Scalars['Int']>;
  mendixCalculatedHours?: Maybe<Scalars['Int']>;
  truckCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['Boolean']>;
  pipeInformation?: Maybe<Array<Maybe<MuleSoftPipeInfo>>>;
  productInformation?: Maybe<Array<Maybe<MuleSoftProductInfo>>>;
};

export type SequenceLogistics = {
  __typename?: 'SequenceLogistics';
  logistics_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  shipping_date?: Maybe<Scalars['String']>;
  truck_number?: Maybe<Scalars['Int']>;
  log_received_date?: Maybe<Scalars['String']>;
  has_shipped?: Maybe<Scalars['Boolean']>;
};

export type SequenceMendixAssociation = {
  __typename?: 'SequenceMendixAssociation';
  sequence_id: Scalars['Int'];
  mendix_id: Scalars['Int'];
};

export type SequencePipeCoating = {
  __typename?: 'SequencePipeCoating';
  coating_type_id: Scalars['Int'];
  coating_type: Scalars['String'];
};

export type SequenceTemplate = {
  __typename?: 'SequenceTemplate';
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Int']>;
  template_id: Scalars['Int'];
  name: Scalars['String'];
  proj_type_id?: Maybe<Scalars['Int']>;
  project_type?: Maybe<ProjectType>;
  sequence_dates: Array<Maybe<SequenceDateType>>;
};

export type Sequence = {
  __typename?: 'Sequence';
  action?: Maybe<Scalars['String']>;
  broker_id?: Maybe<Scalars['Int']>;
  broker_name?: Maybe<Scalars['String']>;
  coating_type_id?: Maybe<Scalars['Int']>;
  coating_type?: Maybe<Scalars['String']>;
  delivery_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  equipment?: Maybe<Array<Maybe<ProjectBillItem>>>;
  fab_contact_id?: Maybe<Scalars['Int']>;
  fab_comments?: Maybe<Scalars['String']>;
  fab_shop_id?: Maybe<Scalars['Int']>;
  fab_contact?: Maybe<FabricationContact>;
  fab_shop?: Maybe<FabricationShop>;
  full_name?: Maybe<Scalars['String']>;
  fab_request_info?: Maybe<SequenceFabRequestInfo>;
  header_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  iso_drop_status?: Maybe<Scalars['String']>;
  logistics: Array<Maybe<SequenceLogistics>>;
  mendix_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  non_vic_fab_contact?: Maybe<Scalars['String']>;
  non_vic_fab_shop?: Maybe<Scalars['String']>;
  notifications: Array<Maybe<ProjectNotification>>;
  order_number?: Maybe<Scalars['Int']>;
  pipe_data?: Maybe<Array<Maybe<ProjectBillItem>>>;
  proj_id: Scalars['Int'];
  sequence_color?: Maybe<Scalars['String']>;
  sequence_dates: Array<Maybe<SequenceDateRecord>>;
  sequence_id: Scalars['Int'];
  sequence_notes?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
  template_name?: Maybe<Scalars['String']>;
  template?: Maybe<SequenceTemplate>;
  transportation_broker?: Maybe<TransportationBroker>;
};

export type SoftwareTracking = {
  __typename?: 'SoftwareTracking';
  user_login?: Maybe<Scalars['String']>;
  rvtVersion?: Maybe<Scalars['String']>;
  vtfrVersion?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  proj_id?: Maybe<Scalars['Int']>;
  applicationBuild?: Maybe<Scalars['String']>;
  savedPath?: Maybe<Scalars['String']>;
  workShared?: Maybe<Scalars['Boolean']>;
  isCentralFile?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type SoldAdminProject = {
  __typename?: 'SoldAdminProject';
  index?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  total_project_dist?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  region_pn?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  price_list_id?: Maybe<Scalars['Int']>;
  project_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  price_list_name?: Maybe<Scalars['String']>;
  coordinator?: Maybe<Scalars['String']>;
  sequence_count?: Maybe<Scalars['Int']>;
  sequence_errors?: Maybe<Scalars['Int']>;
  sequence_missing_dates?: Maybe<Scalars['Int']>;
  line_sales?: Maybe<Scalars['String']>;
  regional_sales?: Maybe<Scalars['String']>;
  divisional_sales?: Maybe<Scalars['String']>;
  contractor_name?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  distributor_po?: Maybe<Scalars['String']>;
  buyer_acceptance_date?: Maybe<Scalars['String']>;
  multiplier_added?: Maybe<Scalars['String']>;
  finance_complete?: Maybe<Scalars['String']>;
  sold_date?: Maybe<Scalars['String']>;
  expected_completion_date?: Maybe<Scalars['String']>;
  onsite_date?: Maybe<Scalars['String']>;
  close_meeting_date?: Maybe<Scalars['String']>;
  ph_status?: Maybe<Scalars['String']>;
  total_sold_material?: Maybe<Scalars['String']>;
  total_sold_material_number?: Maybe<Scalars['Float']>;
  total_sold_material_with_buffer?: Maybe<Scalars['String']>;
  current_material?: Maybe<Scalars['String']>;
  current_material_number?: Maybe<Scalars['Float']>;
  material_difference?: Maybe<Scalars['String']>;
  percentage_material_difference?: Maybe<Scalars['String']>;
  percentage_material_difference_number?: Maybe<Scalars['Float']>;
  quoted_drawing_hours?: Maybe<Scalars['Int']>;
  bim_hours_used?: Maybe<Scalars['Float']>;
  bim_hours_percentage?: Maybe<Scalars['String']>;
  bim_hours_percentage_number?: Maybe<Scalars['Float']>;
  drawing_percentage_complete?: Maybe<Scalars['String']>;
  drawingPercentCompleteNumber?: Maybe<Scalars['Float']>;
  estimated_bim_hours?: Maybe<Scalars['Float']>;
  efficiency?: Maybe<Scalars['Float']>;
  truck_count?: Maybe<Scalars['Int']>;
  fab_truck_count?: Maybe<Scalars['Int']>;
  transportation?: Maybe<Scalars['String']>;
  fab_hours_quoted?: Maybe<Scalars['Int']>;
  fab_hours_actual?: Maybe<Scalars['Int']>;
  fab_hours_actual_string?: Maybe<Scalars['String']>;
  difference_fab_hours?: Maybe<Scalars['String']>;
  difference_fab_hours_numbers?: Maybe<Scalars['Float']>;
  koil_kit_count?: Maybe<Scalars['Int']>;
  koil_kit_value?: Maybe<Scalars['String']>;
  buffer_dollars?: Maybe<Scalars['String']>;
  material_buffer?: Maybe<Scalars['String']>;
  percentage_buffer_used?: Maybe<Scalars['String']>;
  total_project_quoted?: Maybe<Scalars['String']>;
  total_sold_project?: Maybe<Scalars['String']>;
  total_project_actual?: Maybe<Scalars['String']>;
  total_project_actual_number?: Maybe<Scalars['Float']>;
  percentage_total_project_difference?: Maybe<Scalars['String']>;
  percentage_total_project_difference_number?: Maybe<Scalars['Float']>;
  total_open_change_orders?: Maybe<Scalars['String']>;
  total_internal_change_orders?: Maybe<Scalars['String']>;
  finance_notes?: Maybe<Scalars['String']>;
  signed_cos?: Maybe<Scalars['Int']>;
  received_revised_pos?: Maybe<Scalars['Int']>;
  percent_billed?: Maybe<Scalars['String']>;
  number_of_billings?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['String']>;
  open_number?: Maybe<Scalars['Float']>;
  amount_billed?: Maybe<Scalars['Float']>;
  number_of_areas?: Maybe<Scalars['Int']>;
};

export type SplitSystem = {
  __typename?: 'SplitSystem';
  systems?: Maybe<Array<Maybe<System>>>;
  revisions?: Maybe<Array<Maybe<Revision>>>;
};

export type Spool = {
  __typename?: 'Spool';
  row_num?: Maybe<Scalars['Int']>;
  file_name?: Maybe<Scalars['String']>;
  system_id?: Maybe<Scalars['Int']>;
  revision_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemFlips = {
  __typename?: 'SystemFlips';
  flip_sys_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Scalars['Int']>;
  flip_id?: Maybe<Scalars['Int']>;
  labor_rate?: Maybe<Scalars['Float']>;
  prod_factor?: Maybe<Scalars['Float']>;
};

export type SystemQuote = {
  __typename?: 'SystemQuote';
  quoteid?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Int']>;
  quotedate?: Maybe<Scalars['String']>;
  quotename?: Maybe<Scalars['String']>;
  projname?: Maybe<Scalars['String']>;
  projno?: Maybe<Scalars['String']>;
};

export type System = {
  __typename?: 'System';
  system_id?: Maybe<Scalars['Int']>;
  system?: Maybe<Scalars['String']>;
  system_desc?: Maybe<Scalars['String']>;
  market_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  revision_id?: Maybe<Scalars['Int']>;
  dwg?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  project_profile_id?: Maybe<Scalars['Int']>;
  quoteid?: Maybe<Scalars['Int']>;
  quotename?: Maybe<Scalars['String']>;
  gasket_id?: Maybe<Scalars['Int']>;
  gasket_type?: Maybe<Scalars['String']>;
  gasket_value?: Maybe<Scalars['String']>;
};

export type TimesheetHours = {
  __typename?: 'TimesheetHours';
  tsid?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  salesno?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  projdate?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  areaid?: Maybe<Scalars['Int']>;
  subareaid?: Maybe<Scalars['Int']>;
  approval?: Maybe<Scalars['Int']>;
  approvedby?: Maybe<Scalars['Int']>;
  datecreated?: Maybe<Scalars['String']>;
};

export type ProjTotalsColumnsLabels = {
  __typename?: 'ProjTotalsColumnsLabels';
  label?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  billing_category?: Maybe<Scalars['String']>;
};

export type TotalFinances = {
  __typename?: 'TotalFinances';
  type?: Maybe<Scalars['String']>;
  drawing_hours?: Maybe<Scalars['String']>;
  fabrication_hours?: Maybe<Scalars['String']>;
  fab?: Maybe<Scalars['String']>;
  risers?: Maybe<Scalars['String']>;
  kkto_vdc?: Maybe<Scalars['String']>;
  kkto_sales?: Maybe<Scalars['String']>;
  vis?: Maybe<Scalars['String']>;
  skids?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  transportation?: Maybe<Scalars['String']>;
  headers?: Maybe<Scalars['String']>;
  vipds?: Maybe<Scalars['String']>;
  ahus?: Maybe<Scalars['String']>;
  vdc?: Maybe<Scalars['String']>;
  pipe?: Maybe<Scalars['String']>;
  pipe_support?: Maybe<Scalars['String']>;
  third_party_fab?: Maybe<Scalars['String']>;
  third_party_welding?: Maybe<Scalars['String']>;
  standard_prv?: Maybe<Scalars['String']>;
  vic_tool?: Maybe<Scalars['String']>;
  engineering_services?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  total_float?: Maybe<Scalars['Float']>;
};

export type TotalProjectFinancesData = {
  __typename?: 'TotalProjectFinancesData';
  totalFinances?: Maybe<Array<Maybe<TotalFinances>>>;
  projTotalsColumnsLabels?: Maybe<Array<Maybe<ProjTotalsColumnsLabels>>>;
};

export type TrackingActivityGroup = {
  __typename?: 'TrackingActivityGroup';
  activity_group_id?: Maybe<Scalars['Int']>;
  group_type?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type TrackingActivityType = {
  __typename?: 'TrackingActivityType';
  activity_type_id: Scalars['Int'];
  description: Scalars['String'];
  operation_name: Scalars['String'];
  activity_group_id: Scalars['Int'];
};

export type TrackingActivity = {
  __typename?: 'TrackingActivity';
  activity_id?: Maybe<Scalars['Int']>;
  activity_type_id: Scalars['Int'];
  activity_type?: Maybe<TrackingActivityType>;
  additional_info?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  timestamp?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  user_name?: Maybe<Scalars['String']>;
};

export type TrainingContact = {
  __typename?: 'TrainingContact';
  contact_id?: Maybe<Scalars['Int']>;
  training_id?: Maybe<Scalars['Int']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_company?: Maybe<Scalars['String']>;
};

export type TrainingMarket = {
  __typename?: 'TrainingMarket';
  training_id?: Maybe<Scalars['Int']>;
  t_market_id?: Maybe<Scalars['Int']>;
  t_market?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
};

export type TrainingType = {
  __typename?: 'TrainingType';
  type_id?: Maybe<Scalars['Int']>;
  type_var?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TransportationBroker = {
  __typename?: 'TransportationBroker';
  broker_id?: Maybe<Scalars['Int']>;
  broker_name?: Maybe<Scalars['String']>;
  broker_phone?: Maybe<Scalars['String']>;
};

export type TruckRecord = {
  __typename?: 'TruckRecord';
  logistics_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  truck_number?: Maybe<Scalars['Int']>;
  ship_date?: Maybe<Scalars['String']>;
  log_received_date?: Maybe<Scalars['String']>;
  shipping_confirmed?: Maybe<Scalars['Int']>;
  log_receive_confirmed?: Maybe<Scalars['Int']>;
  added_by?: Maybe<Scalars['Int']>;
  added_at?: Maybe<Scalars['String']>;
};

export type UserProjectTotals = {
  __typename?: 'UserProjectTotals';
  hundred_percent?: Maybe<Scalars['Int']>;
  not_complete?: Maybe<Scalars['Int']>;
};

export type UserResources = {
  __typename?: 'UserResources';
  user_resource_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  hours_per_day?: Maybe<Scalars['Float']>;
};

export type UserType = {
  __typename?: 'UserType';
  user_type_id?: Maybe<Scalars['Int']>;
  user_type_name?: Maybe<Scalars['String']>;
};

export type UsersAccess = {
  __typename?: 'UsersAccess';
  user_id?: Maybe<Scalars['Int']>;
  bimcreator?: Maybe<Scalars['Int']>;
  d_vtfr?: Maybe<Scalars['Int']>;
  d_quotes?: Maybe<Scalars['Int']>;
  d_training?: Maybe<Scalars['Int']>;
  d_resources?: Maybe<Scalars['Int']>;
  d_projects?: Maybe<Scalars['Int']>;
  d_highlights?: Maybe<Scalars['Int']>;
  p_resources?: Maybe<Scalars['Int']>;
  p_finances?: Maybe<Scalars['Int']>;
  d_finances?: Maybe<Scalars['Int']>;
  cost_compare?: Maybe<Scalars['Int']>;
  aic_compare?: Maybe<Scalars['Int']>;
  d_emp_hours?: Maybe<Scalars['Int']>;
  pn_profile?: Maybe<Scalars['Int']>;
  projcreator?: Maybe<Scalars['Int']>;
  billviewer?: Maybe<Scalars['Int']>;
  billmanager?: Maybe<Scalars['Int']>;
  prv?: Maybe<Scalars['Int']>;
};

export type UsersGroupedByUserGroup = {
  __typename?: 'UsersGroupedByUserGroup';
  group: Scalars['String'];
  users: Array<Maybe<User>>;
};

export type VdcGroup = {
  __typename?: 'VdcGroup';
  group_id?: Maybe<Scalars['Int']>;
  group_name?: Maybe<Scalars['String']>;
};

export type VdcHighlightsData = {
  __typename?: 'VdcHighlightsData';
  service?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  previous_count?: Maybe<Scalars['Float']>;
  previous_hours?: Maybe<Scalars['Float']>;
  previous_ytd_count?: Maybe<Scalars['Float']>;
  previous_ytd_hours?: Maybe<Scalars['Float']>;
  ytd_count?: Maybe<Scalars['Float']>;
  ytd_hours?: Maybe<Scalars['Float']>;
  count_percentage_change?: Maybe<Scalars['Float']>;
  hours_percentage_change?: Maybe<Scalars['Float']>;
  count_forecast?: Maybe<Scalars['Float']>;
  hours_forecast?: Maybe<Scalars['Float']>;
};

export type VdcServiceType = {
  __typename?: 'VdcServiceType';
  service_type_id?: Maybe<Scalars['Int']>;
  service_name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Int']>;
};

export type VdcTvData = {
  __typename?: 'VdcTvData';
  upcomingDatesProjects?: Maybe<Array<Maybe<BimDashboardTable>>>;
  materialDifference?: Maybe<Array<Maybe<BimDashboardTable>>>;
};

export type VicToolVersion = {
  __typename?: 'VicToolVersion';
  title?: Maybe<Scalars['String']>;
};

export type VicToolsDashboardData = {
  __typename?: 'VicToolsDashboardData';
  title?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_date?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customer_contact?: Maybe<Scalars['String']>;
  sales_rep?: Maybe<Scalars['String']>;
  vicToolVersion2030?: Maybe<Scalars['Int']>;
  vicToolVersion2029?: Maybe<Scalars['Int']>;
  vicToolVersion2028?: Maybe<Scalars['Int']>;
  vicToolVersion2027?: Maybe<Scalars['Int']>;
  vicToolVersion2026?: Maybe<Scalars['Int']>;
  vicToolVersion2025?: Maybe<Scalars['Int']>;
  vicToolVersion2024?: Maybe<Scalars['Int']>;
  vicToolVersion2023?: Maybe<Scalars['Int']>;
  vicToolVersion2022?: Maybe<Scalars['Int']>;
  vicToolVersion2021?: Maybe<Scalars['Int']>;
  vicToolVersion2020?: Maybe<Scalars['Int']>;
  vicToolVersion2019?: Maybe<Scalars['Int']>;
  vicToolVersion2018?: Maybe<Scalars['Int']>;
  vicToolVersion2017?: Maybe<Scalars['Int']>;
  vicToolVersion2016?: Maybe<Scalars['Int']>;
  vicToolVersion2015?: Maybe<Scalars['Int']>;
  vicToolVersion2014?: Maybe<Scalars['Int']>;
  vicToolVersionv14?: Maybe<Scalars['Int']>;
  vicToolVersionv15?: Maybe<Scalars['Int']>;
  vicToolVersionv16?: Maybe<Scalars['Int']>;
  vicToolVersionv17?: Maybe<Scalars['Int']>;
  vicToolVersionv18?: Maybe<Scalars['Int']>;
  vicToolVersionv19?: Maybe<Scalars['Int']>;
  vicToolVersionv20?: Maybe<Scalars['Int']>;
  vicToolVersionv21?: Maybe<Scalars['Int']>;
  vicToolVersionv22?: Maybe<Scalars['Int']>;
  vicToolVersionv23?: Maybe<Scalars['Int']>;
  vicToolVersionv24?: Maybe<Scalars['Int']>;
  vicToolVersionv25?: Maybe<Scalars['Int']>;
  vicToolVersionv26?: Maybe<Scalars['Int']>;
  vicToolVersionv27?: Maybe<Scalars['Int']>;
  vicToolVersionv28?: Maybe<Scalars['Int']>;
  vicToolVersionv29?: Maybe<Scalars['Int']>;
  vicToolVersionv30?: Maybe<Scalars['Int']>;
  totalExpectedRevenue?: Maybe<Scalars['String']>;
  discountAverage?: Maybe<Scalars['String']>;
  totalRevenue?: Maybe<Scalars['String']>;
  totalSales?: Maybe<Scalars['Int']>;
  totalCustomers?: Maybe<Scalars['Int']>;
  averageLicenses?: Maybe<Scalars['Int']>;
  totalVTFRDownloads?: Maybe<Scalars['Int']>;
  sessions?: Maybe<Array<Maybe<Scalars['Float']>>>;
  hours?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type VtfrTrainingsData = {
  __typename?: 'VtfrTrainingsData';
  training_id?: Maybe<Scalars['Int']>;
  trainer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  duration_num?: Maybe<Scalars['Float']>;
  duration_type?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  salesman_name?: Maybe<Scalars['String']>;
  salesman_id?: Maybe<Scalars['Int']>;
  pres_date?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['Int']>;
  quote?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  bill_amt?: Maybe<Scalars['Float']>;
  bill_amount?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  markets?: Maybe<Array<Maybe<TrainingMarket>>>;
  contact?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<TrainingContact>>>;
  user_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  expenses?: Maybe<Scalars['Float']>;
};

export type VtfrTrainingsByTrainerMetrics = {
  __typename?: 'VtfrTrainingsByTrainerMetrics';
  metrics?: Maybe<Array<Maybe<VtfrTrainingsMetrics>>>;
  trainingsTypes?: Maybe<Array<Maybe<TrainingType>>>;
};

export type VtfrTrainingsMetrics = {
  __typename?: 'VtfrTrainingsMetrics';
  rem_pres?: Maybe<Scalars['Int']>;
  on_pres?: Maybe<Scalars['Int']>;
  corp_pres?: Maybe<Scalars['Int']>;
  event_pres?: Maybe<Scalars['Int']>;
  rem_supp?: Maybe<Scalars['Int']>;
  fab_cell?: Maybe<Scalars['Int']>;
  pd_trn?: Maybe<Scalars['Int']>;
  sa_trn?: Maybe<Scalars['Int']>;
  trainer?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type AddAlternativeInput = {
  component_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addComponentAlternative?: Maybe<Array<Maybe<ComponentAlternative>>>;
  removeComponentAlternative?: Maybe<Array<Maybe<ComponentAlternative>>>;
  updateComponentSub?: Maybe<Array<Maybe<ComponentAlternative>>>;
  addComponentSize?: Maybe<Component>;
  addCustomItem?: Maybe<Component>;
  deleteComponentImage?: Maybe<Component>;
  updateComponentSize?: Maybe<Component>;
  updateComponent?: Maybe<Component>;
  copyPartNumberLink?: Maybe<Scalars['Int']>;
  deletePartNumberLink?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addPartNumberProfile?: Maybe<PartNumberProfiles>;
  updatePartNumberProfile?: Maybe<PartNumberProfiles>;
  copyProjectPartNumberLink?: Maybe<Scalars['Int']>;
  deleteProjectPartNumberLink?: Maybe<Array<Maybe<Scalars['Int']>>>;
  addProjectProfile?: Maybe<Array<Maybe<ProjectProfiles>>>;
  updateProjectProfile?: Maybe<Array<Maybe<ProjectProfiles>>>;
  updateSalesmanMarkups?: Maybe<Array<Maybe<SalesmanMarkup>>>;
  updateSalesmanMultipliers?: Maybe<Scalars['Boolean']>;
  updateSalesman?: Maybe<Salesman>;
  approveChangeOrder?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  declineChangeOrder?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  submitChangeOrderRevision?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  submitChangeOrder?: Maybe<Array<Maybe<ProjectChangeOrder>>>;
  updateChangeOrderBasicInfo?: Maybe<Scalars['Boolean']>;
  updateObjectCatalogs?: Maybe<ContentManagementResponse>;
  addBimAndCoObjectPhotos?: Maybe<ContentManagementResponse>;
  deleteBimAndCoObjectPhotos?: Maybe<ContentManagementResponse>;
  setDefaultBimAndCoPhoto?: Maybe<ContentManagementResponse>;
  updateModelProperties?: Maybe<ContentManagementResponse>;
  updateObjectProperties?: Maybe<ContentManagementResponse>;
  removeSpotConnections?: Maybe<ContentManagementResponse>;
  updateObjectModelProperties?: Maybe<ContentManagementResponse>;
  updateObjectSpotConnection?: Maybe<ContentManagementResponse>;
  updateObjectSpotData?: Maybe<ContentManagementResponse>;
  updateObjectSubmittals?: Maybe<ContentManagementResponse>;
  updateSpotSoftware?: Maybe<ContentManagementResponse>;
  updateObjectName?: Maybe<ContentManagementResponse>;
  addQuoteFields?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  autoPopulateAreaQuotes: AutoPopulateAreaQuotesResult;
  createNewAreaPhase?: Maybe<Array<Maybe<ProjectAreaPhase>>>;
  createNewPhaseQuote?: Maybe<PhaseQuote>;
  addActiveQuoteField?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  clearQuoteFieldsFromSection?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  createNewProjectArea?: Maybe<Array<Maybe<ProjectArea>>>;
  createNewMasterQuoteField?: Maybe<Array<Maybe<QuoteField>>>;
  deleteQuote?: Maybe<Array<Maybe<PhaseQuote>>>;
  insertQuoteScheduleRecord?: Maybe<Project>;
  updateAreaPhase?: Maybe<Array<Maybe<ProjectAreaPhase>>>;
  updateBaseQuoteFields?: Maybe<Array<Maybe<QuoteField>>>;
  updateEstimatingAreaHours?: Maybe<EstimatingHoursField>;
  updateFieldManual?: Maybe<Scalars['Boolean']>;
  updateProjectHoursFields?: Maybe<Array<Maybe<HoursFieldRecord>>>;
  updateProjectOffering?: Maybe<Array<Maybe<ProjectOffering>>>;
  updateQuoteFieldData?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  updateQuoteWorksheetID?: Maybe<Scalars['Int']>;
  updateQuoteFieldUnits?: Maybe<Array<Maybe<QuoteFieldRecord>>>;
  updateRiserOffset: Scalars['Boolean'];
  updateUnitsManually?: Maybe<Scalars['Boolean']>;
  resetQuoteEquipment: AutoPopulateAreaQuotesResult;
  updateEstimatingArea?: Maybe<ProjectArea>;
  updateQuoteBaseInfo?: Maybe<Array<Maybe<PhaseQuote>>>;
  addAdditionalFinancesOffering?: Maybe<FinancesData>;
  addFinancesRevision?: Maybe<MtoRevision>;
  deleteFinancesRevision?: Maybe<MtoRevision>;
  editFinancesRevision?: Maybe<MtoRevision>;
  fetchLatestData?: Maybe<LatestData>;
  markFinancesRevisionSold?: Maybe<Array<Maybe<QuoteRevision>>>;
  removeAdditionalFinancesOffering?: Maybe<FinancesData>;
  restoreFinancesRevision?: Maybe<Array<Maybe<QuoteRevision>>>;
  revisionMultipleQuotes?: Maybe<MtoRevision>;
  setFinancesComplete?: Maybe<Scalars['Boolean']>;
  toggleScheduleImpact?: Maybe<Scalars['Boolean']>;
  toggleUpdatedPo?: Maybe<Scalars['Boolean']>;
  updateChangeOrderStatus?: Maybe<Scalars['Boolean']>;
  updateFinancesHours?: Maybe<Array<Maybe<FinancesHours>>>;
  updateFinancesItem?: Maybe<FinancesData>;
  updateProjectHolder?: Maybe<Project>;
  addAppliedProjectNoteType?: Maybe<Array<Maybe<ProjectNoteType>>>;
  addCustomerContact?: Maybe<Project>;
  addNewProject?: Maybe<Project>;
  addProjectNote?: Maybe<ProjectNote>;
  addProjectTeamMember: Array<Maybe<User>>;
  deleteAppliedProjectNoteType?: Maybe<Array<Maybe<ProjectNoteType>>>;
  deleteCustomerContact?: Maybe<Project>;
  deleteProjectNote: Array<Maybe<ProjectNote>>;
  removeProjectTeamMember: Array<Maybe<User>>;
  setProjectInactive?: Maybe<Scalars['Boolean']>;
  updateCustomerContact?: Maybe<Project>;
  updateProjectInfoView?: Maybe<Project>;
  updateProjectLocation?: Maybe<ProjectLocation>;
  updateProjectNote?: Maybe<ProjectNote>;
  updateProjectStatusDate: Project;
  updateProjectStatus?: Maybe<ProjectStatusUpdateResult>;
  updateProjectType?: Maybe<Scalars['String']>;
  updateProjectBimBase: Project;
  addNewHeader?: Maybe<Array<Maybe<Header>>>;
  addNewMaterialItem?: Maybe<Array<Maybe<ProjectBillItem>>>;
  addNewProjectPricing?: Maybe<Array<Maybe<ProjectPricing>>>;
  applyProfile?: Maybe<Scalars['Boolean']>;
  applyProjectPricing?: Maybe<Scalars['Boolean']>;
  applyProjectProfile?: Maybe<Scalars['Boolean']>;
  createProjectAncillaries?: Maybe<ProjectAncillaries>;
  deleteHeaders?: Maybe<Array<Maybe<Header>>>;
  deleteMaterialItem?: Maybe<Array<Maybe<ProjectBillItem>>>;
  flipComponent?: Maybe<Scalars['Boolean']>;
  flipSystem?: Maybe<FlipSystemResult>;
  groupSystems?: Maybe<GroupedSystems>;
  mapSystemsToQuotes?: Maybe<Array<Maybe<System>>>;
  moveHeaders?: Maybe<Array<Maybe<Header>>>;
  addNewRevision?: Maybe<Array<Maybe<Revision>>>;
  deleteRevision?: Maybe<Array<Maybe<Revision>>>;
  markRevisionSold?: Maybe<Array<Maybe<Revision>>>;
  restoreRevision?: Maybe<Array<Maybe<Revision>>>;
  revisionMultipleSystems?: Maybe<Array<Maybe<Revision>>>;
  updateRevision?: Maybe<Array<Maybe<Revision>>>;
  addNewSystem?: Maybe<Array<Maybe<System>>>;
  copySystem?: Maybe<Array<Maybe<System>>>;
  deleteSystem?: Maybe<Array<Maybe<System>>>;
  updateSystem?: Maybe<Array<Maybe<System>>>;
  removePartNumber?: Maybe<Array<Maybe<ProjectBillItem>>>;
  removeProjectPricing?: Maybe<Scalars['Boolean']>;
  setGroupMultipliers?: Maybe<Scalars['Boolean']>;
  splitSystems?: Maybe<SplitSystem>;
  updateHeader?: Maybe<Array<Maybe<Header>>>;
  updateMaterial?: Maybe<Array<Maybe<ProjectBillItem>>>;
  updateProjectPricing?: Maybe<Array<Maybe<ProjectPricing>>>;
  addComponentToProfile?: Maybe<Scalars['Boolean']>;
  addComponentToProjectProfile?: Maybe<Scalars['Boolean']>;
  usePartNumberAlways?: Maybe<Scalars['Boolean']>;
  usePartNumberOnce?: Maybe<Scalars['Boolean']>;
  addNewCustomer?: Maybe<ProjectSelectOptions>;
  updateQuoteDocument?: Maybe<Project>;
  addNewSequence?: Maybe<Project>;
  addSequenceDates?: Maybe<Project>;
  addTemplateDates: Array<Maybe<SequenceTemplate>>;
  assignTruck?: Maybe<Project>;
  copySequence?: Maybe<Project>;
  createSequenceTemplate?: Maybe<ProjectSelectOptions>;
  deleteSequenceDate?: Maybe<Project>;
  deleteSequence: Project;
  deleteTemplateDate: Array<Maybe<SequenceTemplate>>;
  submitFabricationRequest?: Maybe<SubmitFabricationRequestResponse>;
  unassignTruck: Project;
  updateSequenceBase?: Maybe<Project>;
  updateSequenceDate?: Maybe<Project>;
  updateSequenceLogistics?: Maybe<Project>;
  updateSequencesOrder?: Maybe<Scalars['Boolean']>;
  updateTemplateBase: Array<Maybe<SequenceTemplate>>;
  addVtfrTrainingLog?: Maybe<Array<Maybe<VtfrTrainingsData>>>;
  deleteVtfrTrainingLog?: Maybe<Scalars['Boolean']>;
  updateVtfrTrainingLog?: Maybe<Array<Maybe<VtfrTrainingsData>>>;
  updateRecentUserProjects: Array<Maybe<Project>>;
};


export type MutationAddComponentAlternativeArgs = {
  input: AddAlternativeInput;
};


export type MutationRemoveComponentAlternativeArgs = {
  input: RemoveAlternativeInput;
};


export type MutationUpdateComponentSubArgs = {
  input: UpdateComponentSubInput;
};


export type MutationAddComponentSizeArgs = {
  input: AddComponentSizeInput;
};


export type MutationAddCustomItemArgs = {
  input: AddCustomItemInput;
};


export type MutationDeleteComponentImageArgs = {
  input: DeleteComponentImageInput;
};


export type MutationUpdateComponentSizeArgs = {
  input: UpdateComponentSizeInput;
};


export type MutationUpdateComponentArgs = {
  input: UpdateComponentInput;
};


export type MutationCopyPartNumberLinkArgs = {
  input: Array<Maybe<CopyPartNumberLinkInput>>;
};


export type MutationDeletePartNumberLinkArgs = {
  input: Array<Maybe<DeletePartNumberLinkInput>>;
};


export type MutationAddPartNumberProfileArgs = {
  input: AddPartNumberProfileInput;
};


export type MutationUpdatePartNumberProfileArgs = {
  input: UpdatePartNumberProfileInput;
};


export type MutationCopyProjectPartNumberLinkArgs = {
  input: Array<Maybe<CopyProjectPartNumberLinkInput>>;
};


export type MutationDeleteProjectPartNumberLinkArgs = {
  input: Array<Maybe<DeleteProjectPartNumberLinkInput>>;
};


export type MutationAddProjectProfileArgs = {
  input: AddProjectProfileInput;
};


export type MutationUpdateProjectProfileArgs = {
  input: UpdateProjectProfileInput;
};


export type MutationUpdateSalesmanMarkupsArgs = {
  input: Array<Maybe<UpdateSalesmanMarkupsInput>>;
};


export type MutationUpdateSalesmanMultipliersArgs = {
  input: UpdateSalesmanMultipliersInput;
};


export type MutationUpdateSalesmanArgs = {
  input: UpdateSalesmanInput;
};


export type MutationApproveChangeOrderArgs = {
  input: ApproveChangeOrderInput;
};


export type MutationDeclineChangeOrderArgs = {
  input: DeclineChangeOrderInput;
};


export type MutationSubmitChangeOrderRevisionArgs = {
  input: SubmitChangeOrderRevisionInput;
};


export type MutationSubmitChangeOrderArgs = {
  input: SubmitChangeOrderInput;
};


export type MutationUpdateChangeOrderBasicInfoArgs = {
  input: UpdateChangeOrderBasicInfoInput;
};


export type MutationUpdateObjectCatalogsArgs = {
  input: UpdateObjectCatalogsInput;
};


export type MutationAddBimAndCoObjectPhotosArgs = {
  input: AddBimAndCoObjectPhotosInput;
};


export type MutationDeleteBimAndCoObjectPhotosArgs = {
  input: DeleteBimAndCoObjectPhotosInput;
};


export type MutationSetDefaultBimAndCoPhotoArgs = {
  input: SetDefaultBimAndCoPhotoInput;
};


export type MutationUpdateModelPropertiesArgs = {
  input: Array<Maybe<UpdateModelPropertiesInput>>;
};


export type MutationUpdateObjectPropertiesArgs = {
  input: UpdateObjectPropertiesInput;
};


export type MutationRemoveSpotConnectionsArgs = {
  input: RemoveSpotConnectionsInput;
};


export type MutationUpdateObjectModelPropertiesArgs = {
  input: UpdateObjectModelPropertiesInput;
};


export type MutationUpdateObjectSpotConnectionArgs = {
  input: UpdateObjectSpotConnectionInput;
};


export type MutationUpdateObjectSpotDataArgs = {
  input: Array<Maybe<UpdateSpotDataInput>>;
};


export type MutationUpdateObjectSubmittalsArgs = {
  input: UpdateObjectSubmittalsInput;
};


export type MutationUpdateSpotSoftwareArgs = {
  input: Array<Maybe<UpdateSpotSoftwareInput>>;
};


export type MutationUpdateObjectNameArgs = {
  input: UpdateObjectNameInput;
};


export type MutationAddQuoteFieldsArgs = {
  input?: Maybe<Array<Maybe<QuoteFieldRecordInput>>>;
};


export type MutationAutoPopulateAreaQuotesArgs = {
  input: AutoPopulateAreaQuotesInput;
};


export type MutationCreateNewAreaPhaseArgs = {
  input: CreateNewAreaPhaseInput;
};


export type MutationCreateNewPhaseQuoteArgs = {
  input: CreateNewPhaseQuoteInput;
};


export type MutationAddActiveQuoteFieldArgs = {
  input: UpdateActiveQuoteFieldInput;
};


export type MutationClearQuoteFieldsFromSectionArgs = {
  input?: Maybe<Array<Maybe<QuoteFieldRecordInput>>>;
};


export type MutationCreateNewProjectAreaArgs = {
  input: CreateNewProjectAreaInput;
};


export type MutationCreateNewMasterQuoteFieldArgs = {
  input: CreateNewMasterQuoteFieldInput;
};


export type MutationDeleteQuoteArgs = {
  input?: Maybe<DeleteQuoteInput>;
};


export type MutationInsertQuoteScheduleRecordArgs = {
  input: InsertQuoteScheduleRecordInput;
};


export type MutationUpdateAreaPhaseArgs = {
  input: UpdateAreaPhaseInput;
};


export type MutationUpdateBaseQuoteFieldsArgs = {
  input?: Maybe<Array<Maybe<UpdateBaseQuoteFieldsInput>>>;
};


export type MutationUpdateEstimatingAreaHoursArgs = {
  input?: Maybe<UpdateEstimatingHoursInput>;
};


export type MutationUpdateFieldManualArgs = {
  input: UpdateFieldManualInput;
};


export type MutationUpdateProjectHoursFieldsArgs = {
  input?: Maybe<UpdateProjectHoursFieldsInput>;
};


export type MutationUpdateProjectOfferingArgs = {
  input?: Maybe<UpdateProjectOfferingsInput>;
};


export type MutationUpdateQuoteFieldDataArgs = {
  input: Array<Maybe<UpdateQuoteFieldDataInput>>;
};


export type MutationUpdateQuoteWorksheetIdArgs = {
  input: UpdateQuoteWorksheetIdInput;
};


export type MutationUpdateQuoteFieldUnitsArgs = {
  input?: Maybe<QuoteFieldRecordInput>;
};


export type MutationUpdateRiserOffsetArgs = {
  input: UpdateRiserOffsetInput;
};


export type MutationUpdateUnitsManuallyArgs = {
  input: UpdateUnitsManuallyInput;
};


export type MutationResetQuoteEquipmentArgs = {
  input: ResetQuoteEquipmentInput;
};


export type MutationUpdateEstimatingAreaArgs = {
  input?: Maybe<UpdateEstimatingAreaInput>;
};


export type MutationUpdateQuoteBaseInfoArgs = {
  input: UpdateQuoteBaseInfoInput;
};


export type MutationAddAdditionalFinancesOfferingArgs = {
  input: AddAdditionalFinancesOfferingInput;
};


export type MutationAddFinancesRevisionArgs = {
  input: AddFinancesRevisionInput;
};


export type MutationDeleteFinancesRevisionArgs = {
  input: DeleteFinancesRevisionInput;
};


export type MutationEditFinancesRevisionArgs = {
  input: EditFinancesRevisionInput;
};


export type MutationFetchLatestDataArgs = {
  input: FetchLatestDataInput;
};


export type MutationMarkFinancesRevisionSoldArgs = {
  input: MarkFinancesRevisionSoldInput;
};


export type MutationRemoveAdditionalFinancesOfferingArgs = {
  input: RemoveAdditionalFinancesOfferingInput;
};


export type MutationRestoreFinancesRevisionArgs = {
  input: RestoreFinancesRevisionInput;
};


export type MutationRevisionMultipleQuotesArgs = {
  input: RevisionMultipleQuotesInput;
};


export type MutationSetFinancesCompleteArgs = {
  input: SetFinancesCompleteInput;
};


export type MutationToggleScheduleImpactArgs = {
  input: UpdateEstimatingAreaInput;
};


export type MutationToggleUpdatedPoArgs = {
  input: UpdateEstimatingAreaInput;
};


export type MutationUpdateChangeOrderStatusArgs = {
  input: UpdateEstimatingAreaInput;
};


export type MutationUpdateFinancesHoursArgs = {
  input: UpdateFinancesHoursInput;
};


export type MutationUpdateFinancesItemArgs = {
  input: UpdateFinancesItemInput;
};


export type MutationUpdateProjectHolderArgs = {
  input?: Maybe<Scalars['Int']>;
};


export type MutationAddAppliedProjectNoteTypeArgs = {
  input: AddAppliedProjectNoteTypeInput;
};


export type MutationAddCustomerContactArgs = {
  input: AddCustomerContactInput;
};


export type MutationAddNewProjectArgs = {
  input: AddNewProjectInput;
};


export type MutationAddProjectNoteArgs = {
  input: AddProjectNoteInput;
};


export type MutationAddProjectTeamMemberArgs = {
  input: UpdateProjectTeamMemberInput;
};


export type MutationDeleteAppliedProjectNoteTypeArgs = {
  input: DeleteAppliedProjectNoteTypeInput;
};


export type MutationDeleteCustomerContactArgs = {
  input: DeleteCustomerContactInput;
};


export type MutationDeleteProjectNoteArgs = {
  input: DeleteProjectNoteInput;
};


export type MutationRemoveProjectTeamMemberArgs = {
  input: UpdateProjectTeamMemberInput;
};


export type MutationSetProjectInactiveArgs = {
  input: SetProjectInactiveInput;
};


export type MutationUpdateCustomerContactArgs = {
  input: UpdateCustomerContactInput;
};


export type MutationUpdateProjectInfoViewArgs = {
  input: UpdateProjectInfoViewInput;
};


export type MutationUpdateProjectLocationArgs = {
  input: UpdateProjectLocationInput;
};


export type MutationUpdateProjectNoteArgs = {
  input: UpdateProjectNoteInput;
};


export type MutationUpdateProjectStatusDateArgs = {
  input: UpdateProjectStatusDateInput;
};


export type MutationUpdateProjectStatusArgs = {
  input: ProjectStatusUpdateInput;
};


export type MutationUpdateProjectTypeArgs = {
  input?: Maybe<UpdateProjectTypeInput>;
};


export type MutationUpdateProjectBimBaseArgs = {
  input: UpdateProjectBimBaseInput;
};


export type MutationAddNewHeaderArgs = {
  input: AddNewHeaderInput;
};


export type MutationAddNewMaterialItemArgs = {
  input: UpdateMaterialInput;
};


export type MutationAddNewProjectPricingArgs = {
  input: AddNewProjectPricingInput;
};


export type MutationApplyProfileArgs = {
  input: ApplyProfileInput;
};


export type MutationApplyProjectPricingArgs = {
  proj_id: Scalars['Int'];
};


export type MutationApplyProjectProfileArgs = {
  input: ApplyProjectProfileInput;
};


export type MutationCreateProjectAncillariesArgs = {
  input: CreateProjectAncillariesInput;
};


export type MutationDeleteHeadersArgs = {
  input: DeleteHeadersInput;
};


export type MutationDeleteMaterialItemArgs = {
  input: DeleteMaterialInput;
};


export type MutationFlipComponentArgs = {
  input: FlipComponentInput;
};


export type MutationFlipSystemArgs = {
  input: FlipSystemInput;
};


export type MutationGroupSystemsArgs = {
  input: GroupSystemsInput;
};


export type MutationMapSystemsToQuotesArgs = {
  input: Array<Maybe<MapSystemsToQuotesInput>>;
};


export type MutationMoveHeadersArgs = {
  input: MoveHeadersInput;
};


export type MutationAddNewRevisionArgs = {
  input: MutateRevisionInput;
};


export type MutationDeleteRevisionArgs = {
  input: MutateRevisionInput;
};


export type MutationMarkRevisionSoldArgs = {
  input: MutateRevisionInput;
};


export type MutationRestoreRevisionArgs = {
  input: MutateRevisionInput;
};


export type MutationRevisionMultipleSystemsArgs = {
  input: MutateRevisionInput;
};


export type MutationUpdateRevisionArgs = {
  input: MutateRevisionInput;
};


export type MutationAddNewSystemArgs = {
  input: MutateSystemInput;
};


export type MutationCopySystemArgs = {
  input: MutateSystemInput;
};


export type MutationDeleteSystemArgs = {
  input: MutateSystemInput;
};


export type MutationUpdateSystemArgs = {
  input: MutateSystemInput;
};


export type MutationRemovePartNumberArgs = {
  input: RemovePartNumberInput;
};


export type MutationRemoveProjectPricingArgs = {
  project_pricing_id: Scalars['Int'];
};


export type MutationSetGroupMultipliersArgs = {
  input: SetGroupMultipliersInput;
};


export type MutationSplitSystemsArgs = {
  input: SplitSystemsInput;
};


export type MutationUpdateHeaderArgs = {
  input: AddNewHeaderInput;
};


export type MutationUpdateMaterialArgs = {
  input: UpdateMaterialInput;
};


export type MutationUpdateProjectPricingArgs = {
  input: UpdateProjectPricingInput;
};


export type MutationAddComponentToProfileArgs = {
  input: UsePartNumberInput;
};


export type MutationAddComponentToProjectProfileArgs = {
  input: UsePartNumberInput;
};


export type MutationUsePartNumberAlwaysArgs = {
  input: UsePartNumberInput;
};


export type MutationUsePartNumberOnceArgs = {
  input: UsePartNumberInput;
};


export type MutationAddNewCustomerArgs = {
  input: AddNewCustomerInput;
};


export type MutationUpdateQuoteDocumentArgs = {
  input: UpdateQuoteDocumentInput;
};


export type MutationAddNewSequenceArgs = {
  input?: Maybe<AddNewSequenceInput>;
};


export type MutationAddSequenceDatesArgs = {
  input: Array<Maybe<AddSequenceDateInput>>;
};


export type MutationAddTemplateDatesArgs = {
  input: AddTemplateDatesInput;
};


export type MutationAssignTruckArgs = {
  input: AssignTruckInput;
};


export type MutationCopySequenceArgs = {
  input: CopySequenceInput;
};


export type MutationCreateSequenceTemplateArgs = {
  input: CreateSequenceTemplateInput;
};


export type MutationDeleteSequenceDateArgs = {
  input: DeleteSequenceDateInput;
};


export type MutationDeleteSequenceArgs = {
  input: DeleteSequenceInput;
};


export type MutationDeleteTemplateDateArgs = {
  input: DeleteTemplateDateInput;
};


export type MutationSubmitFabricationRequestArgs = {
  input: SubmitFabricationRequestInput;
};


export type MutationUnassignTruckArgs = {
  input: UnassignTruckInput;
};


export type MutationUpdateSequenceBaseArgs = {
  input: UpdateSequenceBaseInput;
};


export type MutationUpdateSequenceDateArgs = {
  input: UpdateSequenceDateInput;
};


export type MutationUpdateSequenceLogisticsArgs = {
  input: UpdateSequenceLogisticsInput;
};


export type MutationUpdateSequencesOrderArgs = {
  input: Array<Maybe<UpdateSequencesOrderInput>>;
};


export type MutationUpdateTemplateBaseArgs = {
  input: UpdateTemplateBaseInput;
};


export type MutationAddVtfrTrainingLogArgs = {
  input: AddVtfrTrainingLogInput;
};


export type MutationDeleteVtfrTrainingLogArgs = {
  training_id: Scalars['Int'];
};


export type MutationUpdateVtfrTrainingLogArgs = {
  input: UpdateVtfrTrainingLogInput;
};


export type MutationUpdateRecentUserProjectsArgs = {
  input: UpdateRecentUserProjectsInput;
};

export type RemoveAlternativeInput = {
  component_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  rowNum?: Maybe<Scalars['Int']>;
};

export type UpdateComponentSubInput = {
  component_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  rowNum?: Maybe<Scalars['Int']>;
  part_number?: Maybe<Scalars['String']>;
  subIndex?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type AddComponentSizeInput = {
  size_desc_imp?: Maybe<Scalars['String']>;
  size_desc_m3?: Maybe<Scalars['String']>;
};

export type AddCustomItemInput = {
  partnumber_us?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  size_code?: Maybe<Scalars['String']>;
  gasket_type?: Maybe<Scalars['String']>;
  style_desc?: Maybe<Scalars['String']>;
  component_desc?: Maybe<Scalars['String']>;
  price_usd?: Maybe<Scalars['Float']>;
  price_eur?: Maybe<Scalars['Float']>;
  price_gbp?: Maybe<Scalars['Float']>;
  price_jpy?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Int']>;
  labor_mcaa?: Maybe<Scalars['Float']>;
};

export type DeleteComponentImageInput = {
  component_id: Scalars['Int'];
  image_id: Scalars['Int'];
};

export type UpdateComponentSizeInput = {
  size_desc_imp?: Maybe<Scalars['String']>;
  size_desc_m3?: Maybe<Scalars['String']>;
  size_code?: Maybe<Scalars['String']>;
};

export type UpdateComponentInput = {
  component_id?: Maybe<Scalars['Int']>;
  gasket_type?: Maybe<Scalars['String']>;
  style_desc?: Maybe<Scalars['String']>;
  component_desc?: Maybe<Scalars['String']>;
  component_desc_m3?: Maybe<Scalars['String']>;
  size_code?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  labor_mcaa?: Maybe<Scalars['Float']>;
  price_usd?: Maybe<Scalars['Float']>;
  price_eur?: Maybe<Scalars['Float']>;
  price_gbp?: Maybe<Scalars['Float']>;
  price_jpy?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Int']>;
};

export type CopyPartNumberLinkInput = {
  component_id?: Maybe<Scalars['Int']>;
  rev_size?: Maybe<Scalars['String']>;
  rev_desc?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type DeletePartNumberLinkInput = {
  link_id?: Maybe<Scalars['Int']>;
};

export type AddPartNumberProfileInput = {
  profile: Scalars['String'];
  profile_desc?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type UpdatePartNumberProfileInput = {
  profile_id?: Maybe<Scalars['Int']>;
  profile: Scalars['String'];
  profile_desc?: Maybe<Scalars['String']>;
};

export type CopyProjectPartNumberLinkInput = {
  component_id?: Maybe<Scalars['Int']>;
  rev_size?: Maybe<Scalars['String']>;
  rev_desc?: Maybe<Scalars['String']>;
  project_profile_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type DeleteProjectPartNumberLinkInput = {
  link_id?: Maybe<Scalars['Int']>;
};

export type AddProjectProfileInput = {
  project_profile: Scalars['String'];
  project_profile_desc?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  proj_id: Scalars['Int'];
};

export type UpdateProjectProfileInput = {
  proj_id: Scalars['Int'];
  project_profile_id: Scalars['Int'];
  project_profile: Scalars['String'];
  project_profile_desc?: Maybe<Scalars['String']>;
};

export type UpdateSalesmanMarkupsInput = {
  salesman_markup_id?: Maybe<Scalars['Int']>;
  salesmanID: Scalars['String'];
  offeringid: Scalars['Int'];
  dist_markup: Scalars['Float'];
  cust_markup: Scalars['Float'];
};

export type UpdateSalesmanMultipliersInput = {
  salesmanID?: Maybe<Scalars['String']>;
  groupMultipliers?: Maybe<Array<Maybe<SalesmanMultiplierInput>>>;
};

export type SalesmanMultiplierInput = {
  group_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  salesman_multiplier_id?: Maybe<Scalars['Int']>;
};

export type UpdateSalesmanInput = {
  salesregion_id?: Maybe<Scalars['String']>;
  metroID?: Maybe<Scalars['String']>;
  groupID?: Maybe<Scalars['Int']>;
  divisionID?: Maybe<Scalars['String']>;
  reports_to?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  salesmanID?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Int']>;
};

export type ApproveChangeOrderInput = {
  proj_id: Scalars['Int'];
  projid: Scalars['Int'];
  coordinator_id: Scalars['Int'];
  areaid: Scalars['Int'];
  change_order_id: Scalars['Int'];
  quoteid: Scalars['Int'];
  user_id: Scalars['Int'];
  supervisor_id: Scalars['Int'];
  change_order_name: Scalars['String'];
  change_order_description: Scalars['String'];
  change_order_drawing_num?: Maybe<Scalars['String']>;
  preChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  postChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  bimHoursData: Array<Maybe<BimHourDataInput>>;
  changeOrderNotes?: Maybe<Array<Maybe<ChangeOrderNoteInput>>>;
  changeOrderSuggestedValues?: Maybe<Array<Maybe<ChangeOrderSuggestedValueInput>>>;
  finances: Array<Maybe<FinancesInput>>;
  financesHours: Array<Maybe<FinancesHoursInput>>;
  isInternal?: Maybe<Scalars['Boolean']>;
  withSuggestedValues?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type DeclineChangeOrderInput = {
  proj_id: Scalars['Int'];
  change_order_id: Scalars['Int'];
  coordinator_id: Scalars['Int'];
  areaid: Scalars['Int'];
  user_id: Scalars['Int'];
  supervisor_id: Scalars['Int'];
};

export type SubmitChangeOrderRevisionInput = {
  proj_id: Scalars['Int'];
  projid: Scalars['Int'];
  user_id: Scalars['Int'];
  supervisor_id: Scalars['Int'];
  change_order_id: Scalars['Int'];
  quoteid: Scalars['Int'];
  areaid: Scalars['Int'];
  preChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  postChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  bimHoursData: Array<Maybe<BimHourDataInput>>;
  changeOrderNotes?: Maybe<Array<Maybe<ChangeOrderNoteInput>>>;
  finances: Array<Maybe<FinancesInput>>;
  financesHours: Array<Maybe<FinancesHoursInput>>;
  changeOrderSuggestedValues?: Maybe<Array<Maybe<ChangeOrderSuggestedValueInput>>>;
};

export type SubmitChangeOrderInput = {
  proj_id: Scalars['Int'];
  projid: Scalars['Int'];
  user_id: Scalars['Int'];
  supervisor_id: Scalars['Int'];
  change_order_name: Scalars['String'];
  change_order_description: Scalars['String'];
  change_order_drawing_num?: Maybe<Scalars['String']>;
  preChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  postChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  bimHoursData: Array<Maybe<BimHourDataInput>>;
  changeOrderNotes?: Maybe<Array<Maybe<ChangeOrderNoteInput>>>;
  finances: Array<Maybe<FinancesInput>>;
  financesHours: Array<Maybe<FinancesHoursInput>>;
  changeOrderSuggestedValues?: Maybe<Array<Maybe<ChangeOrderSuggestedValueInput>>>;
};

export type FinancesInput = {
  offeringid: Scalars['Int'];
  count?: Maybe<Scalars['Int']>;
  value: Scalars['Float'];
  dist_markup: Scalars['Float'];
  cust_markup: Scalars['Float'];
};

export type FinancesHoursInput = {
  finance_hours_type_id: Scalars['Int'];
  value: Scalars['Int'];
};

export type ChangeOrderSuggestedValueInput = {
  offeringid: Scalars['Int'];
  value: Scalars['Float'];
};

export type ChangeOrderNoteInput = {
  note_id?: Maybe<Scalars['Int']>;
  change_order_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
};

export type UpdateChangeOrderBasicInfoInput = {
  user_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  change_order_id: Scalars['Int'];
  change_order_name: Scalars['String'];
  change_order_description: Scalars['String'];
  change_order_drawing_num?: Maybe<Scalars['String']>;
};

export type ChangeOrderPreviewInput = {
  proj_id: Scalars['Int'];
  isFabJob: Scalars['Boolean'];
  region_id: Scalars['Int'];
  preChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  postChangeOrderBom: Array<Maybe<ChangeOrderBom>>;
  bimHoursData: Array<Maybe<BimHourDataInput>>;
  quoteid?: Maybe<Scalars['Int']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isRevision?: Maybe<Scalars['Boolean']>;
};

export type ChangeOrderBom = {
  revision_id: Scalars['Int'];
  revision_name?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['String']>;
  system_id: Scalars['Int'];
};

export type BimHourDataInput = {
  bim_hour_type_id: Scalars['Int'];
  value: Scalars['Int'];
};

export type BimAndCoObjectInput = {
  Id?: Maybe<Scalars['String']>;
  UpdatedAt?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DefaultPhoto?: Maybe<Scalars['String']>;
  Catalogs?: Maybe<Array<Maybe<BimAndCoCatalogInput>>>;
  Photos?: Maybe<Array<Maybe<BimAndCoPhotosInput>>>;
};

export type BimObjectInput = {
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Photo?: Maybe<Scalars['String']>;
  Catalog?: Maybe<BimAndCoCatalogInput>;
};

export type BimAndCoCatalogInput = {
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type UpdateObjectCatalogsInput = {
  Objects?: Maybe<Array<Maybe<UpdateObjectCatalogObjectInput>>>;
};

export type UpdateObjectCatalogObjectInput = {
  ObjectId?: Maybe<Scalars['String']>;
  OldCatalogIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  NewCatalogIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddBimAndCoObjectPhotosInput = {
  objectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  photoUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BimAndCoPhotosInput = {
  Id?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['String']>;
  Path?: Maybe<Scalars['String']>;
};

export type DeleteBimAndCoObjectPhotosInput = {
  objectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  photoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetDefaultBimAndCoPhotoInput = {
  objectId?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModelProperties = {
  ModelId?: Maybe<Scalars['String']>;
  ModelLevelOfDetail?: Maybe<Scalars['String']>;
  ModelTypeFormat?: Maybe<Scalars['String']>;
  ModelSoftwareType?: Maybe<Scalars['String']>;
};

export type UpdateModelPropertiesInput = {
  ObjectId?: Maybe<Scalars['String']>;
  Models?: Maybe<Array<Maybe<ModelProperties>>>;
};

export type UpdateObjectPropertiesInput = {
  ObjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  File?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  DistributionCountries?: Maybe<Scalars['String']>;
  FileType?: Maybe<Scalars['String']>;
};

export type RemoveSpotConnectionsInput = {
  ObjectName?: Maybe<Scalars['String']>;
  Permalink?: Maybe<Scalars['String']>;
};

export type SpotRecordInput = {
  SpotDescription?: Maybe<Scalars['String']>;
  Permalink?: Maybe<Scalars['String']>;
  Style?: Maybe<Scalars['String']>;
};

export type UpdateObjectModelPropertiesInput = {
  ObjectId?: Maybe<Scalars['String']>;
  SoftwareName?: Maybe<Scalars['String']>;
  ModelId?: Maybe<Scalars['String']>;
  ModelName?: Maybe<Scalars['String']>;
  SoftwareId?: Maybe<Scalars['String']>;
  ModelLevelOfDetail?: Maybe<Scalars['String']>;
  ModelTypeFormat?: Maybe<Scalars['String']>;
};

export type UpdateObjectSpotConnectionInput = {
  ObjectName?: Maybe<Scalars['String']>;
  ObjectBimAndCoId?: Maybe<Scalars['String']>;
  SpotRecords?: Maybe<Array<Maybe<SpotRecordInput>>>;
};

export type UpdateSpotDataInput = {
  ObjectName?: Maybe<Scalars['String']>;
  ObjectPermalink?: Maybe<Scalars['String']>;
  FilesToUpdate?: Maybe<Array<Maybe<UpdateSpotFileInput>>>;
};

export type UpdateSpotFileInput = {
  SoftwareName?: Maybe<Scalars['String']>;
  FileDirectory?: Maybe<Scalars['String']>;
  Timestamp?: Maybe<Scalars['String']>;
};

export type UpdateObjectSubmittalsInput = {
  ObjectId?: Maybe<Scalars['String']>;
  Permalinks?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateSpotSoftwareInput = {
  BimObjectId?: Maybe<Scalars['String']>;
  SoftwareRecords?: Maybe<Array<Maybe<UpdateSpotSoftwareRecordInput>>>;
};

export type UpdateSpotSoftwareRecordInput = {
  FilePath?: Maybe<Scalars['String']>;
  PrimeSoftwareId?: Maybe<Scalars['String']>;
  SpotTimestamp?: Maybe<Scalars['String']>;
};

export type UpdateObjectNameInput = {
  Id?: Maybe<Scalars['String']>;
  NewName?: Maybe<Scalars['String']>;
};

export type BimAndCoObjectModel = {
  __typename?: 'BimAndCoObjectModel';
  Id?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  ViewableId?: Maybe<Scalars['String']>;
  LevelOfDetail?: Maybe<Scalars['String']>;
  Software?: Maybe<Scalars['String']>;
};

export type GetBimAndCoObjectModelsResponse = {
  __typename?: 'GetBimAndCoObjectModelsResponse';
  ObjectId?: Maybe<Scalars['String']>;
  Models3D?: Maybe<Array<Maybe<BimAndCoObjectModel>>>;
  Models2D?: Maybe<Array<Maybe<BimAndCoObjectModel>>>;
};

export type SpotSoftwareRecord = {
  __typename?: 'SpotSoftwareRecord';
  FilePath?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  Software?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
};

export type SpotStatus = {
  __typename?: 'SpotStatus';
  content_object_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  content_software_status_id?: Maybe<Scalars['String']>;
  content_object_id?: Maybe<Scalars['String']>;
  uploaded_bim_and_co?: Maybe<Scalars['String']>;
};

export type CreateNewProjectAreaInput = {
  projid: Scalars['Int'];
  areaName: Scalars['String'];
};

export type CreateNewMasterQuoteFieldInput = {
  field: Scalars['String'];
  man_hours?: Maybe<Scalars['Float']>;
  totalled?: Maybe<Scalars['Int']>;
  section_id?: Maybe<Scalars['Int']>;
  worksheet_id?: Maybe<Scalars['Int']>;
};

export type CreateNewPhaseQuoteInput = {
  phase_id: Scalars['Int'];
  quote_name: Scalars['String'];
  worksheet_id?: Maybe<Scalars['Int']>;
  drawing_numbers?: Maybe<Scalars['String']>;
  drawing_revision?: Maybe<Scalars['String']>;
  limits?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rate_calc?: Maybe<Scalars['Float']>;
};

export type DeleteQuoteInput = {
  phase_id: Scalars['Int'];
  quote_id: Scalars['Int'];
};

export type InsertQuoteScheduleRecordInput = {
  service_type?: Maybe<Scalars['String']>;
  mbe_status?: Maybe<Scalars['String']>;
  mbe_planned_completion_date?: Maybe<Scalars['String']>;
  quote_status?: Maybe<Scalars['String']>;
  requested_date_sales?: Maybe<Scalars['String']>;
  mbe_requested_completion_date?: Maybe<Scalars['String']>;
  requested_sales_date_changed?: Maybe<Scalars['String']>;
  kkto_status?: Maybe<Scalars['String']>;
  mbe_resource_start_date?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  koil_kit_resource?: Maybe<Scalars['String']>;
  vdc_resource?: Maybe<Scalars['String']>;
  quote_resource?: Maybe<Scalars['String']>;
};

export type UpdateActiveQuoteFieldInput = {
  quote_id: Scalars['Int'];
  quote_field_id: Scalars['Int'];
  active: Scalars['Int'];
};

export type UpdateEstimatingHoursInput = {
  hfm_id: Scalars['Int'];
  hours: Scalars['Float'];
  area_id: Scalars['Int'];
};

export type UpdateBaseQuoteFieldsInput = {
  quote_field_id: Scalars['Int'];
  field: Scalars['String'];
  man_hours: Scalars['Float'];
  section_id: Scalars['Int'];
  totalled: Scalars['Int'];
  worksheet_id: Scalars['Int'];
};

export type UpdateFieldManualInput = {
  quote_field_id: Scalars['Int'];
  manual: Scalars['Int'];
};

export type UpdateAreaPhaseInput = {
  area_id?: Maybe<Scalars['Int']>;
  phase_id?: Maybe<Scalars['Int']>;
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Int']>;
};

export type UpdateProjectHoursFieldsInput = {
  fill_all: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  delete_all?: Maybe<Scalars['Boolean']>;
  projid: Scalars['Int'];
  hfm_id?: Maybe<Scalars['Int']>;
};

export type UpdateProjectOfferingsInput = {
  delete: Scalars['Boolean'];
  projid: Scalars['Int'];
  offeringid: Scalars['Int'];
  proj_offering_id?: Maybe<Scalars['Int']>;
};

export type UpdateQuoteFieldDataInput = {
  quote_id: Scalars['Int'];
  phase_id: Scalars['Int'];
  quote_field_id?: Maybe<Scalars['Int']>;
  quote_field_master_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  manual?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  man_hours?: Maybe<Scalars['Float']>;
  calc_value?: Maybe<Scalars['Float']>;
  calc_cost?: Maybe<Scalars['Float']>;
};

export type UpdateQuoteWorksheetIdInput = {
  worksheet_id: Scalars['Int'];
  quote_id: Scalars['Int'];
};

export type UpdateRiserOffsetInput = {
  quote_id: Scalars['Int'];
  phase_id: Scalars['Int'];
  riser_offset: Scalars['Float'];
};

export type UpdateUnitsManuallyInput = {
  quote_field_id: Scalars['Int'];
  units: Scalars['Int'];
  calc_value: Scalars['Int'];
  calc_cost: Scalars['Int'];
};

export type QuoteFieldRecordInput = {
  quote_field_id?: Maybe<Scalars['Int']>;
  quote_field_master_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  section_id?: Maybe<Scalars['Int']>;
  field_name?: Maybe<Scalars['String']>;
  man_hours?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Int']>;
  manual?: Maybe<Scalars['Int']>;
  calc_cost?: Maybe<Scalars['Int']>;
  calc_value?: Maybe<Scalars['Int']>;
};

export type AutoPopulateAreaQuotesInput = {
  proj_id: Scalars['Int'];
  isRetrofit: Scalars['Boolean'];
};

export type AutoPopulateAreaQuotesResult = {
  __typename?: 'AutoPopulateAreaQuotesResult';
  missingEquipment: Array<Maybe<EquipmentItem>>;
  quotes: Array<Maybe<PhaseQuote>>;
  quoteFailed: Scalars['Boolean'];
};

export type CreateNewAreaPhaseInput = {
  area_id: Scalars['Int'];
  name: Scalars['String'];
  market: Scalars['String'];
};

export type ResetQuoteEquipmentInput = {
  proj_id: Scalars['Int'];
  quote_id: Scalars['Int'];
};

export type UpdateEstimatingAreaInput = {
  areaid?: Maybe<Scalars['Int']>;
  area?: Maybe<Scalars['String']>;
  changeorder?: Maybe<Scalars['Int']>;
  estimate?: Maybe<Scalars['Int']>;
  marked_for_delete?: Maybe<Scalars['Boolean']>;
  schedule_impact?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  updated_po?: Maybe<Scalars['Int']>;
};

export type UpdateQuoteBaseInfoInput = {
  quote_id: Scalars['Int'];
  phase_id: Scalars['Int'];
  quote_name?: Maybe<Scalars['String']>;
  drawing_numbers?: Maybe<Scalars['String']>;
  drawing_revision?: Maybe<Scalars['String']>;
  limits?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rate_calc?: Maybe<Scalars['Float']>;
};

export type AddAdditionalFinancesOfferingInput = {
  proj_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  quote_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
  offeringid: Scalars['Int'];
};

export type AddFinancesRevisionInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  finance_revision_name: Scalars['String'];
};

export type DeleteFinancesRevisionInput = {
  finance_revision_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  deleteBomRevision: Scalars['Boolean'];
};

export type EditFinancesRevisionInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  finance_revision_name: Scalars['String'];
  finance_revision_id: Scalars['Int'];
};

export type FetchLatestDataInput = {
  proj_id: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
  isFabJob?: Maybe<Scalars['Boolean']>;
  market_id?: Maybe<Scalars['Int']>;
  system_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  revision_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type MarkFinancesRevisionSoldInput = {
  user_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type RemoveAdditionalFinancesOfferingInput = {
  projid: Scalars['Int'];
  proj_id: Scalars['Int'];
  offeringid: Scalars['Int'];
  finance_data_id: Scalars['Int'];
  user_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
  isTransBreakdown?: Maybe<Scalars['Boolean']>;
};

export type RestoreFinancesRevisionInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  quote_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
};

export type RevisionMultipleQuotesInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  quote_ids: Array<Maybe<Scalars['Int']>>;
  finance_revision_name: Scalars['String'];
};

export type SaveFinancesInputParams = {
  finance_revision_id?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  offeringid?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  buffer?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  dist_markup?: Maybe<Scalars['Float']>;
  cust_markup?: Maybe<Scalars['Float']>;
};

export type SetFinancesCompleteInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type UpdateFinancesHoursInput = {
  finance_hours_id: Scalars['Int'];
  value: Scalars['Int'];
  proj_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  quote_id?: Maybe<Scalars['Int']>;
  finance_revision_id?: Maybe<Scalars['Int']>;
};

export type UpdateFinancesItemInput = {
  finance_data_id: Scalars['Int'];
  property: Scalars['String'];
  value: Scalars['Float'];
  proj_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  quote_id: Scalars['Int'];
  finance_revision_id?: Maybe<Scalars['Int']>;
  offeringid?: Maybe<Scalars['Int']>;
  isMatBreakdown?: Maybe<Scalars['Boolean']>;
  isTransBreakdown?: Maybe<Scalars['Boolean']>;
};

export type GetFinancesInput = {
  proj_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  quote_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
  offeringid?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AddAppliedProjectNoteTypeInput = {
  note_id: Scalars['Int'];
  note_type_id: Scalars['Int'];
};

export type AddCustomerContactInput = {
  proj_id: Scalars['Int'];
  cell_phone?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contact_type: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  office_phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type AddNewProjectInput = {
  project_name: Scalars['String'];
  proj_type_id: Scalars['Int'];
  proj_type: Scalars['String'];
  additionalProjectType: Scalars['Int'];
  status: Scalars['String'];
  offeringid?: Maybe<Array<Maybe<Scalars['Int']>>>;
  requested_date_sales: Scalars['String'];
  customer_id?: Maybe<Scalars['Int']>;
  region_id: Scalars['Int'];
  region: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  bid_date?: Maybe<Scalars['String']>;
  post_bid: Scalars['Int'];
  probabilityOfSale?: Maybe<Scalars['String']>;
  autoID?: Maybe<Scalars['Int']>;
  salesregion_id?: Maybe<Scalars['String']>;
  metroID?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  divisionID?: Maybe<Scalars['String']>;
  project_long: Scalars['Float'];
  project_lat: Scalars['Float'];
  project_address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  warehouse_one?: Maybe<Scalars['String']>;
  warehouse_two?: Maybe<Scalars['String']>;
  warehouse_three?: Maybe<Scalars['String']>;
  market_id: Scalars['Int'];
  market: Scalars['String'];
  distributor?: Maybe<Scalars['String']>;
  price_list_id?: Maybe<Scalars['Int']>;
  nda_required: Scalars['Int'];
  legislativeActs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddProjectNoteInput = {
  projid: Scalars['Int'];
  note: Scalars['String'];
  user_id: Scalars['Int'];
  note_type_id: Array<Maybe<Scalars['Int']>>;
};

export type UpdateProjectTeamMemberInput = {
  user_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type DeleteAppliedProjectNoteTypeInput = {
  note_type_applied_id: Scalars['Int'];
  note_id: Scalars['Int'];
};

export type DeleteCustomerContactInput = {
  _id: Scalars['String'];
  proj_id: Scalars['Int'];
};

export type DeleteProjectNoteInput = {
  note_id: Scalars['Int'];
  projid: Scalars['Int'];
};

export type SetProjectInactiveInput = {
  proj_id: Scalars['Int'];
};

export type UpdateCustomerContactInput = {
  proj_id: Scalars['Int'];
  _id?: Maybe<Scalars['String']>;
  cell_phone?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contact_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  office_phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type UpdateProjectInfoViewInput = {
  biddate?: Maybe<Scalars['String']>;
  current_user_id?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  current_project_type?: Maybe<Scalars['Int']>;
  cpsservice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datesubmitted?: Maybe<Scalars['String']>;
  distributor?: Maybe<Scalars['String']>;
  distributor_id?: Maybe<Scalars['String']>;
  employeeid?: Maybe<Scalars['Int']>;
  dom_man?: Maybe<Scalars['String']>;
  dom_note?: Maybe<Scalars['String']>;
  engineerid?: Maybe<Scalars['Int']>;
  market_id?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['String']>;
  postbid?: Maybe<Scalars['Int']>;
  probofsale?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  project_name?: Maybe<Scalars['String']>;
  proj_num?: Maybe<Scalars['String']>;
  project_type_id?: Maybe<Scalars['Int']>;
  proj_type?: Maybe<Scalars['Int']>;
  price_list_id?: Maybe<Scalars['Int']>;
  po_date?: Maybe<Scalars['String']>;
  poaa?: Maybe<Scalars['String']>;
  poaa_date?: Maybe<Scalars['String']>;
  salesuser_id?: Maybe<Scalars['Int']>;
  union_notunion?: Maybe<Scalars['String']>;
  nda_required?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  worksheet_date?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectLocationInput = {
  proj_id: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  regioncode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type UpdateProjectNoteInput = {
  projid: Scalars['Int'];
  note: Scalars['String'];
  user_id: Scalars['Int'];
  note_id: Scalars['Int'];
};

export type UpdateProjectStatusDateInput = {
  status_id: Scalars['Int'];
  status_date: Scalars['String'];
  proj_id: Scalars['Int'];
};

export type ProjectStatusUpdateInput = {
  proj_id?: Maybe<Scalars['Int']>;
  projid?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  reason_id?: Maybe<Scalars['String']>;
  status_date?: Maybe<Scalars['String']>;
  current_project_type?: Maybe<Scalars['String']>;
};

export type UpdateProjectTypeInput = {
  proj_id: Scalars['Int'];
  project_type_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  current_user_id?: Maybe<Scalars['Int']>;
};

export type ProjectSearchInput = {
  coordinator?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['Int']>;
  project_status?: Maybe<Scalars['String']>;
  project_type?: Maybe<Scalars['Int']>;
  project_name_or_number?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['Int']>;
  salesman?: Maybe<Scalars['Int']>;
  team_leader?: Maybe<Scalars['Int']>;
  market?: Maybe<Scalars['Int']>;
  additional_project_type?: Maybe<Scalars['Int']>;
  service?: Maybe<Scalars['String']>;
};

export type UpdateProjectBimBaseInput = {
  proj_id: Scalars['Int'];
  drawing_percent_complete?: Maybe<Scalars['Int']>;
  customer_care_id?: Maybe<Scalars['Int']>;
  ph_status_id?: Maybe<Scalars['Int']>;
  purchase_order_date?: Maybe<Scalars['String']>;
  contractor_turnover_date?: Maybe<Scalars['String']>;
  closeout_meeting_date?: Maybe<Scalars['String']>;
};

export type AddNewHeaderInput = {
  system_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  region_id: Scalars['Int'];
  header_id?: Maybe<Scalars['Int']>;
  price_list_id: Scalars['Int'];
  bag_tag?: Maybe<Scalars['String']>;
  system_abbr?: Maybe<Scalars['String']>;
  mark?: Maybe<Scalars['String']>;
  assembly_name?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['String']>;
  pipe_size: Scalars['String'];
  outlet_size?: Maybe<Scalars['String']>;
  outlet_size2?: Maybe<Scalars['String']>;
  outlet_size3?: Maybe<Scalars['String']>;
  outlet_size4?: Maybe<Scalars['String']>;
  outlet_size5?: Maybe<Scalars['String']>;
  outlet_size6?: Maybe<Scalars['String']>;
  pipe_length_ft: Scalars['Int'];
  pipe_length_in: Scalars['Float'];
  outlet_count?: Maybe<Scalars['Int']>;
  tap_count?: Maybe<Scalars['Int']>;
  three_quarter_tap?: Maybe<Scalars['Int']>;
  one_tap?: Maybe<Scalars['Int']>;
  one_quarter_tap?: Maybe<Scalars['Int']>;
  one_half_tap?: Maybe<Scalars['Int']>;
  two_tap?: Maybe<Scalars['Int']>;
  header_cost?: Maybe<Scalars['Float']>;
  header_cost_ep?: Maybe<Scalars['Float']>;
  header_coating_id?: Maybe<Scalars['Int']>;
  header_lining_id?: Maybe<Scalars['Int']>;
  header_material_id?: Maybe<Scalars['Int']>;
};

export type AddNewProjectPricingInput = {
  proj_id: Scalars['Int'];
  partnumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['Float']>;
  user_id: Scalars['Int'];
};

export type ApplyProfileInput = {
  system_id: Scalars['Int'];
  profile_id: Scalars['Int'];
  revision_id?: Maybe<Scalars['Int']>;
  user_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type ApplyProjectProfileInput = {
  system_id: Scalars['Int'];
  project_profile_id: Scalars['Int'];
  revision_id?: Maybe<Scalars['Int']>;
  user_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type CreateProjectAncillariesInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  systems?: Maybe<Array<Maybe<AncillariesSystem>>>;
};

export type AncillariesSystem = {
  system_id: Scalars['Int'];
  system?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  revisions?: Maybe<Array<Maybe<AncillariesRevision>>>;
};

export type AncillariesRevision = {
  revision_id: Scalars['Int'];
  revision_name?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  system_id: Scalars['Int'];
  actual_sold?: Maybe<Scalars['Boolean']>;
};

export type DeleteHeadersInput = {
  proj_id: Scalars['Int'];
  region_id: Scalars['Int'];
  user_id: Scalars['Int'];
  headers_id: Array<Maybe<Scalars['Int']>>;
};

export type DeleteMaterialInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  price_list_id: Scalars['Int'];
  region_id: Scalars['Int'];
  bill_id: Array<Maybe<Scalars['Int']>>;
  user_id: Scalars['Int'];
};

export type FlipComponentInput = {
  substitutes?: Maybe<Array<Maybe<ComponentSubstitutes>>>;
  input?: Maybe<FlipSystemInput>;
  component_id?: Maybe<Scalars['Int']>;
  rowNum?: Maybe<Scalars['Int']>;
  bill_id?: Maybe<Scalars['Int']>;
};

export type ComponentSubstitutes = {
  PARTCODE?: Maybe<Scalars['Int']>;
  ALTERNATE?: Maybe<Scalars['Int']>;
  VICSUB1?: Maybe<Scalars['Int']>;
  VICSUB1QTY?: Maybe<Scalars['Int']>;
  VICSUB2?: Maybe<Scalars['Int']>;
  VICSUB2QTY?: Maybe<Scalars['Int']>;
  VICSUB3?: Maybe<Scalars['Int']>;
  VICSUB3QTY?: Maybe<Scalars['Int']>;
  VICSUB4?: Maybe<Scalars['Int']>;
  VICSUB4QTY?: Maybe<Scalars['Int']>;
  VICSUB5?: Maybe<Scalars['Int']>;
  VICSUB5QTY?: Maybe<Scalars['Int']>;
  VICSUB6?: Maybe<Scalars['Int']>;
  VICSUB6QTY?: Maybe<Scalars['Int']>;
  VICSUB7?: Maybe<Scalars['Int']>;
  VICSUB7QTY?: Maybe<Scalars['Int']>;
  VICSUB8?: Maybe<Scalars['Int']>;
  VICSUB8QTY?: Maybe<Scalars['Int']>;
  VICSUB9?: Maybe<Scalars['Int']>;
  VICSUB9QTY?: Maybe<Scalars['Int']>;
  VICSUB10?: Maybe<Scalars['Int']>;
  VICSUB10QTY?: Maybe<Scalars['Int']>;
  VICSUB11?: Maybe<Scalars['Int']>;
  VICSUB11QTY?: Maybe<Scalars['Int']>;
  rowNum?: Maybe<Scalars['Int']>;
  REGION_ID?: Maybe<Scalars['Int']>;
};

export type FlipSystemInput = {
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  region_id: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  price_list_id?: Maybe<Scalars['Int']>;
  alwaysPrimary?: Maybe<Scalars['Boolean']>;
};

export type FlipSystemResult = {
  __typename?: 'FlipSystemResult';
  alternatives?: Maybe<Array<Maybe<ComponentAlternative>>>;
  componentData?: Maybe<Component>;
  position?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  flippedProjectBillItems?: Maybe<Array<Maybe<ProjectBillItem>>>;
};

export type GroupSystemsInput = {
  checkedSystems: Array<Maybe<SystemInput>>;
  system_id?: Maybe<Scalars['Int']>;
  system?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  profile_id: Scalars['Int'];
};

export type SystemInput = {
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
};

export type GroupedSystems = {
  __typename?: 'GroupedSystems';
  systems?: Maybe<Array<Maybe<System>>>;
  revisions?: Maybe<Array<Maybe<Revision>>>;
};

export type MapSystemsToQuotesInput = {
  system_id: Scalars['Int'];
  quoteid?: Maybe<Scalars['Int']>;
  proj_id?: Maybe<Scalars['Int']>;
};

export type MoveHeadersInput = {
  system_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  headers_id: Array<Maybe<Scalars['Int']>>;
  user_id: Scalars['Int'];
  region_id: Scalars['Int'];
};

export type MutateRevisionInput = {
  revision_name?: Maybe<Scalars['String']>;
  revision_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  system_id: Array<Maybe<Scalars['Int']>>;
  user_id?: Maybe<Scalars['Int']>;
  forissue?: Maybe<Scalars['Int']>;
  latest_sold?: Maybe<Scalars['Boolean']>;
  actual_sold?: Maybe<Scalars['Boolean']>;
  proj_id: Scalars['Int'];
};

export type MutateSystemInput = {
  system?: Maybe<Scalars['String']>;
  system_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  system_desc?: Maybe<Scalars['String']>;
  quoteid?: Maybe<Scalars['Int']>;
  gasket_id?: Maybe<Scalars['Int']>;
  dwg?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  proj_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type RemovePartNumberInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  price_list_id: Scalars['Int'];
  region_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  bill_id: Array<Maybe<Scalars['Int']>>;
  profile_id: Scalars['Int'];
};

export type SetGroupMultipliersInput = {
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  group_multipliers?: Maybe<Array<Maybe<ComponentGroupInput>>>;
};

export type ComponentGroupInput = {
  group_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type SplitSystemsInput = {
  system_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  user_id: Scalars['Int'];
  splitOptions: Array<Maybe<Scalars['String']>>;
};

export type UpdateMaterialInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  price_list_id: Scalars['Int'];
  region_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  bill_id?: Maybe<Scalars['Int']>;
  component_id?: Maybe<Scalars['Int']>;
  property_name?: Maybe<Scalars['String']>;
  property_value?: Maybe<Scalars['String']>;
};

export type UpdateProjectPricingInput = {
  project_pricing_id: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  multiplier?: Maybe<Scalars['Float']>;
  partnumber?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type UsePartNumberInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_id?: Maybe<Scalars['Int']>;
  component_id: Scalars['Int'];
  bill_id: Scalars['Int'];
  group_id?: Maybe<Scalars['Int']>;
  partnumber?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  rev_desc?: Maybe<Scalars['String']>;
  rev_size?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  project_profile_id?: Maybe<Scalars['Int']>;
};

export type GetAlternativeBomInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  price_list_id: Scalars['Int'];
  region_id: Scalars['Int'];
};

export type GetAssemblyEquipmentInput = {
  proj_id: Scalars['Int'];
  assembly_name: Scalars['String'];
};

export type GetHeaderPriceInput = {
  price_list_id: Scalars['Int'];
  pipe_size: Scalars['String'];
  outlet_size?: Maybe<Scalars['String']>;
  outlet_size2?: Maybe<Scalars['String']>;
  outlet_size3?: Maybe<Scalars['String']>;
  outlet_size4?: Maybe<Scalars['String']>;
  outlet_size5?: Maybe<Scalars['String']>;
  outlet_size6?: Maybe<Scalars['String']>;
  pipe_length_ft: Scalars['Int'];
  pipe_length_in: Scalars['Float'];
  outlet_count?: Maybe<Scalars['Int']>;
  tap_count?: Maybe<Scalars['Int']>;
  region_id: Scalars['Int'];
  header_coating_id?: Maybe<Scalars['Int']>;
  header_material_id?: Maybe<Scalars['Int']>;
  header_lining_id?: Maybe<Scalars['Int']>;
};

export type GetPartNumbersOptionsInput = {
  partnumber?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  rev_desc?: Maybe<Scalars['String']>;
  rev_size?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
};

export type GetPredictedPartNumbersInput = {
  size?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  region_id: Scalars['Int'];
  system_id: Scalars['Int'];
};

export type GetSystemRevisionMaterialListInput = {
  proj_id: Scalars['Int'];
  system_id: Scalars['Int'];
  flip_id: Scalars['Int'];
  revision_id: Scalars['Int'];
  price_list_id: Scalars['Int'];
  region_id: Scalars['Int'];
  fromExport?: Maybe<Scalars['Boolean']>;
};

export type AddNewCustomerInput = {
  name: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  market_id?: Maybe<Scalars['Int']>;
};

export type UpdateQuoteDocumentInput = {
  proj_id?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  drawingInfo?: Maybe<Scalars['String']>;
  specInfo?: Maybe<Scalars['String']>;
  areaLimits?: Maybe<Array<Maybe<AreaInput>>>;
  notes?: Maybe<Array<Maybe<QuoteNoteInput>>>;
  user_id?: Maybe<Scalars['Int']>;
  inclusions?: Maybe<Array<Maybe<InclusionInput>>>;
  areaLimitsString?: Maybe<Scalars['String']>;
  pipingSystemsString?: Maybe<Scalars['String']>;
};

export type QuoteNoteInput = {
  master_class?: Maybe<Scalars['String']>;
  sub_class?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AreaInput = {
  areaid?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Int']>;
  equipments?: Maybe<Array<Maybe<EquipmentInput>>>;
  dwg?: Maybe<Scalars['String']>;
  equipments_modified?: Maybe<Scalars['Boolean']>;
};

export type InclusionInput = {
  inclusion_id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
  default_value?: Maybe<Scalars['Int']>;
};

export type EquipmentInput = {
  mark?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  equipment_id?: Maybe<Scalars['Int']>;
};

export type GetProjectSchedulesInput = {
  project_name_or_number?: Maybe<Scalars['String']>;
  coordinator_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  region_id?: Maybe<Scalars['Int']>;
  vdc_project_type_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddNewSequenceInput = {
  proj_id: Scalars['Int'];
  template_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['Int']>;
  sequence_color?: Maybe<Scalars['String']>;
  sequence_notes?: Maybe<Scalars['String']>;
  fab_shop_id?: Maybe<Scalars['Int']>;
  fab_contact_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type AddSequenceDateInput = {
  sequence_id: Scalars['Int'];
  date_type_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type AddTemplateDatesInput = {
  template_id: Scalars['Int'];
  date_type_ids: Array<Maybe<Scalars['Int']>>;
};

export type AssignTruckInput = {
  proj_id: Scalars['Int'];
  sequence_id: Scalars['Int'];
  logistic_ids: Array<Scalars['Int']>;
};

export type CopySequenceInput = {
  sequence_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  fab_shop_id?: Maybe<Scalars['Int']>;
  fab_contact_id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateSequenceTemplateInput = {
  name: Scalars['String'];
  proj_type_id?: Maybe<Scalars['Int']>;
};

export type DeleteSequenceDateInput = {
  sequence_date_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type DeleteSequenceInput = {
  sequence_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  proj_num: Scalars['String'];
};

export type DeleteTemplateDateInput = {
  template_id: Scalars['Int'];
  date_type_id: Scalars['Int'];
};

export type SubmitFabricationRequestInput = {
  sequence_id: Scalars['Int'];
  proj_id: Scalars['Int'];
  proj_num: Scalars['String'];
};

export type SubmitFabricationRequestResponse = {
  __typename?: 'SubmitFabricationRequestResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type UnassignTruckInput = {
  logistics_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type UpdateSequenceBaseInput = {
  description?: Maybe<Scalars['String']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  sequence_id: Scalars['Int'];
  name: Scalars['String'];
  sequence_color?: Maybe<Scalars['String']>;
  sequence_notes?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['Int']>;
  proj_id: Scalars['Int'];
  header_status?: Maybe<Scalars['String']>;
  iso_drop_status?: Maybe<Scalars['String']>;
  broker_id?: Maybe<Scalars['Int']>;
  delivery_address?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['Int']>;
  fab_shop_id?: Maybe<Scalars['Int']>;
  fab_contact_id?: Maybe<Scalars['Int']>;
  non_vic_fab_contact?: Maybe<Scalars['String']>;
  non_vic_fab_shop?: Maybe<Scalars['String']>;
  coating_type_id?: Maybe<Scalars['Int']>;
  fab_comments?: Maybe<Scalars['String']>;
};

export type UpdateSequenceDateInput = {
  sequence_date_id: Scalars['Int'];
  date_value?: Maybe<Scalars['String']>;
  date_note?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  is_confirmed?: Maybe<Scalars['Boolean']>;
};

export type UpdateSequenceLogisticsInput = {
  logistics_id: Scalars['Int'];
  truck_number: Scalars['Int'];
  shipping_date: Scalars['String'];
  log_received_date?: Maybe<Scalars['String']>;
  proj_id: Scalars['Int'];
  has_shipped?: Maybe<Scalars['Boolean']>;
};

export type UpdateSequencesOrderInput = {
  sequence_id: Scalars['Int'];
  order_number: Scalars['Int'];
};

export type UpdateTemplateBaseInput = {
  name: Scalars['String'];
  template_id: Scalars['Int'];
  active?: Maybe<Scalars['Int']>;
};

export type AddVtfrTrainingContactInput = {
  training_id?: Maybe<Scalars['Int']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_company?: Maybe<Scalars['String']>;
};

export type AddVtfrTrainingLogInput = {
  user_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  duration_num?: Maybe<Scalars['Float']>;
  duration_type?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  salesman_id?: Maybe<Scalars['Int']>;
  sales_division_id?: Maybe<Scalars['String']>;
  sales_region_id?: Maybe<Scalars['String']>;
  sales_metro_id?: Maybe<Scalars['String']>;
  sales_group_id?: Maybe<Scalars['Int']>;
  bill_amt?: Maybe<Scalars['Float']>;
  expenses?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  t_market_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  c_type_id?: Maybe<Scalars['Int']>;
  pres_date?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['Int']>;
  quote?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Maybe<AddVtfrTrainingContactInput>>>;
};

export type UpdateVtfrTrainingContactInput = {
  contact_id?: Maybe<Scalars['Int']>;
  training_id?: Maybe<Scalars['Int']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_company?: Maybe<Scalars['String']>;
};

export type UpdateVtfrTrainingLogInput = {
  training_id: Scalars['Int'];
  user_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  duration_num?: Maybe<Scalars['Float']>;
  duration_type?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  salesman_id?: Maybe<Scalars['Int']>;
  sales_division_id?: Maybe<Scalars['String']>;
  sales_region_id?: Maybe<Scalars['String']>;
  sales_metro_id?: Maybe<Scalars['String']>;
  sales_group_id?: Maybe<Scalars['Int']>;
  bill_amt?: Maybe<Scalars['Float']>;
  expenses?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  t_market_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  c_type_id?: Maybe<Scalars['Int']>;
  pres_date?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['Int']>;
  quote?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Maybe<UpdateVtfrTrainingContactInput>>>;
};

export type VtfrTrainingLogSelectOptions = {
  __typename?: 'VtfrTrainingLogSelectOptions';
  presenters?: Maybe<Array<Maybe<User>>>;
  trainingsTypes?: Maybe<Array<Maybe<TrainingType>>>;
  companies?: Maybe<Array<Maybe<Customer>>>;
  salesman?: Maybe<Array<Maybe<Salesman>>>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  trainingMarkets?: Maybe<Array<Maybe<TrainingMarket>>>;
  customerTypes?: Maybe<Array<Maybe<CustomerType>>>;
  markets?: Maybe<Array<Maybe<Market>>>;
};

export type UpdateRecentUserProjectsInput = {
  user_id: Scalars['Int'];
  proj_id: Scalars['Int'];
};

export type GetAllUsersInput = {
  only_active_users?: Maybe<Scalars['Boolean']>;
};

export type CustomerContactFragmentFragment = (
  { __typename?: 'CustomerContact' }
  & Pick<CustomerContact, '_id' | 'cell_phone' | 'company_name' | 'contact_type' | 'email' | 'name' | 'office_phone' | 'title' | 'address'>
);

export type ProjectContactsFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'proj_id'>
  & { customer_contacts?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerContact' }
    & CustomerContactFragmentFragment
  )>>> }
);

export type ProjectBimBaseFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'proj_id' | 'projid' | 'project_name' | 'proj_num' | 'project_distributor_value' | 'active_status'>
  & { notes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNote' }
    & ProjectNoteFragmentFragment
  )>>>, projectBIM?: Maybe<(
    { __typename?: 'ProjectBIM' }
    & Pick<ProjectBim, 'closeout_meeting_date' | 'contractor_turnover_date' | 'customer_care_id' | 'drawing_percent_complete' | 'expected_drawing_completion_date' | 'purchase_order_date'>
    & { customer_care_contact?: Maybe<(
      { __typename?: 'CustomerCareRep' }
      & Pick<CustomerCareRep, 'rep_id' | 'name'>
    )>, drawing_hours_info?: Maybe<(
      { __typename?: 'ProjectDrawingHoursInfo' }
      & Pick<ProjectDrawingHoursInfo, 'efficiency_rating' | 'current_drawing_hours'>
    )>, project_health_status?: Maybe<(
      { __typename?: 'ProjectHealthStatus' }
      & ProjectHealthStatusFragmentFragment
    )> }
  )>, quote_hours_info?: Maybe<(
    { __typename?: 'ProjectQuoteHoursInfo' }
    & Pick<ProjectQuoteHoursInfo, 'quoted_drawing_hours' | 'quoted_drawing_revenue'>
  )> }
);

export type ProjectHealthStatusFragmentFragment = (
  { __typename?: 'ProjectHealthStatus' }
  & Pick<ProjectHealthStatus, 'ph_status_id' | 'ph_status'>
);

export type AddCustomerContactMutationVariables = Exact<{
  input: AddCustomerContactInput;
}>;


export type AddCustomerContactMutation = (
  { __typename?: 'Mutation' }
  & { addCustomerContact?: Maybe<(
    { __typename?: 'Project' }
    & ProjectContactsFragmentFragment
  )> }
);

export type DeleteCustomerContactMutationVariables = Exact<{
  input: DeleteCustomerContactInput;
}>;


export type DeleteCustomerContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomerContact?: Maybe<(
    { __typename?: 'Project' }
    & ProjectContactsFragmentFragment
  )> }
);

export type SetProjectInactiveMutationVariables = Exact<{
  input: SetProjectInactiveInput;
}>;


export type SetProjectInactiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setProjectInactive'>
);

export type UpdateBaseBimMutationVariables = Exact<{
  input: UpdateProjectBimBaseInput;
}>;


export type UpdateBaseBimMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectBimBase: (
    { __typename?: 'Project' }
    & ProjectBimBaseFragmentFragment
  ) }
);

export type UpdateCustomerContactMutationVariables = Exact<{
  input: UpdateCustomerContactInput;
}>;


export type UpdateCustomerContactMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerContact?: Maybe<(
    { __typename?: 'Project' }
    & ProjectContactsFragmentFragment
  )> }
);

export type GetCustomerContactsQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetCustomerContactsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectContactsFragmentFragment
  )> }
);

export type GetBaseBimDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetBaseBimDataQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { customerCareReps?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerCareRep' }
      & Pick<CustomerCareRep, 'rep_id' | 'name' | 'phone' | 'email'>
    )>>> }
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectBimBaseFragmentFragment
  )> }
);

export type GetProjectHealthDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectHealthDataQuery = (
  { __typename?: 'Query' }
  & { project_health_projects?: Maybe<(
    { __typename?: 'ProjectHealthPayload' }
    & { coordinators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'project_count' | 'cps_group' | 'email_address' | 'employeeid' | 'first_name' | 'full_name' | 'group' | 'last_name' | 'region_id' | 'user_id' | 'user_type' | 'resource_color'>
      & { project_counts?: Maybe<(
        { __typename?: 'CoordCountTotals' }
        & Pick<CoordCountTotals, 'hundred_percent' | 'not_complete'>
      )> }
    )>>>, projects?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'project_name' | 'proj_id' | 'proj_num' | 'coordinator_name' | 'coordinator_user_id' | 'coordinator_employee_id' | 'region_name' | 'market_name' | 'customer_name' | 'expected_drawing_completion_date' | 'percent_complete' | 'rvtVersion'>
      & { project_health_status?: Maybe<(
        { __typename?: 'ProjectHealthStatus' }
        & Pick<ProjectHealthStatus, 'ph_status'>
      )>, changeOrders?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectChangeOrder' }
        & Pick<ProjectChangeOrder, 'change_order_id' | 'change_order_status_id'>
      )>>> }
    )>>>, unassigned?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'project_name' | 'proj_num' | 'proj_id' | 'coordinator_name' | 'coordinator_user_id' | 'coordinator_employee_id' | 'region_name' | 'market_name' | 'customer_name' | 'expected_drawing_completion_date' | 'percent_complete'>
      & { project_health_status?: Maybe<(
        { __typename?: 'ProjectHealthStatus' }
        & Pick<ProjectHealthStatus, 'ph_status'>
      )> }
    )>>>, project_statuses?: Maybe<(
      { __typename?: 'ProjectHealthStatuses' }
      & Pick<ProjectHealthStatuses, 'green_count' | 'yellow_count' | 'red_count' | 'project_count' | 'completed_count' | 'not_completed_count'>
    )> }
  )> }
);

export type AdminChangeOrderFragmentFragment = (
  { __typename?: 'ProjectChangeOrder' }
  & Pick<ProjectChangeOrder, 'change_order_id' | 'proj_id' | 'projid' | 'quoteid' | 'areaid' | 'proj_num' | 'project_name' | 'change_order_name' | 'change_order_description' | 'change_order_drawing_num' | 'change_order_status' | 'change_order_status_id' | 'open_duration' | 'finance_revision_name' | 'finance_revision_id' | 'change_order_number' | 'change_order_price' | 'vdcHours' | 'fabHours' | 'materialCost' | 'fabricationCost' | 'vdcCost' | 'transportationCost' | 'additionalCost' | 'changeOrderNotesString' | 'updated_po'>
  & { bimHoursData?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderBimHourData' }
    & Pick<ChangeOrderBimHourData, 'co_bim_hour_id' | 'bim_hour_type_id' | 'hours'>
  )>>>, comparedBoms?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderComparedBom' }
    & Pick<ChangeOrderComparedBom, 'co_compared_bom_id' | 'system_id' | 'pre_revision_id' | 'post_revision_id'>
  )>>>, changeOrderNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNote' }
    & Pick<ProjectNote, 'note_id' | 'note' | 'added_by' | 'user_id' | 'date_added'>
  )>>>, changeOrderSuggestedValues?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderSuggestedValue' }
    & Pick<ChangeOrderSuggestedValue, 'co_suggested_value_id' | 'offeringid' | 'value' | 'change_order_id' | 'finance_revision_id'>
  )>>>, finances?: Maybe<Array<Maybe<(
    { __typename?: 'Finances' }
    & ChangeOrderFinancesFragmentFragment
  )>>>, financesHours?: Maybe<Array<Maybe<(
    { __typename?: 'FinancesHours' }
    & ChangeOrderFinancesHoursFragmentFragment
  )>>> }
);

export type ChangeOrderFinancesFragmentFragment = (
  { __typename?: 'Finances' }
  & Pick<Finances, 'offeringid' | 'offering' | 'count' | 'value_number' | 'value_string' | 'dist_markup' | 'dist_cost' | 'dist_cost_string' | 'cust_markup' | 'cust_cost' | 'cust_cost_string' | 'suggestedDistValue' | 'suggestedCustValue'>
);

export type ChangeOrderFinancesHoursFragmentFragment = (
  { __typename?: 'FinancesHours' }
  & Pick<FinancesHours, 'finance_hours_type_id' | 'finance_hours_type' | 'value'>
);

export type ChangeOrderNoteFragmentFragment = (
  { __typename?: 'ChangeOrderNote' }
  & Pick<ChangeOrderNote, 'note_id' | 'note' | 'user_id' | 'name' | 'date_added'>
);

export type ProjectChangeOrderFragmentFragment = (
  { __typename?: 'ProjectChangeOrder' }
  & Pick<ProjectChangeOrder, 'change_order_id' | 'proj_id' | 'projid' | 'quoteid' | 'areaid' | 'proj_num' | 'project_name' | 'change_order_name' | 'change_order_description' | 'change_order_status' | 'change_order_status_id' | 'change_order_drawing_num' | 'finance_revision_name' | 'finance_revision_id' | 'change_order_number' | 'change_order_price'>
  & { bimHoursData?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderBimHourData' }
    & Pick<ChangeOrderBimHourData, 'co_bim_hour_id' | 'bim_hour_type_id' | 'hours'>
  )>>>, comparedBoms?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderComparedBom' }
    & Pick<ChangeOrderComparedBom, 'co_compared_bom_id' | 'system_id' | 'pre_revision_id' | 'post_revision_id'>
  )>>>, changeOrderNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNote' }
    & Pick<ProjectNote, 'note_id' | 'note' | 'added_by' | 'user_id' | 'date_added'>
  )>>>, changeOrderSuggestedValues?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderSuggestedValue' }
    & Pick<ChangeOrderSuggestedValue, 'co_suggested_value_id' | 'offeringid' | 'value' | 'change_order_id' | 'finance_revision_id'>
  )>>>, finances?: Maybe<Array<Maybe<(
    { __typename?: 'Finances' }
    & ChangeOrderFinancesFragmentFragment
  )>>>, financesHours?: Maybe<Array<Maybe<(
    { __typename?: 'FinancesHours' }
    & ChangeOrderFinancesHoursFragmentFragment
  )>>> }
);

export type ApproveChangeOrderMutationVariables = Exact<{
  input: ApproveChangeOrderInput;
}>;


export type ApproveChangeOrderMutation = (
  { __typename?: 'Mutation' }
  & { approveChangeOrder?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & AdminChangeOrderFragmentFragment
  )>>> }
);

export type DeclineChangeOrderMutationVariables = Exact<{
  input: DeclineChangeOrderInput;
}>;


export type DeclineChangeOrderMutation = (
  { __typename?: 'Mutation' }
  & { declineChangeOrder?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & AdminChangeOrderFragmentFragment
  )>>> }
);

export type SubmitChangeOrderRevisionMutationVariables = Exact<{
  input: SubmitChangeOrderRevisionInput;
}>;


export type SubmitChangeOrderRevisionMutation = (
  { __typename?: 'Mutation' }
  & { submitChangeOrderRevision?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & ProjectChangeOrderFragmentFragment
  )>>> }
);

export type SubmitChangeOrderMutationVariables = Exact<{
  input: SubmitChangeOrderInput;
}>;


export type SubmitChangeOrderMutation = (
  { __typename?: 'Mutation' }
  & { submitChangeOrder?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & ProjectChangeOrderFragmentFragment
  )>>> }
);

export type UpdateChangeOrderBasicInfoMutationVariables = Exact<{
  input: UpdateChangeOrderBasicInfoInput;
}>;


export type UpdateChangeOrderBasicInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChangeOrderBasicInfo'>
);

export type GetAllChangeOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllChangeOrdersQuery = (
  { __typename?: 'Query' }
  & { getAllChangeOrders?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & AdminChangeOrderFragmentFragment
  )>>> }
);

export type GetChangeOrderNotesQueryVariables = Exact<{
  change_order_id: Scalars['Int'];
  finance_revision_id: Scalars['Int'];
}>;


export type GetChangeOrderNotesQuery = (
  { __typename?: 'Query' }
  & { getChangeOrderNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeOrderNote' }
    & ChangeOrderNoteFragmentFragment
  )>>> }
);

export type GetChangeOrderPreviewQueryVariables = Exact<{
  input: ChangeOrderPreviewInput;
}>;


export type GetChangeOrderPreviewQuery = (
  { __typename?: 'Query' }
  & { getChangeOrderPreview?: Maybe<(
    { __typename?: 'FinancesData' }
    & Pick<FinancesData, 'missingPartNumbers'>
    & { finances?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & ChangeOrderFinancesFragmentFragment
    )>>>, financesHours?: Maybe<Array<Maybe<(
      { __typename?: 'FinancesHours' }
      & ChangeOrderFinancesHoursFragmentFragment
    )>>> }
  )> }
);

export type GetChangeOrdersSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChangeOrdersSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { bimHours?: Maybe<Array<Maybe<(
      { __typename?: 'BimHourType' }
      & Pick<BimHourType, 'bim_hour_type_id' | 'bim_hour_type' | 'positive'>
    )>>>, changeOrderAdditionalOfferings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & Pick<ProjectOffering, 'offeringid' | 'offering'>
    )>>> }
  )> }
);

export type GetProjectChangeOrdersQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectChangeOrdersQuery = (
  { __typename?: 'Query' }
  & { getProjectChangeOrders?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & ProjectChangeOrderFragmentFragment
  )>>> }
);

export type AdminComponentFragmentFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'component_id' | 'partnumber_us' | 'size' | 'concat_component_desc' | 'partnumber_global' | 'nominal_imp' | 'component_desc_m3' | 'component_desc' | 'style_desc' | 'custom_item' | 'image_path' | 'image_id' | 'style' | 'labor_mcaa' | 'weight' | 'size_code' | 'gasket_type' | 'group_id' | 'price_usd' | 'price_eur' | 'price_gbp' | 'price_jpy'>
);

export type ComponentAlternativeFragmentFragment = (
  { __typename?: 'ComponentAlternative' }
  & Pick<ComponentAlternative, 'title' | 'rowNum'>
  & { subParts?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentSubstitute' }
    & Pick<ComponentSubstitute, 'part_number' | 'description' | 'quantity'>
  )>>> }
);

export type ComponentSizeFragmentFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'size' | 'size_desc_m3' | 'size_code'>
);

export type AddComponentAlternativeMutationVariables = Exact<{
  input: AddAlternativeInput;
}>;


export type AddComponentAlternativeMutation = (
  { __typename?: 'Mutation' }
  & { addComponentAlternative?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentAlternative' }
    & ComponentAlternativeFragmentFragment
  )>>> }
);

export type RemoveComponentAlternativeMutationVariables = Exact<{
  input: RemoveAlternativeInput;
}>;


export type RemoveComponentAlternativeMutation = (
  { __typename?: 'Mutation' }
  & { removeComponentAlternative?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentAlternative' }
    & ComponentAlternativeFragmentFragment
  )>>> }
);

export type UpdateComponentSubMutationVariables = Exact<{
  input: UpdateComponentSubInput;
}>;


export type UpdateComponentSubMutation = (
  { __typename?: 'Mutation' }
  & { updateComponentSub?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentAlternative' }
    & ComponentAlternativeFragmentFragment
  )>>> }
);

export type AddComponentSizeMutationVariables = Exact<{
  input: AddComponentSizeInput;
}>;


export type AddComponentSizeMutation = (
  { __typename?: 'Mutation' }
  & { addComponentSize?: Maybe<(
    { __typename?: 'Component' }
    & ComponentSizeFragmentFragment
  )> }
);

export type AddCustomItemMutationVariables = Exact<{
  input: AddCustomItemInput;
}>;


export type AddCustomItemMutation = (
  { __typename?: 'Mutation' }
  & { addCustomItem?: Maybe<(
    { __typename?: 'Component' }
    & Pick<Component, 'active' | 'part_size'>
    & AdminComponentFragmentFragment
  )> }
);

export type DeleteComponentImageMutationVariables = Exact<{
  input: DeleteComponentImageInput;
}>;


export type DeleteComponentImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteComponentImage?: Maybe<(
    { __typename?: 'Component' }
    & AdminComponentFragmentFragment
  )> }
);

export type UpdateComponentSizeMutationVariables = Exact<{
  input: UpdateComponentSizeInput;
}>;


export type UpdateComponentSizeMutation = (
  { __typename?: 'Mutation' }
  & { updateComponentSize?: Maybe<(
    { __typename?: 'Component' }
    & ComponentSizeFragmentFragment
  )> }
);

export type UpdateComponentMutationVariables = Exact<{
  input: UpdateComponentInput;
}>;


export type UpdateComponentMutation = (
  { __typename?: 'Mutation' }
  & { updateComponent?: Maybe<(
    { __typename?: 'Component' }
    & Pick<Component, 'active' | 'part_size'>
    & AdminComponentFragmentFragment
  )> }
);

export type GetComponentsSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetComponentsSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { getComponentsSelectOptions?: Maybe<(
    { __typename?: 'ComponentsSelectOptions' }
    & { partSizes?: Maybe<Array<Maybe<(
      { __typename?: 'PartSize' }
      & Pick<PartSize, 'size_code' | 'part_size'>
    )>>>, gasketTypes?: Maybe<Array<Maybe<(
      { __typename?: 'GasketType' }
      & Pick<GasketType, 'gasket_id' | 'gasket_type' | 'gasket_value'>
    )>>>, componentCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ComponentCategory' }
      & Pick<ComponentCategory, 'group_id' | 'group_desc'>
    )>>>, currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'currency'>
    )>>>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'region_id' | 'region'>
    )>>> }
  )> }
);

export type GetFilteredComponentsQueryVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type GetFilteredComponentsQuery = (
  { __typename?: 'Query' }
  & { getFilteredComponents?: Maybe<Array<Maybe<(
    { __typename?: 'Component' }
    & Pick<Component, 'active' | 'part_size'>
    & AdminComponentFragmentFragment
  )>>> }
);

export type GetComponentsQueryVariables = Exact<{ 
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type GetComponentsQuery = (
  { __typename?: 'Query' }
  & { getAdminComponents?: Maybe<Array<Maybe<(
    { __typename?: 'Component' }
    & Pick<Component, 'active' | 'part_size'>
    & AdminComponentFragmentFragment
  )>>> }
);

export type GetComponentSubstitutesQueryVariables = Exact<{
  user_id: Scalars['Int'];
  component_id: Scalars['Int'];
}>;


export type GetComponentSubstitutesQuery = (
  { __typename?: 'Query' }
  & { getComponentSubstitutes?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentAlternative' }
    & ComponentAlternativeFragmentFragment
  )>>> }
);

export type ProfilePartNumbersFragmentFragment = (
  { __typename?: 'ProfilePartNumbers' }
  & Pick<ProfilePartNumbers, 'component_id' | 'link_id' | 'rev_size' | 'rev_desc' | 'partnumber_us' | 'partnumber_global' | 'linked_size' | 'linked_desc' | 'name' | 'linked_date'>
);

export type CopyPartNumberLinkMutationVariables = Exact<{
  input: Array<Maybe<CopyPartNumberLinkInput>>;
}>;


export type CopyPartNumberLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyPartNumberLink'>
);

export type DeletePartNumberLinkMutationVariables = Exact<{
  input: Array<Maybe<DeletePartNumberLinkInput>>;
}>;


export type DeletePartNumberLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePartNumberLink'>
);

export type AddPartNumberProfileMutationVariables = Exact<{
  input: AddPartNumberProfileInput;
}>;


export type AddPartNumberProfileMutation = (
  { __typename?: 'Mutation' }
  & { addPartNumberProfile?: Maybe<(
    { __typename?: 'PartNumberProfiles' }
    & PartNumberProfileFragmentFragment
  )> }
);

export type UpdatePartNumberProfileMutationVariables = Exact<{
  input: UpdatePartNumberProfileInput;
}>;


export type UpdatePartNumberProfileMutation = (
  { __typename?: 'Mutation' }
  & { updatePartNumberProfile?: Maybe<(
    { __typename?: 'PartNumberProfiles' }
    & PartNumberProfileFragmentFragment
  )> }
);

export type GetPartNumberProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPartNumberProfilesQuery = (
  { __typename?: 'Query' }
  & { getPartNumberProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'PartNumberProfiles' }
    & PartNumberProfileFragmentFragment
  )>>> }
);

export type GetProfilePartNumbersQueryVariables = Exact<{
  profile_id: Scalars['Int'];
}>;


export type GetProfilePartNumbersQuery = (
  { __typename?: 'Query' }
  & { getProfilePartNumbers?: Maybe<Array<Maybe<(
    { __typename?: 'ProfilePartNumbers' }
    & ProfilePartNumbersFragmentFragment
  )>>> }
);

export type CurrencyConversionRateFragmentFragment = (
  { __typename?: 'CurrencyConversionRate' }
  & Pick<CurrencyConversionRate, 'rate_id' | 'date' | 'currency' | 'rate'>
);

export type GetCurrencyConversionRatesQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type GetCurrencyConversionRatesQuery = (
  { __typename?: 'Query' }
  & { getCurrencyConversionRates?: Maybe<Array<Maybe<(
    { __typename?: 'CurrencyConversionRate' }
    & CurrencyConversionRateFragmentFragment
  )>>> }
);

export type ProjectProfilePartNumbersFragmentFragment = (
  { __typename?: 'ProfilePartNumbers' }
  & Pick<ProfilePartNumbers, 'component_id' | 'link_id' | 'rev_size' | 'rev_desc' | 'partnumber_us' | 'partnumber_global' | 'linked_size' | 'linked_desc' | 'name' | 'linked_date'>
);

export type ProjectProfileFragmentFragment = (
  { __typename?: 'ProjectProfiles' }
  & Pick<ProjectProfiles, 'project_profile_id' | 'project_profile' | 'project_profile_desc' | 'user_id' | 'user' | 'proj_id' | 'project_desc'>
);

export type CopyProjectPartNumberLinkMutationVariables = Exact<{
  input: Array<Maybe<CopyProjectPartNumberLinkInput>>;
}>;


export type CopyProjectPartNumberLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyProjectPartNumberLink'>
);

export type DeleteProjectPartNumberLinkMutationVariables = Exact<{
  input: Array<Maybe<DeleteProjectPartNumberLinkInput>>;
}>;


export type DeleteProjectPartNumberLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectPartNumberLink'>
);

export type AddProjectProfileMutationVariables = Exact<{
  input: AddProjectProfileInput;
}>;


export type AddProjectProfileMutation = (
  { __typename?: 'Mutation' }
  & { addProjectProfile?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectProfiles' }
    & ProjectProfileFragmentFragment
  )>>> }
);

export type UpdateProjectProfileMutationVariables = Exact<{
  input: UpdateProjectProfileInput;
}>;


export type UpdateProjectProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectProfile?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectProfiles' }
    & ProjectProfileFragmentFragment
  )>>> }
);

export type GetProjectProfilePartNumbersQueryVariables = Exact<{
  project_profile_id: Scalars['Int'];
}>;


export type GetProjectProfilePartNumbersQuery = (
  { __typename?: 'Query' }
  & { getProjectProfilePartNumbers?: Maybe<Array<Maybe<(
    { __typename?: 'ProfilePartNumbers' }
    & ProjectProfilePartNumbersFragmentFragment
  )>>> }
);

export type GetProjectProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectProfilesQuery = (
  { __typename?: 'Query' }
  & { getProjectProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectProfiles' }
    & ProjectProfileFragmentFragment
  )>>> }
);

export type SoldAdminProjetFragmentFragment = (
  { __typename?: 'SoldAdminProject' }
  & Pick<SoldAdminProject, 'index' | 'proj_id' | 'project_number' | 'project_name' | 'price_list_name' | 'coordinator' | 'sequence_count' | 'sequence_errors' | 'sequence_missing_dates' | 'line_sales' | 'regional_sales' | 'divisional_sales' | 'contractor_name' | 'market' | 'distributor_po' | 'buyer_acceptance_date' | 'multiplier_added' | 'finance_complete' | 'sold_date' | 'expected_completion_date' | 'onsite_date' | 'close_meeting_date' | 'ph_status' | 'total_sold_material' | 'current_material' | 'material_difference' | 'percentage_material_difference' | 'quoted_drawing_hours' | 'bim_hours_used' | 'bim_hours_percentage' | 'drawing_percentage_complete' | 'estimated_bim_hours' | 'efficiency' | 'truck_count' | 'transportation' | 'fab_hours_quoted' | 'fab_hours_actual' | 'difference_fab_hours' | 'koil_kit_count' | 'koil_kit_value' | 'buffer_dollars' | 'material_buffer' | 'percentage_buffer_used' | 'total_project_quoted' | 'total_sold_project' | 'total_project_actual' | 'percentage_total_project_difference' | 'total_open_change_orders' | 'total_internal_change_orders' | 'finance_notes' | 'signed_cos' | 'received_revised_pos' | 'percent_billed' | 'number_of_billings' | 'open' | 'number_of_areas'>
);

export type GetAdminProjectsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetAdminProjectsQuery = (
  { __typename?: 'Query' }
  & { getAdminProjects?: Maybe<Array<Maybe<(
    { __typename?: 'SoldAdminProject' }
    & SoldAdminProjetFragmentFragment
  )>>> }
);

export type AdminInclusionFragmentFragment = (
  { __typename?: 'Inclusion' }
  & Pick<Inclusion, 'inclusion_id' | 'row_id' | 'master_class' | 'sub_class' | 'description' | 'default_value' | 'order' | 'active' | 'pha_fab' | 'pha_craft' | 'drawing' | 'municipal_fab' | 'municipal_craft' | 'oil_gas_fab' | 'oil_gas_craft' | 'other_visible_when_selected' | 'oil_gas_visible_when_selected'>
);

export type GetQuoteDocumentInclusionsQueryVariables = Exact<{
  language?: Maybe<Scalars['String']>;
}>;


export type GetQuoteDocumentInclusionsQuery = (
  { __typename?: 'Query' }
  & { getQuoteDocumentInclusions?: Maybe<Array<Maybe<(
    { __typename?: 'Inclusion' }
    & AdminInclusionFragmentFragment
  )>>> }
);

export type AdminSalesmanFragmentFragment = (
  { __typename?: 'Salesman' }
  & Pick<Salesman, 'name' | 'email' | 'salesmanID' | 'salesregion_name' | 'salesregion_id' | 'group_name' | 'group_id' | 'metro_id' | 'metro' | 'reports_to' | 'reports_to_name' | 'division' | 'divisionID' | 'inactive'>
);

export type SalesmanMarkupFragmentFragment = (
  { __typename?: 'SalesmanMarkup' }
  & Pick<SalesmanMarkup, 'salesman_markup_id' | 'salesmanID' | 'offeringid' | 'offering' | 'dist_markup' | 'cust_markup'>
);

export type SalesmanMultiplierFragmentFragment = (
  { __typename?: 'SalesmanMultiplier' }
  & Pick<SalesmanMultiplier, 'salesman_multiplier_id' | 'salesmanID' | 'group_id' | 'group_desc' | 'value'>
);

export type UpdateSalesmanMarkupsMutationVariables = Exact<{
  input: Array<Maybe<UpdateSalesmanMarkupsInput>>;
}>;


export type UpdateSalesmanMarkupsMutation = (
  { __typename?: 'Mutation' }
  & { updateSalesmanMarkups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesmanMarkup' }
    & SalesmanMarkupFragmentFragment
  )>>> }
);

export type UpdateSalesmanMultipliersMutationVariables = Exact<{
  input: UpdateSalesmanMultipliersInput;
}>;


export type UpdateSalesmanMultipliersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSalesmanMultipliers'>
);

export type UpdateSalesmanMutationVariables = Exact<{
  input: UpdateSalesmanInput;
}>;


export type UpdateSalesmanMutation = (
  { __typename?: 'Mutation' }
  & { updateSalesman?: Maybe<(
    { __typename?: 'Salesman' }
    & AdminSalesmanFragmentFragment
  )> }
);

export type GetAdminSalesmanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminSalesmanQuery = (
  { __typename?: 'Query' }
  & { getAdminSalesman?: Maybe<Array<Maybe<(
    { __typename?: 'Salesman' }
    & AdminSalesmanFragmentFragment
  )>>> }
);

export type GetSalesmanMarkupsQueryVariables = Exact<{
  salesmanID: Scalars['String'];
}>;


export type GetSalesmanMarkupsQuery = (
  { __typename?: 'Query' }
  & { getSalesmanMarkups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesmanMarkup' }
    & SalesmanMarkupFragmentFragment
  )>>> }
);

export type GetSalesmanMultipliersQueryVariables = Exact<{
  salesmanID: Scalars['String'];
}>;


export type GetSalesmanMultipliersQuery = (
  { __typename?: 'Query' }
  & { getSalesmanMultipliers?: Maybe<Array<Maybe<(
    { __typename?: 'SalesmanMultiplier' }
    & SalesmanMultiplierFragmentFragment
  )>>> }
);

export type GetSalesmanSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesmanSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'VdcGroup' }
      & Pick<VdcGroup, 'group_id' | 'group_name'>
    )>>>, metros?: Maybe<Array<Maybe<(
      { __typename?: 'MetropolitanArea' }
      & Pick<MetropolitanArea, 'metro_id' | 'metro'>
    )>>>, salesRegions?: Maybe<Array<Maybe<(
      { __typename?: 'SalesRegion' }
      & Pick<SalesRegion, 'salesregion_id' | 'salesregion_name'>
    )>>> }
  )> }
);

export type ComponentFragmentFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'component_id' | 'partnumber_us' | 'partnumber_m3' | 'size' | 'concat_component_desc' | 'partnumber_global' | 'nominal_imp' | 'component_desc_m3' | 'component_desc' | 'style_desc' | 'custom_item' | 'image_path' | 'image_id' | 'style' | 'labor_mcaa' | 'weight' | 'size_code' | 'gasket_type' | 'group_id' | 'price_usd' | 'price_eur' | 'price_gbp' | 'price_jpy' | 'part_size' | 'active' | 'component_cost' | 'description' | 'bill_id' | 'size_desc_m3'>
);

export type GroupMultipliersFragmentFragment = (
  { __typename?: 'ComponentGroup' }
  & Pick<ComponentGroup, 'group_id' | 'group_char' | 'group_desc' | 'sort_id' | 'value'>
);

export type HeaderFragmentFragment = (
  { __typename?: 'Header' }
  & Pick<Header, 'header_id' | 'proj_id' | 'system_id' | 'price_list_id' | 'price_list_name' | 'header_name' | 'assembly_name' | 'bag_tag' | 'system_abbr' | 'mark' | 'area' | 'sequence' | 'zone' | 'pipe_size' | 'outlet_size' | 'outlet_size2' | 'outlet_size3' | 'outlet_size4' | 'outlet_size5' | 'outlet_size6' | 'three_quarter_tap' | 'one_tap' | 'one_quarter_tap' | 'one_half_tap' | 'two_tap' | 'pipe_length' | 'pipe_length_ft' | 'pipe_length_in' | 'outlet_count' | 'tap_count' | 'price' | 'header_cost' | 'header_cost_ep' | 'current_location' | 'header_coating_id' | 'header_coating' | 'header_lining_id' | 'header_lining' | 'header_material_id' | 'header_material'>
);

export type PartNumberProfileFragmentFragment = (
  { __typename?: 'PartNumberProfiles' }
  & Pick<PartNumberProfiles, 'profile_id' | 'profile' | 'profile_desc' | 'user_id' | 'user' | 'region_id'>
);

export type ProjectBillItemFragmentFragment = (
  { __typename?: 'ProjectBillItem' }
  & Pick<ProjectBillItem, 'bill_id' | 'component_id' | 'proj_id' | 'system_id' | 'revision_id' | 'vh_id' | 'seq_id' | 'tap_count' | 'quantity' | 'multiplier' | 'rev_desc' | 'rev_size' | 'pipe_length' | 'system_abbr' | 'assembly_name' | 'mark' | 'end_prep' | 'vic_system_pt' | 'vic_area_pt' | 'vic_zone' | 'vic_field_material' | 'vic_field_cut' | 'vic_note' | 'vic_bag_tag' | 'mark_up' | 'vic_seq' | 'part_number' | 'weight' | 'labor_mcaa' | 'style_desc' | 'component_desc' | 'size_desc' | 'size' | 'item_price' | 'net_price' | 'extended_net_price' | 'image_path'>
);

export type ProjectPricingFragmentFragment = (
  { __typename?: 'ProjectPricing' }
  & Pick<ProjectPricing, 'price' | 'size' | 'multiplier' | 'component_id' | 'project_pricing_id' | 'description' | 'partnumber_us' | 'partnumber_global' | 'partnumber'>
);

export type RevisionFragmentFragment = (
  { __typename?: 'Revision' }
  & Pick<Revision, 'revision_id' | 'system_id' | 'revision_date' | 'revision_name' | 'user_id' | 'forissue' | 'latest_sold' | 'actual_sold'>
);

export type SystemsFragmentFragment = (
  { __typename?: 'System' }
  & Pick<System, 'system_id' | 'system' | 'system_desc' | 'market_id' | 'proj_id' | 'revision_id' | 'dwg' | 'area' | 'profile_id' | 'project_profile_id' | 'quoteid' | 'quotename' | 'gasket_id'>
);

export type SystemQuoteFragmentFragment = (
  { __typename?: 'SystemQuote' }
  & Pick<SystemQuote, 'quoteid' | 'cost' | 'hours' | 'quotedate' | 'quotename' | 'projname' | 'projno'>
);

export type AddNewHeaderMutationVariables = Exact<{
  input: AddNewHeaderInput;
}>;


export type AddNewHeaderMutation = (
  { __typename?: 'Mutation' }
  & { addNewHeader?: Maybe<Array<Maybe<(
    { __typename?: 'Header' }
    & HeaderFragmentFragment
  )>>> }
);

export type AddNewMaterialItemMutationVariables = Exact<{
  input: UpdateMaterialInput;
}>;


export type AddNewMaterialItemMutation = (
  { __typename?: 'Mutation' }
  & { addNewMaterialItem?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type AddNewProjectPricingMutationVariables = Exact<{
  input: AddNewProjectPricingInput;
}>;


export type AddNewProjectPricingMutation = (
  { __typename?: 'Mutation' }
  & { addNewProjectPricing?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectPricing' }
    & ProjectPricingFragmentFragment
  )>>> }
);

export type ApplyProfileMutationVariables = Exact<{
  input: ApplyProfileInput;
}>;


export type ApplyProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyProfile'>
);

export type ApplyProjectPricingMutationVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type ApplyProjectPricingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyProjectPricing'>
);

export type ApplyProjectProfileMutationVariables = Exact<{
  input: ApplyProjectProfileInput;
}>;


export type ApplyProjectProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'applyProjectProfile'>
);

export type CreateProjectAncillariesMutationVariables = Exact<{
  input: CreateProjectAncillariesInput;
}>;


export type CreateProjectAncillariesMutation = (
  { __typename?: 'Mutation' }
  & { createProjectAncillaries?: Maybe<(
    { __typename?: 'ProjectAncillaries' }
    & { newRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>>, newSystems?: Maybe<Array<Maybe<(
      { __typename?: 'System' }
      & SystemsFragmentFragment
    )>>> }
  )> }
);

export type DeleteHeadersMutationVariables = Exact<{
  input: DeleteHeadersInput;
}>;


export type DeleteHeadersMutation = (
  { __typename?: 'Mutation' }
  & { deleteHeaders?: Maybe<Array<Maybe<(
    { __typename?: 'Header' }
    & HeaderFragmentFragment
  )>>> }
);

export type DeleteMaterialItemMutationVariables = Exact<{
  input: DeleteMaterialInput;
}>;


export type DeleteMaterialItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteMaterialItem?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type FlipComponentMutationVariables = Exact<{
  input: FlipComponentInput;
}>;


export type FlipComponentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'flipComponent'>
);

export type FlipSystemMutationVariables = Exact<{
  input: FlipSystemInput;
}>;


export type FlipSystemMutation = (
  { __typename?: 'Mutation' }
  & { flipSystem?: Maybe<(
    { __typename?: 'FlipSystemResult' }
    & Pick<FlipSystemResult, 'position' | 'totalItems'>
    & { alternatives?: Maybe<Array<Maybe<(
      { __typename?: 'ComponentAlternative' }
      & Pick<ComponentAlternative, 'title' | 'rowNum'>
      & { subParts?: Maybe<Array<Maybe<(
        { __typename?: 'ComponentSubstitute' }
        & Pick<ComponentSubstitute, 'part_number' | 'description' | 'quantity'>
      )>>> }
    )>>>, componentData?: Maybe<(
      { __typename?: 'Component' }
      & ComponentFragmentFragment
    )>, flippedProjectBillItems?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectBillItem' }
      & ProjectBillItemFragmentFragment
    )>>> }
  )> }
);

export type GroupSystemsMutationVariables = Exact<{
  input: GroupSystemsInput;
}>;


export type GroupSystemsMutation = (
  { __typename?: 'Mutation' }
  & { groupSystems?: Maybe<(
    { __typename?: 'GroupedSystems' }
    & { systems?: Maybe<Array<Maybe<(
      { __typename?: 'System' }
      & SystemsFragmentFragment
    )>>>, revisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type MapSystemsToQuotesMutationVariables = Exact<{
  input: Array<Maybe<MapSystemsToQuotesInput>>;
}>;


export type MapSystemsToQuotesMutation = (
  { __typename?: 'Mutation' }
  & { mapSystemsToQuotes?: Maybe<Array<Maybe<(
    { __typename?: 'System' }
    & SystemsFragmentFragment
  )>>> }
);

export type MoveHeadersMutationVariables = Exact<{
  input: MoveHeadersInput;
}>;


export type MoveHeadersMutation = (
  { __typename?: 'Mutation' }
  & { moveHeaders?: Maybe<Array<Maybe<(
    { __typename?: 'Header' }
    & HeaderFragmentFragment
  )>>> }
);

export type AddNewRevisionMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type AddNewRevisionMutation = (
  { __typename?: 'Mutation' }
  & { addNewRevision?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type DeleteRevisionMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type DeleteRevisionMutation = (
  { __typename?: 'Mutation' }
  & { deleteRevision?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type MarkRevisionSoldMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type MarkRevisionSoldMutation = (
  { __typename?: 'Mutation' }
  & { markRevisionSold?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type RestoreRevisionMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type RestoreRevisionMutation = (
  { __typename?: 'Mutation' }
  & { restoreRevision?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type RevisionMultipleSystemsMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type RevisionMultipleSystemsMutation = (
  { __typename?: 'Mutation' }
  & { revisionMultipleSystems?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type UpdateRevisionMutationVariables = Exact<{
  input: MutateRevisionInput;
}>;


export type UpdateRevisionMutation = (
  { __typename?: 'Mutation' }
  & { updateRevision?: Maybe<Array<Maybe<(
    { __typename?: 'Revision' }
    & RevisionFragmentFragment
  )>>> }
);

export type AddNewSystemMutationVariables = Exact<{
  input: MutateSystemInput;
}>;


export type AddNewSystemMutation = (
  { __typename?: 'Mutation' }
  & { addNewSystem?: Maybe<Array<Maybe<(
    { __typename?: 'System' }
    & SystemsFragmentFragment
  )>>> }
);

export type CopySystemMutationVariables = Exact<{
  input: MutateSystemInput;
}>;


export type CopySystemMutation = (
  { __typename?: 'Mutation' }
  & { copySystem?: Maybe<Array<Maybe<(
    { __typename?: 'System' }
    & SystemsFragmentFragment
  )>>> }
);

export type DeleteSystemMutationVariables = Exact<{
  input: MutateSystemInput;
}>;


export type DeleteSystemMutation = (
  { __typename?: 'Mutation' }
  & { deleteSystem?: Maybe<Array<Maybe<(
    { __typename?: 'System' }
    & SystemsFragmentFragment
  )>>> }
);

export type UpdateSystemMutationVariables = Exact<{
  input: MutateSystemInput;
}>;


export type UpdateSystemMutation = (
  { __typename?: 'Mutation' }
  & { updateSystem?: Maybe<Array<Maybe<(
    { __typename?: 'System' }
    & SystemsFragmentFragment
  )>>> }
);

export type RemovePartNumberMutationVariables = Exact<{
  input: RemovePartNumberInput;
}>;


export type RemovePartNumberMutation = (
  { __typename?: 'Mutation' }
  & { removePartNumber?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type RemoveProjectPricingMutationVariables = Exact<{
  project_pricing_id: Scalars['Int'];
}>;


export type RemoveProjectPricingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProjectPricing'>
);

export type SetGroupMultipliersMutationVariables = Exact<{
  input: SetGroupMultipliersInput;
}>;


export type SetGroupMultipliersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setGroupMultipliers'>
);

export type SplitSystemsMutationVariables = Exact<{
  input: SplitSystemsInput;
}>;


export type SplitSystemsMutation = (
  { __typename?: 'Mutation' }
  & { splitSystems?: Maybe<(
    { __typename?: 'SplitSystem' }
    & { systems?: Maybe<Array<Maybe<(
      { __typename?: 'System' }
      & SystemsFragmentFragment
    )>>>, revisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type UpdateHeaderMutationVariables = Exact<{
  input: AddNewHeaderInput;
}>;


export type UpdateHeaderMutation = (
  { __typename?: 'Mutation' }
  & { updateHeader?: Maybe<Array<Maybe<(
    { __typename?: 'Header' }
    & HeaderFragmentFragment
  )>>> }
);

export type UpdateMaterialMutationVariables = Exact<{
  input: UpdateMaterialInput;
}>;


export type UpdateMaterialMutation = (
  { __typename?: 'Mutation' }
  & { updateMaterial?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type UpdateProjectPricingMutationVariables = Exact<{
  input: UpdateProjectPricingInput;
}>;


export type UpdateProjectPricingMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectPricing?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectPricing' }
    & ProjectPricingFragmentFragment
  )>>> }
);

export type AddComponentToProfileMutationVariables = Exact<{
  input: UsePartNumberInput;
}>;


export type AddComponentToProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addComponentToProfile'>
);

export type AddComponentToProjectProfileMutationVariables = Exact<{
  input: UsePartNumberInput;
}>;


export type AddComponentToProjectProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addComponentToProjectProfile'>
);

export type UsePartNumberAlwaysMutationVariables = Exact<{
  input: UsePartNumberInput;
}>;


export type UsePartNumberAlwaysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'usePartNumberAlways'>
);

export type UsePartNumberOnceMutationVariables = Exact<{
  input: UsePartNumberInput;
}>;


export type UsePartNumberOnceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'usePartNumberOnce'>
);

export type GetAlternativeBomQueryVariables = Exact<{
  input: GetAlternativeBomInput;
}>;


export type GetAlternativeBomQuery = (
  { __typename?: 'Query' }
  & { getAlternativeBom?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type GetAssemblyEquipmentQueryVariables = Exact<{
  input: GetAssemblyEquipmentInput;
}>;


export type GetAssemblyEquipmentQuery = (
  { __typename?: 'Query' }
  & { getAssemblyEquipment?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>>> }
);

export type GetGroupMultipliersQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetGroupMultipliersQuery = (
  { __typename?: 'Query' }
  & { getGroupMultipliers?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentGroup' }
    & GroupMultipliersFragmentFragment
  )>>> }
);

export type GetHeaderPriceQueryVariables = Exact<{
  input: GetHeaderPriceInput;
}>;


export type GetHeaderPriceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHeaderPrice'>
);

export type GetHeadersSelectOptionsQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetHeadersSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { getHeadersSelectOptions?: Maybe<(
    { __typename?: 'HeadersSelectOptions' }
    & { price_list?: Maybe<Array<Maybe<(
      { __typename?: 'HeaderPriceList' }
      & Pick<HeaderPriceList, 'option_id' | 'price_list_name'>
    )>>>, areas?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectBillItem' }
      & Pick<ProjectBillItem, 'vic_area_pt'>
    )>>>, zones?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectBillItem' }
      & Pick<ProjectBillItem, 'vic_zone'>
    )>>>, sequences?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectBillItem' }
      & Pick<ProjectBillItem, 'vic_seq'>
    )>>>, mainPipeSizes?: Maybe<Array<Maybe<(
      { __typename?: 'HeaderSizes' }
      & Pick<HeaderSizes, 'nominal_size' | 'headersize_id' | 'outlet_size'>
    )>>>, headerCoatings?: Maybe<Array<Maybe<(
      { __typename?: 'HeaderCoating' }
      & Pick<HeaderCoating, 'header_coating_id' | 'header_coating'>
    )>>>, headerLinings?: Maybe<Array<Maybe<(
      { __typename?: 'HeaderLining' }
      & Pick<HeaderLining, 'header_lining_id' | 'header_lining'>
    )>>>, headerMaterials?: Maybe<Array<Maybe<(
      { __typename?: 'HeaderMaterial' }
      & Pick<HeaderMaterial, 'header_material_id' | 'header_material'>
    )>>> }
  )> }
);

export type GetOutletSizesQueryVariables = Exact<{
  region_id: Scalars['Int'];
  nominal_size: Scalars['String'];
}>;


export type GetOutletSizesQuery = (
  { __typename?: 'Query' }
  & { getOutletSizes?: Maybe<Array<Maybe<(
    { __typename?: 'HeaderSizes' }
    & Pick<HeaderSizes, 'outlet_size'>
  )>>> }
);

export type GetPartNumbersOptionsQueryVariables = Exact<{
  input: GetPartNumbersOptionsInput;
}>;


export type GetPartNumbersOptionsQuery = (
  { __typename?: 'Query' }
  & { getPartNumbersOptions?: Maybe<Array<Maybe<(
    { __typename?: 'Component' }
    & Pick<Component, 'partnumber_us' | 'partnumber_global' | 'partnumber_m3' | 'size_desc_imp' | 'size_desc_m3' | 'description' | 'component_id' | 'group_id'>
  )>>> }
);

export type GetPredictedPartNumbersQueryVariables = Exact<{
  input: GetPredictedPartNumbersInput;
}>;


export type GetPredictedPartNumbersQuery = (
  { __typename?: 'Query' }
  & { getPredictedPartNumbers?: Maybe<(
    { __typename?: 'PredictedPartNumbers' }
    & Pick<PredictedPartNumbers, 'user_type' | 'pn_profile'>
    & { components?: Maybe<Array<Maybe<(
      { __typename?: 'Component' }
      & Pick<Component, 'partnumber_us' | 'partnumber_global' | 'partnumber_m3' | 'size_desc_imp' | 'size_desc_m3' | 'description' | 'component_id' | 'group_id'>
    )>>> }
  )> }
);

export type GetProjectHeadersQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectHeadersQuery = (
  { __typename?: 'Query' }
  & { getProjectHeaders: Array<Maybe<(
    { __typename?: 'Header' }
    & HeaderFragmentFragment
  )>> }
);

export type GetProjectMaterialDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectMaterialDataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'proj_id' | 'projid' | 'project_name' | 'proj_num' | 'price_list_id' | 'region_id' | 'currency_symbol' | 'weight_unit' | 'currency' | 'proj_status' | 'user_id'>
    & { notes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNote' }
      & ProjectNoteFragmentFragment
    )>>>, bill_of_material?: Maybe<(
      { __typename?: 'ProjectMaterialData' }
      & { systems: Array<Maybe<(
        { __typename?: 'System' }
        & SystemsFragmentFragment
      )>>, revisions: Array<Maybe<(
        { __typename?: 'Revision' }
        & RevisionFragmentFragment
      )>>, projectProfiles?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectProfiles' }
        & ProjectProfileFragmentFragment
      )>>>, partNumberProfiles: Array<Maybe<(
        { __typename?: 'PartNumberProfiles' }
        & PartNumberProfileFragmentFragment
      )>>, quotes?: Maybe<Array<Maybe<(
        { __typename?: 'SystemQuote' }
        & SystemQuoteFragmentFragment
      )>>> }
    )> }
  )> }
);

export type GetProjectPricingQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectPricingQuery = (
  { __typename?: 'Query' }
  & { getProjectPricing: Array<Maybe<(
    { __typename?: 'ProjectPricing' }
    & ProjectPricingFragmentFragment
  )>> }
);

export type GetSystemRevisionMaterialListQueryVariables = Exact<{
  input: GetSystemRevisionMaterialListInput;
}>;


export type GetSystemRevisionMaterialListQuery = (
  { __typename?: 'Query' }
  & { getSystemRevisionMaterialList: Array<Maybe<(
    { __typename?: 'ProjectBillItem' }
    & ProjectBillItemFragmentFragment
  )>> }
);

export type KeyPerformanceEquipMetricFragmentFragment = (
  { __typename?: 'KeyPerformanceEquipMetric' }
  & Pick<KeyPerformanceEquipMetric, 'equipment' | 'sold' | 'final'>
);

export type KeyPerformanceProjMetricFragmentFragment = (
  { __typename?: 'KeyPerformanceProjMetric' }
  & Pick<KeyPerformanceProjMetric, 'metric' | 'sold' | 'current' | 'status' | 'difference'>
);

export type PipingSystemMetricFragmentFragment = (
  { __typename?: 'PipingSystemMetric' }
  & Pick<PipingSystemMetric, 'pipingSystem'>
);

export type QuickStatsMetricFragmentFragment = (
  { __typename?: 'QuickStatsMetric' }
  & Pick<QuickStatsMetric, 'metric' | 'current' | 'isPercentage'>
);

export type GetProjectStatsQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectStatsQuery = (
  { __typename?: 'Query' }
  & { getProjectStats?: Maybe<(
    { __typename?: 'ProjectStats' }
    & { keyPerformanceProjMetircs?: Maybe<Array<Maybe<(
      { __typename?: 'KeyPerformanceProjMetric' }
      & KeyPerformanceProjMetricFragmentFragment
    )>>>, keyPerformanceEquipMetrics?: Maybe<Array<Maybe<(
      { __typename?: 'KeyPerformanceEquipMetric' }
      & KeyPerformanceEquipMetricFragmentFragment
    )>>>, quickStatsMetrics?: Maybe<Array<Maybe<(
      { __typename?: 'QuickStatsMetric' }
      & QuickStatsMetricFragmentFragment
    )>>>, pipingSystems?: Maybe<Array<Maybe<(
      { __typename?: 'PipingSystemMetric' }
      & PipingSystemMetricFragmentFragment
    )>>> }
  )> }
);

export type AddBimAndCoObjectPhotosMutationVariables = Exact<{
  input: AddBimAndCoObjectPhotosInput;
}>;


export type AddBimAndCoObjectPhotosMutation = (
  { __typename?: 'Mutation' }
  & { addBimAndCoObjectPhotos?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type DeleteBimAndCoObjectPhotosMutationVariables = Exact<{
  input: DeleteBimAndCoObjectPhotosInput;
}>;


export type DeleteBimAndCoObjectPhotosMutation = (
  { __typename?: 'Mutation' }
  & { deleteBimAndCoObjectPhotos?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type RemoveSpotConnectionsMutationVariables = Exact<{
  input: RemoveSpotConnectionsInput;
}>;


export type RemoveSpotConnectionsMutation = (
  { __typename?: 'Mutation' }
  & { removeSpotConnections?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type SetDefaultBimAndCoPhotoMutationVariables = Exact<{
  input: SetDefaultBimAndCoPhotoInput;
}>;


export type SetDefaultBimAndCoPhotoMutation = (
  { __typename?: 'Mutation' }
  & { setDefaultBimAndCoPhoto?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateBimAndCoObjectCatalogsMutationVariables = Exact<{
  input: UpdateObjectCatalogsInput;
}>;


export type UpdateBimAndCoObjectCatalogsMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectCatalogs?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateObjectPropertiesMutationVariables = Exact<{
  input: UpdateObjectPropertiesInput;
}>;


export type UpdateObjectPropertiesMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectProperties?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateObjectNameMutationVariables = Exact<{
  input: UpdateObjectNameInput;
}>;


export type UpdateObjectNameMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectName?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateModelPropertiesMutationVariables = Exact<{
  input: Array<Maybe<UpdateModelPropertiesInput>>;
}>;


export type UpdateModelPropertiesMutation = (
  { __typename?: 'Mutation' }
  & { updateModelProperties?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateObjectSpotConnectionMutationVariables = Exact<{
  input: UpdateObjectSpotConnectionInput;
}>;


export type UpdateObjectSpotConnectionMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectSpotConnection?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateObjectSpotDataMutationVariables = Exact<{
  input: Array<Maybe<UpdateSpotDataInput>>;
}>;


export type UpdateObjectSpotDataMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectSpotData?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateObjectSubmittalsMutationVariables = Exact<{
  input: UpdateObjectSubmittalsInput;
}>;


export type UpdateObjectSubmittalsMutation = (
  { __typename?: 'Mutation' }
  & { updateObjectSubmittals?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type UpdateSpotSoftwareMutationVariables = Exact<{
  input: Array<Maybe<UpdateSpotSoftwareInput>>;
}>;


export type UpdateSpotSoftwareMutation = (
  { __typename?: 'Mutation' }
  & { updateSpotSoftware?: Maybe<(
    { __typename?: 'ContentManagementResponse' }
    & Pick<ContentManagementResponse, 'responseStatus' | 'responseMessage'>
  )> }
);

export type GetBimCoObjectsQueryVariables = Exact<{
  objectIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type GetBimCoObjectsQuery = (
  { __typename?: 'Query' }
  & { getBimAndCoObjects?: Maybe<Array<Maybe<(
    { __typename?: 'BimAndCoObject' }
    & Pick<BimAndCoObject, 'Id' | 'UpdatedAt' | 'CreatedAt' | 'Name' | 'Description' | 'DefaultPhoto'>
    & { Photos?: Maybe<Array<Maybe<(
      { __typename?: 'BimAndCoPhoto' }
      & Pick<BimAndCoPhoto, 'Id' | 'FileName' | 'IsDefault' | 'Path'>
    )>>>, Catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'BimAndCoCatalog' }
      & Pick<BimAndCoCatalog, 'Id' | 'Name'>
    )>>>, ThreeDModels?: Maybe<Array<Maybe<(
      { __typename?: 'BimAndCoModel' }
      & Pick<BimAndCoModel, 'Id' | 'Name' | 'FileName' | 'Extension' | 'LevelOfDetail'>
    )>>>, TwoDModels?: Maybe<Array<Maybe<(
      { __typename?: 'BimAndCoModel' }
      & Pick<BimAndCoModel, 'Id' | 'Name' | 'FileName' | 'Extension' | 'LevelOfDetail'>
    )>>> }
  )>>> }
);

export type GetBimObjectDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBimObjectDataQuery = (
  { __typename?: 'Query' }
  & { getBimObjects?: Maybe<(
    { __typename?: 'GetBimObjectResponse' }
    & { BimObjects?: Maybe<Array<Maybe<(
      { __typename?: 'BimObject' }
      & Pick<BimObject, 'Id' | 'Name' | 'Photo'>
      & { Catalog?: Maybe<(
        { __typename?: 'BimAndCoCatalog' }
        & Pick<BimAndCoCatalog, 'Id' | 'Name'>
      )> }
    )>>>, Catalogs?: Maybe<Array<Maybe<(
      { __typename?: 'BimAndCoCatalog' }
      & Pick<BimAndCoCatalog, 'Id' | 'Name'>
    )>>> }
  )> }
);

export type GetDamDataQueryVariables = Exact<{
  search_val: Scalars['String'];
}>;


export type GetDamDataQuery = (
  { __typename?: 'Query' }
  & { getDamImages?: Maybe<Array<Maybe<(
    { __typename?: 'DamImage' }
    & Pick<DamImage, 'smallImageUrl' | 'largeImageUrl' | 'fileName'>
  )>>> }
);

export type GetObjectSpotDataQueryVariables = Exact<{
  object_names: Array<Maybe<Scalars['String']>>;
}>;


export type GetObjectSpotDataQuery = (
  { __typename?: 'Query' }
  & { getSpotData?: Maybe<Array<Maybe<(
    { __typename?: 'SpotContentObject' }
    & Pick<SpotContentObject, 'ObjectName' | 'PermaLink'>
    & { PrimeTimestamps?: Maybe<Array<Maybe<(
      { __typename?: 'SpotContentObjectBimTimestamps' }
      & Pick<SpotContentObjectBimTimestamps, 'SoftwareType' | 'LastUpdated'>
    )>>>, SpotData?: Maybe<Array<Maybe<(
      { __typename?: 'SpotContentObjectData' }
      & Pick<SpotContentObjectData, 'Software' | 'FilePath' | 'TimeStamp'>
    )>>> }
  )>>> }
);

export type GetSpotSoftwareRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpotSoftwareRecordsQuery = (
  { __typename?: 'Query' }
  & { getSpotSoftwareRecords?: Maybe<Array<Maybe<(
    { __typename?: 'SpotSoftwareRecord' }
    & Pick<SpotSoftwareRecord, 'FilePath' | 'FileName' | 'Software' | 'LastUpdated'>
  )>>> }
);

export type GetSpotStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpotStatusesQuery = (
  { __typename?: 'Query' }
  & { getSpotStatuses?: Maybe<Array<Maybe<(
    { __typename?: 'SpotStatus' }
    & Pick<SpotStatus, 'content_object_name' | 'description' | 'permalink' | 'style' | 'last_updated' | 'file_path' | 'file_name' | 'content_software_status_id' | 'content_object_id' | 'uploaded_bim_and_co'>
  )>>> }
);

export type GetSpotStylesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpotStylesQuery = (
  { __typename?: 'Query' }
  & { getSpotStyles?: Maybe<Array<Maybe<(
    { __typename?: 'SpotStylesObject' }
    & Pick<SpotStylesObject, 'Style' | 'Description' | 'Permalink' | 'Submittal'>
  )>>> }
);

export type EstimatingAreaBaseFieldsFragmentFragment = (
  { __typename?: 'ProjectArea' }
  & Pick<ProjectArea, 'area' | 'areaid' | 'estimate' | 'changeorder' | 'marked_for_delete' | 'status' | 'schedule_impact' | 'updated_po'>
);

export type MasterQuoteFieldsFragmentFragment = (
  { __typename?: 'QuoteField' }
  & Pick<QuoteField, 'quote_field_id' | 'worksheet_id' | 'section_id' | 'field' | 'drop_count' | 'man_hours' | 'totalled' | 'order_number' | 'active'>
);

export type AddActiveQuoteFieldMutationVariables = Exact<{
  input: UpdateActiveQuoteFieldInput;
}>;


export type AddActiveQuoteFieldMutation = (
  { __typename?: 'Mutation' }
  & { addActiveQuoteField?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteFieldRecord' }
    & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
  )>>> }
);

export type AddQuoteFieldRecordMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<QuoteFieldRecordInput>>>;
}>;


export type AddQuoteFieldRecordMutation = (
  { __typename?: 'Mutation' }
  & { addQuoteFields?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteFieldRecord' }
    & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
  )>>> }
);

export type AutoPopulateAreaQuotesMutationVariables = Exact<{
  input: AutoPopulateAreaQuotesInput;
}>;


export type AutoPopulateAreaQuotesMutation = (
  { __typename?: 'Mutation' }
  & { autoPopulateAreaQuotes: (
    { __typename?: 'AutoPopulateAreaQuotesResult' }
    & Pick<AutoPopulateAreaQuotesResult, 'quoteFailed'>
    & { missingEquipment: Array<Maybe<(
      { __typename?: 'EquipmentItem' }
      & Pick<EquipmentItem, 'type' | 'count'>
    )>>, quotes: Array<Maybe<(
      { __typename?: 'PhaseQuote' }
      & Pick<PhaseQuote, 'quote_id'>
    )>> }
  ) }
);

export type ClearQuoteFieldsFromSectionMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<QuoteFieldRecordInput>>>;
}>;


export type ClearQuoteFieldsFromSectionMutation = (
  { __typename?: 'Mutation' }
  & { clearQuoteFieldsFromSection?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteFieldRecord' }
    & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
  )>>> }
);

export type CreateNewAreaPhaseMutationVariables = Exact<{
  input: CreateNewAreaPhaseInput;
}>;


export type CreateNewAreaPhaseMutation = (
  { __typename?: 'Mutation' }
  & { createNewAreaPhase?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectAreaPhase' }
    & Pick<ProjectAreaPhase, 'area_id' | 'is_active' | 'name' | 'phase_id'>
  )>>> }
);

export type CreateNewAreaMutationVariables = Exact<{
  input: CreateNewProjectAreaInput;
}>;


export type CreateNewAreaMutation = (
  { __typename?: 'Mutation' }
  & { createNewProjectArea?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectArea' }
    & EstimatingAreaBaseFieldsFragmentFragment
  )>>> }
);

export type CreateNewMasterQuoteFieldMutationVariables = Exact<{
  input: CreateNewMasterQuoteFieldInput;
}>;


export type CreateNewMasterQuoteFieldMutation = (
  { __typename?: 'Mutation' }
  & { createNewMasterQuoteField?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteField' }
    & MasterQuoteFieldsFragmentFragment
  )>>> }
);

export type CreateNewPhaseQuoteMutationVariables = Exact<{
  input: CreateNewPhaseQuoteInput;
}>;


export type CreateNewPhaseQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createNewPhaseQuote?: Maybe<(
    { __typename?: 'PhaseQuote' }
    & Pick<PhaseQuote, 'quote_id' | 'quote_name' | 'drawing_numbers' | 'drawing_revision' | 'limits' | 'rate' | 'rate_calc' | 'phase_id' | 'total_layout' | 'worksheet_id' | 'riser_offset'>
    & { quote_fields?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteFieldRecord' }
      & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
    )>>> }
  )> }
);

export type DeleteQuoteMutationVariables = Exact<{
  input: DeleteQuoteInput;
}>;


export type DeleteQuoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuote?: Maybe<Array<Maybe<(
    { __typename?: 'PhaseQuote' }
    & Pick<PhaseQuote, 'phase_id' | 'drawing_numbers' | 'drawing_revision' | 'limits' | 'quote_id' | 'quote_name' | 'rate' | 'rate_calc' | 'riser_offset' | 'total_layout' | 'worksheet_id'>
    & { quote_fields?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteFieldRecord' }
      & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
    )>>> }
  )>>> }
);

export type ResetQuoteEquipmentMutationVariables = Exact<{
  input: ResetQuoteEquipmentInput;
}>;


export type ResetQuoteEquipmentMutation = (
  { __typename?: 'Mutation' }
  & { resetQuoteEquipment: (
    { __typename?: 'AutoPopulateAreaQuotesResult' }
    & Pick<AutoPopulateAreaQuotesResult, 'quoteFailed'>
    & { missingEquipment: Array<Maybe<(
      { __typename?: 'EquipmentItem' }
      & Pick<EquipmentItem, 'type' | 'count'>
    )>>, quotes: Array<Maybe<(
      { __typename?: 'PhaseQuote' }
      & Pick<PhaseQuote, 'quote_id'>
    )>> }
  ) }
);

export type UpdateAreaEstimatingHoursMutationVariables = Exact<{
  input: UpdateEstimatingHoursInput;
}>;


export type UpdateAreaEstimatingHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimatingAreaHours?: Maybe<(
    { __typename?: 'EstimatingHoursField' }
    & Pick<EstimatingHoursField, 'area_id' | 'hfm_id' | 'hours'>
  )> }
);

export type UpdateAreaPhaseMutationVariables = Exact<{
  input: UpdateAreaPhaseInput;
}>;


export type UpdateAreaPhaseMutation = (
  { __typename?: 'Mutation' }
  & { updateAreaPhase?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectAreaPhase' }
    & Pick<ProjectAreaPhase, 'phase_id' | 'name' | 'market' | 'is_active' | 'area_id'>
  )>>> }
);

export type UpdateBaseQuoteFieldsMutationVariables = Exact<{
  input: Array<Maybe<UpdateBaseQuoteFieldsInput>>;
}>;


export type UpdateBaseQuoteFieldsMutation = (
  { __typename?: 'Mutation' }
  & { updateBaseQuoteFields?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteField' }
    & MasterQuoteFieldsFragmentFragment
  )>>> }
);

export type UpdateEstimatingAreaMutationVariables = Exact<{
  input?: Maybe<UpdateEstimatingAreaInput>;
}>;


export type UpdateEstimatingAreaMutation = (
  { __typename?: 'Mutation' }
  & { updateEstimatingArea?: Maybe<(
    { __typename?: 'ProjectArea' }
    & EstimatingAreaBaseFieldsFragmentFragment
  )> }
);

export type UpdateFieldManualMutationVariables = Exact<{
  input: UpdateFieldManualInput;
}>;


export type UpdateFieldManualMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFieldManual'>
);

export type UpdateProjectFieldsHoursMutationVariables = Exact<{
  input?: Maybe<UpdateProjectHoursFieldsInput>;
}>;


export type UpdateProjectFieldsHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectHoursFields?: Maybe<Array<Maybe<(
    { __typename?: 'HoursFieldRecord' }
    & Pick<HoursFieldRecord, 'hfm_id' | 'pf_id'>
  )>>> }
);

export type UpdateProjectOfferingsMutationVariables = Exact<{
  input?: Maybe<UpdateProjectOfferingsInput>;
}>;


export type UpdateProjectOfferingsMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectOffering?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectOffering' }
    & Pick<ProjectOffering, 'offeringid'>
  )>>> }
);

export type UpdateQuoteBaseInfoMutationVariables = Exact<{
  input: UpdateQuoteBaseInfoInput;
}>;


export type UpdateQuoteBaseInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteBaseInfo?: Maybe<Array<Maybe<(
    { __typename?: 'PhaseQuote' }
    & Pick<PhaseQuote, 'drawing_numbers' | 'drawing_revision' | 'limits' | 'phase_id' | 'quote_id' | 'quote_name' | 'rate' | 'rate_calc' | 'riser_offset' | 'total_layout' | 'worksheet_id'>
    & { quote_fields?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteFieldRecord' }
      & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
    )>>> }
  )>>> }
);

export type UpdateQuoteFieldDataMutationVariables = Exact<{
  input: Array<Maybe<UpdateQuoteFieldDataInput>>;
}>;


export type UpdateQuoteFieldDataMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteFieldData?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteFieldRecord' }
    & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
  )>>> }
);

export type UpdateUnitsManuallyMutationVariables = Exact<{
  input: UpdateUnitsManuallyInput;
}>;


export type UpdateUnitsManuallyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUnitsManually'>
);

export type UpdateQuoteFieldUnitsMutationVariables = Exact<{
  input?: Maybe<QuoteFieldRecordInput>;
}>;


export type UpdateQuoteFieldUnitsMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteFieldUnits?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteFieldRecord' }
    & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
  )>>> }
);

export type UpdateQuoteWorksheetIdMutationVariables = Exact<{
  input: UpdateQuoteWorksheetIdInput;
}>;


export type UpdateQuoteWorksheetIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateQuoteWorksheetID'>
);

export type UpdateRiserOffsetMutationVariables = Exact<{
  input: UpdateRiserOffsetInput;
}>;


export type UpdateRiserOffsetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRiserOffset'>
);

export type GetEstimatingSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEstimatingSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { changeOrderStatusTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ChangeOrderStatus' }
      & Pick<ChangeOrderStatus, 'status_id' | 'status_name'>
    )>>>, hoursFields?: Maybe<Array<Maybe<(
      { __typename?: 'HoursField' }
      & Pick<HoursField, 'hfm_id' | 'field' | 'category' | 'hour_category' | 'percent' | 'section_id' | 'estimated'>
    )>>>, hoursFieldsCategories?: Maybe<Array<Maybe<(
      { __typename?: 'HoursFieldCategory' }
      & Pick<HoursFieldCategory, 'category_id' | 'category'>
    )>>>, offerings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & Pick<ProjectOffering, 'unique_id' | 'offeringid' | 'offering'>
    )>>>, quoteFields?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteField' }
      & MasterQuoteFieldsFragmentFragment
    )>>>, quoteSections?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteSection' }
      & Pick<QuoteSection, 'section_id' | 'section'>
    )>>>, quoteWorksheets?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteWorksheet' }
      & Pick<QuoteWorksheet, 'worksheet_id' | 'worksheet'>
    )>>> }
  )> }
);

export type GetProjectEstimatingDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectEstimatingDataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'proj_id' | 'projid' | 'proj_status'>
    & { areas?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectArea' }
      & { phases?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectAreaPhase' }
        & Pick<ProjectAreaPhase, 'area_id' | 'is_active' | 'market' | 'name' | 'phase_id'>
        & { quotes?: Maybe<Array<Maybe<(
          { __typename?: 'PhaseQuote' }
          & Pick<PhaseQuote, 'phase_id' | 'drawing_numbers' | 'drawing_revision' | 'limits' | 'quote_id' | 'quote_name' | 'rate' | 'rate_calc' | 'riser_offset' | 'total_layout' | 'worksheet_id'>
          & { quote_fields?: Maybe<Array<Maybe<(
            { __typename?: 'QuoteFieldRecord' }
            & Pick<QuoteFieldRecord, 'quote_field_id' | 'quote_id' | 'quote_field_master_id' | 'section_id' | 'units' | 'field_name' | 'man_hours' | 'calc_value' | 'calc_cost' | 'active' | 'manual'>
          )>>> }
        )>>> }
      )>>>, estimatingHours?: Maybe<Array<Maybe<(
        { __typename?: 'EstimatingHoursField' }
        & Pick<EstimatingHoursField, 'est_id' | 'hfm_id' | 'hours'>
      )>>> }
      & EstimatingAreaBaseFieldsFragmentFragment
    )>>>, hours_fields?: Maybe<Array<Maybe<(
      { __typename?: 'HoursFieldRecord' }
      & Pick<HoursFieldRecord, 'pf_id' | 'hfm_id'>
    )>>>, offerings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & Pick<ProjectOffering, 'offeringid'>
    )>>>, notes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNote' }
      & ProjectNoteFragmentFragment
    )>>> }
  )> }
);

export type ProjectFabViewFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'proj_id' | 'project_name'>
  & { areas?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectArea' }
    & Pick<ProjectArea, 'area' | 'areaid' | 'quoteid' | 'changeorder' | 'estimate'>
    & { areaFabrication?: Maybe<(
      { __typename?: 'AreaFabrication' }
      & Pick<AreaFabrication, 'coupling_total_hours' | 'coupling_count' | 'mech_hours' | 'mech_count' | 'fabrication_allowance' | 'fab_hours' | 'fab_total_plus10' | 'fab_total_no_overhead' | 'total_fab_new_estimating' | 'fab_labor_rate' | 'region_id' | 'pipecubicFootagetMultiplier' | 'shopMultiplier' | 'truckCubicFootage' | 'lessThan12Diam' | 'twelveTo16Diam' | 'eighteenPlusDiam' | 'lessThan12Shop' | 'twelveTo16Shop' | 'eighteenPlusShop' | 'lessThan12Hours' | 'twelveTo16Hours' | 'eighteenPlusHours' | 'groove_count' | 'pipeCubicFeetNoMultiplier' | 'pipeCubicPlus25' | 'truck_count' | 'pallet_cost' | 'pallet_count' | 'pump_drop_count' | 'ahu_count' | 'header_count' | 'quoted_fab_hours' | 'quoted_fab_cost' | 'quoted_truck_count'>
      & { couplings?: Maybe<Array<Maybe<(
        { __typename?: 'CouplingRecord' }
        & Pick<CouplingRecord, 'size' | 'qty' | 'diameterInches'>
      )>>>, pipe?: Maybe<Array<Maybe<(
        { __typename?: 'PipeRecord' }
        & Pick<PipeRecord, 'linear_feet' | 'size' | 'cubic_feet'>
      )>>> }
    )> }
  )>>> }
);

export type CheckRequiresManualFabQuoteQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type CheckRequiresManualFabQuoteQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'requires_manual_fab_quote'>
  )> }
);

export type GetProjectFabViewBaseQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectFabViewBaseQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectFabViewFragmentFragment
  )> }
);

export type GetProjectFabCalcDataV2QueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectFabCalcDataV2Query = (
  { __typename?: 'Query' }
  & { getProjectFabCalcDataV2?: Maybe<(
    { __typename?: 'Project' }
    & ProjectFabViewFragmentFragment
  )> }
);

export type ChangeOrderStatusTypesFragmentFragment = (
  { __typename?: 'ChangeOrderStatus' }
  & Pick<ChangeOrderStatus, 'status_name' | 'status_id'>
);

export type FinancesFragmentFragment = (
  { __typename?: 'Finances' }
  & Pick<Finances, 'finance_data_id' | 'finance_revision_id' | 'proj_id' | 'quoteid' | 'offeringid' | 'offering' | 'count' | 'buffer' | 'value' | 'value_string' | 'value_with_buffer' | 'value_with_buffer_string' | 'dist_markup' | 'dist_cost' | 'dist_cost_string' | 'cust_markup' | 'cust_cost' | 'cust_cost_string' | 'group_as_material' | 'group_as_transportation'>
);

export type FinancesHoursFragmentFragment = (
  { __typename?: 'FinancesHours' }
  & Pick<FinancesHours, 'finance_hours_id' | 'finance_hours_type_id' | 'finance_hours_type' | 'value' | 'finance_revision_id' | 'proj_id' | 'quoteid'>
);

export type OfferingsFragmentFragment = (
  { __typename?: 'ProjectOffering' }
  & Pick<ProjectOffering, 'offeringid' | 'offering'>
);

export type ProjTotalsColumnsLabelsFragmentFragment = (
  { __typename?: 'ProjTotalsColumnsLabels' }
  & Pick<ProjTotalsColumnsLabels, 'label' | 'property' | 'billing_category'>
);

export type QuoteRevisionsFragmentFragment = (
  { __typename?: 'QuoteRevision' }
  & Pick<QuoteRevision, 'finance_revision_id' | 'finance_revision_name' | 'finance_revision_date' | 'proj_id' | 'quoteid' | 'sold'>
);

export type QuotesFragmentFragment = (
  { __typename?: 'PhaseQuote' }
  & Pick<PhaseQuote, 'quote_id' | 'quote_name' | 'areaid' | 'change_order' | 'change_order_id' | 'schedule_impact' | 'updated_po' | 'status_id'>
);

export type TotalFinancesFragmentFragment = (
  { __typename?: 'TotalFinances' }
  & Pick<TotalFinances, 'type' | 'fab' | 'risers' | 'kkto_vdc' | 'kkto_sales' | 'vis' | 'skids' | 'material' | 'transportation' | 'headers' | 'vipds' | 'ahus' | 'vdc' | 'pipe' | 'pipe_support' | 'third_party_fab' | 'third_party_welding' | 'standard_prv' | 'vic_tool' | 'drawing_hours' | 'fabrication_hours' | 'engineering_services' | 'total'>
);

export type AddAdditionalFinancesOfferingMutationVariables = Exact<{
  input: AddAdditionalFinancesOfferingInput;
}>;


export type AddAdditionalFinancesOfferingMutation = (
  { __typename?: 'Mutation' }
  & { addAdditionalFinancesOffering?: Maybe<(
    { __typename?: 'FinancesData' }
    & { finances?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, additionalOfferings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & OfferingsFragmentFragment
    )>>>, materialBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, transportationBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>> }
  )> }
);

export type RemoveAdditionalFinancesOfferingMutationVariables = Exact<{
  input: RemoveAdditionalFinancesOfferingInput;
}>;


export type RemoveAdditionalFinancesOfferingMutation = (
  { __typename?: 'Mutation' }
  & { removeAdditionalFinancesOffering?: Maybe<(
    { __typename?: 'FinancesData' }
    & { finances?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, additionalOfferings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & OfferingsFragmentFragment
    )>>>, materialBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, transportationBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>> }
  )> }
);

export type FetchLatestDataMutationVariables = Exact<{
  input: FetchLatestDataInput;
}>;


export type FetchLatestDataMutation = (
  { __typename?: 'Mutation' }
  & { fetchLatestData?: Maybe<(
    { __typename?: 'LatestData' }
    & Pick<LatestData, 'errors'>
    & { financesData?: Maybe<(
      { __typename?: 'FinancesData' }
      & { finances?: Maybe<Array<Maybe<(
        { __typename?: 'Finances' }
        & FinancesFragmentFragment
      )>>>, materialBreakdown?: Maybe<Array<Maybe<(
        { __typename?: 'Finances' }
        & FinancesFragmentFragment
      )>>>, transportationBreakdown?: Maybe<Array<Maybe<(
        { __typename?: 'Finances' }
        & FinancesFragmentFragment
      )>>>, financesHours?: Maybe<Array<Maybe<(
        { __typename?: 'FinancesHours' }
        & FinancesHoursFragmentFragment
      )>>>, additionalOfferings?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectOffering' }
        & OfferingsFragmentFragment
      )>>> }
    )> }
  )> }
);

export type SetFinancesCompleteMutationVariables = Exact<{
  input: SetFinancesCompleteInput;
}>;


export type SetFinancesCompleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setFinancesComplete'>
);

export type UpdateFinancesHoursMutationVariables = Exact<{
  input: UpdateFinancesHoursInput;
}>;


export type UpdateFinancesHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateFinancesHours?: Maybe<Array<Maybe<(
    { __typename?: 'FinancesHours' }
    & FinancesHoursFragmentFragment
  )>>> }
);

export type UpdateFinancesItemMutationVariables = Exact<{
  input: UpdateFinancesItemInput;
}>;


export type UpdateFinancesItemMutation = (
  { __typename?: 'Mutation' }
  & { updateFinancesItem?: Maybe<(
    { __typename?: 'FinancesData' }
    & { finances?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, materialBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, transportationBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>> }
  )> }
);

export type AddFinancesRevisionMutationVariables = Exact<{
  input: AddFinancesRevisionInput;
}>;


export type AddFinancesRevisionMutation = (
  { __typename?: 'Mutation' }
  & { addFinancesRevision?: Maybe<(
    { __typename?: 'MtoRevision' }
    & { quoteRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteRevision' }
      & QuoteRevisionsFragmentFragment
    )>>>, bomRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type DeleteFinancesRevisionMutationVariables = Exact<{
  input: DeleteFinancesRevisionInput;
}>;


export type DeleteFinancesRevisionMutation = (
  { __typename?: 'Mutation' }
  & { deleteFinancesRevision?: Maybe<(
    { __typename?: 'MtoRevision' }
    & { quoteRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteRevision' }
      & QuoteRevisionsFragmentFragment
    )>>>, bomRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type EditFinancesRevisionMutationVariables = Exact<{
  input: EditFinancesRevisionInput;
}>;


export type EditFinancesRevisionMutation = (
  { __typename?: 'Mutation' }
  & { editFinancesRevision?: Maybe<(
    { __typename?: 'MtoRevision' }
    & { quoteRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteRevision' }
      & QuoteRevisionsFragmentFragment
    )>>>, bomRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type MarkFinancesRevisionSoldMutationVariables = Exact<{
  input: MarkFinancesRevisionSoldInput;
}>;


export type MarkFinancesRevisionSoldMutation = (
  { __typename?: 'Mutation' }
  & { markFinancesRevisionSold?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteRevision' }
    & QuoteRevisionsFragmentFragment
  )>>> }
);

export type RestoreFinancesRevisionMutationVariables = Exact<{
  input: RestoreFinancesRevisionInput;
}>;


export type RestoreFinancesRevisionMutation = (
  { __typename?: 'Mutation' }
  & { restoreFinancesRevision?: Maybe<Array<Maybe<(
    { __typename?: 'QuoteRevision' }
    & QuoteRevisionsFragmentFragment
  )>>> }
);

export type RevisionMultipleQuotesMutationVariables = Exact<{
  input: RevisionMultipleQuotesInput;
}>;


export type RevisionMultipleQuotesMutation = (
  { __typename?: 'Mutation' }
  & { revisionMultipleQuotes?: Maybe<(
    { __typename?: 'MtoRevision' }
    & { quoteRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteRevision' }
      & QuoteRevisionsFragmentFragment
    )>>>, bomRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & RevisionFragmentFragment
    )>>> }
  )> }
);

export type ToggleScheduleImpactMutationVariables = Exact<{
  input: UpdateEstimatingAreaInput;
}>;


export type ToggleScheduleImpactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleScheduleImpact'>
);

export type ToggleUpdatedPoMutationVariables = Exact<{
  input: UpdateEstimatingAreaInput;
}>;


export type ToggleUpdatedPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleUpdatedPo'>
);

export type UpdateChangeOrderStatusMutationVariables = Exact<{
  input: UpdateEstimatingAreaInput;
}>;


export type UpdateChangeOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChangeOrderStatus'>
);

export type GetFinancesQueryVariables = Exact<{
  input: GetFinancesInput;
}>;


export type GetFinancesQuery = (
  { __typename?: 'Query' }
  & { getFinances?: Maybe<(
    { __typename?: 'FinancesData' }
    & { finances?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, materialBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, transportationBreakdown?: Maybe<Array<Maybe<(
      { __typename?: 'Finances' }
      & FinancesFragmentFragment
    )>>>, financesHours?: Maybe<Array<Maybe<(
      { __typename?: 'FinancesHours' }
      & FinancesHoursFragmentFragment
    )>>>, additionalOfferings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & OfferingsFragmentFragment
    )>>> }
  )> }
);

export type GetProjectFinancesDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectFinancesDataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'proj_id' | 'projid' | 'project_name' | 'proj_num' | 'price_list_id' | 'region_id' | 'market_id' | 'currency' | 'currency_symbol' | 'proj_status'>
    & { notes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNote' }
      & ProjectNoteFragmentFragment
    )>>>, project_finances?: Maybe<(
      { __typename?: 'ProjectFinancesData' }
      & { quotes?: Maybe<Array<Maybe<(
        { __typename?: 'PhaseQuote' }
        & QuotesFragmentFragment
      )>>>, quoteRevisions?: Maybe<Array<Maybe<(
        { __typename?: 'QuoteRevision' }
        & QuoteRevisionsFragmentFragment
      )>>>, changeOrderStatusTypes?: Maybe<Array<Maybe<(
        { __typename?: 'ChangeOrderStatus' }
        & ChangeOrderStatusTypesFragmentFragment
      )>>> }
    )> }
  )> }
);

export type GetProjectTotalFinancesQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectTotalFinancesQuery = (
  { __typename?: 'Query' }
  & { getProjectTotalFinances?: Maybe<(
    { __typename?: 'TotalProjectFinancesData' }
    & { totalFinances?: Maybe<Array<Maybe<(
      { __typename?: 'TotalFinances' }
      & TotalFinancesFragmentFragment
    )>>>, projTotalsColumnsLabels?: Maybe<Array<Maybe<(
      { __typename?: 'ProjTotalsColumnsLabels' }
      & ProjTotalsColumnsLabelsFragmentFragment
    )>>> }
  )> }
);

export type ProjectNoteFragmentFragment = (
  { __typename?: 'ProjectNote' }
  & Pick<ProjectNote, 'projid' | 'note_id' | 'note' | 'date_added' | 'user_id' | 'added_by'>
  & { appliedNoteTypes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNoteType' }
    & ProjectNoteTypeFragmentFragment
  )>>>, availableNoteTypes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNoteType' }
    & ProjectNoteTypeFragmentFragment
  )>>>, history?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNoteHistory' }
    & ProjectNoteHistoryFragmentFragment
  )>>> }
);

export type ProjectNoteHistoryFragmentFragment = (
  { __typename?: 'ProjectNoteHistory' }
  & Pick<ProjectNoteHistory, 'note_history_id' | 'note_id' | 'note' | 'date_added' | 'added_by'>
);

export type ProjectNoteTypeFragmentFragment = (
  { __typename?: 'ProjectNoteType' }
  & Pick<ProjectNoteType, 'note_type_applied_id' | 'note_id' | 'note_type_id' | 'note_type'>
);

export type AddAppliedProjectNoteTypeMutationVariables = Exact<{
  input: AddAppliedProjectNoteTypeInput;
}>;


export type AddAppliedProjectNoteTypeMutation = (
  { __typename?: 'Mutation' }
  & { addAppliedProjectNoteType?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNoteType' }
    & ProjectNoteTypeFragmentFragment
  )>>> }
);

export type AddProjectNoteMutationVariables = Exact<{
  input: AddProjectNoteInput;
}>;


export type AddProjectNoteMutation = (
  { __typename?: 'Mutation' }
  & { addProjectNote?: Maybe<(
    { __typename?: 'ProjectNote' }
    & ProjectNoteFragmentFragment
  )> }
);

export type DeleteAppliedProjectNoteTypeMutationVariables = Exact<{
  input: DeleteAppliedProjectNoteTypeInput;
}>;


export type DeleteAppliedProjectNoteTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteAppliedProjectNoteType?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNoteType' }
    & ProjectNoteTypeFragmentFragment
  )>>> }
);

export type DeleteProjectNoteMutationVariables = Exact<{
  input: DeleteProjectNoteInput;
}>;


export type DeleteProjectNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectNote: Array<Maybe<(
    { __typename?: 'ProjectNote' }
    & ProjectNoteFragmentFragment
  )>> }
);

export type UpdateProjectNoteMutationVariables = Exact<{
  input: UpdateProjectNoteInput;
}>;


export type UpdateProjectNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectNote?: Maybe<(
    { __typename?: 'ProjectNote' }
    & ProjectNoteFragmentFragment
  )> }
);

export type GetProjectNoteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectNoteTypesQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { projectNoteTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNoteType' }
      & ProjectNoteTypeFragmentFragment
    )>>> }
  )> }
);

export type GetProjectNotesQueryVariables = Exact<{
  projid: Scalars['Int'];
}>;


export type GetProjectNotesQuery = (
  { __typename?: 'Query' }
  & { projectNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectNote' }
    & ProjectNoteFragmentFragment
  )>>> }
);

export type TrackingActivityFragmentFragment = (
  { __typename?: 'TrackingActivity' }
  & Pick<TrackingActivity, 'activity_id' | 'activity_type_id' | 'additional_info' | 'description' | 'proj_id' | 'timestamp' | 'user_id' | 'user_name'>
  & { activity_type?: Maybe<(
    { __typename?: 'TrackingActivityType' }
    & Pick<TrackingActivityType, 'description' | 'activity_group_id' | 'operation_name' | 'activity_type_id'>
  )> }
);

export type ProjectLocationFragmentFragment = (
  { __typename?: 'ProjectLocation' }
  & Pick<ProjectLocation, 'address' | 'latitude' | 'longitude' | 'country' | 'region' | 'city' | 'state'>
);

export type ProjectViewCoreFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'bid_date' | 'cpsservice' | 'current_project_type' | 'current_project_type_id' | 'currency' | 'datesubmitted' | 'distributor' | 'distributor_id' | 'engineerid' | 'is_kkto_project' | 'kkto_status' | 'multiplier_date' | 'nda_required' | 'order_number' | 'postbid' | 'probofsale' | 'project_name' | 'projid' | 'proj_id' | 'proj_status' | 'proj_num' | 'po_date' | 'poaa' | 'poaa_date' | 'po_amount' | 'region_id' | 'region_pn' | 'standard' | 'regioncode' | 'schedule_link' | 'union_notunion' | 'worksheet_date' | 'sold_financials_updated' | 'user_id'>
  & { coordinator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'user_id' | 'full_name' | 'employeeid'>
  )>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'customer'>
  )>, domestic_requirements?: Maybe<(
    { __typename?: 'DomesticRequirement' }
    & Pick<DomesticRequirement, 'requirement' | 'requirement_note'>
  )>, estimator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'user_id' | 'full_name'>
  )>, market?: Maybe<(
    { __typename?: 'Market' }
    & Pick<Market, 'market' | 'market_id'>
  )>, price_list?: Maybe<(
    { __typename?: 'PriceList' }
    & Pick<PriceList, 'price_list_name'>
  )>, statusHistory?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectStatusRecord' }
    & Pick<ProjectStatusRecord, 'status_id' | 'status' | 'reason' | 'status_date'>
  )>>>, proj_type?: Maybe<(
    { __typename?: 'AdditionalProjectType' }
    & Pick<AdditionalProjectType, 'project_type_id' | 'project_type'>
  )>, salesman?: Maybe<(
    { __typename?: 'Salesman' }
    & Pick<Salesman, 'name' | 'group_name' | 'country' | 'metro' | 'salesregion_name'>
  )> }
);

export type TeamMembersFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'user_id' | 'full_name'>
);

export type AddProjectTeamMemberMutationVariables = Exact<{
  input: UpdateProjectTeamMemberInput;
}>;


export type AddProjectTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { addProjectTeamMember: Array<Maybe<(
    { __typename?: 'User' }
    & TeamMembersFragmentFragment
  )>> }
);

export type AddNewProjectMutationVariables = Exact<{
  input: AddNewProjectInput;
}>;


export type AddNewProjectMutation = (
  { __typename?: 'Mutation' }
  & { addNewProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'project_name' | 'proj_id' | 'proj_num'>
  )> }
);

export type RemoveProjectTeamMemberMutationVariables = Exact<{
  input: UpdateProjectTeamMemberInput;
}>;


export type RemoveProjectTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeProjectTeamMember: Array<Maybe<(
    { __typename?: 'User' }
    & TeamMembersFragmentFragment
  )>> }
);

export type UpdateProjectInfoViewMutationVariables = Exact<{
  input: UpdateProjectInfoViewInput;
}>;


export type UpdateProjectInfoViewMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectInfoView?: Maybe<(
    { __typename?: 'Project' }
    & { project_location?: Maybe<(
      { __typename?: 'ProjectLocation' }
      & ProjectLocationFragmentFragment
    )>, team_members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & TeamMembersFragmentFragment
    )>>>, offerings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & Pick<ProjectOffering, 'offeringid'>
    )>>> }
    & ProjectViewCoreFragmentFragment
  )> }
);

export type UpdateProjectLocationMutationVariables = Exact<{
  input: UpdateProjectLocationInput;
}>;


export type UpdateProjectLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectLocation?: Maybe<(
    { __typename?: 'ProjectLocation' }
    & ProjectLocationFragmentFragment
  )> }
);

export type UpdateProjectStatusDateMutationVariables = Exact<{
  input: UpdateProjectStatusDateInput;
}>;


export type UpdateProjectStatusDateMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectStatusDate: (
    { __typename?: 'Project' }
    & { statusHistory?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatusRecord' }
      & Pick<ProjectStatusRecord, 'status_id' | 'status' | 'reason' | 'status_date'>
    )>>> }
  ) }
);

export type UpdateProjectStatusMutationVariables = Exact<{
  input: ProjectStatusUpdateInput;
}>;


export type UpdateProjectStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectStatus?: Maybe<(
    { __typename?: 'ProjectStatusUpdateResult' }
    & Pick<ProjectStatusUpdateResult, 'missingFields'>
    & { projectStatusRecords?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatusRecord' }
      & Pick<ProjectStatusRecord, 'status' | 'reason' | 'status_date'>
    )>>> }
  )> }
);

export type GetProjectActivityHistoryQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectActivityHistoryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'proj_id'>
    & { activity_history?: Maybe<Array<Maybe<(
      { __typename?: 'TrackingActivity' }
      & TrackingActivityFragmentFragment
    )>>> }
  )> }
);

export type GetProjectNotificationsQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectNotificationsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { notifications?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNotification' }
      & Pick<ProjectNotification, 'has_notification' | 'message'>
    )>>> }
  )> }
);

export type ProjectSearchQueryVariables = Exact<{
  searchFields?: Maybe<ProjectSearchInput>;
}>;


export type ProjectSearchQuery = (
  { __typename?: 'Query' }
  & { projectSearch: Array<Maybe<(
    { __typename?: 'ProjectSearchResult' }
    & Pick<ProjectSearchResult, 'region' | 'project_name' | 'proj_id' | 'proj_status' | 'proj_num'>
  )>> }
);

export type GetProjectSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { customers?: Maybe<Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'customer' | 'customer_id'>
    )>>>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'region_id' | 'region'>
    )>>>, salesmen?: Maybe<Array<Maybe<(
      { __typename?: 'Salesman' }
      & Pick<Salesman, 'autoID' | 'name'>
    )>>>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'full_name' | 'employeeid' | 'cps_group' | 'status' | 'group'>
    )>>>, projectStatuses?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatus' }
      & Pick<ProjectStatus, 'project_status_id' | 'status_name'>
    )>>>, projectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype'>
    )>>>, markets?: Maybe<Array<Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'market_id' | 'market'>
    )>>>, additionalProjectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'AdditionalProjectType' }
      & Pick<AdditionalProjectType, 'project_type_id' | 'project_type'>
    )>>>, services?: Maybe<Array<Maybe<(
      { __typename?: 'VdcServiceType' }
      & Pick<VdcServiceType, 'service_type_id' | 'service_name'>
    )>>> }
  )> }
);

export type GetProjectInformationViewQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetProjectInformationViewQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & Pick<ProjectSelectOptions, 'states'>
    & { additionalProjectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'AdditionalProjectType' }
      & Pick<AdditionalProjectType, 'project_type_id' | 'project_type'>
    )>>>, countries?: Maybe<Array<Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'country_id' | 'country'>
    )>>>, projectStatuses?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatus' }
      & Pick<ProjectStatus, 'project_status_id' | 'status_name'>
    )>>>, currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'currency'>
    )>>>, customers?: Maybe<Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'customer' | 'customer_id'>
    )>>>, customerTypes?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'c_type_id' | 'c_type'>
    )>>>, markets?: Maybe<Array<Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'market_id' | 'market'>
    )>>>, priceLists?: Maybe<Array<Maybe<(
      { __typename?: 'PriceList' }
      & Pick<PriceList, 'price_list_id' | 'price_list_name'>
    )>>>, projectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype'>
    )>>>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'region' | 'region_id'>
    )>>>, services?: Maybe<Array<Maybe<(
      { __typename?: 'VdcServiceType' }
      & Pick<VdcServiceType, 'service_type_id' | 'service_name'>
    )>>>, provinces?: Maybe<Array<Maybe<(
      { __typename?: 'Province' }
      & Pick<Province, 'province' | 'province_name'>
    )>>>, engineers?: Maybe<Array<Maybe<(
      { __typename?: 'Engineer' }
      & Pick<Engineer, 'engineer_id' | 'name'>
    )>>>, salesmen?: Maybe<Array<Maybe<(
      { __typename?: 'Salesman' }
      & Pick<Salesman, 'name' | 'autoID'>
    )>>>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'employeeid' | 'full_name'>
    )>>> }
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & { project_location?: Maybe<(
      { __typename?: 'ProjectLocation' }
      & ProjectLocationFragmentFragment
    )>, team_members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & TeamMembersFragmentFragment
    )>>>, notes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectNote' }
      & ProjectNoteFragmentFragment
    )>>> }
    & ProjectViewCoreFragmentFragment
  )> }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'user_id' | 'full_name' | 'first_name' | 'last_name' | 'email_address' | 'employeeid' | 'region_id' | 'status' | 'cps_group' | 'user_type'>
);

export type AddNewCustomerMutationVariables = Exact<{
  input: AddNewCustomerInput;
}>;


export type AddNewCustomerMutation = (
  { __typename?: 'Mutation' }
  & { addNewCustomer?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { customers?: Maybe<Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'customer' | 'customer_id'>
    )>>> }
  )> }
);

export type GetCurrenciesByRegionQueryVariables = Exact<{
  region_id: Scalars['Int'];
}>;


export type GetCurrenciesByRegionQuery = (
  { __typename?: 'Query' }
  & { currencyByRegion?: Maybe<Array<Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'currency'>
  )>>> }
);

export type GetFilteredPriceListsQueryVariables = Exact<{
  region_id: Scalars['Int'];
  currency: Scalars['String'];
}>;


export type GetFilteredPriceListsQuery = (
  { __typename?: 'Query' }
  & { filteredPriceList?: Maybe<Array<Maybe<(
    { __typename?: 'PriceList' }
    & Pick<PriceList, 'price_list_id' | 'price_list_name'>
  )>>> }
);

export type GetCommonSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommonSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'region' | 'region_id'>
    )>>>, projectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>>>, markets?: Maybe<Array<Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'market_id' | 'market'>
    )>>>, additionalProjectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'AdditionalProjectType' }
      & Pick<AdditionalProjectType, 'project_type_id' | 'project_type'>
    )>>>, offerings?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectOffering' }
      & Pick<ProjectOffering, 'offering' | 'offeringid'>
    )>>>, customers?: Maybe<Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'customer_id' | 'customer' | 'city' | 'state'>
    )>>>, projectStatuses?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatus' }
      & Pick<ProjectStatus, 'status_name' | 'project_status_id'>
    )>>>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>>>, salesmen?: Maybe<Array<Maybe<(
      { __typename?: 'Salesman' }
      & Pick<Salesman, 'name' | 'salesregion_id' | 'groupID' | 'metroID' | 'divisionID' | 'autoID'>
    )>>>, customerTypes?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'c_type_id' | 'c_type'>
    )>>>, usersAccess?: Maybe<Array<Maybe<(
      { __typename?: 'UsersAccess' }
      & Pick<UsersAccess, 'user_id' | 'bimcreator'>
    )>>>, legislativeActs?: Maybe<Array<Maybe<(
      { __typename?: 'LegislativeAct' }
      & Pick<LegislativeAct, 'act_id' | 'act_name'>
    )>>>, gasket_types?: Maybe<Array<Maybe<(
      { __typename?: 'GasketType' }
      & Pick<GasketType, 'gasket_id' | 'gasket_type' | 'gasket_value'>
    )>>>, bomColumns?: Maybe<Array<Maybe<(
      { __typename?: 'BomColumn' }
      & Pick<BomColumn, 'column_id' | 'column' | 'label' | 'is_split' | 'selected_by_default' | 'editable' | 'type' | 'width'>
    )>>> }
  )> }
);

export type GetTrackingActivityGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrackingActivityGroupsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { trackingActivityGroups?: Maybe<Array<Maybe<(
      { __typename?: 'TrackingActivityGroup' }
      & Pick<TrackingActivityGroup, 'activity_group_id' | 'group_type' | 'active'>
    )>>> }
  )> }
);

export type AreaLimitFragmentFragment = (
  { __typename?: 'AreaLimit' }
  & Pick<AreaLimit, 'area' | 'areaid' | 'value' | 'selected' | 'equipments_modified' | 'dwg'>
  & { equipments?: Maybe<Array<Maybe<(
    { __typename?: 'EquipmentItem' }
    & EquipmentFragmentFragment
  )>>> }
);

export type EquipmentFragmentFragment = (
  { __typename?: 'EquipmentItem' }
  & Pick<EquipmentItem, 'equipment_id' | 'type' | 'mark'>
);

export type InclusionFragmentFragment = (
  { __typename?: 'Inclusion' }
  & Pick<Inclusion, 'inclusion_id' | 'value' | 'default_value'>
);

export type QuoteNoteFragmentFragment = (
  { __typename?: 'QuoteNote' }
  & Pick<QuoteNote, 'master_class' | 'sub_class' | 'value'>
);

export type QuoteDocRevisionFragmentFragment = (
  { __typename?: 'Revision' }
  & Pick<Revision, 'revision_name' | 'revision_id'>
);

export type UpdateQuoteDocumentMutationVariables = Exact<{
  input: UpdateQuoteDocumentInput;
}>;


export type UpdateQuoteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteDocument?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'projectcountry' | 'region_id' | 'current_project_type_id'>
  )> }
);

export type GetQuoteDocumentDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetQuoteDocumentDataQuery = (
  { __typename?: 'Query' }
  & { getQuoteDocumentData?: Maybe<(
    { __typename?: 'QuoteDocument' }
    & Pick<QuoteDocument, 'latestRevUsed' | 'drawingInfo' | 'specInfo' | 'pipingSystems' | 'numberOfSubclasses' | 'areaLimitsString' | 'pipingSystemsString'>
    & { quoteRevisions?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & QuoteDocRevisionFragmentFragment
    )>>>, areaLimits?: Maybe<Array<Maybe<(
      { __typename?: 'AreaLimit' }
      & AreaLimitFragmentFragment
    )>>>, notes?: Maybe<Array<Maybe<(
      { __typename?: 'QuoteNote' }
      & QuoteNoteFragmentFragment
    )>>>, inclusionsDictionary?: Maybe<(
      { __typename?: 'MasterClass' }
      & { Drawing?: Maybe<Array<Maybe<(
        { __typename?: 'SubClass' }
        & Pick<SubClass, 'index' | 'sub_class'>
        & { inclusions?: Maybe<Array<Maybe<(
          { __typename?: 'Inclusion' }
          & Pick<Inclusion, 'inclusion_id' | 'master_class' | 'sub_class' | 'description' | 'default_value' | 'value'>
        )>>> }
      )>>>, General?: Maybe<Array<Maybe<(
        { __typename?: 'SubClass' }
        & Pick<SubClass, 'index' | 'sub_class'>
        & { inclusions?: Maybe<Array<Maybe<(
          { __typename?: 'Inclusion' }
          & Pick<Inclusion, 'inclusion_id' | 'master_class' | 'sub_class' | 'description' | 'default_value' | 'value'>
        )>>> }
      )>>>, Material?: Maybe<Array<Maybe<(
        { __typename?: 'SubClass' }
        & Pick<SubClass, 'index' | 'sub_class'>
        & { inclusions?: Maybe<Array<Maybe<(
          { __typename?: 'Inclusion' }
          & Pick<Inclusion, 'inclusion_id' | 'master_class' | 'sub_class' | 'description' | 'default_value' | 'value'>
        )>>> }
      )>>> }
    )>, inclusionsList?: Maybe<Array<Maybe<(
      { __typename?: 'Inclusion' }
      & InclusionFragmentFragment
    )>>> }
  )> }
);

export type ProjectScheduleFragmentFragment = (
  { __typename?: 'ProjectSchedule' }
  & { coordinator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'user_id' | 'full_name' | 'resource_color'>
  )>, bim_schedule?: Maybe<(
    { __typename?: 'ProjectBimSchedule' }
    & { buyer_acceptance_agreement_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, kickoff_meeting_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, purchase_order_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, expected_drawing_completion_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, closeout_meeting_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, contractor_turnover_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )> }
  )>, estimating_schedule?: Maybe<(
    { __typename?: 'ProjectEstimatingSchedule' }
    & { vdc_received_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, bid_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, model_resource_start_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, model_requested_completion_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, model_resource_planned_completion_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, quality_control_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, estimated_completion_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )>, requested_sales_date?: Maybe<(
      { __typename?: 'ProjectScheduleDateType' }
      & Pick<ProjectScheduleDateType, 'date_type' | 'date_value'>
    )> }
  )>, sequences: Array<Maybe<(
    { __typename?: 'Sequence' }
    & Pick<Sequence, 'name' | 'sequence_id'>
    & { sequence_dates: Array<Maybe<(
      { __typename?: 'SequenceDateRecord' }
      & SequenceDateRecordsFragmentFragment
    )>>, logistics: Array<Maybe<(
      { __typename?: 'SequenceLogistics' }
      & LogisticsFragmentFragment
    )>> }
  )>> }
);

export type GetBimCoordinatorsScheduleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBimCoordinatorsScheduleQuery = (
  { __typename?: 'Query' }
  & { getBimCoordinationSchedule: (
    { __typename?: 'BimCoordinationSchedule' }
    & { gantt_tasks: Array<Maybe<(
      { __typename?: 'BimCoordinationGanttTask' }
      & Pick<BimCoordinationGanttTask, 'id' | 'text' | 'duration' | 'start_date' | 'project_name' | 'color' | 'parent' | 'open'>
    )>>, calendar_events: Array<Maybe<(
      { __typename?: 'BimCoordinationCalendarEvent' }
      & Pick<BimCoordinationCalendarEvent, 'title' | 'start'>
      & { color?: Maybe<(
        { __typename?: 'ProjectSchedulingCalendarEventColor' }
        & Pick<ProjectSchedulingCalendarEventColor, 'primary' | 'secondary'>
      )> }
    )>> }
  ) }
);

export type GetPreBidModelResourceScheduleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPreBidModelResourceScheduleQuery = (
  { __typename?: 'Query' }
  & { getPreBidModelResourceSchedule: (
    { __typename?: 'PreBidModelResourceSchedule' }
    & { gantt_tasks: Array<Maybe<(
      { __typename?: 'PrebidModelResourceTask' }
      & Pick<PrebidModelResourceTask, 'id' | 'text' | 'duration' | 'start_date' | 'project_name' | 'color' | 'parent' | 'open' | 'not_entered' | 'mbe_planned_completion_date' | 'quality_control_date' | 'model_submission_date' | 'requested_date_sales' | 'proj_id' | 'proj_base_name' | 'model_status' | 'project_status' | 'model_resource' | 'status_color' | 'project_status_color'>
    )>> }
  ), projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { projectStatuses?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectStatus' }
      & Pick<ProjectStatus, 'status_name'>
    )>>> }
  )> }
);

export type GetPreBidQualityControlResourceScheduleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPreBidQualityControlResourceScheduleQuery = (
  { __typename?: 'Query' }
  & { getPreBidQualityControlResourceSchedule: (
    { __typename?: 'PreBidQualityControlResourceSchedule' }
    & { gantt_tasks: Array<Maybe<(
      { __typename?: 'PrebidQualityControlResourceTask' }
      & Pick<PrebidQualityControlResourceTask, 'id' | 'text' | 'duration' | 'start_date' | 'project_name' | 'color' | 'parent' | 'open' | 'not_entered'>
    )>> }
  ) }
);

export type GetProjectSchedulesQueryVariables = Exact<{
  input: GetProjectSchedulesInput;
}>;


export type GetProjectSchedulesQuery = (
  { __typename?: 'Query' }
  & { getProjectSchedules: Array<Maybe<(
    { __typename?: 'ProjectSchedule' }
    & Pick<ProjectSchedule, 'proj_id' | 'project_name' | 'proj_num'>
    & ProjectScheduleFragmentFragment
  )>> }
);

export type GetSingleProjectScheduleQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetSingleProjectScheduleQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'proj_id' | 'project_name' | 'proj_num' | 'schedule_link'>
    & { schedule?: Maybe<(
      { __typename?: 'ProjectSchedule' }
      & ProjectScheduleFragmentFragment
    )>, sequences?: Maybe<Array<Maybe<(
      { __typename?: 'Sequence' }
      & Pick<Sequence, 'name' | 'sequence_color'>
      & { sequence_dates: Array<Maybe<(
        { __typename?: 'SequenceDateRecord' }
        & SequenceDateRecordsFragmentFragment
      )>>, logistics: Array<Maybe<(
        { __typename?: 'SequenceLogistics' }
        & LogisticsFragmentFragment
      )>> }
    )>>> }
  )> }
);

export type LogisticsFragmentFragment = (
  { __typename?: 'SequenceLogistics' }
  & Pick<SequenceLogistics, 'logistics_id' | 'proj_id' | 'sequence_id' | 'shipping_date' | 'log_received_date' | 'truck_number' | 'has_shipped'>
);

export type SequenceDateRecordsFragmentFragment = (
  { __typename?: 'SequenceDateRecord' }
  & Pick<SequenceDateRecord, 'id' | 'sequence_date_id' | 'sequence_id' | 'date_type_id' | 'date_value' | 'date_note' | 'proj_id' | 'is_confirmed'>
  & { date_type?: Maybe<(
    { __typename?: 'SequenceDateType' }
    & Pick<SequenceDateType, 'sequence_date_type_id' | 'date_type' | 'mendix_managed'>
  )> }
);

export type SequenceProjectDataFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'project_name' | 'proj_id' | 'proj_num'>
  & { sequenceTrucksAvailable?: Maybe<Array<Maybe<(
    { __typename?: 'SequenceLogistics' }
    & LogisticsFragmentFragment
  )>>>, sequences?: Maybe<Array<Maybe<(
    { __typename?: 'Sequence' }
    & Pick<Sequence, 'broker_id' | 'coating_type_id' | 'coating_type' | 'delivery_address' | 'description' | 'id' | 'name' | 'fab_comments' | 'full_name' | 'header_status' | 'is_complete' | 'iso_drop_status' | 'mendix_id' | 'non_vic_fab_contact' | 'non_vic_fab_shop' | 'order_number' | 'proj_id' | 'sequence_color' | 'sequence_id' | 'sequence_notes' | 'template_id' | 'template_name'>
    & { notifications: Array<Maybe<(
      { __typename?: 'ProjectNotification' }
      & Pick<ProjectNotification, 'message'>
    )>>, transportation_broker?: Maybe<(
      { __typename?: 'TransportationBroker' }
      & Pick<TransportationBroker, 'broker_id' | 'broker_name' | 'broker_phone'>
    )>, sequence_dates: Array<Maybe<(
      { __typename?: 'SequenceDateRecord' }
      & SequenceDateRecordsFragmentFragment
    )>>, logistics: Array<Maybe<(
      { __typename?: 'SequenceLogistics' }
      & LogisticsFragmentFragment
    )>>, fab_shop?: Maybe<(
      { __typename?: 'FabricationShop' }
      & Pick<FabricationShop, 'fab_shop_id' | 'fab_shop_name'>
    )>, fab_contact?: Maybe<(
      { __typename?: 'FabricationContact' }
      & Pick<FabricationContact, 'fab_contact_id' | 'name'>
    )> }
  )>>> }
);

export type SequenceTemplateFragmentFragment = (
  { __typename?: 'SequenceTemplate' }
  & Pick<SequenceTemplate, 'id' | 'name' | 'template_id'>
  & { project_type?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
  )>, sequence_dates: Array<Maybe<(
    { __typename?: 'SequenceDateType' }
    & Pick<SequenceDateType, 'sequence_date_type_id' | 'date_type'>
  )>> }
);

export type AddNewSequenceMutationVariables = Exact<{
  input: AddNewSequenceInput;
}>;


export type AddNewSequenceMutation = (
  { __typename?: 'Mutation' }
  & { addNewSequence?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type AddSequenceDateMutationVariables = Exact<{
  input: Array<Maybe<AddSequenceDateInput>>;
}>;


export type AddSequenceDateMutation = (
  { __typename?: 'Mutation' }
  & { addSequenceDates?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type AddTemplateDatesMutationVariables = Exact<{
  input: AddTemplateDatesInput;
}>;


export type AddTemplateDatesMutation = (
  { __typename?: 'Mutation' }
  & { addTemplateDates: Array<Maybe<(
    { __typename?: 'SequenceTemplate' }
    & SequenceTemplateFragmentFragment
  )>> }
);

export type AssignTruckMutationVariables = Exact<{
  input: AssignTruckInput;
}>;


export type AssignTruckMutation = (
  { __typename?: 'Mutation' }
  & { assignTruck?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type CopySequenceMutationVariables = Exact<{
  input: CopySequenceInput;
}>;


export type CopySequenceMutation = (
  { __typename?: 'Mutation' }
  & { copySequence?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type CreateSequenceTemplateMutationVariables = Exact<{
  input: CreateSequenceTemplateInput;
}>;


export type CreateSequenceTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createSequenceTemplate?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { availableTemplateProjTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>>>, sequenceTemplates?: Maybe<Array<Maybe<(
      { __typename?: 'SequenceTemplate' }
      & SequenceTemplateFragmentFragment
    )>>> }
  )> }
);

export type DeleteSequenceDateMutationVariables = Exact<{
  input: DeleteSequenceDateInput;
}>;


export type DeleteSequenceDateMutation = (
  { __typename?: 'Mutation' }
  & { deleteSequenceDate?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type DeleteSequenceMutationVariables = Exact<{
  input: DeleteSequenceInput;
}>;


export type DeleteSequenceMutation = (
  { __typename?: 'Mutation' }
  & { deleteSequence: (
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  ) }
);

export type DeleteTemplateDateMutationVariables = Exact<{
  input: DeleteTemplateDateInput;
}>;


export type DeleteTemplateDateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplateDate: Array<Maybe<(
    { __typename?: 'SequenceTemplate' }
    & SequenceTemplateFragmentFragment
  )>> }
);

export type SubmitFabricationRequestMutationVariables = Exact<{
  input: SubmitFabricationRequestInput;
}>;


export type SubmitFabricationRequestMutation = (
  { __typename?: 'Mutation' }
  & { submitFabricationRequest?: Maybe<(
    { __typename?: 'SubmitFabricationRequestResponse' }
    & Pick<SubmitFabricationRequestResponse, 'success' | 'message'>
  )> }
);

export type UnassignTruckMutationVariables = Exact<{
  input: UnassignTruckInput;
}>;


export type UnassignTruckMutation = (
  { __typename?: 'Mutation' }
  & { unassignTruck: (
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  ) }
);

export type UpdateSequenceBaseMutationVariables = Exact<{
  input: UpdateSequenceBaseInput;
}>;


export type UpdateSequenceBaseMutation = (
  { __typename?: 'Mutation' }
  & { updateSequenceBase?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type UpdateSequenceDateMutationVariables = Exact<{
  input: UpdateSequenceDateInput;
}>;


export type UpdateSequenceDateMutation = (
  { __typename?: 'Mutation' }
  & { updateSequenceDate?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type UpdateSequenceLogisticsMutationVariables = Exact<{
  input: UpdateSequenceLogisticsInput;
}>;


export type UpdateSequenceLogisticsMutation = (
  { __typename?: 'Mutation' }
  & { updateSequenceLogistics?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type UpdateSequencesOrderMutationVariables = Exact<{
  input: Array<Maybe<UpdateSequencesOrderInput>>;
}>;


export type UpdateSequencesOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSequencesOrder'>
);

export type UpdateTemplateBaseMutationVariables = Exact<{
  input: UpdateTemplateBaseInput;
}>;


export type UpdateTemplateBaseMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplateBase: Array<Maybe<(
    { __typename?: 'SequenceTemplate' }
    & SequenceTemplateFragmentFragment
  )>> }
);

export type GetFabRequestInfoQueryVariables = Exact<{
  sequence_id: Scalars['Int'];
}>;


export type GetFabRequestInfoQuery = (
  { __typename?: 'Query' }
  & { getFabRequestInfo?: Maybe<(
    { __typename?: 'SequenceFabRequestInfo' }
    & Pick<SequenceFabRequestInfo, 'truckCount' | 'onSiteLocation' | 'onSiteDate' | 'comments' | 'subcontractor' | 'calculatedHours' | 'mendixCalculatedHours'>
    & { pipeInformation?: Maybe<Array<Maybe<(
      { __typename?: 'MuleSoftPipeInfo' }
      & Pick<MuleSoftPipeInfo, 'description' | 'size'>
      & { grooves?: Maybe<Array<Maybe<(
        { __typename?: 'MuleSoftGrooveInfo' }
        & Pick<MuleSoftGrooveInfo, 'endPreparation' | 'count'>
      )>>>, cuts?: Maybe<(
        { __typename?: 'MuleSoftPipeCutInfo' }
        & Pick<MuleSoftPipeCutInfo, 'count'>
      )> }
    )>>>, productInformation?: Maybe<Array<Maybe<(
      { __typename?: 'MuleSoftProductInfo' }
      & { coupling?: Maybe<Array<Maybe<(
        { __typename?: 'MuleSoftCouplingInfo' }
        & { field?: Maybe<Array<Maybe<(
          { __typename?: 'MuleSoftProductInfoType' }
          & Pick<MuleSoftProductInfoType, 'productId' | 'productDescription' | 'size' | 'count'>
        )>>>, shop?: Maybe<Array<Maybe<(
          { __typename?: 'MuleSoftProductInfoType' }
          & Pick<MuleSoftProductInfoType, 'productId' | 'productDescription' | 'size' | 'count'>
        )>>> }
      )>>>, holeCut?: Maybe<Array<Maybe<(
        { __typename?: 'MuleSoftProductInfoType' }
        & Pick<MuleSoftProductInfoType, 'productId' | 'productDescription' | 'size' | 'count'>
      )>>> }
    )>>> }
  )> }
);

export type GetSequenceManagementDataQueryVariables = Exact<{
  proj_id: Scalars['Int'];
}>;


export type GetSequenceManagementDataQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { sequencePipeCoatings?: Maybe<Array<Maybe<(
      { __typename?: 'SequencePipeCoating' }
      & Pick<SequencePipeCoating, 'coating_type_id' | 'coating_type'>
    )>>>, projectTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>>>, availableTemplateProjTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>>>, transportationBrokers?: Maybe<Array<Maybe<(
      { __typename?: 'TransportationBroker' }
      & Pick<TransportationBroker, 'broker_id' | 'broker_name' | 'broker_phone'>
    )>>>, sequenceTemplates?: Maybe<Array<Maybe<(
      { __typename?: 'SequenceTemplate' }
      & Pick<SequenceTemplate, 'id' | 'name' | 'template_id'>
      & { project_type?: Maybe<(
        { __typename?: 'ProjectType' }
        & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
      )>, sequence_dates: Array<Maybe<(
        { __typename?: 'SequenceDateType' }
        & Pick<SequenceDateType, 'sequence_date_type_id' | 'date_type'>
      )>> }
    )>>>, sequenceDates?: Maybe<Array<Maybe<(
      { __typename?: 'SequenceDateType' }
      & Pick<SequenceDateType, 'sequence_date_type_id' | 'date_type'>
    )>>>, fabShops?: Maybe<Array<Maybe<(
      { __typename?: 'FabricationShop' }
      & Pick<FabricationShop, 'fab_shop_id' | 'fab_shop_name'>
    )>>>, fabContacts?: Maybe<Array<Maybe<(
      { __typename?: 'FabricationContact' }
      & Pick<FabricationContact, 'fab_contact_id' | 'name'>
    )>>> }
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & SequenceProjectDataFragmentFragment
  )> }
);

export type GetSequenceTemplateItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSequenceTemplateItemsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { availableTemplateProjTypes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>>> }
  )>, getSequenceTemplates?: Maybe<Array<Maybe<(
    { __typename?: 'SequenceTemplate' }
    & Pick<SequenceTemplate, 'id' | 'name' | 'template_id'>
    & { project_type?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'projtypeid' | 'projtype' | 'projtypesuffix'>
    )>, sequence_dates: Array<Maybe<(
      { __typename?: 'SequenceDateType' }
      & Pick<SequenceDateType, 'sequence_date_type_id' | 'date_type'>
    )>> }
  )>>> }
);

export type GetBimErrorRateQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetBimErrorRateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bimErrorRate'>
);

export type GetBimMetricsDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  coordinators?: Maybe<Array<Maybe<Scalars['Int']>>>;
  markets?: Maybe<Array<Maybe<Scalars['Int']>>>;
}>;


export type GetBimMetricsDataQuery = (
  { __typename?: 'Query' }
  & { bimMetricsData?: Maybe<(
    { __typename?: 'BimDashboardData' }
    & Pick<BimDashboardData, 'efficiency' | 'materialDifference' | 'projectHoursSold' | 'projectMaterialSold'>
    & { bimTablesData?: Maybe<Array<Maybe<(
      { __typename?: 'BimDashboardTable' }
      & Pick<BimDashboardTable, 'project_number' | 'project_name' | 'coordinator' | 'quoted_drawing_hours' | 'bim_hours_used' | 'percent_complete' | 'percent_date' | 'efficiency' | 'total_sold_material' | 'current_material' | 'material_difference' | 'material_difference_number' | 'missing_pn' | 'sequence_count' | 'sequence_errors' | 'sequence_missing_dates' | 'sequence_date_value' | 'sequence_date_type' | 'amount' | 'amount_number' | 'quote_name' | 'status_name'>
    )>>>, upcomingDatesProjects?: Maybe<Array<Maybe<(
      { __typename?: 'BimDashboardTable' }
      & Pick<BimDashboardTable, 'project_number' | 'project_name' | 'coordinator' | 'sequence_name' | 'sequence_date_value' | 'sequence_date_type'>
    )>>>, coordinatorsInfoList?: Maybe<Array<Maybe<(
      { __typename?: 'BimDashboardTable' }
      & Pick<BimDashboardTable, 'coordinator' | 'numberOfProjects' | 'efficiency' | 'material_difference'>
    )>>> }
  )> }
);

export type GetBimSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBimSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { markets?: Maybe<Array<Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'market_id' | 'market'>
    )>>>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'full_name' | 'cps_group' | 'status' | 'group'>
    )>>> }
  )> }
);

export type GetPreBidChartsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetPreBidChartsQuery = (
  { __typename?: 'Query' }
  & { preBidCharts?: Maybe<(
    { __typename?: 'PreBidDashboardData' }
    & Pick<PreBidDashboardData, 'legislativeDaysToQuote' | 'nonLegislativeDaysToQuote' | 'allProjectsDaysToQuote' | 'legislativeHoursToQuote' | 'nonLegislativeHoursToQuote' | 'allProjectsHoursToQuote' | 'departmentTimeToQuote' | 'collectiveHoursToQuote' | 'quotesByServiceType'>
  )> }
);

export type GetPreBidKpIsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetPreBidKpIsQuery = (
  { __typename?: 'Query' }
  & { preBidKPIs?: Maybe<(
    { __typename?: 'PreBidDashboardData' }
    & Pick<PreBidDashboardData, 'activeSuccessRate' | 'modelCompletionSuccessRate' | 'mtoModelCompletionSuccessRate' | 'daysStatusIsEP' | 'averageOfQuotes'>
  )> }
);

export type PtoCalendarDataFragmentFragment = (
  { __typename?: 'PtoCalendarData' }
  & Pick<PtoCalendarData, 'tsid' | 'user_id' | 'full_name' | 'group' | 'cps_group' | 'date' | 'notes' | 'full_day'>
);

export type GetPtoCalendarQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetPtoCalendarQuery = (
  { __typename?: 'Query' }
  & { getPtoCalendar?: Maybe<Array<Maybe<(
    { __typename?: 'PtoCalendarData' }
    & PtoCalendarDataFragmentFragment
  )>>> }
);

export type GetPtoDashboardSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPtoDashboardSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & Pick<ProjectSelectOptions, 'cpsGroups' | 'userGroups'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'full_name' | 'cps_group' | 'group'>
    )>>> }
  )> }
);

export type TrainingContactFragmentFragment = (
  { __typename?: 'TrainingContact' }
  & Pick<TrainingContact, 'contact_id' | 'training_id' | 'contact_name' | 'contact_email' | 'contact_company'>
);

export type TrainingMarketFragmentFragment = (
  { __typename?: 'TrainingMarket' }
  & Pick<TrainingMarket, 't_market_id' | 't_market'>
);

export type TrainingTypeFragmentFragment = (
  { __typename?: 'TrainingType' }
  & Pick<TrainingType, 'type_var' | 'type' | 'type_id'>
);

export type VtfrTrainingDataFragmentFragment = (
  { __typename?: 'VtfrTrainingsData' }
  & Pick<VtfrTrainingsData, 'training_id' | 'trainer' | 'type' | 'duration_num' | 'duration_type' | 'duration' | 'company' | 'salesman_name' | 'salesman_id' | 'pres_date' | 'notes' | 'status' | 'bill_amt' | 'bill_amount' | 'currency' | 'market' | 'contact' | 'user_id' | 'type_id' | 'customer_id' | 'expenses' | 'sold' | 'quote'>
  & { markets?: Maybe<Array<Maybe<(
    { __typename?: 'TrainingMarket' }
    & TrainingMarketFragmentFragment
  )>>>, contacts?: Maybe<Array<Maybe<(
    { __typename?: 'TrainingContact' }
    & TrainingContactFragmentFragment
  )>>> }
);

export type VtfrTrainingsMetricsFragmentFragment = (
  { __typename?: 'VtfrTrainingsMetrics' }
  & Pick<VtfrTrainingsMetrics, 'rem_pres' | 'on_pres' | 'corp_pres' | 'event_pres' | 'rem_supp' | 'fab_cell' | 'pd_trn' | 'sa_trn' | 'trainer' | 'total'>
);

export type AddVtfrTrainingLogMutationVariables = Exact<{
  input: AddVtfrTrainingLogInput;
}>;


export type AddVtfrTrainingLogMutation = (
  { __typename?: 'Mutation' }
  & { addVtfrTrainingLog?: Maybe<Array<Maybe<(
    { __typename?: 'VtfrTrainingsData' }
    & VtfrTrainingDataFragmentFragment
  )>>> }
);

export type DeleteVtfrTrainingLogMutationVariables = Exact<{
  training_id: Scalars['Int'];
}>;


export type DeleteVtfrTrainingLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVtfrTrainingLog'>
);

export type UpdateVtfrTrainingLogMutationVariables = Exact<{
  input: UpdateVtfrTrainingLogInput;
}>;


export type UpdateVtfrTrainingLogMutation = (
  { __typename?: 'Mutation' }
  & { updateVtfrTrainingLog?: Maybe<Array<Maybe<(
    { __typename?: 'VtfrTrainingsData' }
    & VtfrTrainingDataFragmentFragment
  )>>> }
);

export type GetVtfrTrainingDashboardSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVtfrTrainingDashboardSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { projectSelectOptions?: Maybe<(
    { __typename?: 'ProjectSelectOptions' }
    & { presenters?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'user_id' | 'full_name'>
    )>>>, trainingsTypes?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingType' }
      & TrainingTypeFragmentFragment
    )>>>, customers?: Maybe<Array<Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'customer_id' | 'customer' | 'c_type_id' | 'type'>
    )>>>, customerTypes?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<CustomerType, 'c_type_id' | 'c_type'>
    )>>>, salesmen?: Maybe<Array<Maybe<(
      { __typename?: 'Salesman' }
      & Pick<Salesman, 'autoID' | 'name' | 'salesregion_name' | 'salesregion_id' | 'group_name' | 'groupID' | 'division' | 'divisionID' | 'metro' | 'metroID'>
    )>>>, currencies?: Maybe<Array<Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'currency'>
    )>>>, trainingMarkets?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingMarket' }
      & Pick<TrainingMarket, 't_market_id' | 't_market' | 'checked'>
    )>>>, markets?: Maybe<Array<Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'market_id' | 'market'>
    )>>> }
  )> }
);

export type GetVtfrTrainingsCalendarDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVtfrTrainingsCalendarDataQuery = (
  { __typename?: 'Query' }
  & { vtfrTrainingsCalendar?: Maybe<Array<Maybe<(
    { __typename?: 'VtfrTrainingsData' }
    & VtfrTrainingDataFragmentFragment
  )>>> }
);

export type GetVtfrTrainingsMetricsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetVtfrTrainingsMetricsQuery = (
  { __typename?: 'Query' }
  & { vtfrTrainingsMetrics?: Maybe<(
    { __typename?: 'VtfrTrainingsByTrainerMetrics' }
    & { trainingsTypes?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingType' }
      & TrainingTypeFragmentFragment
    )>>>, metrics?: Maybe<Array<Maybe<(
      { __typename?: 'VtfrTrainingsMetrics' }
      & VtfrTrainingsMetricsFragmentFragment
    )>>> }
  )> }
);

export type GetVtfrTrainingsTableDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetVtfrTrainingsTableDataQuery = (
  { __typename?: 'Query' }
  & { vtfrTrainingsTable?: Maybe<Array<Maybe<(
    { __typename?: 'VtfrTrainingsData' }
    & VtfrTrainingDataFragmentFragment
  )>>> }
);

export type GetVdcHighlightsDataQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type GetVdcHighlightsDataQuery = (
  { __typename?: 'Query' }
  & { getVdcHighlightsData?: Maybe<Array<Maybe<(
    { __typename?: 'VdcHighlightsData' }
    & Pick<VdcHighlightsData, 'service' | 'location' | 'previous_count' | 'previous_hours' | 'previous_ytd_count' | 'previous_ytd_hours' | 'ytd_count' | 'ytd_hours' | 'count_percentage_change' | 'hours_percentage_change' | 'count_forecast' | 'hours_forecast'>
  )>>> }
);

export type GetVicToolSalesByQuarterQueryVariables = Exact<{
  toolName: Scalars['String'];
}>;


export type GetVicToolSalesByQuarterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vicToolSalesByQuarter'>
);

export type GetVicToolSalesByYearQueryVariables = Exact<{
  toolName: Scalars['String'];
}>;


export type GetVicToolSalesByYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vicToolSalesByYear'>
);

export type GetVicToolSalesTableQueryVariables = Exact<{
  toolName: Scalars['String'];
}>;


export type GetVicToolSalesTableQuery = (
  { __typename?: 'Query' }
  & { vicToolSalesTable?: Maybe<Array<Maybe<(
    { __typename?: 'VicToolsDashboardData' }
    & Pick<VicToolsDashboardData, 'order_id' | 'order_date' | 'customer' | 'city' | 'state' | 'country' | 'customer_contact' | 'sales_rep' | 'vicToolVersion2030' | 'vicToolVersion2029' | 'vicToolVersion2028' | 'vicToolVersion2027' | 'vicToolVersion2026' | 'vicToolVersion2025' | 'vicToolVersion2024' | 'vicToolVersion2023' | 'vicToolVersion2022' | 'vicToolVersion2021' | 'vicToolVersion2020' | 'vicToolVersion2019' | 'vicToolVersion2018' | 'vicToolVersion2017' | 'vicToolVersion2016' | 'vicToolVersion2015' | 'vicToolVersion2014' | 'vicToolVersionv14' | 'vicToolVersionv15' | 'vicToolVersionv16' | 'vicToolVersionv17' | 'vicToolVersionv18' | 'vicToolVersionv19' | 'vicToolVersionv20' | 'vicToolVersionv21' | 'vicToolVersionv22' | 'vicToolVersionv23' | 'vicToolVersionv24' | 'vicToolVersionv25' | 'vicToolVersionv26' | 'vicToolVersionv27' | 'vicToolVersionv28' | 'vicToolVersionv29' | 'vicToolVersionv30'>
  )>>> }
);

export type GetVicToolTotalSalesQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  toolName: Scalars['String'];
}>;


export type GetVicToolTotalSalesQuery = (
  { __typename?: 'Query' }
  & { vicToolTotalSales?: Maybe<(
    { __typename?: 'VicToolsDashboardData' }
    & Pick<VicToolsDashboardData, 'totalSales' | 'totalCustomers' | 'averageLicenses'>
  )> }
);

export type GetVicToolsVersionsQueryVariables = Exact<{
  toolName: Scalars['String'];
}>;


export type GetVicToolsVersionsQuery = (
  { __typename?: 'Query' }
  & { vicToolsVersions?: Maybe<Array<Maybe<(
    { __typename?: 'VicToolVersion' }
    & Pick<VicToolVersion, 'title'>
  )>>> }
);

export type GetVtfrDownloadsByQuarterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVtfrDownloadsByQuarterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vtfrDownloadsByQuarter'>
);

export type GetVtfrRevenueByYearQueryVariables = Exact<{
  toolName: Scalars['String'];
}>;


export type GetVtfrRevenueByYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'vtfrRevenueByYear'>
);

export type GetVtfrTotalDownloadsQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetVtfrTotalDownloadsQuery = (
  { __typename?: 'Query' }
  & { vtfrTotalDownloads?: Maybe<(
    { __typename?: 'VicToolsDashboardData' }
    & Pick<VicToolsDashboardData, 'totalVTFRDownloads'>
  )> }
);

export type GetVtfrTotalRevenueQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetVtfrTotalRevenueQuery = (
  { __typename?: 'Query' }
  & { vtfrTotalRevenue?: Maybe<(
    { __typename?: 'VicToolsDashboardData' }
    & Pick<VicToolsDashboardData, 'totalExpectedRevenue' | 'discountAverage' | 'totalRevenue'>
  )> }
);

export type GetVtfrTotalWebPresentationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVtfrTotalWebPresentationsQuery = (
  { __typename?: 'Query' }
  & { vtfrWebPresentations?: Maybe<(
    { __typename?: 'VicToolsDashboardData' }
    & Pick<VicToolsDashboardData, 'sessions' | 'hours'>
  )> }
);

export type GetVdcTvDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVdcTvDataQuery = (
  { __typename?: 'Query' }
  & { getVdcTvData: (
    { __typename?: 'VdcTvData' }
    & { upcomingDatesProjects?: Maybe<Array<Maybe<(
      { __typename?: 'BimDashboardTable' }
      & Pick<BimDashboardTable, 'project_number' | 'project_name' | 'sequence_name' | 'sequence_date_value'>
    )>>>, materialDifference?: Maybe<Array<Maybe<(
      { __typename?: 'BimDashboardTable' }
      & Pick<BimDashboardTable, 'project_number' | 'project_name' | 'material_difference_dollars' | 'material_difference' | 'status'>
    )>>> }
  ) }
);

export type UserRecentProjectsFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'project_name' | 'proj_num' | 'proj_id' | 'projid' | 'is_favorite'>
  & { changeOrders?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectChangeOrder' }
    & Pick<ProjectChangeOrder, 'change_order_id' | 'change_order_status_id'>
  )>>>, coordinator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'full_name'>
  )>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'customer'>
  )>, software_tracking?: Maybe<(
    { __typename?: 'SoftwareTracking' }
    & Pick<SoftwareTracking, 'rvtVersion'>
  )> }
);

export type UpdateRecentUserProjectsMutationVariables = Exact<{
  input: UpdateRecentUserProjectsInput;
}>;


export type UpdateRecentUserProjectsMutation = (
  { __typename?: 'Mutation' }
  & { updateRecentUserProjects: Array<Maybe<(
    { __typename?: 'Project' }
    & UserRecentProjectsFragmentFragment
  )>> }
);

export type RecentUserProjectsQueryVariables = Exact<{
  user_id: Scalars['Int'];
}>;


export type RecentUserProjectsQuery = (
  { __typename?: 'Query' }
  & { recentUserProjects?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & UserRecentProjectsFragmentFragment
  )>>> }
);

export type GetAllUsersGroupedByUserGroupQueryVariables = Exact<{
  input?: Maybe<GetAllUsersInput>;
}>;


export type GetAllUsersGroupedByUserGroupQuery = (
  { __typename?: 'Query' }
  & { getAllUsersGroupedByUserGroup: Array<Maybe<(
    { __typename?: 'UsersGroupedByUserGroup' }
    & Pick<UsersGroupedByUserGroup, 'group'>
    & { users: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'cps_group' | 'currency' | 'date_added' | 'email_address' | 'employeeid' | 'first_name' | 'full_name' | 'group' | 'last_name' | 'picture' | 'presenter' | 'region_id' | 'resource_color' | 'status' | 'supervisor_id' | 'user_id' | 'user_type'>
    )>> }
  )>> }
);

export const CustomerContactFragmentFragmentDoc = gql`
    fragment CustomerContactFragment on CustomerContact {
  _id
  cell_phone
  company_name
  contact_type
  email
  name
  office_phone
  title
  address
}
    `;
export const ProjectContactsFragmentFragmentDoc = gql`
    fragment ProjectContactsFragment on Project {
  proj_id
  customer_contacts {
    ...CustomerContactFragment
  }
}
    ${CustomerContactFragmentFragmentDoc}`;
export const ProjectNoteTypeFragmentFragmentDoc = gql`
    fragment ProjectNoteTypeFragment on ProjectNoteType {
  note_type_applied_id
  note_id
  note_type_id
  note_type
}
    `;
export const ProjectNoteHistoryFragmentFragmentDoc = gql`
    fragment ProjectNoteHistoryFragment on ProjectNoteHistory {
  note_history_id
  note_id
  note
  date_added
  added_by
}
    `;
export const ProjectNoteFragmentFragmentDoc = gql`
    fragment ProjectNoteFragment on ProjectNote {
  projid
  note_id
  note
  date_added
  user_id
  added_by
  appliedNoteTypes {
    ...ProjectNoteTypeFragment
  }
  availableNoteTypes {
    ...ProjectNoteTypeFragment
  }
  history {
    ...ProjectNoteHistoryFragment
  }
}
    ${ProjectNoteTypeFragmentFragmentDoc}
${ProjectNoteHistoryFragmentFragmentDoc}`;
export const ProjectHealthStatusFragmentFragmentDoc = gql`
    fragment ProjectHealthStatusFragment on ProjectHealthStatus {
  ph_status_id
  ph_status
}
    `;
export const ProjectBimBaseFragmentFragmentDoc = gql`
    fragment ProjectBimBaseFragment on Project {
  proj_id
  projid
  project_name
  proj_num
  project_distributor_value
  active_status
  notes {
    ...ProjectNoteFragment
  }
  projectBIM {
    closeout_meeting_date
    contractor_turnover_date
    customer_care_contact {
      rep_id
      name
    }
    customer_care_id
    drawing_percent_complete
    expected_drawing_completion_date
    purchase_order_date
    drawing_hours_info {
      efficiency_rating
      current_drawing_hours
    }
    project_health_status {
      ...ProjectHealthStatusFragment
    }
  }
  quote_hours_info {
    quoted_drawing_hours
    quoted_drawing_revenue
  }
}
    ${ProjectNoteFragmentFragmentDoc}
${ProjectHealthStatusFragmentFragmentDoc}`;
export const ChangeOrderFinancesFragmentFragmentDoc = gql`
    fragment ChangeOrderFinancesFragment on Finances {
  offeringid
  offering
  count
  value_number
  value_string
  dist_markup
  dist_cost
  dist_cost_string
  cust_markup
  cust_cost
  cust_cost_string
  suggestedDistValue
  suggestedCustValue
}
    `;
export const ChangeOrderFinancesHoursFragmentFragmentDoc = gql`
    fragment ChangeOrderFinancesHoursFragment on FinancesHours {
  finance_hours_type_id
  finance_hours_type
  value
}
    `;
export const AdminChangeOrderFragmentFragmentDoc = gql`
    fragment AdminChangeOrderFragment on ProjectChangeOrder {
  change_order_id
  proj_id
  projid
  quoteid
  areaid
  proj_num
  project_name
  change_order_name
  change_order_description
  change_order_drawing_num
  change_order_status
  change_order_status_id
  open_duration
  finance_revision_name
  finance_revision_id
  change_order_number
  change_order_price
  bimHoursData {
    co_bim_hour_id
    bim_hour_type_id
    hours
  }
  comparedBoms {
    co_compared_bom_id
    system_id
    pre_revision_id
    post_revision_id
  }
  changeOrderNotes {
    note_id
    note
    added_by
    user_id
    date_added
  }
  changeOrderSuggestedValues {
    co_suggested_value_id
    offeringid
    value
    change_order_id
    finance_revision_id
  }
  finances {
    ...ChangeOrderFinancesFragment
  }
  financesHours {
    ...ChangeOrderFinancesHoursFragment
  }
  vdcHours
  fabHours
  materialCost
  fabricationCost
  vdcCost
  transportationCost
  additionalCost
  changeOrderNotesString
  updated_po
}
    ${ChangeOrderFinancesFragmentFragmentDoc}
${ChangeOrderFinancesHoursFragmentFragmentDoc}`;
export const ChangeOrderNoteFragmentFragmentDoc = gql`
    fragment ChangeOrderNoteFragment on ChangeOrderNote {
  note_id
  note
  user_id
  name
  date_added
}
    `;
export const ProjectChangeOrderFragmentFragmentDoc = gql`
    fragment ProjectChangeOrderFragment on ProjectChangeOrder {
  change_order_id
  proj_id
  projid
  quoteid
  areaid
  proj_num
  project_name
  change_order_name
  change_order_description
  change_order_status
  change_order_status_id
  change_order_drawing_num
  finance_revision_name
  finance_revision_id
  change_order_number
  change_order_price
  bimHoursData {
    co_bim_hour_id
    bim_hour_type_id
    hours
  }
  comparedBoms {
    co_compared_bom_id
    system_id
    pre_revision_id
    post_revision_id
  }
  changeOrderNotes {
    note_id
    note
    added_by
    user_id
    date_added
  }
  changeOrderSuggestedValues {
    co_suggested_value_id
    offeringid
    value
    change_order_id
    finance_revision_id
  }
  finances {
    ...ChangeOrderFinancesFragment
  }
  financesHours {
    ...ChangeOrderFinancesHoursFragment
  }
}
    ${ChangeOrderFinancesFragmentFragmentDoc}
${ChangeOrderFinancesHoursFragmentFragmentDoc}`;
export const AdminComponentFragmentFragmentDoc = gql`
    fragment AdminComponentFragment on Component {
  component_id
  partnumber_us
  size
  size_desc_m3
  concat_component_desc
  partnumber_global
  nominal_imp
  component_desc_m3
  component_desc
  style_desc
  custom_item
  image_path
  image_id
  style
  labor_mcaa
  weight
  size_code
  gasket_type
  group_id
  price_usd
  price_eur
  price_gbp
  price_jpy
}
    `;
export const ComponentAlternativeFragmentFragmentDoc = gql`
    fragment ComponentAlternativeFragment on ComponentAlternative {
  title
  rowNum
  subParts {
    part_number
    description
    quantity
  }
}
    `;
export const ComponentSizeFragmentFragmentDoc = gql`
    fragment ComponentSizeFragment on Component {
  size
  size_desc_m3
  size_code
}
    `;
export const ProfilePartNumbersFragmentFragmentDoc = gql`
    fragment ProfilePartNumbersFragment on ProfilePartNumbers {
  component_id
  link_id
  rev_size
  rev_desc
  partnumber_us
  partnumber_global
  linked_size
  linked_desc
  name
  linked_date
}
    `;
export const CurrencyConversionRateFragmentFragmentDoc = gql`
    fragment CurrencyConversionRateFragment on CurrencyConversionRate {
  rate_id
  date
  currency
  rate
}
    `;
export const ProjectProfilePartNumbersFragmentFragmentDoc = gql`
    fragment ProjectProfilePartNumbersFragment on ProfilePartNumbers {
  component_id
  link_id
  rev_size
  rev_desc
  partnumber_us
  partnumber_global
  linked_size
  linked_desc
  name
  linked_date
}
    `;
export const ProjectProfileFragmentFragmentDoc = gql`
    fragment ProjectProfileFragment on ProjectProfiles {
  project_profile_id
  project_profile
  project_profile_desc
  user_id
  user
  proj_id
  project_desc
}
    `;
export const SoldAdminProjetFragmentFragmentDoc = gql`
    fragment SoldAdminProjetFragment on SoldAdminProject {
  index
  proj_id
  project_number
  project_name
  price_list_name
  coordinator
  sequence_count
  sequence_errors
  sequence_missing_dates
  line_sales
  regional_sales
  divisional_sales
  contractor_name
  market
  distributor_po
  buyer_acceptance_date
  multiplier_added
  finance_complete
  sold_date
  expected_completion_date
  onsite_date
  close_meeting_date
  ph_status
  total_sold_material
  current_material
  material_difference
  percentage_material_difference
  quoted_drawing_hours
  bim_hours_used
  bim_hours_percentage
  drawing_percentage_complete
  estimated_bim_hours
  efficiency
  truck_count
  transportation
  fab_hours_quoted
  fab_hours_actual
  difference_fab_hours
  koil_kit_count
  koil_kit_value
  buffer_dollars
  material_buffer
  percentage_buffer_used
  total_project_quoted
  total_sold_project
  total_project_actual
  percentage_total_project_difference
  total_open_change_orders
  total_internal_change_orders
  finance_notes
  signed_cos
  received_revised_pos
  percent_billed
  number_of_billings
  open
  number_of_areas
}
    `;
export const AdminInclusionFragmentFragmentDoc = gql`
    fragment AdminInclusionFragment on Inclusion {
  inclusion_id
  row_id
  master_class
  sub_class
  description
  default_value
  order
  active
  pha_fab
  pha_craft
  drawing
  municipal_fab
  municipal_craft
  oil_gas_fab
  oil_gas_craft
  other_visible_when_selected
  oil_gas_visible_when_selected
}
    `;
export const AdminSalesmanFragmentFragmentDoc = gql`
    fragment AdminSalesmanFragment on Salesman {
  name
  email
  salesmanID
  salesregion_name
  salesregion_id
  group_name
  group_id
  metro_id
  metro
  reports_to
  reports_to_name
  division
  divisionID
  inactive
}
    `;
export const SalesmanMarkupFragmentFragmentDoc = gql`
    fragment SalesmanMarkupFragment on SalesmanMarkup {
  salesman_markup_id
  salesmanID
  offeringid
  offering
  dist_markup
  cust_markup
}
    `;
export const SalesmanMultiplierFragmentFragmentDoc = gql`
    fragment SalesmanMultiplierFragment on SalesmanMultiplier {
  salesman_multiplier_id
  salesmanID
  group_id
  group_desc
  value
}
    `;
export const ComponentFragmentFragmentDoc = gql`
    fragment ComponentFragment on Component {
  component_id
  partnumber_us
  partnumber_m3
  size
  concat_component_desc
  partnumber_global
  nominal_imp
  component_desc_m3
  component_desc
  style_desc
  custom_item
  image_path
  image_id
  style
  labor_mcaa
  weight
  size_code
  gasket_type
  group_id
  price_usd
  price_eur
  price_gbp
  price_jpy
  part_size
  active
  component_cost
  description
  bill_id
  size_desc_m3
}
    `;
export const GroupMultipliersFragmentFragmentDoc = gql`
    fragment GroupMultipliersFragment on ComponentGroup {
  group_id
  group_char
  group_desc
  sort_id
  value
}
    `;
export const HeaderFragmentFragmentDoc = gql`
    fragment HeaderFragment on Header {
  header_id
  proj_id
  system_id
  price_list_id
  price_list_name
  header_name
  assembly_name
  bag_tag
  system_abbr
  mark
  area
  sequence
  zone
  pipe_size
  outlet_size
  outlet_size2
  outlet_size3
  outlet_size4
  outlet_size5
  outlet_size6
  three_quarter_tap
  one_tap
  one_quarter_tap
  one_half_tap
  two_tap
  pipe_length
  pipe_length_ft
  pipe_length_in
  outlet_count
  tap_count
  price
  header_cost
  header_cost_ep
  current_location
  header_coating_id
  header_coating
  header_lining_id
  header_lining
  header_material_id
  header_material
}
    `;
export const PartNumberProfileFragmentFragmentDoc = gql`
    fragment PartNumberProfileFragment on PartNumberProfiles {
  profile_id
  profile
  profile_desc
  user_id
  user
  region_id
}
    `;
export const ProjectBillItemFragmentFragmentDoc = gql`
    fragment ProjectBillItemFragment on ProjectBillItem {
  bill_id
  component_id
  proj_id
  system_id
  revision_id
  vh_id
  seq_id
  tap_count
  quantity
  multiplier
  rev_desc
  rev_size
  pipe_length
  system_abbr
  assembly_name
  mark
  end_prep
  vic_system_pt
  vic_area_pt
  vic_zone
  vic_field_material
  vic_field_cut
  vic_note
  vic_bag_tag
  mark_up
  vic_seq
  part_number
  weight
  labor_mcaa
  style_desc
  component_desc
  size_desc
  size
  item_price
  net_price
  extended_net_price
  image_path
}
    `;
export const ProjectPricingFragmentFragmentDoc = gql`
    fragment ProjectPricingFragment on ProjectPricing {
  price
  size
  multiplier
  component_id
  project_pricing_id
  description
  partnumber_us
  partnumber_global
  partnumber
}
    `;
export const RevisionFragmentFragmentDoc = gql`
    fragment RevisionFragment on Revision {
  revision_id
  system_id
  revision_date
  revision_name
  user_id
  forissue
  latest_sold
  actual_sold
}
    `;
export const SystemsFragmentFragmentDoc = gql`
    fragment SystemsFragment on System {
  system_id
  system
  system_desc
  market_id
  proj_id
  revision_id
  dwg
  area
  profile_id
  project_profile_id
  quoteid
  quotename
  gasket_id
}
    `;
export const SystemQuoteFragmentFragmentDoc = gql`
    fragment SystemQuoteFragment on SystemQuote {
  quoteid
  cost
  hours
  quotedate
  quotename
  projname
  projno
}
    `;
export const KeyPerformanceEquipMetricFragmentFragmentDoc = gql`
    fragment KeyPerformanceEquipMetricFragment on KeyPerformanceEquipMetric {
  equipment
  sold
  final
}
    `;
export const KeyPerformanceProjMetricFragmentFragmentDoc = gql`
    fragment KeyPerformanceProjMetricFragment on KeyPerformanceProjMetric {
  metric
  sold
  current
  status
  difference
}
    `;
export const PipingSystemMetricFragmentFragmentDoc = gql`
    fragment PipingSystemMetricFragment on PipingSystemMetric {
  pipingSystem
}
    `;
export const QuickStatsMetricFragmentFragmentDoc = gql`
    fragment QuickStatsMetricFragment on QuickStatsMetric {
  metric
  current
  isPercentage
}
    `;
export const EstimatingAreaBaseFieldsFragmentFragmentDoc = gql`
    fragment EstimatingAreaBaseFieldsFragment on ProjectArea {
  area
  areaid
  estimate
  changeorder
  marked_for_delete
  status
  schedule_impact
  updated_po
}
    `;
export const MasterQuoteFieldsFragmentFragmentDoc = gql`
    fragment MasterQuoteFieldsFragment on QuoteField {
  quote_field_id
  worksheet_id
  section_id
  field
  drop_count
  man_hours
  totalled
  order_number
  active
}
    `;
export const ProjectFabViewFragmentFragmentDoc = gql`
    fragment ProjectFabViewFragment on Project {
  proj_id
  project_name
  areas {
    area
    areaid
    quoteid
    changeorder
    estimate
    areaFabrication {
      couplings {
        size
        qty
        diameterInches
      }
      coupling_total_hours
      coupling_count
      mech_hours
      mech_count
      fabrication_allowance
      fab_hours
      fab_total_plus10
      fab_total_no_overhead
      total_fab_new_estimating
      fab_labor_rate
      region_id
      pipecubicFootagetMultiplier
      shopMultiplier
      truckCubicFootage
      lessThan12Diam
      twelveTo16Diam
      eighteenPlusDiam
      lessThan12Shop
      twelveTo16Shop
      eighteenPlusShop
      lessThan12Hours
      twelveTo16Hours
      eighteenPlusHours
      pipe {
        linear_feet
        size
        cubic_feet
      }
      groove_count
      pipeCubicFeetNoMultiplier
      pipeCubicPlus25
      truck_count
      pallet_cost
      pallet_count
      pump_drop_count
      ahu_count
      header_count
      quoted_fab_hours
      quoted_fab_cost
      quoted_truck_count
    }
  }
}
    `;
export const ChangeOrderStatusTypesFragmentFragmentDoc = gql`
    fragment ChangeOrderStatusTypesFragment on ChangeOrderStatus {
  status_name
  status_id
}
    `;
export const FinancesFragmentFragmentDoc = gql`
    fragment FinancesFragment on Finances {
  finance_data_id
  finance_revision_id
  proj_id
  quoteid
  offeringid
  offering
  count
  buffer
  value
  value_string
  value_with_buffer
  value_with_buffer_string
  dist_markup
  dist_cost
  dist_cost_string
  cust_markup
  cust_cost
  cust_cost_string
  group_as_material
  group_as_transportation
}
    `;
export const FinancesHoursFragmentFragmentDoc = gql`
    fragment FinancesHoursFragment on FinancesHours {
  finance_hours_id
  finance_hours_type_id
  finance_hours_type
  value
  finance_revision_id
  proj_id
  quoteid
}
    `;
export const OfferingsFragmentFragmentDoc = gql`
    fragment OfferingsFragment on ProjectOffering {
  offeringid
  offering
}
    `;
export const ProjTotalsColumnsLabelsFragmentFragmentDoc = gql`
    fragment ProjTotalsColumnsLabelsFragment on ProjTotalsColumnsLabels {
  label
  property
  billing_category
}
    `;
export const QuoteRevisionsFragmentFragmentDoc = gql`
    fragment QuoteRevisionsFragment on QuoteRevision {
  finance_revision_id
  finance_revision_name
  finance_revision_date
  proj_id
  quoteid
  sold
}
    `;
export const QuotesFragmentFragmentDoc = gql`
    fragment QuotesFragment on PhaseQuote {
  quote_id
  quote_name
  areaid
  change_order
  change_order_id
  schedule_impact
  updated_po
  status_id
}
    `;
export const TotalFinancesFragmentFragmentDoc = gql`
    fragment TotalFinancesFragment on TotalFinances {
  type
  fab
  risers
  kkto_vdc
  kkto_sales
  vis
  skids
  material
  transportation
  headers
  vipds
  ahus
  vdc
  pipe
  pipe_support
  third_party_fab
  third_party_welding
  standard_prv
  vic_tool
  drawing_hours
  fabrication_hours
  engineering_services
  total
}
    `;
export const TrackingActivityFragmentFragmentDoc = gql`
    fragment TrackingActivityFragment on TrackingActivity {
  activity_id
  activity_type_id
  activity_type {
    description
    activity_group_id
    operation_name
    activity_type_id
  }
  additional_info
  description
  proj_id
  timestamp
  user_id
  user_name
}
    `;
export const ProjectLocationFragmentFragmentDoc = gql`
    fragment ProjectLocationFragment on ProjectLocation {
  address
  latitude
  longitude
  country
  region
  city
  state
}
    `;
export const ProjectViewCoreFragmentFragmentDoc = gql`
    fragment ProjectViewCoreFragment on Project {
  bid_date
  coordinator {
    user_id
    full_name
    employeeid
  }
  cpsservice
  current_project_type
  current_project_type_id
  currency
  customer {
    customer
  }
  datesubmitted
  distributor
  distributor_id
  domestic_requirements {
    requirement
    requirement_note
  }
  engineerid
  estimator {
    user_id
    full_name
  }
  is_kkto_project
  kkto_status
  market {
    market
    market_id
  }
  multiplier_date
  nda_required
  order_number
  postbid
  price_list {
    price_list_name
  }
  probofsale
  statusHistory {
    status_id
    status
    reason
    status_date
  }
  project_name
  projid
  proj_id
  proj_status
  proj_num
  po_date
  poaa
  poaa_date
  po_amount
  proj_type {
    project_type_id
    project_type
  }
  region_id
  region_pn
  standard
  regioncode
  salesman {
    name
    group_name
    country
    metro
    salesregion_name
  }
  schedule_link
  union_notunion
  worksheet_date
  sold_financials_updated
  user_id
}
    `;
export const TeamMembersFragmentFragmentDoc = gql`
    fragment TeamMembersFragment on User {
  user_id
  full_name
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  user_id
  full_name
  first_name
  last_name
  email_address
  employeeid
  region_id
  status
  cps_group
  user_type
}
    `;
export const EquipmentFragmentFragmentDoc = gql`
    fragment EquipmentFragment on EquipmentItem {
  equipment_id
  type
  mark
}
    `;
export const AreaLimitFragmentFragmentDoc = gql`
    fragment AreaLimitFragment on AreaLimit {
  area
  areaid
  value
  selected
  equipments_modified
  dwg
  equipments {
    ...EquipmentFragment
  }
}
    ${EquipmentFragmentFragmentDoc}`;
export const InclusionFragmentFragmentDoc = gql`
    fragment InclusionFragment on Inclusion {
  inclusion_id
  value
  default_value
}
    `;
export const QuoteNoteFragmentFragmentDoc = gql`
    fragment QuoteNoteFragment on QuoteNote {
  master_class
  sub_class
  value
}
    `;
export const QuoteDocRevisionFragmentFragmentDoc = gql`
    fragment QuoteDocRevisionFragment on Revision {
  revision_name
  revision_id
}
    `;
export const SequenceDateRecordsFragmentFragmentDoc = gql`
    fragment SequenceDateRecordsFragment on SequenceDateRecord {
  id
  sequence_date_id
  sequence_id
  date_type_id
  date_type {
    sequence_date_type_id
    date_type
    mendix_managed
  }
  date_value
  date_note
  proj_id
  is_confirmed
}
    `;
export const LogisticsFragmentFragmentDoc = gql`
    fragment LogisticsFragment on SequenceLogistics {
  logistics_id
  proj_id
  sequence_id
  shipping_date
  log_received_date
  truck_number
  has_shipped
}
    `;
export const ProjectScheduleFragmentFragmentDoc = gql`
    fragment ProjectScheduleFragment on ProjectSchedule {
  coordinator {
    user_id
    full_name
    resource_color
  }
  bim_schedule {
    buyer_acceptance_agreement_date {
      date_type
      date_value
    }
    kickoff_meeting_date {
      date_type
      date_value
    }
    purchase_order_date {
      date_type
      date_value
    }
    expected_drawing_completion_date {
      date_type
      date_value
    }
    closeout_meeting_date {
      date_type
      date_value
    }
    contractor_turnover_date {
      date_type
      date_value
    }
  }
  estimating_schedule {
    vdc_received_date {
      date_type
      date_value
    }
    bid_date {
      date_type
      date_value
    }
    model_resource_start_date {
      date_type
      date_value
    }
    model_requested_completion_date {
      date_type
      date_value
    }
    model_resource_planned_completion_date {
      date_type
      date_value
    }
    quality_control_date {
      date_type
      date_value
    }
    estimated_completion_date {
      date_type
      date_value
    }
    requested_sales_date {
      date_type
      date_value
    }
  }
  sequences {
    name
    sequence_id
    sequence_dates {
      ...SequenceDateRecordsFragment
    }
    logistics {
      ...LogisticsFragment
    }
  }
}
    ${SequenceDateRecordsFragmentFragmentDoc}
${LogisticsFragmentFragmentDoc}`;
export const SequenceProjectDataFragmentFragmentDoc = gql`
    fragment SequenceProjectDataFragment on Project {
  project_name
  proj_id
  proj_num
  sequenceTrucksAvailable {
    ...LogisticsFragment
  }
  sequences {
    broker_id
    coating_type_id
    coating_type
    delivery_address
    description
    id
    name
    fab_comments
    full_name
    header_status
    is_complete
    iso_drop_status
    mendix_id
    non_vic_fab_contact
    non_vic_fab_shop
    order_number
    proj_id
    sequence_color
    sequence_id
    sequence_notes
    template_id
    template_name
    notifications {
      message
    }
    transportation_broker {
      broker_id
      broker_name
      broker_phone
    }
    sequence_dates {
      ...SequenceDateRecordsFragment
    }
    logistics {
      ...LogisticsFragment
    }
    fab_shop {
      fab_shop_id
      fab_shop_name
    }
    fab_contact {
      fab_contact_id
      name
    }
  }
}
    ${LogisticsFragmentFragmentDoc}
${SequenceDateRecordsFragmentFragmentDoc}`;
export const SequenceTemplateFragmentFragmentDoc = gql`
    fragment SequenceTemplateFragment on SequenceTemplate {
  id
  name
  template_id
  project_type {
    projtypeid
    projtype
    projtypesuffix
  }
  sequence_dates {
    sequence_date_type_id
    date_type
  }
}
    `;
export const PtoCalendarDataFragmentFragmentDoc = gql`
    fragment PtoCalendarDataFragment on PtoCalendarData {
  tsid
  user_id
  full_name
  group
  cps_group
  date
  notes
  full_day
}
    `;
export const TrainingTypeFragmentFragmentDoc = gql`
    fragment TrainingTypeFragment on TrainingType {
  type_var
  type
  type_id
}
    `;
export const TrainingMarketFragmentFragmentDoc = gql`
    fragment TrainingMarketFragment on TrainingMarket {
  t_market_id
  t_market
}
    `;
export const TrainingContactFragmentFragmentDoc = gql`
    fragment TrainingContactFragment on TrainingContact {
  contact_id
  training_id
  contact_name
  contact_email
  contact_company
}
    `;
export const VtfrTrainingDataFragmentFragmentDoc = gql`
    fragment VtfrTrainingDataFragment on VtfrTrainingsData {
  training_id
  trainer
  type
  duration_num
  duration_type
  duration
  company
  salesman_name
  salesman_id
  pres_date
  notes
  status
  bill_amt
  bill_amount
  currency
  market
  markets {
    ...TrainingMarketFragment
  }
  contact
  contacts {
    ...TrainingContactFragment
  }
  user_id
  type_id
  customer_id
  expenses
  sold
  quote
}
    ${TrainingMarketFragmentFragmentDoc}
${TrainingContactFragmentFragmentDoc}`;
export const VtfrTrainingsMetricsFragmentFragmentDoc = gql`
    fragment VtfrTrainingsMetricsFragment on VtfrTrainingsMetrics {
  rem_pres
  on_pres
  corp_pres
  event_pres
  rem_supp
  fab_cell
  pd_trn
  sa_trn
  trainer
  total
}
    `;
export const UserRecentProjectsFragmentFragmentDoc = gql`
    fragment UserRecentProjectsFragment on Project {
  project_name
  proj_num
  proj_id
  projid
  is_favorite
  changeOrders {
    change_order_id
    change_order_status_id
  }
  coordinator {
    full_name
  }
  customer {
    customer
  }
  software_tracking {
    rvtVersion
  }
}
    `;
export const AddCustomerContactDocument = gql`
    mutation addCustomerContact($input: AddCustomerContactInput!) {
  addCustomerContact(input: $input) {
    ...ProjectContactsFragment
  }
}
    ${ProjectContactsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCustomerContactGQL extends Apollo.Mutation<AddCustomerContactMutation, AddCustomerContactMutationVariables> {
    document = AddCustomerContactDocument;
    
  }
export const DeleteCustomerContactDocument = gql`
    mutation deleteCustomerContact($input: DeleteCustomerContactInput!) {
  deleteCustomerContact(input: $input) {
    ...ProjectContactsFragment
  }
}
    ${ProjectContactsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCustomerContactGQL extends Apollo.Mutation<DeleteCustomerContactMutation, DeleteCustomerContactMutationVariables> {
    document = DeleteCustomerContactDocument;
    
  }
export const SetProjectInactiveDocument = gql`
    mutation setProjectInactive($input: SetProjectInactiveInput!) {
  setProjectInactive(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetProjectInactiveGQL extends Apollo.Mutation<SetProjectInactiveMutation, SetProjectInactiveMutationVariables> {
    document = SetProjectInactiveDocument;
    
  }
export const UpdateBaseBimDocument = gql`
    mutation updateBaseBim($input: UpdateProjectBimBaseInput!) {
  updateProjectBimBase(input: $input) {
    ...ProjectBimBaseFragment
  }
}
    ${ProjectBimBaseFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBaseBimGQL extends Apollo.Mutation<UpdateBaseBimMutation, UpdateBaseBimMutationVariables> {
    document = UpdateBaseBimDocument;
    
  }
export const UpdateCustomerContactDocument = gql`
    mutation updateCustomerContact($input: UpdateCustomerContactInput!) {
  updateCustomerContact(input: $input) {
    ...ProjectContactsFragment
  }
}
    ${ProjectContactsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomerContactGQL extends Apollo.Mutation<UpdateCustomerContactMutation, UpdateCustomerContactMutationVariables> {
    document = UpdateCustomerContactDocument;
    
  }
export const GetCustomerContactsDocument = gql`
    query getCustomerContacts($proj_id: Int!) {
  project(proj_id: $proj_id) {
    ...ProjectContactsFragment
  }
}
    ${ProjectContactsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomerContactsGQL extends Apollo.Query<GetCustomerContactsQuery, GetCustomerContactsQueryVariables> {
    document = GetCustomerContactsDocument;
    
  }
export const GetBaseBimDataDocument = gql`
    query getBaseBimData($proj_id: Int!) {
  projectSelectOptions {
    customerCareReps {
      rep_id
      name
      phone
      email
    }
  }
  project(proj_id: $proj_id) {
    ...ProjectBimBaseFragment
  }
}
    ${ProjectBimBaseFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBaseBimDataGQL extends Apollo.Query<GetBaseBimDataQuery, GetBaseBimDataQueryVariables> {
    document = GetBaseBimDataDocument;
    
  }
export const GetProjectHealthDataDocument = gql`
    query getProjectHealthData {
  project_health_projects {
    coordinators {
      project_count
      project_counts {
        hundred_percent
        not_complete
      }
      cps_group
      email_address
      employeeid
      first_name
      full_name
      group
      last_name
      region_id
      user_id
      user_type
      resource_color
    }
    projects {
      project_name
      proj_id
      proj_num
      project_health_status {
        ph_status
      }
      changeOrders {
        change_order_id
        change_order_status_id
      }
      coordinator_name
      coordinator_user_id
      coordinator_employee_id
      region_name
      market_name
      customer_name
      expected_drawing_completion_date
      percent_complete
      rvtVersion
    }
    unassigned {
      project_name
      proj_num
      proj_id
      project_health_status {
        ph_status
      }
      coordinator_name
      coordinator_user_id
      coordinator_employee_id
      region_name
      market_name
      customer_name
      expected_drawing_completion_date
      percent_complete
    }
    project_statuses {
      green_count
      yellow_count
      red_count
      project_count
      completed_count
      not_completed_count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectHealthDataGQL extends Apollo.Query<GetProjectHealthDataQuery, GetProjectHealthDataQueryVariables> {
    document = GetProjectHealthDataDocument;
    
  }
export const ApproveChangeOrderDocument = gql`
    mutation approveChangeOrder($input: ApproveChangeOrderInput!) {
  approveChangeOrder(input: $input) {
    ...AdminChangeOrderFragment
  }
}
    ${AdminChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApproveChangeOrderGQL extends Apollo.Mutation<ApproveChangeOrderMutation, ApproveChangeOrderMutationVariables> {
    document = ApproveChangeOrderDocument;
    
  }
export const DeclineChangeOrderDocument = gql`
    mutation declineChangeOrder($input: DeclineChangeOrderInput!) {
  declineChangeOrder(input: $input) {
    ...AdminChangeOrderFragment
  }
}
    ${AdminChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeclineChangeOrderGQL extends Apollo.Mutation<DeclineChangeOrderMutation, DeclineChangeOrderMutationVariables> {
    document = DeclineChangeOrderDocument;
    
  }
export const SubmitChangeOrderRevisionDocument = gql`
    mutation submitChangeOrderRevision($input: SubmitChangeOrderRevisionInput!) {
  submitChangeOrderRevision(input: $input) {
    ...ProjectChangeOrderFragment
  }
}
    ${ProjectChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitChangeOrderRevisionGQL extends Apollo.Mutation<SubmitChangeOrderRevisionMutation, SubmitChangeOrderRevisionMutationVariables> {
    document = SubmitChangeOrderRevisionDocument;
    
  }
export const SubmitChangeOrderDocument = gql`
    mutation submitChangeOrder($input: SubmitChangeOrderInput!) {
  submitChangeOrder(input: $input) {
    ...ProjectChangeOrderFragment
  }
}
    ${ProjectChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitChangeOrderGQL extends Apollo.Mutation<SubmitChangeOrderMutation, SubmitChangeOrderMutationVariables> {
    document = SubmitChangeOrderDocument;
    
  }
export const UpdateChangeOrderBasicInfoDocument = gql`
    mutation updateChangeOrderBasicInfo($input: UpdateChangeOrderBasicInfoInput!) {
  updateChangeOrderBasicInfo(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChangeOrderBasicInfoGQL extends Apollo.Mutation<UpdateChangeOrderBasicInfoMutation, UpdateChangeOrderBasicInfoMutationVariables> {
    document = UpdateChangeOrderBasicInfoDocument;
    
  }
export const GetAllChangeOrdersDocument = gql`
    query getAllChangeOrders {
  getAllChangeOrders {
    ...AdminChangeOrderFragment
  }
}
    ${AdminChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllChangeOrdersGQL extends Apollo.Query<GetAllChangeOrdersQuery, GetAllChangeOrdersQueryVariables> {
    document = GetAllChangeOrdersDocument;
    
  }
export const GetChangeOrderNotesDocument = gql`
    query getChangeOrderNotes($change_order_id: Int!, $finance_revision_id: Int!) {
  getChangeOrderNotes(change_order_id: $change_order_id, finance_revision_id: $finance_revision_id) {
    ...ChangeOrderNoteFragment
  }
}
    ${ChangeOrderNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetChangeOrderNotesGQL extends Apollo.Query<GetChangeOrderNotesQuery, GetChangeOrderNotesQueryVariables> {
    document = GetChangeOrderNotesDocument;
    
  }
export const GetChangeOrderPreviewDocument = gql`
    query getChangeOrderPreview($input: ChangeOrderPreviewInput!) {
  getChangeOrderPreview(input: $input) {
    finances {
      ...ChangeOrderFinancesFragment
    }
    financesHours {
      ...ChangeOrderFinancesHoursFragment
    }
    missingPartNumbers
  }
}
    ${ChangeOrderFinancesFragmentFragmentDoc}
${ChangeOrderFinancesHoursFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetChangeOrderPreviewGQL extends Apollo.Query<GetChangeOrderPreviewQuery, GetChangeOrderPreviewQueryVariables> {
    document = GetChangeOrderPreviewDocument;
    
  }
export const GetChangeOrdersSelectOptionsDocument = gql`
    query getChangeOrdersSelectOptions {
  projectSelectOptions {
    bimHours {
      bim_hour_type_id
      bim_hour_type
      positive
    }
    changeOrderAdditionalOfferings {
      offeringid
      offering
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetChangeOrdersSelectOptionsGQL extends Apollo.Query<GetChangeOrdersSelectOptionsQuery, GetChangeOrdersSelectOptionsQueryVariables> {
    document = GetChangeOrdersSelectOptionsDocument;
    
  }
export const GetProjectChangeOrdersDocument = gql`
    query getProjectChangeOrders($proj_id: Int!) {
  getProjectChangeOrders(proj_id: $proj_id) {
    ...ProjectChangeOrderFragment
  }
}
    ${ProjectChangeOrderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectChangeOrdersGQL extends Apollo.Query<GetProjectChangeOrdersQuery, GetProjectChangeOrdersQueryVariables> {
    document = GetProjectChangeOrdersDocument;
    
  }
export const AddComponentAlternativeDocument = gql`
    mutation addComponentAlternative($input: AddAlternativeInput!) {
  addComponentAlternative(input: $input) {
    ...ComponentAlternativeFragment
  }
}
    ${ComponentAlternativeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddComponentAlternativeGQL extends Apollo.Mutation<AddComponentAlternativeMutation, AddComponentAlternativeMutationVariables> {
    document = AddComponentAlternativeDocument;
    
  }
export const RemoveComponentAlternativeDocument = gql`
    mutation removeComponentAlternative($input: RemoveAlternativeInput!) {
  removeComponentAlternative(input: $input) {
    ...ComponentAlternativeFragment
  }
}
    ${ComponentAlternativeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveComponentAlternativeGQL extends Apollo.Mutation<RemoveComponentAlternativeMutation, RemoveComponentAlternativeMutationVariables> {
    document = RemoveComponentAlternativeDocument;
    
  }
export const UpdateComponentSubDocument = gql`
    mutation updateComponentSub($input: UpdateComponentSubInput!) {
  updateComponentSub(input: $input) {
    ...ComponentAlternativeFragment
  }
}
    ${ComponentAlternativeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateComponentSubGQL extends Apollo.Mutation<UpdateComponentSubMutation, UpdateComponentSubMutationVariables> {
    document = UpdateComponentSubDocument;
    
  }
export const AddComponentSizeDocument = gql`
    mutation addComponentSize($input: AddComponentSizeInput!) {
  addComponentSize(input: $input) {
    ...ComponentSizeFragment
  }
}
    ${ComponentSizeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddComponentSizeGQL extends Apollo.Mutation<AddComponentSizeMutation, AddComponentSizeMutationVariables> {
    document = AddComponentSizeDocument;
    
  }
export const AddCustomItemDocument = gql`
    mutation addCustomItem($input: AddCustomItemInput!) {
  addCustomItem(input: $input) {
    ...AdminComponentFragment
    active
    part_size
  }
}
    ${AdminComponentFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCustomItemGQL extends Apollo.Mutation<AddCustomItemMutation, AddCustomItemMutationVariables> {
    document = AddCustomItemDocument;
    
  }
export const DeleteComponentImageDocument = gql`
    mutation deleteComponentImage($input: DeleteComponentImageInput!) {
  deleteComponentImage(input: $input) {
    ...AdminComponentFragment
  }
}
    ${AdminComponentFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteComponentImageGQL extends Apollo.Mutation<DeleteComponentImageMutation, DeleteComponentImageMutationVariables> {
    document = DeleteComponentImageDocument;
    
  }
export const UpdateComponentSizeDocument = gql`
    mutation updateComponentSize($input: UpdateComponentSizeInput!) {
  updateComponentSize(input: $input) {
    ...ComponentSizeFragment
  }
}
    ${ComponentSizeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateComponentSizeGQL extends Apollo.Mutation<UpdateComponentSizeMutation, UpdateComponentSizeMutationVariables> {
    document = UpdateComponentSizeDocument;
    
  }
export const UpdateComponentDocument = gql`
    mutation updateComponent($input: UpdateComponentInput!) {
  updateComponent(input: $input) {
    ...AdminComponentFragment
    active
    part_size
  }
}
    ${AdminComponentFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateComponentGQL extends Apollo.Mutation<UpdateComponentMutation, UpdateComponentMutationVariables> {
    document = UpdateComponentDocument;
    
  }
export const GetComponentsSelectOptionsDocument = gql`
    query GetComponentsSelectOptions {
  getComponentsSelectOptions {
    partSizes {
      size_code
      part_size
    }
    gasketTypes {
      gasket_id
      gasket_type
      gasket_value
    }
    componentCategories {
      group_id
      group_desc
    }
    currencies {
      currency
    }
    regions {
      region_id
      region
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetComponentsSelectOptionsGQL extends Apollo.Query<GetComponentsSelectOptionsQuery, GetComponentsSelectOptionsQueryVariables> {
    document = GetComponentsSelectOptionsDocument;
    
  }
export const GetFilteredComponentsDocument = gql`
    query getFilteredComponents($keyword: String!) {
  getFilteredComponents(keyword: $keyword) {
    ...AdminComponentFragment
    active
    part_size
  }
}
    ${AdminComponentFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilteredComponentsGQL extends Apollo.Query<GetFilteredComponentsQuery, GetFilteredComponentsQueryVariables> {
    document = GetFilteredComponentsDocument;
    
  }
export const GetComponentsDocument = gql`
    query getComponents($pageNumber: Int!, $pageSize: Int!) {
  getAdminComponents(pageNumber: $pageNumber, pageSize: $pageSize) {
    ...AdminComponentFragment
    active
    part_size
  }
}
    ${AdminComponentFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetComponentsGQL extends Apollo.Query<GetComponentsQuery, GetComponentsQueryVariables> {
    document = GetComponentsDocument;
    
  }
export const GetComponentSubstitutesDocument = gql`
    query getComponentSubstitutes($user_id: Int!, $component_id: Int!) {
  getComponentSubstitutes(user_id: $user_id, component_id: $component_id) {
    ...ComponentAlternativeFragment
  }
}
    ${ComponentAlternativeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetComponentSubstitutesGQL extends Apollo.Query<GetComponentSubstitutesQuery, GetComponentSubstitutesQueryVariables> {
    document = GetComponentSubstitutesDocument;
    
  }
export const CopyPartNumberLinkDocument = gql`
    mutation copyPartNumberLink($input: [CopyPartNumberLinkInput]!) {
  copyPartNumberLink(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyPartNumberLinkGQL extends Apollo.Mutation<CopyPartNumberLinkMutation, CopyPartNumberLinkMutationVariables> {
    document = CopyPartNumberLinkDocument;
    
  }
export const DeletePartNumberLinkDocument = gql`
    mutation deletePartNumberLink($input: [DeletePartNumberLinkInput]!) {
  deletePartNumberLink(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePartNumberLinkGQL extends Apollo.Mutation<DeletePartNumberLinkMutation, DeletePartNumberLinkMutationVariables> {
    document = DeletePartNumberLinkDocument;
    
  }
export const AddPartNumberProfileDocument = gql`
    mutation addPartNumberProfile($input: AddPartNumberProfileInput!) {
  addPartNumberProfile(input: $input) {
    ...PartNumberProfileFragment
  }
}
    ${PartNumberProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPartNumberProfileGQL extends Apollo.Mutation<AddPartNumberProfileMutation, AddPartNumberProfileMutationVariables> {
    document = AddPartNumberProfileDocument;
    
  }
export const UpdatePartNumberProfileDocument = gql`
    mutation updatePartNumberProfile($input: UpdatePartNumberProfileInput!) {
  updatePartNumberProfile(input: $input) {
    ...PartNumberProfileFragment
  }
}
    ${PartNumberProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePartNumberProfileGQL extends Apollo.Mutation<UpdatePartNumberProfileMutation, UpdatePartNumberProfileMutationVariables> {
    document = UpdatePartNumberProfileDocument;
    
  }
export const GetPartNumberProfilesDocument = gql`
    query getPartNumberProfiles {
  getPartNumberProfiles {
    ...PartNumberProfileFragment
  }
}
    ${PartNumberProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPartNumberProfilesGQL extends Apollo.Query<GetPartNumberProfilesQuery, GetPartNumberProfilesQueryVariables> {
    document = GetPartNumberProfilesDocument;
    
  }
export const GetProfilePartNumbersDocument = gql`
    query getProfilePartNumbers($profile_id: Int!) {
  getProfilePartNumbers(profile_id: $profile_id) {
    ...ProfilePartNumbersFragment
  }
}
    ${ProfilePartNumbersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfilePartNumbersGQL extends Apollo.Query<GetProfilePartNumbersQuery, GetProfilePartNumbersQueryVariables> {
    document = GetProfilePartNumbersDocument;
    
  }
export const GetCurrencyConversionRatesDocument = gql`
    query getCurrencyConversionRates($year: Int) {
  getCurrencyConversionRates(year: $year) {
    ...CurrencyConversionRateFragment
  }
}
    ${CurrencyConversionRateFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrencyConversionRatesGQL extends Apollo.Query<GetCurrencyConversionRatesQuery, GetCurrencyConversionRatesQueryVariables> {
    document = GetCurrencyConversionRatesDocument;
    
  }
export const CopyProjectPartNumberLinkDocument = gql`
    mutation copyProjectPartNumberLink($input: [CopyProjectPartNumberLinkInput]!) {
  copyProjectPartNumberLink(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyProjectPartNumberLinkGQL extends Apollo.Mutation<CopyProjectPartNumberLinkMutation, CopyProjectPartNumberLinkMutationVariables> {
    document = CopyProjectPartNumberLinkDocument;
    
  }
export const DeleteProjectPartNumberLinkDocument = gql`
    mutation deleteProjectPartNumberLink($input: [DeleteProjectPartNumberLinkInput]!) {
  deleteProjectPartNumberLink(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectPartNumberLinkGQL extends Apollo.Mutation<DeleteProjectPartNumberLinkMutation, DeleteProjectPartNumberLinkMutationVariables> {
    document = DeleteProjectPartNumberLinkDocument;
    
  }
export const AddProjectProfileDocument = gql`
    mutation addProjectProfile($input: AddProjectProfileInput!) {
  addProjectProfile(input: $input) {
    ...ProjectProfileFragment
  }
}
    ${ProjectProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectProfileGQL extends Apollo.Mutation<AddProjectProfileMutation, AddProjectProfileMutationVariables> {
    document = AddProjectProfileDocument;
    
  }
export const UpdateProjectProfileDocument = gql`
    mutation updateProjectProfile($input: UpdateProjectProfileInput!) {
  updateProjectProfile(input: $input) {
    ...ProjectProfileFragment
  }
}
    ${ProjectProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectProfileGQL extends Apollo.Mutation<UpdateProjectProfileMutation, UpdateProjectProfileMutationVariables> {
    document = UpdateProjectProfileDocument;
    
  }
export const GetProjectProfilePartNumbersDocument = gql`
    query getProjectProfilePartNumbers($project_profile_id: Int!) {
  getProjectProfilePartNumbers(project_profile_id: $project_profile_id) {
    ...ProjectProfilePartNumbersFragment
  }
}
    ${ProjectProfilePartNumbersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectProfilePartNumbersGQL extends Apollo.Query<GetProjectProfilePartNumbersQuery, GetProjectProfilePartNumbersQueryVariables> {
    document = GetProjectProfilePartNumbersDocument;
    
  }
export const GetProjectProfilesDocument = gql`
    query getProjectProfiles {
  getProjectProfiles {
    ...ProjectProfileFragment
  }
}
    ${ProjectProfileFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectProfilesGQL extends Apollo.Query<GetProjectProfilesQuery, GetProjectProfilesQueryVariables> {
    document = GetProjectProfilesDocument;
    
  }
export const GetAdminProjectsDocument = gql`
    query getAdminProjects($startDate: String!, $endDate: String!) {
  getAdminProjects(startDate: $startDate, endDate: $endDate) {
    ...SoldAdminProjetFragment
  }
}
    ${SoldAdminProjetFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminProjectsGQL extends Apollo.Query<GetAdminProjectsQuery, GetAdminProjectsQueryVariables> {
    document = GetAdminProjectsDocument;
    
  }
export const GetQuoteDocumentInclusionsDocument = gql`
    query getQuoteDocumentInclusions($language: String) {
  getQuoteDocumentInclusions(language: $language) {
    ...AdminInclusionFragment
  }
}
    ${AdminInclusionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuoteDocumentInclusionsGQL extends Apollo.Query<GetQuoteDocumentInclusionsQuery, GetQuoteDocumentInclusionsQueryVariables> {
    document = GetQuoteDocumentInclusionsDocument;
    
  }
export const UpdateSalesmanMarkupsDocument = gql`
    mutation updateSalesmanMarkups($input: [UpdateSalesmanMarkupsInput]!) {
  updateSalesmanMarkups(input: $input) {
    ...SalesmanMarkupFragment
  }
}
    ${SalesmanMarkupFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSalesmanMarkupsGQL extends Apollo.Mutation<UpdateSalesmanMarkupsMutation, UpdateSalesmanMarkupsMutationVariables> {
    document = UpdateSalesmanMarkupsDocument;
    
  }
export const UpdateSalesmanMultipliersDocument = gql`
    mutation updateSalesmanMultipliers($input: UpdateSalesmanMultipliersInput!) {
  updateSalesmanMultipliers(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSalesmanMultipliersGQL extends Apollo.Mutation<UpdateSalesmanMultipliersMutation, UpdateSalesmanMultipliersMutationVariables> {
    document = UpdateSalesmanMultipliersDocument;
    
  }
export const UpdateSalesmanDocument = gql`
    mutation updateSalesman($input: UpdateSalesmanInput!) {
  updateSalesman(input: $input) {
    ...AdminSalesmanFragment
  }
}
    ${AdminSalesmanFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSalesmanGQL extends Apollo.Mutation<UpdateSalesmanMutation, UpdateSalesmanMutationVariables> {
    document = UpdateSalesmanDocument;
    
  }
export const GetAdminSalesmanDocument = gql`
    query getAdminSalesman {
  getAdminSalesman {
    ...AdminSalesmanFragment
  }
}
    ${AdminSalesmanFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdminSalesmanGQL extends Apollo.Query<GetAdminSalesmanQuery, GetAdminSalesmanQueryVariables> {
    document = GetAdminSalesmanDocument;
    
  }
export const GetSalesmanMarkupsDocument = gql`
    query getSalesmanMarkups($salesmanID: String!) {
  getSalesmanMarkups(salesmanID: $salesmanID) {
    ...SalesmanMarkupFragment
  }
}
    ${SalesmanMarkupFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSalesmanMarkupsGQL extends Apollo.Query<GetSalesmanMarkupsQuery, GetSalesmanMarkupsQueryVariables> {
    document = GetSalesmanMarkupsDocument;
    
  }
export const GetSalesmanMultipliersDocument = gql`
    query getSalesmanMultipliers($salesmanID: String!) {
  getSalesmanMultipliers(salesmanID: $salesmanID) {
    ...SalesmanMultiplierFragment
  }
}
    ${SalesmanMultiplierFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSalesmanMultipliersGQL extends Apollo.Query<GetSalesmanMultipliersQuery, GetSalesmanMultipliersQueryVariables> {
    document = GetSalesmanMultipliersDocument;
    
  }
export const GetSalesmanSelectOptionsDocument = gql`
    query getSalesmanSelectOptions {
  projectSelectOptions {
    groups {
      group_id
      group_name
    }
    metros {
      metro_id
      metro
    }
    salesRegions {
      salesregion_id
      salesregion_name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSalesmanSelectOptionsGQL extends Apollo.Query<GetSalesmanSelectOptionsQuery, GetSalesmanSelectOptionsQueryVariables> {
    document = GetSalesmanSelectOptionsDocument;
    
  }
export const AddNewHeaderDocument = gql`
    mutation addNewHeader($input: AddNewHeaderInput!) {
  addNewHeader(input: $input) {
    ...HeaderFragment
  }
}
    ${HeaderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewHeaderGQL extends Apollo.Mutation<AddNewHeaderMutation, AddNewHeaderMutationVariables> {
    document = AddNewHeaderDocument;
    
  }
export const AddNewMaterialItemDocument = gql`
    mutation addNewMaterialItem($input: UpdateMaterialInput!) {
  addNewMaterialItem(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewMaterialItemGQL extends Apollo.Mutation<AddNewMaterialItemMutation, AddNewMaterialItemMutationVariables> {
    document = AddNewMaterialItemDocument;
    
  }
export const AddNewProjectPricingDocument = gql`
    mutation addNewProjectPricing($input: AddNewProjectPricingInput!) {
  addNewProjectPricing(input: $input) {
    ...ProjectPricingFragment
  }
}
    ${ProjectPricingFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewProjectPricingGQL extends Apollo.Mutation<AddNewProjectPricingMutation, AddNewProjectPricingMutationVariables> {
    document = AddNewProjectPricingDocument;
    
  }
export const ApplyProfileDocument = gql`
    mutation applyProfile($input: ApplyProfileInput!) {
  applyProfile(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplyProfileGQL extends Apollo.Mutation<ApplyProfileMutation, ApplyProfileMutationVariables> {
    document = ApplyProfileDocument;
    
  }
export const ApplyProjectPricingDocument = gql`
    mutation applyProjectPricing($proj_id: Int!) {
  applyProjectPricing(proj_id: $proj_id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplyProjectPricingGQL extends Apollo.Mutation<ApplyProjectPricingMutation, ApplyProjectPricingMutationVariables> {
    document = ApplyProjectPricingDocument;
    
  }
export const ApplyProjectProfileDocument = gql`
    mutation applyProjectProfile($input: ApplyProjectProfileInput!) {
  applyProjectProfile(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplyProjectProfileGQL extends Apollo.Mutation<ApplyProjectProfileMutation, ApplyProjectProfileMutationVariables> {
    document = ApplyProjectProfileDocument;
    
  }
export const CreateProjectAncillariesDocument = gql`
    mutation createProjectAncillaries($input: CreateProjectAncillariesInput!) {
  createProjectAncillaries(input: $input) {
    newRevisions {
      ...RevisionFragment
    }
    newSystems {
      ...SystemsFragment
    }
  }
}
    ${RevisionFragmentFragmentDoc}
${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectAncillariesGQL extends Apollo.Mutation<CreateProjectAncillariesMutation, CreateProjectAncillariesMutationVariables> {
    document = CreateProjectAncillariesDocument;
    
  }
export const DeleteHeadersDocument = gql`
    mutation deleteHeaders($input: DeleteHeadersInput!) {
  deleteHeaders(input: $input) {
    ...HeaderFragment
  }
}
    ${HeaderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteHeadersGQL extends Apollo.Mutation<DeleteHeadersMutation, DeleteHeadersMutationVariables> {
    document = DeleteHeadersDocument;
    
  }
export const DeleteMaterialItemDocument = gql`
    mutation deleteMaterialItem($input: DeleteMaterialInput!) {
  deleteMaterialItem(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMaterialItemGQL extends Apollo.Mutation<DeleteMaterialItemMutation, DeleteMaterialItemMutationVariables> {
    document = DeleteMaterialItemDocument;
    
  }
export const FlipComponentDocument = gql`
    mutation flipComponent($input: FlipComponentInput!) {
  flipComponent(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FlipComponentGQL extends Apollo.Mutation<FlipComponentMutation, FlipComponentMutationVariables> {
    document = FlipComponentDocument;
    
  }
export const FlipSystemDocument = gql`
    mutation flipSystem($input: FlipSystemInput!) {
  flipSystem(input: $input) {
    alternatives {
      title
      rowNum
      subParts {
        part_number
        description
        quantity
      }
    }
    componentData {
      ...ComponentFragment
    }
    position
    totalItems
    flippedProjectBillItems {
      ...ProjectBillItemFragment
    }
  }
}
    ${ComponentFragmentFragmentDoc}
${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FlipSystemGQL extends Apollo.Mutation<FlipSystemMutation, FlipSystemMutationVariables> {
    document = FlipSystemDocument;
    
  }
export const GroupSystemsDocument = gql`
    mutation groupSystems($input: GroupSystemsInput!) {
  groupSystems(input: $input) {
    systems {
      ...SystemsFragment
    }
    revisions {
      ...RevisionFragment
    }
  }
}
    ${SystemsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GroupSystemsGQL extends Apollo.Mutation<GroupSystemsMutation, GroupSystemsMutationVariables> {
    document = GroupSystemsDocument;
    
  }
export const MapSystemsToQuotesDocument = gql`
    mutation mapSystemsToQuotes($input: [MapSystemsToQuotesInput]!) {
  mapSystemsToQuotes(input: $input) {
    ...SystemsFragment
  }
}
    ${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MapSystemsToQuotesGQL extends Apollo.Mutation<MapSystemsToQuotesMutation, MapSystemsToQuotesMutationVariables> {
    document = MapSystemsToQuotesDocument;
    
  }
export const MoveHeadersDocument = gql`
    mutation moveHeaders($input: MoveHeadersInput!) {
  moveHeaders(input: $input) {
    ...HeaderFragment
  }
}
    ${HeaderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MoveHeadersGQL extends Apollo.Mutation<MoveHeadersMutation, MoveHeadersMutationVariables> {
    document = MoveHeadersDocument;
    
  }
export const AddNewRevisionDocument = gql`
    mutation addNewRevision($input: MutateRevisionInput!) {
  addNewRevision(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewRevisionGQL extends Apollo.Mutation<AddNewRevisionMutation, AddNewRevisionMutationVariables> {
    document = AddNewRevisionDocument;
    
  }
export const DeleteRevisionDocument = gql`
    mutation deleteRevision($input: MutateRevisionInput!) {
  deleteRevision(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRevisionGQL extends Apollo.Mutation<DeleteRevisionMutation, DeleteRevisionMutationVariables> {
    document = DeleteRevisionDocument;
    
  }
export const MarkRevisionSoldDocument = gql`
    mutation markRevisionSold($input: MutateRevisionInput!) {
  markRevisionSold(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkRevisionSoldGQL extends Apollo.Mutation<MarkRevisionSoldMutation, MarkRevisionSoldMutationVariables> {
    document = MarkRevisionSoldDocument;
    
  }
export const RestoreRevisionDocument = gql`
    mutation restoreRevision($input: MutateRevisionInput!) {
  restoreRevision(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreRevisionGQL extends Apollo.Mutation<RestoreRevisionMutation, RestoreRevisionMutationVariables> {
    document = RestoreRevisionDocument;
    
  }
export const RevisionMultipleSystemsDocument = gql`
    mutation revisionMultipleSystems($input: MutateRevisionInput!) {
  revisionMultipleSystems(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevisionMultipleSystemsGQL extends Apollo.Mutation<RevisionMultipleSystemsMutation, RevisionMultipleSystemsMutationVariables> {
    document = RevisionMultipleSystemsDocument;
    
  }
export const UpdateRevisionDocument = gql`
    mutation updateRevision($input: MutateRevisionInput!) {
  updateRevision(input: $input) {
    ...RevisionFragment
  }
}
    ${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRevisionGQL extends Apollo.Mutation<UpdateRevisionMutation, UpdateRevisionMutationVariables> {
    document = UpdateRevisionDocument;
    
  }
export const AddNewSystemDocument = gql`
    mutation addNewSystem($input: MutateSystemInput!) {
  addNewSystem(input: $input) {
    ...SystemsFragment
  }
}
    ${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewSystemGQL extends Apollo.Mutation<AddNewSystemMutation, AddNewSystemMutationVariables> {
    document = AddNewSystemDocument;
    
  }
export const CopySystemDocument = gql`
    mutation copySystem($input: MutateSystemInput!) {
  copySystem(input: $input) {
    ...SystemsFragment
  }
}
    ${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CopySystemGQL extends Apollo.Mutation<CopySystemMutation, CopySystemMutationVariables> {
    document = CopySystemDocument;
    
  }
export const DeleteSystemDocument = gql`
    mutation deleteSystem($input: MutateSystemInput!) {
  deleteSystem(input: $input) {
    ...SystemsFragment
  }
}
    ${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSystemGQL extends Apollo.Mutation<DeleteSystemMutation, DeleteSystemMutationVariables> {
    document = DeleteSystemDocument;
    
  }
export const UpdateSystemDocument = gql`
    mutation updateSystem($input: MutateSystemInput!) {
  updateSystem(input: $input) {
    ...SystemsFragment
  }
}
    ${SystemsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSystemGQL extends Apollo.Mutation<UpdateSystemMutation, UpdateSystemMutationVariables> {
    document = UpdateSystemDocument;
    
  }
export const RemovePartNumberDocument = gql`
    mutation removePartNumber($input: RemovePartNumberInput!) {
  removePartNumber(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePartNumberGQL extends Apollo.Mutation<RemovePartNumberMutation, RemovePartNumberMutationVariables> {
    document = RemovePartNumberDocument;
    
  }
export const RemoveProjectPricingDocument = gql`
    mutation removeProjectPricing($project_pricing_id: Int!) {
  removeProjectPricing(project_pricing_id: $project_pricing_id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProjectPricingGQL extends Apollo.Mutation<RemoveProjectPricingMutation, RemoveProjectPricingMutationVariables> {
    document = RemoveProjectPricingDocument;
    
  }
export const SetGroupMultipliersDocument = gql`
    mutation setGroupMultipliers($input: SetGroupMultipliersInput!) {
  setGroupMultipliers(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGroupMultipliersGQL extends Apollo.Mutation<SetGroupMultipliersMutation, SetGroupMultipliersMutationVariables> {
    document = SetGroupMultipliersDocument;
    
  }
export const SplitSystemsDocument = gql`
    mutation splitSystems($input: SplitSystemsInput!) {
  splitSystems(input: $input) {
    systems {
      ...SystemsFragment
    }
    revisions {
      ...RevisionFragment
    }
  }
}
    ${SystemsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SplitSystemsGQL extends Apollo.Mutation<SplitSystemsMutation, SplitSystemsMutationVariables> {
    document = SplitSystemsDocument;
    
  }
export const UpdateHeaderDocument = gql`
    mutation updateHeader($input: AddNewHeaderInput!) {
  updateHeader(input: $input) {
    ...HeaderFragment
  }
}
    ${HeaderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHeaderGQL extends Apollo.Mutation<UpdateHeaderMutation, UpdateHeaderMutationVariables> {
    document = UpdateHeaderDocument;
    
  }
export const UpdateMaterialDocument = gql`
    mutation updateMaterial($input: UpdateMaterialInput!) {
  updateMaterial(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaterialGQL extends Apollo.Mutation<UpdateMaterialMutation, UpdateMaterialMutationVariables> {
    document = UpdateMaterialDocument;
    
  }
export const UpdateProjectPricingDocument = gql`
    mutation updateProjectPricing($input: UpdateProjectPricingInput!) {
  updateProjectPricing(input: $input) {
    ...ProjectPricingFragment
  }
}
    ${ProjectPricingFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectPricingGQL extends Apollo.Mutation<UpdateProjectPricingMutation, UpdateProjectPricingMutationVariables> {
    document = UpdateProjectPricingDocument;
    
  }
export const AddComponentToProfileDocument = gql`
    mutation addComponentToProfile($input: UsePartNumberInput!) {
  addComponentToProfile(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddComponentToProfileGQL extends Apollo.Mutation<AddComponentToProfileMutation, AddComponentToProfileMutationVariables> {
    document = AddComponentToProfileDocument;
    
  }
export const AddComponentToProjectProfileDocument = gql`
    mutation addComponentToProjectProfile($input: UsePartNumberInput!) {
  addComponentToProjectProfile(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddComponentToProjectProfileGQL extends Apollo.Mutation<AddComponentToProjectProfileMutation, AddComponentToProjectProfileMutationVariables> {
    document = AddComponentToProjectProfileDocument;
    
  }
export const UsePartNumberAlwaysDocument = gql`
    mutation usePartNumberAlways($input: UsePartNumberInput!) {
  usePartNumberAlways(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsePartNumberAlwaysGQL extends Apollo.Mutation<UsePartNumberAlwaysMutation, UsePartNumberAlwaysMutationVariables> {
    document = UsePartNumberAlwaysDocument;
    
  }
export const UsePartNumberOnceDocument = gql`
    mutation usePartNumberOnce($input: UsePartNumberInput!) {
  usePartNumberOnce(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsePartNumberOnceGQL extends Apollo.Mutation<UsePartNumberOnceMutation, UsePartNumberOnceMutationVariables> {
    document = UsePartNumberOnceDocument;
    
  }
export const GetAlternativeBomDocument = gql`
    query getAlternativeBom($input: GetAlternativeBomInput!) {
  getAlternativeBom(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAlternativeBomGQL extends Apollo.Query<GetAlternativeBomQuery, GetAlternativeBomQueryVariables> {
    document = GetAlternativeBomDocument;
    
  }
export const GetAssemblyEquipmentDocument = gql`
    query getAssemblyEquipment($input: GetAssemblyEquipmentInput!) {
  getAssemblyEquipment(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssemblyEquipmentGQL extends Apollo.Query<GetAssemblyEquipmentQuery, GetAssemblyEquipmentQueryVariables> {
    document = GetAssemblyEquipmentDocument;
    
  }
export const GetGroupMultipliersDocument = gql`
    query getGroupMultipliers($proj_id: Int!) {
  getGroupMultipliers(proj_id: $proj_id) {
    ...GroupMultipliersFragment
  }
}
    ${GroupMultipliersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGroupMultipliersGQL extends Apollo.Query<GetGroupMultipliersQuery, GetGroupMultipliersQueryVariables> {
    document = GetGroupMultipliersDocument;
    
  }
export const GetHeaderPriceDocument = gql`
    query getHeaderPrice($input: GetHeaderPriceInput!) {
  getHeaderPrice(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHeaderPriceGQL extends Apollo.Query<GetHeaderPriceQuery, GetHeaderPriceQueryVariables> {
    document = GetHeaderPriceDocument;
    
  }
export const GetHeadersSelectOptionsDocument = gql`
    query getHeadersSelectOptions($proj_id: Int!) {
  getHeadersSelectOptions(proj_id: $proj_id) {
    price_list {
      option_id
      price_list_name
    }
    areas {
      vic_area_pt
    }
    zones {
      vic_zone
    }
    sequences {
      vic_seq
    }
    mainPipeSizes {
      nominal_size
      headersize_id
      outlet_size
    }
    headerCoatings {
      header_coating_id
      header_coating
    }
    headerLinings {
      header_lining_id
      header_lining
    }
    headerMaterials {
      header_material_id
      header_material
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHeadersSelectOptionsGQL extends Apollo.Query<GetHeadersSelectOptionsQuery, GetHeadersSelectOptionsQueryVariables> {
    document = GetHeadersSelectOptionsDocument;
    
  }
export const GetOutletSizesDocument = gql`
    query getOutletSizes($region_id: Int!, $nominal_size: String!) {
  getOutletSizes(region_id: $region_id, nominal_size: $nominal_size) {
    outlet_size
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOutletSizesGQL extends Apollo.Query<GetOutletSizesQuery, GetOutletSizesQueryVariables> {
    document = GetOutletSizesDocument;
    
  }
export const GetPartNumbersOptionsDocument = gql`
    query getPartNumbersOptions($input: GetPartNumbersOptionsInput!) {
  getPartNumbersOptions(input: $input) {
    partnumber_us
    partnumber_global
    partnumber_m3
    size_desc_imp
    size_desc_m3
    description
    component_id
    group_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPartNumbersOptionsGQL extends Apollo.Query<GetPartNumbersOptionsQuery, GetPartNumbersOptionsQueryVariables> {
    document = GetPartNumbersOptionsDocument;
    
  }
export const GetPredictedPartNumbersDocument = gql`
    query getPredictedPartNumbers($input: GetPredictedPartNumbersInput!) {
  getPredictedPartNumbers(input: $input) {
    components {
      partnumber_us
      partnumber_global
      partnumber_m3
      size_desc_imp
      size_desc_m3
      description
      component_id
      group_id
    }
    user_type
    pn_profile
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPredictedPartNumbersGQL extends Apollo.Query<GetPredictedPartNumbersQuery, GetPredictedPartNumbersQueryVariables> {
    document = GetPredictedPartNumbersDocument;
    
  }
export const GetProjectHeadersDocument = gql`
    query getProjectHeaders($proj_id: Int!) {
  getProjectHeaders(proj_id: $proj_id) {
    ...HeaderFragment
  }
}
    ${HeaderFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectHeadersGQL extends Apollo.Query<GetProjectHeadersQuery, GetProjectHeadersQueryVariables> {
    document = GetProjectHeadersDocument;
    
  }
export const GetProjectMaterialDataDocument = gql`
    query getProjectMaterialData($proj_id: Int!) {
  project(proj_id: $proj_id) {
    proj_id
    projid
    project_name
    proj_num
    price_list_id
    region_id
    currency_symbol
    weight_unit
    currency
    proj_status
    user_id
    notes {
      ...ProjectNoteFragment
    }
    bill_of_material {
      systems {
        ...SystemsFragment
      }
      revisions {
        ...RevisionFragment
      }
      projectProfiles {
        ...ProjectProfileFragment
      }
      partNumberProfiles {
        ...PartNumberProfileFragment
      }
      quotes {
        ...SystemQuoteFragment
      }
    }
  }
}
    ${ProjectNoteFragmentFragmentDoc}
${SystemsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}
${ProjectProfileFragmentFragmentDoc}
${PartNumberProfileFragmentFragmentDoc}
${SystemQuoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectMaterialDataGQL extends Apollo.Query<GetProjectMaterialDataQuery, GetProjectMaterialDataQueryVariables> {
    document = GetProjectMaterialDataDocument;
    
  }
export const GetProjectPricingDocument = gql`
    query getProjectPricing($proj_id: Int!) {
  getProjectPricing(proj_id: $proj_id) {
    ...ProjectPricingFragment
  }
}
    ${ProjectPricingFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectPricingGQL extends Apollo.Query<GetProjectPricingQuery, GetProjectPricingQueryVariables> {
    document = GetProjectPricingDocument;
    
  }
export const GetSystemRevisionMaterialListDocument = gql`
    query getSystemRevisionMaterialList($input: GetSystemRevisionMaterialListInput!) {
  getSystemRevisionMaterialList(input: $input) {
    ...ProjectBillItemFragment
  }
}
    ${ProjectBillItemFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSystemRevisionMaterialListGQL extends Apollo.Query<GetSystemRevisionMaterialListQuery, GetSystemRevisionMaterialListQueryVariables> {
    document = GetSystemRevisionMaterialListDocument;
    
  }
export const GetProjectStatsDocument = gql`
    query getProjectStats($proj_id: Int!) {
  getProjectStats(proj_id: $proj_id) {
    keyPerformanceProjMetircs {
      ...KeyPerformanceProjMetricFragment
    }
    keyPerformanceEquipMetrics {
      ...KeyPerformanceEquipMetricFragment
    }
    quickStatsMetrics {
      ...QuickStatsMetricFragment
    }
    pipingSystems {
      ...PipingSystemMetricFragment
    }
  }
}
    ${KeyPerformanceProjMetricFragmentFragmentDoc}
${KeyPerformanceEquipMetricFragmentFragmentDoc}
${QuickStatsMetricFragmentFragmentDoc}
${PipingSystemMetricFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectStatsGQL extends Apollo.Query<GetProjectStatsQuery, GetProjectStatsQueryVariables> {
    document = GetProjectStatsDocument;
    
  }
export const AddBimAndCoObjectPhotosDocument = gql`
    mutation addBimAndCoObjectPhotos($input: AddBimAndCoObjectPhotosInput!) {
  addBimAndCoObjectPhotos(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBimAndCoObjectPhotosGQL extends Apollo.Mutation<AddBimAndCoObjectPhotosMutation, AddBimAndCoObjectPhotosMutationVariables> {
    document = AddBimAndCoObjectPhotosDocument;
    
  }
export const DeleteBimAndCoObjectPhotosDocument = gql`
    mutation deleteBimAndCoObjectPhotos($input: DeleteBimAndCoObjectPhotosInput!) {
  deleteBimAndCoObjectPhotos(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBimAndCoObjectPhotosGQL extends Apollo.Mutation<DeleteBimAndCoObjectPhotosMutation, DeleteBimAndCoObjectPhotosMutationVariables> {
    document = DeleteBimAndCoObjectPhotosDocument;
    
  }
export const RemoveSpotConnectionsDocument = gql`
    mutation removeSpotConnections($input: RemoveSpotConnectionsInput!) {
  removeSpotConnections(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveSpotConnectionsGQL extends Apollo.Mutation<RemoveSpotConnectionsMutation, RemoveSpotConnectionsMutationVariables> {
    document = RemoveSpotConnectionsDocument;
    
  }
export const SetDefaultBimAndCoPhotoDocument = gql`
    mutation setDefaultBimAndCoPhoto($input: SetDefaultBimAndCoPhotoInput!) {
  setDefaultBimAndCoPhoto(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDefaultBimAndCoPhotoGQL extends Apollo.Mutation<SetDefaultBimAndCoPhotoMutation, SetDefaultBimAndCoPhotoMutationVariables> {
    document = SetDefaultBimAndCoPhotoDocument;
    
  }
export const UpdateBimAndCoObjectCatalogsDocument = gql`
    mutation updateBimAndCoObjectCatalogs($input: UpdateObjectCatalogsInput!) {
  updateObjectCatalogs(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBimAndCoObjectCatalogsGQL extends Apollo.Mutation<UpdateBimAndCoObjectCatalogsMutation, UpdateBimAndCoObjectCatalogsMutationVariables> {
    document = UpdateBimAndCoObjectCatalogsDocument;
    
  }
export const UpdateObjectPropertiesDocument = gql`
    mutation updateObjectProperties($input: UpdateObjectPropertiesInput!) {
  updateObjectProperties(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateObjectPropertiesGQL extends Apollo.Mutation<UpdateObjectPropertiesMutation, UpdateObjectPropertiesMutationVariables> {
    document = UpdateObjectPropertiesDocument;
    
  }
export const UpdateObjectNameDocument = gql`
    mutation updateObjectName($input: UpdateObjectNameInput!) {
  updateObjectName(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateObjectNameGQL extends Apollo.Mutation<UpdateObjectNameMutation, UpdateObjectNameMutationVariables> {
    document = UpdateObjectNameDocument;
    
  }
export const UpdateModelPropertiesDocument = gql`
    mutation updateModelProperties($input: [UpdateModelPropertiesInput]!) {
  updateModelProperties(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelPropertiesGQL extends Apollo.Mutation<UpdateModelPropertiesMutation, UpdateModelPropertiesMutationVariables> {
    document = UpdateModelPropertiesDocument;
    
  }
export const UpdateObjectSpotConnectionDocument = gql`
    mutation updateObjectSpotConnection($input: UpdateObjectSpotConnectionInput!) {
  updateObjectSpotConnection(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateObjectSpotConnectionGQL extends Apollo.Mutation<UpdateObjectSpotConnectionMutation, UpdateObjectSpotConnectionMutationVariables> {
    document = UpdateObjectSpotConnectionDocument;
    
  }
export const UpdateObjectSpotDataDocument = gql`
    mutation updateObjectSpotData($input: [UpdateSpotDataInput]!) {
  updateObjectSpotData(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateObjectSpotDataGQL extends Apollo.Mutation<UpdateObjectSpotDataMutation, UpdateObjectSpotDataMutationVariables> {
    document = UpdateObjectSpotDataDocument;
    
  }
export const UpdateObjectSubmittalsDocument = gql`
    mutation UpdateObjectSubmittals($input: UpdateObjectSubmittalsInput!) {
  updateObjectSubmittals(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateObjectSubmittalsGQL extends Apollo.Mutation<UpdateObjectSubmittalsMutation, UpdateObjectSubmittalsMutationVariables> {
    document = UpdateObjectSubmittalsDocument;
    
  }
export const UpdateSpotSoftwareDocument = gql`
    mutation updateSpotSoftware($input: [UpdateSpotSoftwareInput]!) {
  updateSpotSoftware(input: $input) {
    responseStatus
    responseMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSpotSoftwareGQL extends Apollo.Mutation<UpdateSpotSoftwareMutation, UpdateSpotSoftwareMutationVariables> {
    document = UpdateSpotSoftwareDocument;
    
  }
export const GetBimCoObjectsDocument = gql`
    query getBimCoObjects($objectIdList: [String]) {
  getBimAndCoObjects(objectIdList: $objectIdList) {
    Id
    UpdatedAt
    CreatedAt
    Name
    Description
    DefaultPhoto
    Photos {
      Id
      FileName
      IsDefault
      Path
    }
    Catalogs {
      Id
      Name
    }
    ThreeDModels {
      Id
      Name
      FileName
      Extension
      LevelOfDetail
    }
    TwoDModels {
      Id
      Name
      FileName
      Extension
      LevelOfDetail
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimCoObjectsGQL extends Apollo.Query<GetBimCoObjectsQuery, GetBimCoObjectsQueryVariables> {
    document = GetBimCoObjectsDocument;
    
  }
export const GetBimObjectDataDocument = gql`
    query getBimObjectData {
  getBimObjects {
    BimObjects {
      Id
      Name
      Photo
      Catalog {
        Id
        Name
      }
    }
    Catalogs {
      Id
      Name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimObjectDataGQL extends Apollo.Query<GetBimObjectDataQuery, GetBimObjectDataQueryVariables> {
    document = GetBimObjectDataDocument;
    
  }
export const GetDamDataDocument = gql`
    query getDamData($search_val: String!) {
  getDamImages(search_val: $search_val) {
    smallImageUrl
    largeImageUrl
    fileName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDamDataGQL extends Apollo.Query<GetDamDataQuery, GetDamDataQueryVariables> {
    document = GetDamDataDocument;
    
  }
export const GetObjectSpotDataDocument = gql`
    query getObjectSpotData($object_names: [String]!) {
  getSpotData(object_names: $object_names) {
    ObjectName
    PermaLink
    PrimeTimestamps {
      SoftwareType
      LastUpdated
    }
    SpotData {
      Software
      FilePath
      TimeStamp
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetObjectSpotDataGQL extends Apollo.Query<GetObjectSpotDataQuery, GetObjectSpotDataQueryVariables> {
    document = GetObjectSpotDataDocument;
    
  }
export const GetSpotSoftwareRecordsDocument = gql`
    query getSpotSoftwareRecords {
  getSpotSoftwareRecords {
    FilePath
    FileName
    Software
    LastUpdated
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpotSoftwareRecordsGQL extends Apollo.Query<GetSpotSoftwareRecordsQuery, GetSpotSoftwareRecordsQueryVariables> {
    document = GetSpotSoftwareRecordsDocument;
    
  }
export const GetSpotStatusesDocument = gql`
    query getSpotStatuses {
  getSpotStatuses {
    content_object_name
    description
    permalink
    style
    last_updated
    file_path
    file_name
    content_software_status_id
    content_object_id
    uploaded_bim_and_co
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpotStatusesGQL extends Apollo.Query<GetSpotStatusesQuery, GetSpotStatusesQueryVariables> {
    document = GetSpotStatusesDocument;
    
  }
export const GetSpotStylesDocument = gql`
    query getSpotStyles {
  getSpotStyles {
    Style
    Description
    Permalink
    Submittal
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpotStylesGQL extends Apollo.Query<GetSpotStylesQuery, GetSpotStylesQueryVariables> {
    document = GetSpotStylesDocument;
    
  }
export const AddActiveQuoteFieldDocument = gql`
    mutation addActiveQuoteField($input: UpdateActiveQuoteFieldInput!) {
  addActiveQuoteField(input: $input) {
    quote_field_id
    quote_id
    quote_field_master_id
    section_id
    units
    field_name
    man_hours
    calc_value
    calc_cost
    active
    manual
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddActiveQuoteFieldGQL extends Apollo.Mutation<AddActiveQuoteFieldMutation, AddActiveQuoteFieldMutationVariables> {
    document = AddActiveQuoteFieldDocument;
    
  }
export const AddQuoteFieldRecordDocument = gql`
    mutation addQuoteFieldRecord($input: [QuoteFieldRecordInput]) {
  addQuoteFields(input: $input) {
    quote_field_id
    quote_id
    quote_field_master_id
    section_id
    units
    field_name
    man_hours
    calc_value
    calc_cost
    active
    manual
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddQuoteFieldRecordGQL extends Apollo.Mutation<AddQuoteFieldRecordMutation, AddQuoteFieldRecordMutationVariables> {
    document = AddQuoteFieldRecordDocument;
    
  }
export const AutoPopulateAreaQuotesDocument = gql`
    mutation autoPopulateAreaQuotes($input: AutoPopulateAreaQuotesInput!) {
  autoPopulateAreaQuotes(input: $input) {
    missingEquipment {
      type
      count
    }
    quotes {
      quote_id
    }
    quoteFailed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoPopulateAreaQuotesGQL extends Apollo.Mutation<AutoPopulateAreaQuotesMutation, AutoPopulateAreaQuotesMutationVariables> {
    document = AutoPopulateAreaQuotesDocument;
    
  }
export const ClearQuoteFieldsFromSectionDocument = gql`
    mutation clearQuoteFieldsFromSection($input: [QuoteFieldRecordInput]) {
  clearQuoteFieldsFromSection(input: $input) {
    quote_field_id
    quote_id
    quote_field_master_id
    section_id
    units
    field_name
    man_hours
    calc_value
    calc_cost
    active
    manual
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClearQuoteFieldsFromSectionGQL extends Apollo.Mutation<ClearQuoteFieldsFromSectionMutation, ClearQuoteFieldsFromSectionMutationVariables> {
    document = ClearQuoteFieldsFromSectionDocument;
    
  }
export const CreateNewAreaPhaseDocument = gql`
    mutation createNewAreaPhase($input: CreateNewAreaPhaseInput!) {
  createNewAreaPhase(input: $input) {
    area_id
    is_active
    name
    phase_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewAreaPhaseGQL extends Apollo.Mutation<CreateNewAreaPhaseMutation, CreateNewAreaPhaseMutationVariables> {
    document = CreateNewAreaPhaseDocument;
    
  }
export const CreateNewAreaDocument = gql`
    mutation createNewArea($input: CreateNewProjectAreaInput!) {
  createNewProjectArea(input: $input) {
    ...EstimatingAreaBaseFieldsFragment
  }
}
    ${EstimatingAreaBaseFieldsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewAreaGQL extends Apollo.Mutation<CreateNewAreaMutation, CreateNewAreaMutationVariables> {
    document = CreateNewAreaDocument;
    
  }
export const CreateNewMasterQuoteFieldDocument = gql`
    mutation createNewMasterQuoteField($input: CreateNewMasterQuoteFieldInput!) {
  createNewMasterQuoteField(input: $input) {
    ...MasterQuoteFieldsFragment
  }
}
    ${MasterQuoteFieldsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewMasterQuoteFieldGQL extends Apollo.Mutation<CreateNewMasterQuoteFieldMutation, CreateNewMasterQuoteFieldMutationVariables> {
    document = CreateNewMasterQuoteFieldDocument;
    
  }
export const CreateNewPhaseQuoteDocument = gql`
    mutation createNewPhaseQuote($input: CreateNewPhaseQuoteInput!) {
  createNewPhaseQuote(input: $input) {
    quote_id
    quote_name
    drawing_numbers
    drawing_revision
    limits
    rate
    rate_calc
    phase_id
    total_layout
    worksheet_id
    riser_offset
    quote_fields {
      quote_field_id
      quote_id
      quote_field_master_id
      section_id
      units
      field_name
      man_hours
      calc_value
      calc_cost
      active
      manual
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewPhaseQuoteGQL extends Apollo.Mutation<CreateNewPhaseQuoteMutation, CreateNewPhaseQuoteMutationVariables> {
    document = CreateNewPhaseQuoteDocument;
    
  }
export const DeleteQuoteDocument = gql`
    mutation deleteQuote($input: DeleteQuoteInput!) {
  deleteQuote(input: $input) {
    phase_id
    drawing_numbers
    drawing_revision
    limits
    quote_id
    quote_name
    rate
    rate_calc
    riser_offset
    total_layout
    worksheet_id
    quote_fields {
      quote_field_id
      quote_id
      quote_field_master_id
      section_id
      units
      field_name
      man_hours
      calc_value
      calc_cost
      active
      manual
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteQuoteGQL extends Apollo.Mutation<DeleteQuoteMutation, DeleteQuoteMutationVariables> {
    document = DeleteQuoteDocument;
    
  }
export const ResetQuoteEquipmentDocument = gql`
    mutation resetQuoteEquipment($input: ResetQuoteEquipmentInput!) {
  resetQuoteEquipment(input: $input) {
    missingEquipment {
      type
      count
    }
    quotes {
      quote_id
    }
    quoteFailed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetQuoteEquipmentGQL extends Apollo.Mutation<ResetQuoteEquipmentMutation, ResetQuoteEquipmentMutationVariables> {
    document = ResetQuoteEquipmentDocument;
    
  }
export const UpdateAreaEstimatingHoursDocument = gql`
    mutation updateAreaEstimatingHours($input: UpdateEstimatingHoursInput!) {
  updateEstimatingAreaHours(input: $input) {
    area_id
    hfm_id
    hours
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAreaEstimatingHoursGQL extends Apollo.Mutation<UpdateAreaEstimatingHoursMutation, UpdateAreaEstimatingHoursMutationVariables> {
    document = UpdateAreaEstimatingHoursDocument;
    
  }
export const UpdateAreaPhaseDocument = gql`
    mutation updateAreaPhase($input: UpdateAreaPhaseInput!) {
  updateAreaPhase(input: $input) {
    phase_id
    name
    market
    is_active
    area_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAreaPhaseGQL extends Apollo.Mutation<UpdateAreaPhaseMutation, UpdateAreaPhaseMutationVariables> {
    document = UpdateAreaPhaseDocument;
    
  }
export const UpdateBaseQuoteFieldsDocument = gql`
    mutation updateBaseQuoteFields($input: [UpdateBaseQuoteFieldsInput]!) {
  updateBaseQuoteFields(input: $input) {
    ...MasterQuoteFieldsFragment
  }
}
    ${MasterQuoteFieldsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBaseQuoteFieldsGQL extends Apollo.Mutation<UpdateBaseQuoteFieldsMutation, UpdateBaseQuoteFieldsMutationVariables> {
    document = UpdateBaseQuoteFieldsDocument;
    
  }
export const UpdateEstimatingAreaDocument = gql`
    mutation updateEstimatingArea($input: UpdateEstimatingAreaInput) {
  updateEstimatingArea(input: $input) {
    ...EstimatingAreaBaseFieldsFragment
  }
}
    ${EstimatingAreaBaseFieldsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEstimatingAreaGQL extends Apollo.Mutation<UpdateEstimatingAreaMutation, UpdateEstimatingAreaMutationVariables> {
    document = UpdateEstimatingAreaDocument;
    
  }
export const UpdateFieldManualDocument = gql`
    mutation updateFieldManual($input: UpdateFieldManualInput!) {
  updateFieldManual(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFieldManualGQL extends Apollo.Mutation<UpdateFieldManualMutation, UpdateFieldManualMutationVariables> {
    document = UpdateFieldManualDocument;
    
  }
export const UpdateProjectFieldsHoursDocument = gql`
    mutation updateProjectFieldsHours($input: UpdateProjectHoursFieldsInput) {
  updateProjectHoursFields(input: $input) {
    hfm_id
    pf_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectFieldsHoursGQL extends Apollo.Mutation<UpdateProjectFieldsHoursMutation, UpdateProjectFieldsHoursMutationVariables> {
    document = UpdateProjectFieldsHoursDocument;
    
  }
export const UpdateProjectOfferingsDocument = gql`
    mutation UpdateProjectOfferings($input: UpdateProjectOfferingsInput) {
  updateProjectOffering(input: $input) {
    offeringid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectOfferingsGQL extends Apollo.Mutation<UpdateProjectOfferingsMutation, UpdateProjectOfferingsMutationVariables> {
    document = UpdateProjectOfferingsDocument;
    
  }
export const UpdateQuoteBaseInfoDocument = gql`
    mutation UpdateQuoteBaseInfo($input: UpdateQuoteBaseInfoInput!) {
  updateQuoteBaseInfo(input: $input) {
    drawing_numbers
    drawing_revision
    limits
    phase_id
    quote_id
    quote_name
    rate
    rate_calc
    riser_offset
    total_layout
    worksheet_id
    quote_fields {
      quote_field_id
      quote_id
      quote_field_master_id
      section_id
      units
      field_name
      man_hours
      calc_value
      calc_cost
      active
      manual
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuoteBaseInfoGQL extends Apollo.Mutation<UpdateQuoteBaseInfoMutation, UpdateQuoteBaseInfoMutationVariables> {
    document = UpdateQuoteBaseInfoDocument;
    
  }
export const UpdateQuoteFieldDataDocument = gql`
    mutation updateQuoteFieldData($input: [UpdateQuoteFieldDataInput]!) {
  updateQuoteFieldData(input: $input) {
    quote_field_id
    quote_id
    quote_field_master_id
    section_id
    units
    field_name
    man_hours
    calc_value
    calc_cost
    active
    manual
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuoteFieldDataGQL extends Apollo.Mutation<UpdateQuoteFieldDataMutation, UpdateQuoteFieldDataMutationVariables> {
    document = UpdateQuoteFieldDataDocument;
    
  }
export const UpdateUnitsManuallyDocument = gql`
    mutation updateUnitsManually($input: UpdateUnitsManuallyInput!) {
  updateUnitsManually(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUnitsManuallyGQL extends Apollo.Mutation<UpdateUnitsManuallyMutation, UpdateUnitsManuallyMutationVariables> {
    document = UpdateUnitsManuallyDocument;
    
  }
export const UpdateQuoteFieldUnitsDocument = gql`
    mutation updateQuoteFieldUnits($input: QuoteFieldRecordInput) {
  updateQuoteFieldUnits(input: $input) {
    quote_field_id
    quote_id
    quote_field_master_id
    section_id
    units
    field_name
    man_hours
    calc_value
    calc_cost
    active
    manual
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuoteFieldUnitsGQL extends Apollo.Mutation<UpdateQuoteFieldUnitsMutation, UpdateQuoteFieldUnitsMutationVariables> {
    document = UpdateQuoteFieldUnitsDocument;
    
  }
export const UpdateQuoteWorksheetIdDocument = gql`
    mutation updateQuoteWorksheetID($input: UpdateQuoteWorksheetIdInput!) {
  updateQuoteWorksheetID(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuoteWorksheetIdGQL extends Apollo.Mutation<UpdateQuoteWorksheetIdMutation, UpdateQuoteWorksheetIdMutationVariables> {
    document = UpdateQuoteWorksheetIdDocument;
    
  }
export const UpdateRiserOffsetDocument = gql`
    mutation updateRiserOffset($input: UpdateRiserOffsetInput!) {
  updateRiserOffset(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRiserOffsetGQL extends Apollo.Mutation<UpdateRiserOffsetMutation, UpdateRiserOffsetMutationVariables> {
    document = UpdateRiserOffsetDocument;
    
  }
export const GetEstimatingSelectOptionsDocument = gql`
    query GetEstimatingSelectOptions {
  projectSelectOptions {
    changeOrderStatusTypes {
      status_id
      status_name
    }
    hoursFields {
      hfm_id
      field
      category
      hour_category
      percent
      section_id
      estimated
    }
    hoursFieldsCategories {
      category_id
      category
    }
    offerings {
      unique_id
      offeringid
      offering
    }
    quoteFields {
      ...MasterQuoteFieldsFragment
    }
    quoteSections {
      section_id
      section
    }
    quoteWorksheets {
      worksheet_id
      worksheet
    }
  }
}
    ${MasterQuoteFieldsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEstimatingSelectOptionsGQL extends Apollo.Query<GetEstimatingSelectOptionsQuery, GetEstimatingSelectOptionsQueryVariables> {
    document = GetEstimatingSelectOptionsDocument;
    
  }
export const GetProjectEstimatingDataDocument = gql`
    query GetProjectEstimatingData($proj_id: Int!) {
  project(proj_id: $proj_id) {
    proj_id
    projid
    proj_status
    areas {
      ...EstimatingAreaBaseFieldsFragment
      phases {
        area_id
        is_active
        market
        name
        phase_id
        quotes {
          phase_id
          drawing_numbers
          drawing_revision
          limits
          quote_id
          quote_name
          rate
          rate_calc
          riser_offset
          total_layout
          worksheet_id
          quote_fields {
            quote_field_id
            quote_id
            quote_field_master_id
            section_id
            units
            field_name
            man_hours
            calc_value
            calc_cost
            active
            manual
          }
        }
      }
      estimatingHours {
        est_id
        hfm_id
        hours
      }
    }
    hours_fields {
      pf_id
      hfm_id
    }
    offerings {
      offeringid
    }
    notes {
      ...ProjectNoteFragment
    }
  }
}
    ${EstimatingAreaBaseFieldsFragmentFragmentDoc}
${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectEstimatingDataGQL extends Apollo.Query<GetProjectEstimatingDataQuery, GetProjectEstimatingDataQueryVariables> {
    document = GetProjectEstimatingDataDocument;
    
  }
export const CheckRequiresManualFabQuoteDocument = gql`
    query checkRequiresManualFabQuote($proj_id: Int!) {
  project(proj_id: $proj_id) {
    requires_manual_fab_quote
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckRequiresManualFabQuoteGQL extends Apollo.Query<CheckRequiresManualFabQuoteQuery, CheckRequiresManualFabQuoteQueryVariables> {
    document = CheckRequiresManualFabQuoteDocument;
    
  }
export const GetProjectFabViewBaseDocument = gql`
    query getProjectFabViewBase($proj_id: Int!) {
  project(proj_id: $proj_id) {
    ...ProjectFabViewFragment
  }
}
    ${ProjectFabViewFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectFabViewBaseGQL extends Apollo.Query<GetProjectFabViewBaseQuery, GetProjectFabViewBaseQueryVariables> {
    document = GetProjectFabViewBaseDocument;
    
  }
export const GetProjectFabCalcDataV2Document = gql`
    query getProjectFabCalcDataV2($proj_id: Int!) {
  getProjectFabCalcDataV2(proj_id: $proj_id) {
    ...ProjectFabViewFragment
  }
}
    ${ProjectFabViewFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectFabCalcDataV2GQL extends Apollo.Query<GetProjectFabCalcDataV2Query, GetProjectFabCalcDataV2QueryVariables> {
    document = GetProjectFabCalcDataV2Document;
    
  }
export const AddAdditionalFinancesOfferingDocument = gql`
    mutation addAdditionalFinancesOffering($input: AddAdditionalFinancesOfferingInput!) {
  addAdditionalFinancesOffering(input: $input) {
    finances {
      ...FinancesFragment
    }
    additionalOfferings {
      ...OfferingsFragment
    }
    materialBreakdown {
      ...FinancesFragment
    }
    transportationBreakdown {
      ...FinancesFragment
    }
  }
}
    ${FinancesFragmentFragmentDoc}
${OfferingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAdditionalFinancesOfferingGQL extends Apollo.Mutation<AddAdditionalFinancesOfferingMutation, AddAdditionalFinancesOfferingMutationVariables> {
    document = AddAdditionalFinancesOfferingDocument;
    
  }
export const RemoveAdditionalFinancesOfferingDocument = gql`
    mutation removeAdditionalFinancesOffering($input: RemoveAdditionalFinancesOfferingInput!) {
  removeAdditionalFinancesOffering(input: $input) {
    finances {
      ...FinancesFragment
    }
    additionalOfferings {
      ...OfferingsFragment
    }
    materialBreakdown {
      ...FinancesFragment
    }
    transportationBreakdown {
      ...FinancesFragment
    }
  }
}
    ${FinancesFragmentFragmentDoc}
${OfferingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveAdditionalFinancesOfferingGQL extends Apollo.Mutation<RemoveAdditionalFinancesOfferingMutation, RemoveAdditionalFinancesOfferingMutationVariables> {
    document = RemoveAdditionalFinancesOfferingDocument;
    
  }
export const FetchLatestDataDocument = gql`
    mutation fetchLatestData($input: FetchLatestDataInput!) {
  fetchLatestData(input: $input) {
    financesData {
      finances {
        ...FinancesFragment
      }
      materialBreakdown {
        ...FinancesFragment
      }
      transportationBreakdown {
        ...FinancesFragment
      }
      financesHours {
        ...FinancesHoursFragment
      }
      additionalOfferings {
        ...OfferingsFragment
      }
    }
    errors
  }
}
    ${FinancesFragmentFragmentDoc}
${FinancesHoursFragmentFragmentDoc}
${OfferingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchLatestDataGQL extends Apollo.Mutation<FetchLatestDataMutation, FetchLatestDataMutationVariables> {
    document = FetchLatestDataDocument;
    
  }
export const SetFinancesCompleteDocument = gql`
    mutation setFinancesComplete($input: SetFinancesCompleteInput!) {
  setFinancesComplete(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFinancesCompleteGQL extends Apollo.Mutation<SetFinancesCompleteMutation, SetFinancesCompleteMutationVariables> {
    document = SetFinancesCompleteDocument;
    
  }
export const UpdateFinancesHoursDocument = gql`
    mutation updateFinancesHours($input: UpdateFinancesHoursInput!) {
  updateFinancesHours(input: $input) {
    ...FinancesHoursFragment
  }
}
    ${FinancesHoursFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFinancesHoursGQL extends Apollo.Mutation<UpdateFinancesHoursMutation, UpdateFinancesHoursMutationVariables> {
    document = UpdateFinancesHoursDocument;
    
  }
export const UpdateFinancesItemDocument = gql`
    mutation updateFinancesItem($input: UpdateFinancesItemInput!) {
  updateFinancesItem(input: $input) {
    finances {
      ...FinancesFragment
    }
    materialBreakdown {
      ...FinancesFragment
    }
    transportationBreakdown {
      ...FinancesFragment
    }
  }
}
    ${FinancesFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFinancesItemGQL extends Apollo.Mutation<UpdateFinancesItemMutation, UpdateFinancesItemMutationVariables> {
    document = UpdateFinancesItemDocument;
    
  }
export const AddFinancesRevisionDocument = gql`
    mutation addFinancesRevision($input: AddFinancesRevisionInput!) {
  addFinancesRevision(input: $input) {
    quoteRevisions {
      ...QuoteRevisionsFragment
    }
    bomRevisions {
      ...RevisionFragment
    }
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddFinancesRevisionGQL extends Apollo.Mutation<AddFinancesRevisionMutation, AddFinancesRevisionMutationVariables> {
    document = AddFinancesRevisionDocument;
    
  }
export const DeleteFinancesRevisionDocument = gql`
    mutation deleteFinancesRevision($input: DeleteFinancesRevisionInput!) {
  deleteFinancesRevision(input: $input) {
    quoteRevisions {
      ...QuoteRevisionsFragment
    }
    bomRevisions {
      ...RevisionFragment
    }
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFinancesRevisionGQL extends Apollo.Mutation<DeleteFinancesRevisionMutation, DeleteFinancesRevisionMutationVariables> {
    document = DeleteFinancesRevisionDocument;
    
  }
export const EditFinancesRevisionDocument = gql`
    mutation editFinancesRevision($input: EditFinancesRevisionInput!) {
  editFinancesRevision(input: $input) {
    quoteRevisions {
      ...QuoteRevisionsFragment
    }
    bomRevisions {
      ...RevisionFragment
    }
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EditFinancesRevisionGQL extends Apollo.Mutation<EditFinancesRevisionMutation, EditFinancesRevisionMutationVariables> {
    document = EditFinancesRevisionDocument;
    
  }
export const MarkFinancesRevisionSoldDocument = gql`
    mutation markFinancesRevisionSold($input: MarkFinancesRevisionSoldInput!) {
  markFinancesRevisionSold(input: $input) {
    ...QuoteRevisionsFragment
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkFinancesRevisionSoldGQL extends Apollo.Mutation<MarkFinancesRevisionSoldMutation, MarkFinancesRevisionSoldMutationVariables> {
    document = MarkFinancesRevisionSoldDocument;
    
  }
export const RestoreFinancesRevisionDocument = gql`
    mutation restoreFinancesRevision($input: RestoreFinancesRevisionInput!) {
  restoreFinancesRevision(input: $input) {
    ...QuoteRevisionsFragment
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreFinancesRevisionGQL extends Apollo.Mutation<RestoreFinancesRevisionMutation, RestoreFinancesRevisionMutationVariables> {
    document = RestoreFinancesRevisionDocument;
    
  }
export const RevisionMultipleQuotesDocument = gql`
    mutation revisionMultipleQuotes($input: RevisionMultipleQuotesInput!) {
  revisionMultipleQuotes(input: $input) {
    quoteRevisions {
      ...QuoteRevisionsFragment
    }
    bomRevisions {
      ...RevisionFragment
    }
  }
}
    ${QuoteRevisionsFragmentFragmentDoc}
${RevisionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RevisionMultipleQuotesGQL extends Apollo.Mutation<RevisionMultipleQuotesMutation, RevisionMultipleQuotesMutationVariables> {
    document = RevisionMultipleQuotesDocument;
    
  }
export const ToggleScheduleImpactDocument = gql`
    mutation toggleScheduleImpact($input: UpdateEstimatingAreaInput!) {
  toggleScheduleImpact(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleScheduleImpactGQL extends Apollo.Mutation<ToggleScheduleImpactMutation, ToggleScheduleImpactMutationVariables> {
    document = ToggleScheduleImpactDocument;
    
  }
export const ToggleUpdatedPoDocument = gql`
    mutation toggleUpdatedPo($input: UpdateEstimatingAreaInput!) {
  toggleUpdatedPo(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleUpdatedPoGQL extends Apollo.Mutation<ToggleUpdatedPoMutation, ToggleUpdatedPoMutationVariables> {
    document = ToggleUpdatedPoDocument;
    
  }
export const UpdateChangeOrderStatusDocument = gql`
    mutation updateChangeOrderStatus($input: UpdateEstimatingAreaInput!) {
  updateChangeOrderStatus(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChangeOrderStatusGQL extends Apollo.Mutation<UpdateChangeOrderStatusMutation, UpdateChangeOrderStatusMutationVariables> {
    document = UpdateChangeOrderStatusDocument;
    
  }
export const GetFinancesDocument = gql`
    query getFinances($input: GetFinancesInput!) {
  getFinances(input: $input) {
    finances {
      ...FinancesFragment
    }
    materialBreakdown {
      ...FinancesFragment
    }
    transportationBreakdown {
      ...FinancesFragment
    }
    financesHours {
      ...FinancesHoursFragment
    }
    additionalOfferings {
      ...OfferingsFragment
    }
  }
}
    ${FinancesFragmentFragmentDoc}
${FinancesHoursFragmentFragmentDoc}
${OfferingsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFinancesGQL extends Apollo.Query<GetFinancesQuery, GetFinancesQueryVariables> {
    document = GetFinancesDocument;
    
  }
export const GetProjectFinancesDataDocument = gql`
    query getProjectFinancesData($proj_id: Int!) {
  project(proj_id: $proj_id) {
    proj_id
    projid
    project_name
    proj_num
    price_list_id
    region_id
    market_id
    currency
    currency_symbol
    proj_status
    notes {
      ...ProjectNoteFragment
    }
    project_finances {
      quotes {
        ...QuotesFragment
      }
      quoteRevisions {
        ...QuoteRevisionsFragment
      }
      changeOrderStatusTypes {
        ...ChangeOrderStatusTypesFragment
      }
    }
  }
}
    ${ProjectNoteFragmentFragmentDoc}
${QuotesFragmentFragmentDoc}
${QuoteRevisionsFragmentFragmentDoc}
${ChangeOrderStatusTypesFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectFinancesDataGQL extends Apollo.Query<GetProjectFinancesDataQuery, GetProjectFinancesDataQueryVariables> {
    document = GetProjectFinancesDataDocument;
    
  }
export const GetProjectTotalFinancesDocument = gql`
    query getProjectTotalFinances($proj_id: Int!) {
  getProjectTotalFinances(proj_id: $proj_id) {
    totalFinances {
      ...TotalFinancesFragment
    }
    projTotalsColumnsLabels {
      ...ProjTotalsColumnsLabelsFragment
    }
  }
}
    ${TotalFinancesFragmentFragmentDoc}
${ProjTotalsColumnsLabelsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectTotalFinancesGQL extends Apollo.Query<GetProjectTotalFinancesQuery, GetProjectTotalFinancesQueryVariables> {
    document = GetProjectTotalFinancesDocument;
    
  }
export const AddAppliedProjectNoteTypeDocument = gql`
    mutation addAppliedProjectNoteType($input: AddAppliedProjectNoteTypeInput!) {
  addAppliedProjectNoteType(input: $input) {
    ...ProjectNoteTypeFragment
  }
}
    ${ProjectNoteTypeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAppliedProjectNoteTypeGQL extends Apollo.Mutation<AddAppliedProjectNoteTypeMutation, AddAppliedProjectNoteTypeMutationVariables> {
    document = AddAppliedProjectNoteTypeDocument;
    
  }
export const AddProjectNoteDocument = gql`
    mutation addProjectNote($input: AddProjectNoteInput!) {
  addProjectNote(input: $input) {
    ...ProjectNoteFragment
  }
}
    ${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectNoteGQL extends Apollo.Mutation<AddProjectNoteMutation, AddProjectNoteMutationVariables> {
    document = AddProjectNoteDocument;
    
  }
export const DeleteAppliedProjectNoteTypeDocument = gql`
    mutation deleteAppliedProjectNoteType($input: DeleteAppliedProjectNoteTypeInput!) {
  deleteAppliedProjectNoteType(input: $input) {
    ...ProjectNoteTypeFragment
  }
}
    ${ProjectNoteTypeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAppliedProjectNoteTypeGQL extends Apollo.Mutation<DeleteAppliedProjectNoteTypeMutation, DeleteAppliedProjectNoteTypeMutationVariables> {
    document = DeleteAppliedProjectNoteTypeDocument;
    
  }
export const DeleteProjectNoteDocument = gql`
    mutation deleteProjectNote($input: DeleteProjectNoteInput!) {
  deleteProjectNote(input: $input) {
    ...ProjectNoteFragment
  }
}
    ${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectNoteGQL extends Apollo.Mutation<DeleteProjectNoteMutation, DeleteProjectNoteMutationVariables> {
    document = DeleteProjectNoteDocument;
    
  }
export const UpdateProjectNoteDocument = gql`
    mutation updateProjectNote($input: UpdateProjectNoteInput!) {
  updateProjectNote(input: $input) {
    ...ProjectNoteFragment
  }
}
    ${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectNoteGQL extends Apollo.Mutation<UpdateProjectNoteMutation, UpdateProjectNoteMutationVariables> {
    document = UpdateProjectNoteDocument;
    
  }
export const GetProjectNoteTypesDocument = gql`
    query getProjectNoteTypes {
  projectSelectOptions {
    projectNoteTypes {
      ...ProjectNoteTypeFragment
    }
  }
}
    ${ProjectNoteTypeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectNoteTypesGQL extends Apollo.Query<GetProjectNoteTypesQuery, GetProjectNoteTypesQueryVariables> {
    document = GetProjectNoteTypesDocument;
    
  }
export const GetProjectNotesDocument = gql`
    query GetProjectNotes($projid: Int!) {
  projectNotes(projid: $projid) {
    ...ProjectNoteFragment
  }
}
    ${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectNotesGQL extends Apollo.Query<GetProjectNotesQuery, GetProjectNotesQueryVariables> {
    document = GetProjectNotesDocument;
    
  }
export const AddProjectTeamMemberDocument = gql`
    mutation addProjectTeamMember($input: UpdateProjectTeamMemberInput!) {
  addProjectTeamMember(input: $input) {
    ...TeamMembersFragment
  }
}
    ${TeamMembersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectTeamMemberGQL extends Apollo.Mutation<AddProjectTeamMemberMutation, AddProjectTeamMemberMutationVariables> {
    document = AddProjectTeamMemberDocument;
    
  }
export const AddNewProjectDocument = gql`
    mutation addNewProject($input: AddNewProjectInput!) {
  addNewProject(input: $input) {
    project_name
    proj_id
    proj_num
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewProjectGQL extends Apollo.Mutation<AddNewProjectMutation, AddNewProjectMutationVariables> {
    document = AddNewProjectDocument;
    
  }
export const RemoveProjectTeamMemberDocument = gql`
    mutation removeProjectTeamMember($input: UpdateProjectTeamMemberInput!) {
  removeProjectTeamMember(input: $input) {
    ...TeamMembersFragment
  }
}
    ${TeamMembersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProjectTeamMemberGQL extends Apollo.Mutation<RemoveProjectTeamMemberMutation, RemoveProjectTeamMemberMutationVariables> {
    document = RemoveProjectTeamMemberDocument;
    
  }
export const UpdateProjectInfoViewDocument = gql`
    mutation updateProjectInfoView($input: UpdateProjectInfoViewInput!) {
  updateProjectInfoView(input: $input) {
    ...ProjectViewCoreFragment
    project_location {
      ...ProjectLocationFragment
    }
    team_members {
      ...TeamMembersFragment
    }
    offerings {
      offeringid
    }
  }
}
    ${ProjectViewCoreFragmentFragmentDoc}
${ProjectLocationFragmentFragmentDoc}
${TeamMembersFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectInfoViewGQL extends Apollo.Mutation<UpdateProjectInfoViewMutation, UpdateProjectInfoViewMutationVariables> {
    document = UpdateProjectInfoViewDocument;
    
  }
export const UpdateProjectLocationDocument = gql`
    mutation updateProjectLocation($input: UpdateProjectLocationInput!) {
  updateProjectLocation(input: $input) {
    ...ProjectLocationFragment
  }
}
    ${ProjectLocationFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectLocationGQL extends Apollo.Mutation<UpdateProjectLocationMutation, UpdateProjectLocationMutationVariables> {
    document = UpdateProjectLocationDocument;
    
  }
export const UpdateProjectStatusDateDocument = gql`
    mutation updateProjectStatusDate($input: UpdateProjectStatusDateInput!) {
  updateProjectStatusDate(input: $input) {
    statusHistory {
      status_id
      status
      reason
      status_date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectStatusDateGQL extends Apollo.Mutation<UpdateProjectStatusDateMutation, UpdateProjectStatusDateMutationVariables> {
    document = UpdateProjectStatusDateDocument;
    
  }
export const UpdateProjectStatusDocument = gql`
    mutation updateProjectStatus($input: ProjectStatusUpdateInput!) {
  updateProjectStatus(input: $input) {
    projectStatusRecords {
      status
      reason
      status_date
    }
    missingFields
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectStatusGQL extends Apollo.Mutation<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables> {
    document = UpdateProjectStatusDocument;
    
  }
export const GetProjectActivityHistoryDocument = gql`
    query getProjectActivityHistory($proj_id: Int!) {
  project(proj_id: $proj_id) {
    proj_id
    activity_history {
      ...TrackingActivityFragment
    }
  }
}
    ${TrackingActivityFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectActivityHistoryGQL extends Apollo.Query<GetProjectActivityHistoryQuery, GetProjectActivityHistoryQueryVariables> {
    document = GetProjectActivityHistoryDocument;
    
  }
export const GetProjectNotificationsDocument = gql`
    query GetProjectNotifications($proj_id: Int!) {
  project(proj_id: $proj_id) {
    notifications {
      has_notification
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectNotificationsGQL extends Apollo.Query<GetProjectNotificationsQuery, GetProjectNotificationsQueryVariables> {
    document = GetProjectNotificationsDocument;
    
  }
export const ProjectSearchDocument = gql`
    query projectSearch($searchFields: ProjectSearchInput) {
  projectSearch(searchFields: $searchFields) {
    region
    project_name
    proj_id
    proj_status
    proj_num
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectSearchGQL extends Apollo.Query<ProjectSearchQuery, ProjectSearchQueryVariables> {
    document = ProjectSearchDocument;
    
  }
export const GetProjectSelectOptionsDocument = gql`
    query GetProjectSelectOptions {
  projectSelectOptions {
    customers {
      customer
      customer_id
    }
    regions {
      region_id
      region
    }
    salesmen {
      autoID
      name
    }
    users {
      user_id
      full_name
      employeeid
      cps_group
      status
      group
    }
    projectStatuses {
      project_status_id
      status_name
    }
    projectTypes {
      projtypeid
      projtype
    }
    markets {
      market_id
      market
    }
    additionalProjectTypes {
      project_type_id
      project_type
    }
    services {
      service_type_id
      service_name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectSelectOptionsGQL extends Apollo.Query<GetProjectSelectOptionsQuery, GetProjectSelectOptionsQueryVariables> {
    document = GetProjectSelectOptionsDocument;
    
  }
export const GetProjectInformationViewDocument = gql`
    query GetProjectInformationView($proj_id: Int!) {
  projectSelectOptions {
    additionalProjectTypes {
      project_type_id
      project_type
    }
    countries {
      country_id
      country
    }
    projectStatuses {
      project_status_id
      status_name
    }
    currencies {
      currency
    }
    customers {
      customer
      customer_id
    }
    customerTypes {
      c_type_id
      c_type
    }
    markets {
      market_id
      market
    }
    priceLists {
      price_list_id
      price_list_name
    }
    projectTypes {
      projtypeid
      projtype
    }
    regions {
      region
      region_id
    }
    services {
      service_type_id
      service_name
    }
    states
    provinces {
      province
      province_name
    }
    engineers {
      engineer_id
      name
    }
    salesmen {
      name
      autoID
    }
    users {
      user_id
      employeeid
      full_name
    }
  }
  project(proj_id: $proj_id) {
    ...ProjectViewCoreFragment
    project_location {
      ...ProjectLocationFragment
    }
    team_members {
      ...TeamMembersFragment
    }
    notes {
      ...ProjectNoteFragment
    }
  }
}
    ${ProjectViewCoreFragmentFragmentDoc}
${ProjectLocationFragmentFragmentDoc}
${TeamMembersFragmentFragmentDoc}
${ProjectNoteFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectInformationViewGQL extends Apollo.Query<GetProjectInformationViewQuery, GetProjectInformationViewQueryVariables> {
    document = GetProjectInformationViewDocument;
    
  }
export const AddNewCustomerDocument = gql`
    mutation addNewCustomer($input: AddNewCustomerInput!) {
  addNewCustomer(input: $input) {
    customers {
      customer
      customer_id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewCustomerGQL extends Apollo.Mutation<AddNewCustomerMutation, AddNewCustomerMutationVariables> {
    document = AddNewCustomerDocument;
    
  }
export const GetCurrenciesByRegionDocument = gql`
    query GetCurrenciesByRegion($region_id: Int!) {
  currencyByRegion(region_id: $region_id) {
    currency
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrenciesByRegionGQL extends Apollo.Query<GetCurrenciesByRegionQuery, GetCurrenciesByRegionQueryVariables> {
    document = GetCurrenciesByRegionDocument;
    
  }
export const GetFilteredPriceListsDocument = gql`
    query GetFilteredPriceLists($region_id: Int!, $currency: String!) {
  filteredPriceList(region_id: $region_id, currency: $currency) {
    price_list_id
    price_list_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilteredPriceListsGQL extends Apollo.Query<GetFilteredPriceListsQuery, GetFilteredPriceListsQueryVariables> {
    document = GetFilteredPriceListsDocument;
    
  }
export const GetCommonSelectOptionsDocument = gql`
    query GetCommonSelectOptions {
  projectSelectOptions {
    regions {
      region
      region_id
    }
    projectTypes {
      projtypeid
      projtype
      projtypesuffix
    }
    markets {
      market_id
      market
    }
    additionalProjectTypes {
      project_type_id
      project_type
    }
    offerings {
      offering
      offeringid
    }
    customers {
      customer_id
      customer
      city
      state
    }
    projectStatuses {
      status_name
      project_status_id
    }
    users {
      ...UserFragment
    }
    salesmen {
      name
      salesregion_id
      groupID
      metroID
      divisionID
      autoID
    }
    customerTypes {
      c_type_id
      c_type
    }
    customers {
      customer_id
      customer
      city
      state
    }
    usersAccess {
      user_id
      bimcreator
    }
    legislativeActs {
      act_id
      act_name
    }
    gasket_types {
      gasket_id
      gasket_type
      gasket_value
    }
    bomColumns {
      column_id
      column
      label
      is_split
      selected_by_default
      editable
      type
      width
    }
  }
}
    ${UserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCommonSelectOptionsGQL extends Apollo.Query<GetCommonSelectOptionsQuery, GetCommonSelectOptionsQueryVariables> {
    document = GetCommonSelectOptionsDocument;
    
  }
export const GetTrackingActivityGroupsDocument = gql`
    query GetTrackingActivityGroups {
  projectSelectOptions {
    trackingActivityGroups {
      activity_group_id
      group_type
      active
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTrackingActivityGroupsGQL extends Apollo.Query<GetTrackingActivityGroupsQuery, GetTrackingActivityGroupsQueryVariables> {
    document = GetTrackingActivityGroupsDocument;
    
  }
export const UpdateQuoteDocumentDocument = gql`
    mutation updateQuoteDocument($input: UpdateQuoteDocumentInput!) {
  updateQuoteDocument(input: $input) {
    projectcountry
    region_id
    current_project_type_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuoteDocumentGQL extends Apollo.Mutation<UpdateQuoteDocumentMutation, UpdateQuoteDocumentMutationVariables> {
    document = UpdateQuoteDocumentDocument;
    
  }
export const GetQuoteDocumentDataDocument = gql`
    query getQuoteDocumentData($proj_id: Int!) {
  getQuoteDocumentData(proj_id: $proj_id) {
    latestRevUsed
    quoteRevisions {
      ...QuoteDocRevisionFragment
    }
    drawingInfo
    specInfo
    pipingSystems
    areaLimits {
      ...AreaLimitFragment
    }
    notes {
      ...QuoteNoteFragment
    }
    inclusionsDictionary {
      Drawing {
        index
        sub_class
        inclusions {
          inclusion_id
          master_class
          sub_class
          description
          default_value
          value
        }
      }
      General {
        index
        sub_class
        inclusions {
          inclusion_id
          master_class
          sub_class
          description
          default_value
          value
        }
      }
      Material {
        index
        sub_class
        inclusions {
          inclusion_id
          master_class
          sub_class
          description
          default_value
          value
        }
      }
    }
    inclusionsList {
      ...InclusionFragment
    }
    numberOfSubclasses
    areaLimitsString
    pipingSystemsString
  }
}
    ${QuoteDocRevisionFragmentFragmentDoc}
${AreaLimitFragmentFragmentDoc}
${QuoteNoteFragmentFragmentDoc}
${InclusionFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuoteDocumentDataGQL extends Apollo.Query<GetQuoteDocumentDataQuery, GetQuoteDocumentDataQueryVariables> {
    document = GetQuoteDocumentDataDocument;
    
  }
export const GetBimCoordinatorsScheduleDocument = gql`
    query getBimCoordinatorsSchedule {
  getBimCoordinationSchedule {
    gantt_tasks {
      id
      text
      duration
      start_date
      project_name
      color
      parent
      open
    }
    calendar_events {
      title
      start
      color {
        primary
        secondary
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimCoordinatorsScheduleGQL extends Apollo.Query<GetBimCoordinatorsScheduleQuery, GetBimCoordinatorsScheduleQueryVariables> {
    document = GetBimCoordinatorsScheduleDocument;
    
  }
export const GetPreBidModelResourceScheduleDocument = gql`
    query getPreBidModelResourceSchedule {
  getPreBidModelResourceSchedule {
    gantt_tasks {
      id
      text
      duration
      start_date
      project_name
      color
      parent
      open
      not_entered
      mbe_planned_completion_date
      quality_control_date
      model_submission_date
      requested_date_sales
      proj_id
      proj_base_name
      model_status
      project_status
      model_resource
      status_color
      project_status_color
    }
  }
  projectSelectOptions {
    projectStatuses {
      status_name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreBidModelResourceScheduleGQL extends Apollo.Query<GetPreBidModelResourceScheduleQuery, GetPreBidModelResourceScheduleQueryVariables> {
    document = GetPreBidModelResourceScheduleDocument;
    
  }
export const GetPreBidQualityControlResourceScheduleDocument = gql`
    query getPreBidQualityControlResourceSchedule {
  getPreBidQualityControlResourceSchedule {
    gantt_tasks {
      id
      text
      duration
      start_date
      project_name
      color
      parent
      open
      not_entered
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreBidQualityControlResourceScheduleGQL extends Apollo.Query<GetPreBidQualityControlResourceScheduleQuery, GetPreBidQualityControlResourceScheduleQueryVariables> {
    document = GetPreBidQualityControlResourceScheduleDocument;
    
  }
export const GetProjectSchedulesDocument = gql`
    query getProjectSchedules($input: GetProjectSchedulesInput!) {
  getProjectSchedules(input: $input) {
    proj_id
    project_name
    proj_num
    ...ProjectScheduleFragment
  }
}
    ${ProjectScheduleFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectSchedulesGQL extends Apollo.Query<GetProjectSchedulesQuery, GetProjectSchedulesQueryVariables> {
    document = GetProjectSchedulesDocument;
    
  }
export const GetSingleProjectScheduleDocument = gql`
    query getSingleProjectSchedule($proj_id: Int!) {
  project(proj_id: $proj_id) {
    proj_id
    project_name
    proj_num
    schedule_link
    schedule {
      ...ProjectScheduleFragment
    }
    sequences {
      name
      sequence_color
      sequence_dates {
        ...SequenceDateRecordsFragment
      }
      logistics {
        ...LogisticsFragment
      }
    }
  }
}
    ${ProjectScheduleFragmentFragmentDoc}
${SequenceDateRecordsFragmentFragmentDoc}
${LogisticsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSingleProjectScheduleGQL extends Apollo.Query<GetSingleProjectScheduleQuery, GetSingleProjectScheduleQueryVariables> {
    document = GetSingleProjectScheduleDocument;
    
  }
export const AddNewSequenceDocument = gql`
    mutation addNewSequence($input: AddNewSequenceInput!) {
  addNewSequence(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewSequenceGQL extends Apollo.Mutation<AddNewSequenceMutation, AddNewSequenceMutationVariables> {
    document = AddNewSequenceDocument;
    
  }
export const AddSequenceDateDocument = gql`
    mutation addSequenceDate($input: [AddSequenceDateInput]!) {
  addSequenceDates(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSequenceDateGQL extends Apollo.Mutation<AddSequenceDateMutation, AddSequenceDateMutationVariables> {
    document = AddSequenceDateDocument;
    
  }
export const AddTemplateDatesDocument = gql`
    mutation addTemplateDates($input: AddTemplateDatesInput!) {
  addTemplateDates(input: $input) {
    ...SequenceTemplateFragment
  }
}
    ${SequenceTemplateFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTemplateDatesGQL extends Apollo.Mutation<AddTemplateDatesMutation, AddTemplateDatesMutationVariables> {
    document = AddTemplateDatesDocument;
    
  }
export const AssignTruckDocument = gql`
    mutation assignTruck($input: AssignTruckInput!) {
  assignTruck(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignTruckGQL extends Apollo.Mutation<AssignTruckMutation, AssignTruckMutationVariables> {
    document = AssignTruckDocument;
    
  }
export const CopySequenceDocument = gql`
    mutation copySequence($input: CopySequenceInput!) {
  copySequence(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CopySequenceGQL extends Apollo.Mutation<CopySequenceMutation, CopySequenceMutationVariables> {
    document = CopySequenceDocument;
    
  }
export const CreateSequenceTemplateDocument = gql`
    mutation CreateSequenceTemplate($input: CreateSequenceTemplateInput!) {
  createSequenceTemplate(input: $input) {
    availableTemplateProjTypes {
      projtypeid
      projtype
      projtypesuffix
    }
    sequenceTemplates {
      ...SequenceTemplateFragment
    }
  }
}
    ${SequenceTemplateFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSequenceTemplateGQL extends Apollo.Mutation<CreateSequenceTemplateMutation, CreateSequenceTemplateMutationVariables> {
    document = CreateSequenceTemplateDocument;
    
  }
export const DeleteSequenceDateDocument = gql`
    mutation deleteSequenceDate($input: DeleteSequenceDateInput!) {
  deleteSequenceDate(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSequenceDateGQL extends Apollo.Mutation<DeleteSequenceDateMutation, DeleteSequenceDateMutationVariables> {
    document = DeleteSequenceDateDocument;
    
  }
export const DeleteSequenceDocument = gql`
    mutation deleteSequence($input: DeleteSequenceInput!) {
  deleteSequence(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSequenceGQL extends Apollo.Mutation<DeleteSequenceMutation, DeleteSequenceMutationVariables> {
    document = DeleteSequenceDocument;
    
  }
export const DeleteTemplateDateDocument = gql`
    mutation deleteTemplateDate($input: DeleteTemplateDateInput!) {
  deleteTemplateDate(input: $input) {
    ...SequenceTemplateFragment
  }
}
    ${SequenceTemplateFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTemplateDateGQL extends Apollo.Mutation<DeleteTemplateDateMutation, DeleteTemplateDateMutationVariables> {
    document = DeleteTemplateDateDocument;
    
  }
export const SubmitFabricationRequestDocument = gql`
    mutation submitFabricationRequest($input: SubmitFabricationRequestInput!) {
  submitFabricationRequest(input: $input) {
    success
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitFabricationRequestGQL extends Apollo.Mutation<SubmitFabricationRequestMutation, SubmitFabricationRequestMutationVariables> {
    document = SubmitFabricationRequestDocument;
    
  }
export const UnassignTruckDocument = gql`
    mutation unassignTruck($input: UnassignTruckInput!) {
  unassignTruck(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UnassignTruckGQL extends Apollo.Mutation<UnassignTruckMutation, UnassignTruckMutationVariables> {
    document = UnassignTruckDocument;
    
  }
export const UpdateSequenceBaseDocument = gql`
    mutation updateSequenceBase($input: UpdateSequenceBaseInput!) {
  updateSequenceBase(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSequenceBaseGQL extends Apollo.Mutation<UpdateSequenceBaseMutation, UpdateSequenceBaseMutationVariables> {
    document = UpdateSequenceBaseDocument;
    
  }
export const UpdateSequenceDateDocument = gql`
    mutation updateSequenceDate($input: UpdateSequenceDateInput!) {
  updateSequenceDate(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSequenceDateGQL extends Apollo.Mutation<UpdateSequenceDateMutation, UpdateSequenceDateMutationVariables> {
    document = UpdateSequenceDateDocument;
    
  }
export const UpdateSequenceLogisticsDocument = gql`
    mutation updateSequenceLogistics($input: UpdateSequenceLogisticsInput!) {
  updateSequenceLogistics(input: $input) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSequenceLogisticsGQL extends Apollo.Mutation<UpdateSequenceLogisticsMutation, UpdateSequenceLogisticsMutationVariables> {
    document = UpdateSequenceLogisticsDocument;
    
  }
export const UpdateSequencesOrderDocument = gql`
    mutation updateSequencesOrder($input: [UpdateSequencesOrderInput]!) {
  updateSequencesOrder(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSequencesOrderGQL extends Apollo.Mutation<UpdateSequencesOrderMutation, UpdateSequencesOrderMutationVariables> {
    document = UpdateSequencesOrderDocument;
    
  }
export const UpdateTemplateBaseDocument = gql`
    mutation updateTemplateBase($input: UpdateTemplateBaseInput!) {
  updateTemplateBase(input: $input) {
    ...SequenceTemplateFragment
  }
}
    ${SequenceTemplateFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateBaseGQL extends Apollo.Mutation<UpdateTemplateBaseMutation, UpdateTemplateBaseMutationVariables> {
    document = UpdateTemplateBaseDocument;
    
  }
export const GetFabRequestInfoDocument = gql`
    query getFabRequestInfo($sequence_id: Int!) {
  getFabRequestInfo(sequence_id: $sequence_id) {
    truckCount
    onSiteLocation
    onSiteDate
    comments
    subcontractor
    calculatedHours
    mendixCalculatedHours
    pipeInformation {
      description
      size
      grooves {
        endPreparation
        count
      }
      cuts {
        count
      }
    }
    productInformation {
      coupling {
        field {
          productId
          productDescription
          size
          count
        }
        shop {
          productId
          productDescription
          size
          count
        }
      }
      holeCut {
        productId
        productDescription
        size
        count
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFabRequestInfoGQL extends Apollo.Query<GetFabRequestInfoQuery, GetFabRequestInfoQueryVariables> {
    document = GetFabRequestInfoDocument;
    
  }
export const GetSequenceManagementDataDocument = gql`
    query getSequenceManagementData($proj_id: Int!) {
  projectSelectOptions {
    sequencePipeCoatings {
      coating_type_id
      coating_type
    }
    projectTypes {
      projtypeid
      projtype
      projtypesuffix
    }
    availableTemplateProjTypes {
      projtypeid
      projtype
      projtypesuffix
    }
    transportationBrokers {
      broker_id
      broker_name
      broker_phone
    }
    sequenceTemplates {
      id
      name
      template_id
      project_type {
        projtypeid
        projtype
        projtypesuffix
      }
      sequence_dates {
        sequence_date_type_id
        date_type
      }
    }
    sequenceDates {
      sequence_date_type_id
      date_type
    }
    fabShops {
      fab_shop_id
      fab_shop_name
    }
    fabContacts {
      fab_contact_id
      name
    }
  }
  project(proj_id: $proj_id) {
    ...SequenceProjectDataFragment
  }
}
    ${SequenceProjectDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSequenceManagementDataGQL extends Apollo.Query<GetSequenceManagementDataQuery, GetSequenceManagementDataQueryVariables> {
    document = GetSequenceManagementDataDocument;
    
  }
export const GetSequenceTemplateItemsDocument = gql`
    query getSequenceTemplateItems {
  projectSelectOptions {
    availableTemplateProjTypes {
      projtypeid
      projtype
      projtypesuffix
    }
  }
  getSequenceTemplates {
    id
    name
    template_id
    project_type {
      projtypeid
      projtype
      projtypesuffix
    }
    sequence_dates {
      sequence_date_type_id
      date_type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSequenceTemplateItemsGQL extends Apollo.Query<GetSequenceTemplateItemsQuery, GetSequenceTemplateItemsQueryVariables> {
    document = GetSequenceTemplateItemsDocument;
    
  }
export const GetBimErrorRateDocument = gql`
    query getBimErrorRate($startDate: String!, $endDate: String!) {
  bimErrorRate(startDate: $startDate, endDate: $endDate)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimErrorRateGQL extends Apollo.Query<GetBimErrorRateQuery, GetBimErrorRateQueryVariables> {
    document = GetBimErrorRateDocument;
    
  }
export const GetBimMetricsDataDocument = gql`
    query getBimMetricsData($startDate: String!, $endDate: String!, $coordinators: [Int], $markets: [Int]) {
  bimMetricsData(startDate: $startDate, endDate: $endDate, coordinators: $coordinators, markets: $markets) {
    efficiency
    materialDifference
    projectHoursSold
    projectMaterialSold
    bimTablesData {
      project_number
      project_name
      coordinator
      quoted_drawing_hours
      bim_hours_used
      percent_complete
      percent_date
      efficiency
      total_sold_material
      current_material
      material_difference
      material_difference_number
      missing_pn
      sequence_count
      sequence_errors
      sequence_missing_dates
      sequence_date_value
      sequence_date_type
      amount
      amount_number
      quote_name
      status_name
    }
    upcomingDatesProjects {
      project_number
      project_name
      coordinator
      sequence_name
      sequence_date_value
      sequence_date_type
    }
    coordinatorsInfoList {
      coordinator
      numberOfProjects
      efficiency
      material_difference
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimMetricsDataGQL extends Apollo.Query<GetBimMetricsDataQuery, GetBimMetricsDataQueryVariables> {
    document = GetBimMetricsDataDocument;
    
  }
export const GetBimSelectOptionsDocument = gql`
    query getBimSelectOptions {
  projectSelectOptions {
    markets {
      market_id
      market
    }
    users {
      user_id
      full_name
      cps_group
      status
      group
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBimSelectOptionsGQL extends Apollo.Query<GetBimSelectOptionsQuery, GetBimSelectOptionsQueryVariables> {
    document = GetBimSelectOptionsDocument;
    
  }
export const GetPreBidChartsDocument = gql`
    query getPreBidCharts($startDate: String!, $endDate: String!) {
  preBidCharts(startDate: $startDate, endDate: $endDate) {
    legislativeDaysToQuote
    nonLegislativeDaysToQuote
    allProjectsDaysToQuote
    legislativeHoursToQuote
    nonLegislativeHoursToQuote
    allProjectsHoursToQuote
    departmentTimeToQuote
    collectiveHoursToQuote
    quotesByServiceType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreBidChartsGQL extends Apollo.Query<GetPreBidChartsQuery, GetPreBidChartsQueryVariables> {
    document = GetPreBidChartsDocument;
    
  }
export const GetPreBidKpIsDocument = gql`
    query getPreBidKPIs($startDate: String!, $endDate: String!) {
  preBidKPIs(startDate: $startDate, endDate: $endDate) {
    activeSuccessRate
    modelCompletionSuccessRate
    mtoModelCompletionSuccessRate
    daysStatusIsEP
    averageOfQuotes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPreBidKpIsGQL extends Apollo.Query<GetPreBidKpIsQuery, GetPreBidKpIsQueryVariables> {
    document = GetPreBidKpIsDocument;
    
  }
export const GetPtoCalendarDocument = gql`
    query getPtoCalendar($date: String!) {
  getPtoCalendar(date: $date) {
    ...PtoCalendarDataFragment
  }
}
    ${PtoCalendarDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPtoCalendarGQL extends Apollo.Query<GetPtoCalendarQuery, GetPtoCalendarQueryVariables> {
    document = GetPtoCalendarDocument;
    
  }
export const GetPtoDashboardSelectOptionsDocument = gql`
    query getPtoDashboardSelectOptions {
  projectSelectOptions {
    cpsGroups
    userGroups
    users {
      user_id
      full_name
      cps_group
      group
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPtoDashboardSelectOptionsGQL extends Apollo.Query<GetPtoDashboardSelectOptionsQuery, GetPtoDashboardSelectOptionsQueryVariables> {
    document = GetPtoDashboardSelectOptionsDocument;
    
  }
export const AddVtfrTrainingLogDocument = gql`
    mutation addVtfrTrainingLog($input: AddVtfrTrainingLogInput!) {
  addVtfrTrainingLog(input: $input) {
    ...VtfrTrainingDataFragment
  }
}
    ${VtfrTrainingDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddVtfrTrainingLogGQL extends Apollo.Mutation<AddVtfrTrainingLogMutation, AddVtfrTrainingLogMutationVariables> {
    document = AddVtfrTrainingLogDocument;
    
  }
export const DeleteVtfrTrainingLogDocument = gql`
    mutation deleteVtfrTrainingLog($training_id: Int!) {
  deleteVtfrTrainingLog(training_id: $training_id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVtfrTrainingLogGQL extends Apollo.Mutation<DeleteVtfrTrainingLogMutation, DeleteVtfrTrainingLogMutationVariables> {
    document = DeleteVtfrTrainingLogDocument;
    
  }
export const UpdateVtfrTrainingLogDocument = gql`
    mutation updateVtfrTrainingLog($input: UpdateVtfrTrainingLogInput!) {
  updateVtfrTrainingLog(input: $input) {
    ...VtfrTrainingDataFragment
  }
}
    ${VtfrTrainingDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateVtfrTrainingLogGQL extends Apollo.Mutation<UpdateVtfrTrainingLogMutation, UpdateVtfrTrainingLogMutationVariables> {
    document = UpdateVtfrTrainingLogDocument;
    
  }
export const GetVtfrTrainingDashboardSelectOptionsDocument = gql`
    query getVtfrTrainingDashboardSelectOptions {
  projectSelectOptions {
    presenters {
      user_id
      full_name
    }
    trainingsTypes {
      ...TrainingTypeFragment
    }
    customers {
      customer_id
      customer
      c_type_id
      type
    }
    customerTypes {
      c_type_id
      c_type
    }
    salesmen {
      autoID
      name
      salesregion_name
      salesregion_id
      group_name
      groupID
      division
      divisionID
      metro
      metroID
    }
    currencies {
      currency
    }
    trainingMarkets {
      t_market_id
      t_market
      checked
    }
    markets {
      market_id
      market
    }
  }
}
    ${TrainingTypeFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTrainingDashboardSelectOptionsGQL extends Apollo.Query<GetVtfrTrainingDashboardSelectOptionsQuery, GetVtfrTrainingDashboardSelectOptionsQueryVariables> {
    document = GetVtfrTrainingDashboardSelectOptionsDocument;
    
  }
export const GetVtfrTrainingsCalendarDataDocument = gql`
    query getVtfrTrainingsCalendarData {
  vtfrTrainingsCalendar {
    ...VtfrTrainingDataFragment
  }
}
    ${VtfrTrainingDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTrainingsCalendarDataGQL extends Apollo.Query<GetVtfrTrainingsCalendarDataQuery, GetVtfrTrainingsCalendarDataQueryVariables> {
    document = GetVtfrTrainingsCalendarDataDocument;
    
  }
export const GetVtfrTrainingsMetricsDocument = gql`
    query getVtfrTrainingsMetrics($startDate: String!, $endDate: String!) {
  vtfrTrainingsMetrics(startDate: $startDate, endDate: $endDate) {
    trainingsTypes {
      ...TrainingTypeFragment
    }
    metrics {
      ...VtfrTrainingsMetricsFragment
    }
  }
}
    ${TrainingTypeFragmentFragmentDoc}
${VtfrTrainingsMetricsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTrainingsMetricsGQL extends Apollo.Query<GetVtfrTrainingsMetricsQuery, GetVtfrTrainingsMetricsQueryVariables> {
    document = GetVtfrTrainingsMetricsDocument;
    
  }
export const GetVtfrTrainingsTableDataDocument = gql`
    query getVtfrTrainingsTableData($startDate: String!, $endDate: String!) {
  vtfrTrainingsTable(startDate: $startDate, endDate: $endDate) {
    ...VtfrTrainingDataFragment
  }
}
    ${VtfrTrainingDataFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTrainingsTableDataGQL extends Apollo.Query<GetVtfrTrainingsTableDataQuery, GetVtfrTrainingsTableDataQueryVariables> {
    document = GetVtfrTrainingsTableDataDocument;
    
  }
export const GetVdcHighlightsDataDocument = gql`
    query getVdcHighlightsData($year: Int!) {
  getVdcHighlightsData(year: $year) {
    service
    location
    previous_count
    previous_hours
    previous_ytd_count
    previous_ytd_hours
    ytd_count
    ytd_hours
    count_percentage_change
    hours_percentage_change
    count_forecast
    hours_forecast
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVdcHighlightsDataGQL extends Apollo.Query<GetVdcHighlightsDataQuery, GetVdcHighlightsDataQueryVariables> {
    document = GetVdcHighlightsDataDocument;
    
  }
export const GetVicToolSalesByQuarterDocument = gql`
    query getVicToolSalesByQuarter($toolName: String!) {
  vicToolSalesByQuarter(toolName: $toolName)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVicToolSalesByQuarterGQL extends Apollo.Query<GetVicToolSalesByQuarterQuery, GetVicToolSalesByQuarterQueryVariables> {
    document = GetVicToolSalesByQuarterDocument;
    
  }
export const GetVicToolSalesByYearDocument = gql`
    query getVicToolSalesByYear($toolName: String!) {
  vicToolSalesByYear(toolName: $toolName)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVicToolSalesByYearGQL extends Apollo.Query<GetVicToolSalesByYearQuery, GetVicToolSalesByYearQueryVariables> {
    document = GetVicToolSalesByYearDocument;
    
  }
export const GetVicToolSalesTableDocument = gql`
    query getVicToolSalesTable($toolName: String!) {
  vicToolSalesTable(toolName: $toolName) {
    order_id
    order_date
    customer
    city
    state
    country
    customer_contact
    sales_rep
    vicToolVersion2030
    vicToolVersion2029
    vicToolVersion2028
    vicToolVersion2027
    vicToolVersion2026
    vicToolVersion2025
    vicToolVersion2024
    vicToolVersion2023
    vicToolVersion2022
    vicToolVersion2021
    vicToolVersion2020
    vicToolVersion2019
    vicToolVersion2018
    vicToolVersion2017
    vicToolVersion2016
    vicToolVersion2015
    vicToolVersion2014
    vicToolVersionv14
    vicToolVersionv15
    vicToolVersionv16
    vicToolVersionv17
    vicToolVersionv18
    vicToolVersionv19
    vicToolVersionv20
    vicToolVersionv21
    vicToolVersionv22
    vicToolVersionv23
    vicToolVersionv24
    vicToolVersionv25
    vicToolVersionv26
    vicToolVersionv27
    vicToolVersionv28
    vicToolVersionv29
    vicToolVersionv30
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVicToolSalesTableGQL extends Apollo.Query<GetVicToolSalesTableQuery, GetVicToolSalesTableQueryVariables> {
    document = GetVicToolSalesTableDocument;
    
  }
export const GetVicToolTotalSalesDocument = gql`
    query getVicToolTotalSales($startDate: String!, $endDate: String!, $toolName: String!) {
  vicToolTotalSales(startDate: $startDate, endDate: $endDate, toolName: $toolName) {
    totalSales
    totalCustomers
    averageLicenses
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVicToolTotalSalesGQL extends Apollo.Query<GetVicToolTotalSalesQuery, GetVicToolTotalSalesQueryVariables> {
    document = GetVicToolTotalSalesDocument;
    
  }
export const GetVicToolsVersionsDocument = gql`
    query GetVicToolsVersions($toolName: String!) {
  vicToolsVersions(toolName: $toolName) {
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVicToolsVersionsGQL extends Apollo.Query<GetVicToolsVersionsQuery, GetVicToolsVersionsQueryVariables> {
    document = GetVicToolsVersionsDocument;
    
  }
export const GetVtfrDownloadsByQuarterDocument = gql`
    query getVtfrDownloadsByQuarter {
  vtfrDownloadsByQuarter
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrDownloadsByQuarterGQL extends Apollo.Query<GetVtfrDownloadsByQuarterQuery, GetVtfrDownloadsByQuarterQueryVariables> {
    document = GetVtfrDownloadsByQuarterDocument;
    
  }
export const GetVtfrRevenueByYearDocument = gql`
    query getVtfrRevenueByYear($toolName: String!) {
  vtfrRevenueByYear(toolName: $toolName)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrRevenueByYearGQL extends Apollo.Query<GetVtfrRevenueByYearQuery, GetVtfrRevenueByYearQueryVariables> {
    document = GetVtfrRevenueByYearDocument;
    
  }
export const GetVtfrTotalDownloadsDocument = gql`
    query getVtfrTotalDownloads($startDate: String!, $endDate: String!) {
  vtfrTotalDownloads(startDate: $startDate, endDate: $endDate) {
    totalVTFRDownloads
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTotalDownloadsGQL extends Apollo.Query<GetVtfrTotalDownloadsQuery, GetVtfrTotalDownloadsQueryVariables> {
    document = GetVtfrTotalDownloadsDocument;
    
  }
export const GetVtfrTotalRevenueDocument = gql`
    query getVtfrTotalRevenue($startDate: String!, $endDate: String!) {
  vtfrTotalRevenue(startDate: $startDate, endDate: $endDate) {
    totalExpectedRevenue
    discountAverage
    totalRevenue
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTotalRevenueGQL extends Apollo.Query<GetVtfrTotalRevenueQuery, GetVtfrTotalRevenueQueryVariables> {
    document = GetVtfrTotalRevenueDocument;
    
  }
export const GetVtfrTotalWebPresentationsDocument = gql`
    query getVtfrTotalWebPresentations {
  vtfrWebPresentations {
    sessions
    hours
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVtfrTotalWebPresentationsGQL extends Apollo.Query<GetVtfrTotalWebPresentationsQuery, GetVtfrTotalWebPresentationsQueryVariables> {
    document = GetVtfrTotalWebPresentationsDocument;
    
  }
export const GetVdcTvDataDocument = gql`
    query getVdcTvData {
  getVdcTvData {
    upcomingDatesProjects {
      project_number
      project_name
      sequence_name
      sequence_date_value
    }
    materialDifference {
      project_number
      project_name
      material_difference_dollars
      material_difference
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVdcTvDataGQL extends Apollo.Query<GetVdcTvDataQuery, GetVdcTvDataQueryVariables> {
    document = GetVdcTvDataDocument;
    
  }
export const UpdateRecentUserProjectsDocument = gql`
    mutation updateRecentUserProjects($input: UpdateRecentUserProjectsInput!) {
  updateRecentUserProjects(input: $input) {
    ...UserRecentProjectsFragment
  }
}
    ${UserRecentProjectsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRecentUserProjectsGQL extends Apollo.Mutation<UpdateRecentUserProjectsMutation, UpdateRecentUserProjectsMutationVariables> {
    document = UpdateRecentUserProjectsDocument;
    
  }
export const RecentUserProjectsDocument = gql`
    query recentUserProjects($user_id: Int!) {
  recentUserProjects(user_id: $user_id) {
    ...UserRecentProjectsFragment
  }
}
    ${UserRecentProjectsFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RecentUserProjectsGQL extends Apollo.Query<RecentUserProjectsQuery, RecentUserProjectsQueryVariables> {
    document = RecentUserProjectsDocument;
    
  }
export const GetAllUsersGroupedByUserGroupDocument = gql`
    query getAllUsersGroupedByUserGroup($input: GetAllUsersInput) {
  getAllUsersGroupedByUserGroup(input: $input) {
    group
    users {
      cps_group
      currency
      date_added
      email_address
      employeeid
      first_name
      full_name
      group
      last_name
      picture
      presenter
      region_id
      resource_color
      status
      supervisor_id
      user_id
      user_type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUsersGroupedByUserGroupGQL extends Apollo.Query<GetAllUsersGroupedByUserGroupQuery, GetAllUsersGroupedByUserGroupQueryVariables> {
    document = GetAllUsersGroupedByUserGroupDocument;
    
  }